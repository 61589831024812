import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Typography, InputLabel, MenuItem, OutlinedInput, Select
} from '@material-ui/core';
import { getScene } from '../../store/selectors/scenesSelectors';
import txns from '../../store/actions/TxnManager';
import { updateAction } from '../../store/actions/elementActions';
import ActionSchemaTypes from '../../constants/ActionSchemaTypes';
import SchemaContainersByType from '../../constants/SchemaContainersByType';

const SCHEMA_VISUAL_STYLE = 'iux.style';

const visualLanguageOptions = [
  {
    displayName: 'Default',
    value: 'default'
  },
  {
    displayName: 'Web',
    value: 'web'
  }
]

/**
 * Settings for configuring IUX visuals
 */
class IUXSettings extends React.Component {

  render() {
    const {
      scene: { id: sceneId, elements: { schema = {} } },
      onUpdateSceneSetting
    } = this.props;

    const strings = schema[SchemaContainersByType.string] || {};

    const currentIndex = Math.max(0, visualLanguageOptions.findIndex(option => option.value === strings[SCHEMA_VISUAL_STYLE]));

    const onChange = (index) => {
      onUpdateSceneSetting(sceneId, ActionSchemaTypes.STRING, SCHEMA_VISUAL_STYLE, visualLanguageOptions[index].value)
    }

    return (
      <div style={{ width: '100%' }}>
        <InputLabel variant='outlined'>Visual Style</InputLabel>
        <Select
          fullWidth
          input={<OutlinedInput />}
          value={currentIndex}
          onChange={({ target: { value: newValue } }) => onChange(newValue)}
          variant='outlined'
        >
          {visualLanguageOptions.map(({ displayName }, i) => (
            <MenuItem key={i} value={i}>
              {displayName}
            </MenuItem>
          ))}
        </Select>
        <Typography variant='caption'>
          Note: Changes will be applied after reloading the editor.
        </Typography>
      </div>
    );
  }
}

IUXSettings.propTypes = {
  scene: PropTypes.object.isRequired,
  onUpdateSceneSetting: PropTypes.func.isRequired
};

const mapStoreToProps = store => ({
  scene: getScene(store)
});

const mapDispatchToProps = dispatch => ({
  onUpdateSceneSetting: (sceneId, actionType, key, value) => {
    txns.request(sceneId, updateAction('root', actionType, key, value, value));
  }
});

export default connect(mapStoreToProps, mapDispatchToProps)(IUXSettings);
