export const createTag = (item, { appName, appId }) => {
  const { name: itemName } = item;
  switch (itemName) {
    case appId:
    case appName:
    case '__app__':
      return {
        ...item,
        name: appName
      };

    case '__user__':
      return {
        ...item,
        name: 'User'
      };

    case '__public__':
      return {
        ...item,
        name: 'Public'
      };

    default:
      return {
        name: (item.name || '').replace('__app__.', ''),
        url: item.url === appId ? '__app__' : item.url
      };
  }
};
