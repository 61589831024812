const globalStyles = theme => ({
  '@global': {
    '.scrollable-y, .ace_scrollbar-v': {
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        width: 7
      },
      '&::-webkit-scrollbar-thumb:vertical': {
        borderRadius: 4,
        backgroundColor: theme.palette.primary.main
      }
    },
    '.scrollable-both, .ace_scrollbar-h': {
      overflow: 'auto !important',
      '&::-webkit-scrollbar-thumb:vertical': {
        borderRadius: 4,
        backgroundColor: theme.palette.primary.main
      },
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        width: 7,
        height: 7
      },
      '&::-webkit-scrollbar-thumb:horizontal': {
        borderRadius: 4,
        backgroundColor: theme.palette.primary.main
      },
      '&::-webkit-scrollbar-corner': {
        background: 'transparent'
      }
    }
  }
});

export default globalStyles;
