export const REMOTE_ASSIST_TERMINATE = "assist.terminate";
export const assistTerminate = _ => ({
   type: REMOTE_ASSIST_TERMINATE
});

export const REMOTE_ASSIST_CONNECTING = "assist.connecting";
export const assistConnect = _ => ({
    type: REMOTE_ASSIST_CONNECTING
});

export const REMOTE_ASSIST_LEFT = "assist.left";
export const assistLeft = _ => ({
    type: REMOTE_ASSIST_LEFT
});

export const REMOTE_ASSIST_LOG = "assist.log";
export const assistLog = payload => ({
    type: REMOTE_ASSIST_LOG,
    payload
});

export const REMOTE_ASSIST_LOG_CLEAR = "assist.log.clear"
export const assistLogClear = _ => ({
    type: REMOTE_ASSIST_LOG_CLEAR
});
