import {
  Box,
  Typography,
  ExpansionPanel as Accordion,
  ExpansionPanelSummary as AccordionSummary,
  ExpansionPanelDetails as AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { getAppIsReadOnly } from '../../store/selectors/appSelectors';
import { getScene } from '../../store/selectors/scenesSelectors';
import { updateAction } from '../../store/actions/elementActions';
import ActionSchemaTypes from '../../constants/ActionSchemaTypes';
import CheckboxField from '../material-ui/CheckboxField';
import txns from '../../store/actions/TxnManager';
import QualitySettings from './QualitySettings';
import PostProcessingSettings from './PostProcessingSettings';
import IUXSettings from './IUXSettings';

const KEY_LIVEUPDATES = 'trellis.islive';
const KEY_WORLDANCHOR_ENABLED = 'worldanchor.enabled';
const KEY_MESH_OCCLUSION = 'occlusion.mesh';
const KEY_HAND_OCCLUSION = 'occlusion.hand';

const DEFAULT_LIVEUPDATES = true;
const DEFAULT_ANCHORS = null;
const DEFAULT_MESH_OCCLUSION = true;
const DEFAULT_HAND_OCCLUSION = false;

/**
 * Settings and their controls available for the current scene.
 */
const SceneSettings = (props) => {
  const {
    isReadOnly,
    scene: {
      id: sceneId,
      elements: {
        schema: {
          bools: {
            [KEY_LIVEUPDATES]: liveUpdates = DEFAULT_LIVEUPDATES,
            [KEY_WORLDANCHOR_ENABLED]: worldAnchorsEnabled = DEFAULT_ANCHORS,
            [KEY_MESH_OCCLUSION]: meshOcclusion = DEFAULT_MESH_OCCLUSION,
            [KEY_HAND_OCCLUSION]: handOcclusion = DEFAULT_HAND_OCCLUSION
          } = {}
        } = {}
      } = {},
      qualitySettings
    } = {},
    onUpdateElementSchema
  } = props;

  const sectionHeader = (title) => (
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography variant="h6">{title}</Typography>
    </AccordionSummary>
  );

  return (
    <Box p={1} overflow="auto" className={'scrollable-y'}>
      {isReadOnly && <Typography>READ ONLY</Typography>}
      <Typography variant="h5" gutterBottom>
        Scene Settings
      </Typography>

      <form>
        <Accordion>
          {sectionHeader('Connection')}
          <AccordionDetails>
            <CheckboxField
              label="Live Updates"
              value={liveUpdates}
              onChange={({ target: { checked } }) => {
                onUpdateElementSchema({
                  sceneId,
                  schemaType: ActionSchemaTypes.BOOL,
                  key: KEY_LIVEUPDATES,
                  value: checked,
                  defaultValue: DEFAULT_LIVEUPDATES
                });
              }}
            />
          </AccordionDetails>
        </Accordion>

        {worldAnchorsEnabled !== null && <Accordion>
          {sectionHeader('Anchoring (Legacy)')}
          <AccordionDetails>
            <CheckboxField
              label="Auto World Anchoring"
              value={worldAnchorsEnabled}
              onChange={({ target: { checked } }) => {
                onUpdateElementSchema({
                  sceneId,
                  schemaType: ActionSchemaTypes.BOOL,
                  key: KEY_WORLDANCHOR_ENABLED,
                  value: checked,
                  defaultValue: DEFAULT_ANCHORS
                });
              }}
            />
          </AccordionDetails>
        </Accordion>}

        <Accordion>
          {sectionHeader('Occlusion')}
          <AccordionDetails>
            <Box
              display="flex"
              flexDirection="column"
            >
              <CheckboxField
                label="Space Occlusion"
                value={meshOcclusion}
                onChange={({ target: { checked } }) => {
                  onUpdateElementSchema({
                    sceneId,
                    schemaType: ActionSchemaTypes.BOOL,
                    key: KEY_MESH_OCCLUSION,
                    value: checked,
                    defaultValue: DEFAULT_MESH_OCCLUSION
                  });
                }}
              />
              <CheckboxField
                label="Hand Occlusion"
                value={handOcclusion}
                onChange={({ target: { checked } }) => {
                  onUpdateElementSchema({
                    sceneId,
                    schemaType: ActionSchemaTypes.BOOL,
                    key: KEY_HAND_OCCLUSION,
                    value: checked,
                    defaultValue: DEFAULT_HAND_OCCLUSION
                  });
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          {sectionHeader('Post-Processing')}
          <AccordionDetails>
            <PostProcessingSettings />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          {sectionHeader('IUX')}
          <AccordionDetails>
            <IUXSettings />
          </AccordionDetails>
        </Accordion>

        {qualitySettings.settings && <Accordion>
          {sectionHeader('Quality Settings')}
          <AccordionDetails>
            <QualitySettings />
          </AccordionDetails>
        </Accordion>}
      </form>
    </Box>
  );
};

SceneSettings.propTypes = {
  scene: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  onUpdateElementSchema: PropTypes.func.isRequired
};

export default connect(
  state => ({
    scene: getScene(state),
    isReadOnly: getAppIsReadOnly(state)
  }),
  dispatch => ({
    onUpdateElementSchema: ({
      sceneId, schemaType, key, value, defaultValue
    }) => txns.request(sceneId, updateAction('root', schemaType, key, value, defaultValue))
  })
)(SceneSettings);
