import {
 Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select
} from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';

import { rgbToHex, hexToRgb } from '../../../util/util';
import { updateAction } from '../../../store/actions/elementActions';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import ColorPickerField from '../../material-ui/ColorPickerField';
import DraggerInput from '../MuiDraggerInput';
import InspectableIdentity, { elementToInspectable } from '../common/MuiInspectableIdentity';
import InspectorInterface from '../common/InspectorInterface';
import Transform from '../MuiTransform';
import txns from '../../../store/actions/TxnManager';

const useStyles = makeStyles(theme => ({
  inputFieldsContainer: {
    marginTop: theme.spacing(2),
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  }
}));

/**
 * Custom inspector for light elements.
 */
const LightElementInspector = ({
  element,
  scene: { id: sceneId },
  updateElementFloat,
  previewUpdateElementFloat,
  onUpdateElement,
  updateElementCol,
  updateElementString
}) => {
  const classes = useStyles();
  // element data
  const defaultType = 'Directional';
  const defaultColor = {
    r: 1,
    g: 1,
    b: 1,
    a: 1
  };
  const defaultIntensity = 1.0;
  const defaultShadows = 'None';
  const defaultPointRange = 1.0;
  const defaultSpotRange = 1.0;
  const defaultSpotAngle = 30.0;

  const {
    id: elementId,
    schema: {
      strings: { lightType: elementType = defaultType, shadows: elementShadows = defaultShadows } = {},
      floats: {
        intensity: elementIntensity = defaultIntensity,
        'point.range': elementPointRange = defaultPointRange,
        'spot.range': elementSpotRange = defaultSpotRange,
        'spot.angle': elementSpotAngle = defaultSpotAngle
      } = {},
      colors: { color: elementColor = defaultColor }
    } = {}
  } = element;

  return (
    <Box p={1}>
      <InspectableIdentity
        inspectable={element}
        inspectableTranslator={elementToInspectable}
        onUpdate={onUpdateElement}
      />

      <Transform hideTitle element={element} sceneId={sceneId} />

      {/* Settings */}
      <form className={classes.inputFieldsContainer}>
        <FormControl fullWidth>
          <InputLabel variant="outlined">Type</InputLabel>
          <Select
            input={<OutlinedInput />}
            value={elementType}
            onChange={evt => updateElementString(sceneId, elementId, 'lightType', evt.target.value)}
            variant="outlined"
          >
            <MenuItem value="Directional">Directional</MenuItem>
            <MenuItem value="Point">Point</MenuItem>
            <MenuItem value="Spot">Spot</MenuItem>
          </Select>
        </FormControl>

        <ColorPickerField
          label="Color"
          value={rgbToHex(elementColor)}
          onChange={color => updateElementCol(sceneId, elementId, 'color', hexToRgb(color))}
        />

        <DraggerInput
          fullWidth
          inputProps={{
            margin: ''
          }}
          value={elementIntensity}
          scale={1}
          onPreview={value => previewUpdateElementFloat(sceneId, elementId, 'intensity', value)}
          onChange={value => updateElementFloat(sceneId, elementId, 'intensity', value)}
        >
          <span>Intensity</span>
        </DraggerInput>

        <FormControl fullWidth>
          <InputLabel variant="outlined">Shadows</InputLabel>
          <Select
            input={<OutlinedInput />}
            value={elementShadows}
            onChange={evt => updateElementString(sceneId, elementId, 'shadows', evt.target.value)}
            variant="outlined"
          >
            <MenuItem value="None">None</MenuItem>
            <MenuItem value="Hard">Hard</MenuItem>
            <MenuItem value="Soft">Soft</MenuItem>
          </Select>
        </FormControl>

        {elementType === 'Point' && (
          <DraggerInput
            fullWidth
            inputProps={{
              margin: ''
            }}
            value={elementPointRange}
            scale={1}
            onPreview={value => previewUpdateElementFloat(sceneId, elementId, 'point.range', value)}
            onChange={value => updateElementFloat(sceneId, elementId, 'point.range', value)}
          >
            <span>Range</span>
          </DraggerInput>
        )}

        {elementType === 'Spot' && (
          <DraggerInput
            fullWidth
            inputProps={{
              margin: ''
            }}
            value={elementSpotRange}
            scale={1}
            onPreview={value => previewUpdateElementFloat(sceneId, elementId, 'spot.range', value)}
            onChange={value => updateElementFloat(sceneId, elementId, 'spot.range', value)}
          >
            <span>Range</span>
          </DraggerInput>
        )}

        {elementType === 'Spot' && (
          <DraggerInput
            fullWidth
            inputProps={{
              margin: ''
            }}
            value={elementSpotAngle}
            scale={1}
            onPreview={value => previewUpdateElementFloat(sceneId, elementId, 'spot.angle', value)}
            onChange={value => updateElementFloat(sceneId, elementId, 'spot.angle', value)}
          >
            <span>Spot Angle</span>
          </DraggerInput>
        )}
      </form>
    </Box>
  );
};

LightElementInspector.propTypes = {
  ...InspectorInterface,
  updateElementFloat: PropTypes.func,
  previewUpdateElementFloat: PropTypes.func,
  updateElementString: PropTypes.func,
  updateElementCol: PropTypes.func
};

export default withRouter(
  connect(
    () => ({}),
    () => ({
      /**
       * Updates an element string value.
       */
      updateElementString: (sceneId, elementId, key, value) => {
        txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.STRING, key, value));
      },

      /**
       * Updates an element float value.
       */
      updateElementFloat: (sceneId, elementId, key, value) => {
        txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.FLOAT, key, value));
      },

      /**
       * Updates an element vec value.
       */
      updateElementCol: (sceneId, elementId, key, value) => {
        txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.COL4, key, value));
      },

      /**
       * Sends actions straight to player rather than authenticating them.
       */
      previewUpdateElementFloat: (sceneId, elementId, key, value) => {
        txns.preview(sceneId, updateAction(elementId, ActionSchemaTypes.FLOAT, key, value));
      }
    })
  )(LightElementInspector)
);
