export const SIGNUP_WITH_PAYMENT = 'signup.payment';
// eslint-disable-next-line no-unused-vars
export const signupwithpayment = ({ email, password, handle, token }) => dispatch => new Promise(
  (res, rej) => fetch(
    `${window.env.stargazerBaseUrl}/v1/subscription`,
    {
      method: 'POST',
      body: JSON.stringify({
        token,
        email,
        password,
        handle,
        agreedToEula: true
      })
    }
  )
    .then(response => response.json())
    .then(({ success, error, body = {} }) => {
      if (!success) {
        rej(error || 'An unknown signup error occurred.');
      } else {
        res(body);
      }
    })
    .catch(rej));

export const SIGNUP = 'signup';
// eslint-disable-next-line no-unused-vars
//REMOVED the 'with license' part, so is currently free sign-up
export const signup = ({ email, password, handle }) => dispatch => new Promise(
  (res, rej) => fetch(
    `${window.env.stargazerBaseUrl}/v1/users/create`,
    {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
        handle,
        agreedToEula: true
      })
    }
  )
    .then(response => response.json())
      .then(({ success, error, body: { user } = {} }) => {
        if (!success) {
          rej(error || 'An unknown signup error occurred.');
        } else {
           res(user);
          // const { mixpanel } = window;
          // if (mixpanel) {
          //   mixpanel.track('app.signup.email', {'Device': 'Web'});
          // }
        }
      })
      .catch(rej)
    .then(() => {
        fetch(
            `${window.env.stargazerBaseUrl}/v1/users/crm/createContact`,
            {
                method: 'POST',
                body: JSON.stringify({
                    email
                })
            })
            .then(response => response.json())
            .then((res) => {
                const contactId = res.body.contactId
                fetch(
                    `${window.env.stargazerBaseUrl}/v1/users/crm/updateContact`,
                    {
                        method: 'POST',
                        body: JSON.stringify({
                            contactId
                        })
                    })
            })}));
