import { ADD_PROCESSING_ASSET, ADD_UPLOADING_ASSET, REMOVE_PROCESSING_ASSET } from '../../actions/assetActions';
import LibraryTypes from '../../../constants/LibraryTypes';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_UPLOADING_ASSET: {
      const { asset } = action;
      return {
        ...state,
        [asset.uploadId]: {
          ...asset,
          name: 'Uploading...',
          tags: `${asset.tags},${LibraryTypes.UPLOADING}`
        }
      };
    }

    case ADD_PROCESSING_ASSET: {
      const { [action.asset.uploadId]: removedAsset, ...newState } = state;
      return {
        ...newState,
        [action.asset.id]: {
          ...action.asset,
          name: 'Processing...',
          tags: `${removedAsset.tags},${LibraryTypes.UPLOADING}`
        }
      };
    }

    case REMOVE_PROCESSING_ASSET: {
      const { assetId } = action;
      const { [assetId]: removedAsset, ...newState } = state;
      return newState;
    }

    default: {
      return state;
    }
  }
};
