/**
 * Allows changing the context options.
 */
export const SET_CONTEXT = 'debug.setcontext';
export const setcontext = options => ({
  type: SET_CONTEXT,
  options
});

/**
 * Allows clearing app data.
 */
export const CLEARDATA = 'debug.cleardata';
export const setcleardata = value => ({
  type: CLEARDATA,
  value
});
