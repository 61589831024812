// TODO Import this instead of putting it in the HTML.
import { AsyncStatus } from '@enklu/server-api';
import Fuse from 'fuse.js';
import React from 'react';

import { log } from './log';
import ElementTypes from '../constants/ElementTypes';

export const getInitialsFromName = (name) => {
  let initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  return initials;
};

/**
 * Generates UUIDs that are v4 compliant and "good enough".
 */
export const uuidv4 = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

// ucs-2 string to base64 encoded ascii
export const utoa = str => window.btoa(unescape(encodeURIComponent(str)));

/**
 * Given a hierarchy of nodes of the following structure, this utility will find a node by its contentId.
 * node: {
 * 	contentId: ''
 * 	children: []
 * }
 *
 * @param {node} startNode - the node in which to begin the search
 * @param {string} contentId - the contentId we are looking for
 * @returns {node}
 */
export const findNodeById = ({ startNode, contentId }) => {
  if (startNode.contentId === contentId) {
    return startNode;
  }

  for (let i = 0; i < startNode.children.length; i++) {
    const child = startNode.children[i];
    const value = findNodeById({
      startNode: child,
      contentId
    });
    if (value) {
      return value;
    }
  }
};

/**
 * Finds a schema value on this node or an ancestor and returns the node if found.
 * @param node
 * @param type - the schema type (e.g. 'bools')
 * @param name - the name of the value sought
 * @param value - the value we're looking for
 * @returns the node with the specified value, or null if not found.
 */
export const findAncestorSchema = (node, type, name, value) => {
  const { schema = {} } = node;
  const { [type]: schemaObj = {} } = schema;
  const { [name]: testValue } = schemaObj;
  if (testValue === value) {
    return node;
  }

  if (node.parent) {
    return findAncestorSchema(node.parent, type, name, value);
  }

  return null;
};

/**
 * Given a hierarchy of nodes of the following structure, this utility will find a node by its path. It will also
 * remove the node if doRemove is set to true.
 *
 * node: {
 * 	children: []
 * }
 *
 * @param {node} startNode - the node in which to begin the search
 * @param {array} path - an array of indices, e.g. [0, 2, 4]
 * @param {boolean} doRemove - whether the utility should remove the node once found
 * @returns {node}
 */
export const findNodeByPath = ({ startNode, path, doRemove = false }) => path.reduce(
    (currentNode, nodeIndex, pathIndex, path) => (doRemove && pathIndex === path.length - 1
        ? currentNode.children.splice(nodeIndex, 1)[0]
        : currentNode.children[nodeIndex]),
    startNode
  );

/**
 * Checks for required parameters.
 *
 * @param {object} obj - the object to check
 * @param {array of String} requiredParams - a list of params it should contain
 */
export const hasParams = ({ obj, requiredParams }) => {
  // Use a variable so we can get every error message instead of just the first (with a return).
  let okay = true;
  requiredParams.forEach((paramName) => {
    if (!obj.hasOwnProperty(paramName)) {
      log.error(`Missing required parameter '${paramName}'.`);
      okay = false;
    }
  });

  return okay;
};

/**
 * Takes an array of items and returns an array of tags.
 *
 * @param items {array} - An array of items, each with a comma-delimited string property called `tags`.
 * @returns {array}    - The list of tags, in form {id, text}
 */
export const getTags = (items) => {
  const tags = [];
  const tagLookup = {};

  for (const item of items) {
    const itemTags = (item.tags || '').split(',');
    for (const tag of itemTags) {
      if (!tag || tag === 'none' || tagLookup.hasOwnProperty(tag)) {
        continue;
      }

      tagLookup[tag] = true;
      tags.push(tag);
    }
  }

  return tags;
};

/**
 * Reduces set down to unique set, using result with highest score.
 * Assumes that each item has a numeric `score` property, but uniqueness is determined with `uniqueField` and `mapFunc`.
 *
 * @param arguments.items {array} - Array of items, each of which contains a 'score' property
 * @param arguments.mapFunc {function} - A function that takes an item and returns the nested item that should be unique
 * @param arguments.uniqueField {string} - A field that should be unique per item
 */
export const getUniqueRecords = ({ items, mapFunc = item => item, uniqueField = 'id' }) => {
  const itemsByUniqueField = {};
  const unique = [];

  items.forEach((item) => {
    const { score: itemScore = 0 } = item;

    const { [uniqueField]: uniqueFieldValue } = mapFunc(item);
    const prevItem = itemsByUniqueField[uniqueFieldValue];

    if (prevItem) {
      const { score: prevItemScore = 0 } = prevItem;
      itemsByUniqueField[uniqueFieldValue] = itemScore > prevItemScore ? item : prevItem;
    } else {
      itemsByUniqueField[uniqueFieldValue] = item;
    }
  });

  for (const uniqueId in itemsByUniqueField) {
    unique.push(itemsByUniqueField[uniqueId]);
  }

  return unique;
};

/**
 * Takes the intersection of two sets. If there are multiple values, takes the one with highest score.
 * Assumes that each item has a numeric `score` property, but equality comparisons are done using the optional
 * `mapFunc` argument. This allows for e.g. a wrapper to be used if necessary.
 *
 * NOTE This doesn't eliminate duplicates in `setA`.
 * TODO This should take an arbitrary number of sets.
 */
export const getIntersection = ({ setA, setB, mapFunc = item => item }) => {
  const intersection = [];

  setA.forEach((itemA) => {
    const mappedItemA = mapFunc(itemA);
    const itemB = setB.find(item => mapFunc(item) === mappedItemA);

    if (itemB) {
      const { score: scoreA = 0 } = itemA;
      const { score: scoreB = 0 } = itemB;

      intersection.push(scoreA > scoreB ? itemA : itemB);
    }
  });

  return intersection;
};

/**
 * This function filters on both tags and a filter string, and returns the intersection of the two sets.
 *
 * @param {array[]} items - the items to be filtered
 * @param {string[]} tags - the tags on which to filter
 * @param {string} filterString - the string on which to filter
 * @returns {array[]} - the filtered items
 */
export const filter = ({ items, tags = [], filterString = '' }) => {
  if (!tags.length && !filterString.length) {
    return items;
  }

  const searchOptions = {
    shouldSort: true,
    threshold: 0.4,
    distance: 100,
    includeScore: true,
    keys: ['name', 'description', 'tags']
  };

  let filteredItems = [];
  let tagFilteredItems = [];
  let stringFilteredItems = [];
  const fuse = new Fuse(items, searchOptions);

  // Fuse wraps items so it can put a score on them, so we need to unwrap any time we manipulate them.
  const mapFunc = wrappedItem => wrappedItem.item;

  // Filter on tags first.
  if (tags.length) {
    // search on each tag
    // TODO Do we really need { id, text } for tags? I don't like this dependency on another library.
    tags.forEach(({ text: tag }, i) => {
      tagFilteredItems = i === 0
          ? fuse.search(tag)
          : getIntersection({
              setA: tagFilteredItems,
              setB: fuse.search(tag),
              mapFunc
            });
    });
  }

  // Now filter on the filter string.
  if (filterString.length) {
    stringFilteredItems = fuse.search(filterString);
  }

  // Now combine the two sets of results.
  filteredItems = tags.length && filterString.length
      ? getIntersection({
          setA: tagFilteredItems,
          setB: stringFilteredItems,
          mapFunc
        })
      : tagFilteredItems.concat(stringFilteredItems);

  // Remove duplicates.
  filteredItems = getUniqueRecords({
    items: filteredItems,
    mapFunc
  });

  // TODO This is the worst. There has to be a better way to keep the score around.
  return filteredItems.map(mapFunc);
};

/**
 * Checks for equality in all elements of two arrays, including order.
 *
 * @param {array[]} lhs - An array for comparision.
 * @param {array[]} rhs - An array for comparision.
 * @returns {bool} true if the arrays are considered equal.
 */
export const areArraysShallowEqual = (lhs, rhs) => {
  if (lhs === rhs) {
    return true;
  }

  if (!(Array.isArray(lhs) && Array.isArray(rhs))) {
    return false;
  }

  if (lhs === null || rhs === null) {
    return false;
  }

  if (lhs.length != rhs.length) {
    return false;
  }

  for (const i in lhs) {
    if (lhs[i] !== rhs[i]) {
      return false;
    }
  }

  return true;
};

const componentToHex = (c) => {
  const hex = (c * 255.0).toString(16);
  return hex.length == 1 ? `0${hex}` : hex;
};

export const rgbToHex = ({ r, g, b }) => `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16) / 255.0,
        g: parseInt(result[2], 16) / 255.0,
        b: parseInt(result[3], 16) / 255.0
      }
    : {
        r: 0,
        g: 0,
        b: 0
      };
};

export const rgbToRgba = (rgb, a) => `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${a})`;

/**
 * Creates a category for use in the UI.
 */
export const newCategory = (name, url) => ({
  name: name.charAt(0).toUpperCase() + name.slice(1),
  url: url || name.toLowerCase()
});

export const newAsset = ({
  crc,
  createdAt,
  description,
  id,
  name = 'NONE',
  owner,
  stats,
  status,
  tags,
  type,
  updatedAt,
  uri,
  uriThumb,
  version,
  uploadId = -1
}) => ({
  uploadId, // Not from the server; only for files we need to keep track of until they are processed.

  crc,
  createdAt,
  description,
  id,
  name,
  owner,
  stats, // {}
  status,
  tags,
  type,
  updatedAt,
  uri,
  uriThumb,
  version
});

/**
 * This is a distillation of a large table of rules for allowing/disallowing parent-child relationships.
 * @see ElementTypes
 *
 * @param parent {object} - An object with {type, parent} properties.
 * @param childType {object} - An object with a {type} property.
 * @returns {boolean} - Whether the relationship is allowed.
 */
export const isValidRelationship = (parent, child) => {
  const { type: parentType } = parent;
  const { type: childType } = child;
  let isLegit = true;

  // Anything can go in a GROUP.
  if (parentType !== ElementTypes.GROUP) {
    // These types can _only_ go in GROUPs.
    if ([ElementTypes.WORLD_ANCHOR, ElementTypes.QR_ANCHOR].includes(childType)) {
      isLegit = false;
    } else if (childType === ElementTypes.FLOAT) {
      // FLOATs are a little looser.
      isLegit = ![ElementTypes.WORLD_ANCHOR, ElementTypes.FLOAT, ElementTypes.SCREEN].includes(parentType); // TODO 'SCREEN'?
    }
  }

  // Congratulations, your relationship is legit... UNLESS the parent has skeletons in its own ancestry.
  return isLegit && parent.parent ? isValidRelationship(parent.parent, child) : isLegit;
};

export const funcNewMonitor = (actionCreator) => {
  const [status, setStatus] = React.useState(AsyncStatus.UNSENT);
  const [error, setError] = React.useState('');

  const runAction = async ({ params = [], onFinally }) => {
    setStatus(AsyncStatus.IN_PROGRESS);
    const promise = actionCreator(...params);

    return promise
      .then(() => {
        setStatus(AsyncStatus.SUCCESS);
        setError('');
      })
      .catch(({ error: actionError }) => {
        setStatus(AsyncStatus.FAILURE);
        setError(actionError);
      })
      .finally(() => (onFinally ? onFinally() : null));
  };

  return {
    status,
    setStatus,
    error,
    setError,
    runAction
  };
};

// TODO Is this a memory leak because it has a reference to setState()?
// TODO Doesn't handle multiple calls yet.
// TODO This needs some general thinkin' anyway.
export const newMonitor = component => ({
 name, actionCreator, params = [], onFinally
}) => {
  const progressName = `${name}Progress`;
  const progress = component.state[progressName];
  component.setState({
    [progressName]: {
      ...progress,
      status: AsyncStatus.IN_PROGRESS
    }
  });

  const promise = actionCreator(...params);

  return promise
    .then(() => {
      component.setState({
        [progressName]: {
          ...progress,
          status: AsyncStatus.SUCCESS,
          error: ''
        }
      });
    })
    .catch(({ error }) => {
      component.setState({
        [progressName]: {
          ...progress,
          status: AsyncStatus.FAILURE,
          error
        }
      });
    })
    .finally(() => (onFinally ? onFinally() : null));
};

/**
 * Returns the list of tags from an asset. This function strips other information
 * out.
 */
export const getAssetTagsArray = tagsString => tagsString
    .split(',')
    .filter(tagName => tagName !== 'none' && tagName !== '')
    .map(tagName => tagName.toLowerCase());

/**
 * Returns the list of tags from a script. This function strips out other meta.
 */
export const getScriptTagsArray = (tagsString) => {
  const [tags] = tagsString.split(';');

  return tags
    .split(',')
    .filter(tagName => tagName !== 'behavior' && tagName !== '' && tagName !== 'vine')
    .map(tagName => tagName.toLowerCase());
};

/**
 * Removes a tag from script tags.
 */
export const removeFromScriptTags = (tagsString, tag) => {
  const [onlyTags, meta] = tagsString.split(';');
  const tags = onlyTags.split(',');
  const index = tags.indexOf(tag);
  if (index === -1) {
    return tagsString;
  }

  tags.splice(index, 1);
  return `${tags.join(',')};${meta}`;
};

/**
 * Adds a tag to script tags.
 */
export const addToScriptTags = (tagsString, tag) => `${tag},${tagsString}`;

/**
 * element.schema.strings.assetSrc -> [assetId, assetVersion].
 *
 * If no version is found, -1 is returned as the version.
 */
export const parseAssetSrc = (assetSrc) => {
  if (!assetSrc) {
    return ['', -1];
  }

  const substr = assetSrc.split(':');
  const assetId = substr[0];
  if (substr.length > 1) {
    return [assetId, parseInt(substr[1])];
  }

  return [assetId, -1];
};

/**
 * [assetId, assetVersion] -> element.schema.strings.assetSrc.
 */
export const stringifyAssetSrc = (asset, version) => {
  if (!asset) {
    return '';
  }

  if (version === 'latest') {
    return asset.id;
  }

  return `${asset.id}:${version}`;
};

/**
 * Constructs the absolute url for a thumbnail.
 */
export const assetThumbUrl = ({
 id, uriThumb, version, type
}, versionOverride = -1) => {
  // special handling for audio
  if (type === 'audio') {
    return 'assets/img/audio.svg';
  }

  if (uriThumb) {
    // v3
    if (uriThumb.startsWith('v3:')) {
      const parts = uriThumb.substring(3).split('.');
      if (parts.length === 3 && parts[1] !== 'override') {
        return `${window.env.thumbsUrl}${parts[0]}.${versionOverride > -1 ? versionOverride : version}.${parts[2]}`;
      }

      return `${window.env.thumbsUrl}${uriThumb.substring(3)}`;
    }

    // v1
    if (uriThumb.startsWith('/thumbs')) {
      return `${window.env.thumbsUrl}${id}.png`;
    }

    // v2
    return `${window.env.thumbsUrl}${uriThumb}`;
  }
};

/**
 * Constructs the absolute url for an experience's thumbnail.
 */
export const experienceThumbUrl = ({ id, isTemplate=false }) => {
  if (isTemplate) {
    return `${window.env.thumbsUrl}${id}.svg`
  }
  return `${window.env.thumbsUrl}${id}.png`
}

/**
 * Constructs the absolute url for a bundle.
 */
export const assetBundleUrl = (uri, version, platform) => {
  // v3
  if (uri.startsWith('v3:')) {
    const parts = uri.substring(3).split('.');
    if (parts.length === 3) {
      // v3
      return `${window.env.bundlesUrl}${parts[0]}.${version}.${parts[2]}`.replace('{{platform}}', platform);
    }
  }

  // v2
  if (uri.includes('{{platform}}')) {
    return `${window.env.bundlesUrl}${uri.replace('{{platform}}', platform)}`;
  }

  // v1
  const index = uri.indexOf('.bundle');
  const split = `${uri.substring(0, index)}_${platform}.bundle`.split('/');

  return `${window.env.bundlesUrl}${split[split.length - 1]}`;
};

/**
 * Nicely formats a timestamp for logging.
 * Brazenly stolen from https://github.com/thegoldenmule/naturallog
 *
 * @param      {long}    timestamp  The timestamp
 * @param options.includeData - whether to include the date as well as the time
 */
export const formatTimestamp = (timestamp, { includeDate = false } = {}) => {
  const date = new Date(timestamp);
  const time = [date.getHours(), date.getMinutes(), date.getSeconds()];
  const suffix = time[0] < 12 ? 'AM' : 'PM';

  // Convert hour from military time
  time[0] = time[0] < 12 ? time[0] : time[0] - 12;

  // If hour is 0, set it to 12
  time[0] = time[0] || 12;

  // If seconds and minutes are less than 10, add a zero
  for (let i = 1; i < 3; i += 1) {
    if (time[i] < 10) {
      time[i] = `0${time[i]}`;
    }
  }

  let result = `${time.join(':')} ${suffix}`;

  if (includeDate) {
    const months = [
      'January',
      'February',
      'March',
      'Apri',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    result = `${months[date.getMonth()]} ${date.getDate()}, ${result}`;
  }

  // Return the formatted string
  return result;
};

/**
 * Determines if a string is probably a guid. This will have false positives, but
 * false negatives.
 *
 * @param      {<type>}   str     The string
 * @return     {boolean}  True if probably unique identifier, False otherwise.
 */
export const isProbablyGuid = str => typeof str === 'string' && str.split('-').length === 5;

/**
 * Returns whether an item is an object.
 * @param candidate
 * @returns {*|boolean}
 */
export const isObject = candidate => candidate && typeof candidate === 'object' && candidate.constructor === Object;

/**
 * Constrains a numeric value between two others.
 * @param a - bound #1
 * @param b - bound #2
 * @param value - the value to constrain
 */
export const constrain = (a, b, value) => {
  let r = value;
  const [a1, b1] = [a, b].sort((a, b) => a - b);
  r = Math.max(a1, r);
  r = Math.min(b1, r);

  return r;
};

/**
 * Attempts to trigger a download from a URL.
 * Note: This has to be called in close proximity to a user action for the browser not to block it.
 * @param url - Url to download from
 */
export const downloadUrl = async (url, name) => {
  let usingBlob = false;

  // Attempt to pull the object into the browser, blob it, and host it.
  try {
    const blob = await (await fetch(url)).blob();
    url = window.URL.createObjectURL(blob);
    usingBlob = true;
  } catch (err) {
    log.warn(`Could not create blob for download: ${err}`);
  }

  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;

  // This will only work if the url was from a local blob.
  a.download = name;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  if (usingBlob) {
    window.URL.revokeObjectURL(url);
  }
};
