import { combineReducers } from 'redux';
import tree from './tree';
import content from './content';
import asset from './asset';
import spaces from './spaces';

const inspectorReducers = combineReducers({
  tree,
  content,
  asset,
  spaces
});

export default inspectorReducers;
