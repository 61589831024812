/**
 * Sets a single setting to a value.
 */
export const SET_SETTING = 'settings.setSetting';
export const setsetting = ({ category, setting, value, defaultValue }) => ({
  type: SET_SETTING,
  category,
  setting,
  value,
  defaultValue
});
