// TODO: DEPRECATED FOR MATERIAL_UI
import React from 'react';
import PropTypes from 'prop-types';
import qrious from 'qrious';
// import { MouseHoveringDetection } from 'react-detect-mouse-over';
import { Button } from 'react-bootstrap';
import uuid from 'uuid';

/**
 * Renders QR code.
 */
class Qr extends React.Component {
  styles = {
    canvas: {
      width: '275px',
      height: '275px',
      margin: '10px 6.5px',
      position: 'relative',
      border: '1px solid lightgray'
    },

    link: {
      zIndex: 1000,
      position: 'relative'
    },

    button: {
      marginTop: '120px',
      width: '100%'
    }
  };

  id = uuid();

  /**
   * Constructor.
   */
  constructor(props) {
    super(props);
  }

  /**
   * Forces a download of the QR code.
   */
  download() {
    const downloadLink = document.getElementById(`qrDownloadLink_${this.id}`);
    const canvas = document.getElementById(`canvas_${this.id}`);

    downloadLink.href = canvas.toDataURL();
    downloadLink.download = 'QR.png';
  }

  /**
   * Renders component.
   */
  render() {
    const { canvas, link, button } = this.styles;
    const { payload, isHoveringOver, style } = this.props;

    if (this.qr) {
      this.qr.value = payload;
    }

    return (
      <div style={{ ...canvas, ...style }}>
        <canvas id={`canvas_${this.id}`} />
        {isHoveringOver && (
          <a style={link} id={`qrDownloadLink_${this.id}`}>
            <Button style={button} onClick={this.download.bind(this)}>
              Download
            </Button>
          </a>
        )}
      </div>
    );
  }

  /**
   * When the component mounts, create a QR code.
   */
  componentDidMount() {
    this.qr = new qrious({
      element: document.getElementById(`canvas_${this.id}`),
      value: this.props.payload,
      level: 'H',
      size: 275
    });
  }
}

Qr.propTypes = {
  payload: PropTypes.string.isRequired
};

Qr.defaultProps = {
  mouseHoveringOver: true
};

// export default MouseHoveringDetection(Qr);
export default Qr;
