// TODO: DEPRECATED FOR MATERIAL_UI
import {
 Box, Button, TextField, Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';

import { getDebug } from '../../store/selectors/debugSelectors';
import { setcontext } from '../../store/actions/debugActions';
import BackButton from '../../components/material-ui/BackButton';
import CheckboxField from '../../components/material-ui/CheckboxField';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(1)
  },
  inputContainer: {
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  }
}));

/**
 * Renders all debug options.
 * Desktop and Mobile have different options reflecting what debug options can be used on each device.
 */
const DebugOptions = ({ debug: { context }, setContext, history }) => {
  /**
   * Render the controls.
   */

  const classes = useStyles();

  return (
    isMobile ?
    renderMobile(classes, history) :
    renderBrowser({
      classes, 
      context, 
      history,
      setContext
    })    
  );
};

const renderBrowser = ({
  classes, 
  context, 
  history,
  setContext
}) => {
  const {
    disabled, local, ip, logging
   } = context;
  return (
    <Box p={1}>
      <BackButton
        onBack={() => {
          history.goBack();
        }}
      />
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box mt={4} mb={2} display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="h3">Settings</Typography>

          <Box mb={2} display="flex" flexDirection="column">
            <Typography variant="h5">Player</Typography>
            <CheckboxField
              variant="switch"
              label="Enabled"
              value={!disabled}
              onChange={({ target: { checked } }) => setContext({ ...context, disabled: !checked })}
            />
            <Typography variant="caption">
              WebGL is required to render the scene, but disabling it will result in faster loads. NOTE: Some UI
              features (such as reparenting of nodes) require WebGL as well.
            </Typography>
          </Box>

          <Box mb={2} display="flex" flexDirection="column">
            <Typography variant="h5">Remote Player</Typography>
            <CheckboxField
              label="Remote Player"
              value={!local}
              disabled={disabled}
              onChange={({ target: { checked } }) => setContext({ ...context, local: !checked })}
            />
            {!local && (
              <TextField
                className={classes.textField}
                label={'Remote Address'}
                onChange={({ target: { value } }) => setContext({ ...context, ip: value })}
                value={ip}
                disabled={local}
                variant="outlined"
              />
            )}
          </Box>
          
          <Box mb={2} display="flex" flexDirection="column">
            <Typography variant="h5">Logging</Typography>
            <CheckboxField
              label="Enabled"
              value={logging}
              onChange={({ target: { checked } }) => setContext({ ...context, logging: checked })}
            />
            <Typography variant="caption">
              Some scripts can produce enough log messages to slow the editor. If you are experiencing editor slowdowns,
              try disabling logging.
            </Typography>
          </Box>
          
          <Box mb={2} display="flex" flexDirection="column">
            <Typography variant="h5">Unique ID</Typography>
            <TextField
              className={classes.textField}
              variant="outlined"
              defaultValue={window.intellumAppId || ''}
              onChange={(e) => {
                window.intellumAppId = e.target.value;
              }}
            />
          </Box>
          <Box mb={2} display="flex" flexDirection="column">
            <Typography variant="caption">
                Enklu supports integration with certain external services that require unique numerical IDs.<br/>
                If this applies to you, use this box to enter your ID. It will be linked with your Enklu account the next time you Login.
            </Typography>
          </Box>
          
          <Box display="flex" flexDirection="column" alignItems="flex-start" className={classes.inputContainer}>
            <Typography variant="h5">Local Storage</Typography>
            <Button color="secondary" variant="contained" size="large" onClick={() => window.localStorage.clear()}>
              Clear Local Storage
            </Button>
            <Typography variant="caption">
              This will clear all local data (last experience loaded, etc.). Account data will be unaffected. If you are
              having issues loading into a scene, try clearing this.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const renderMobile = (classes, history) => (
  <Box p={1}>
    <BackButton
      onBack={() => {
        history.goBack();
      }}
    />
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Box mt={4} mb={2} display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant="h3">Settings</Typography>
        <Box mb={2} display="flex" flexDirection="column">
          <Typography variant="h5">Unique ID</Typography>
          <TextField
            className={classes.textField}
            variant="outlined"
            defaultValue={window.intellumAppId || ''}
            onChange={(e) => {
              window.intellumAppId = e.target.value;
            }}
          />
        </Box>
        <Box mb={2} display="flex" flexDirection="column">
          <Typography variant="caption">
              Enklu supports integration with certain external services that require unique numerical IDs.<br/>
              If this applies to you, use this box to enter your ID. It will be linked with your Enklu account the next time you Login.
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box>
);

DebugOptions.propTypes = {
  debug: PropTypes.shape({
    context: PropTypes.object.isRequired
  }).isRequired,
  setContext: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({ debug: getDebug(state) });

const mapDispatchToProps = dispatch => ({
  /**
   * Dispatches a set context action.
   */
  setContext: options => dispatch(setcontext(options))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DebugOptions));
