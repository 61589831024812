import {
  ASSET_SET_UI_STATE,
  ASSET_ERROR
} from '../../actions/assetActions';

const initialState = {
  mode: 'viewing', // TODO const-ize (still in use?)
  enableUploadButton: false, // TODO Move to component state. (still in use?)

  // All errors, by asset id.
  assetImportErrors: {}
};

const asset = (state = initialState, action) => {
  switch (action.type) {
    case ASSET_ERROR: {
      return {
        ...state,
        assetImportErrors: {
          ...state.assetImportErrors,
          [action.assetId]: action.errors
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default asset;
