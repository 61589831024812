import { Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import BaseSwitch from './BaseSwitch';

const useStyles = makeStyles({
  checkbox: {
    marginLeft: 0
  }
});

const CheckboxField = ({
 value, label, onChange, inputProps, variant, ...otherProps
}) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.checkbox}
      onChange={onChange}
      checked={value}
      control={
        variant === 'switch' ? (
          <BaseSwitch {...inputProps} />
        ) : (
          <Checkbox style={{ backgroundColor: 'transparent', paddingLeft: 0 }} disableRipple {...inputProps} />
        )
      }
      label={label}
      {...otherProps}
    />
  );
};

CheckboxField.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  inputProps: PropTypes.object
};

export default CheckboxField;
