import { Dialog } from '@material-ui/core';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import queryString from 'query-string';

import ModalHeader from '../material-ui/ModalHeader';
import Modals from './Modals';

const ModalWatcher = (props) => {
  const { location, history } = props;
  const search = queryString.parse(location.search);
  const { wmodal: modalType, ...newSearch } = search;

  const closeModal = () => {
    history.push({ ...location, search: queryString.stringify(newSearch) });
  };

  const modalSelected = Modals[modalType];
  const { render, title, overrideDefault } = modalSelected || {};

  return overrideDefault ? (
    render({ onClose: closeModal })
  ) : (
    <Dialog onClose={closeModal} open={!!modalSelected}>
      <ModalHeader title={title} onClose={closeModal} />
      {modalSelected && render({ onClose: closeModal })}
    </Dialog>
  );
};

ModalWatcher.propTypes = {
  // withRouter
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(ModalWatcher);
