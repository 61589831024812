/**
 * A component to display when no inspectable in selected.
 *
 * @param text - the message to display to the user
 */

import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const NoInspectable = ({ text }) => (
  <Box>
    <Typography variant="h5">Properties</Typography>
    <Typography variante="h6">{text}</Typography>
  </Box>
);

NoInspectable.propTypes = {
  text: PropTypes.string
};

NoInspectable.defaultProps = {
  text: 'Select an item to edit its properties.'
};

export default NoInspectable;
