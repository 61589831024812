import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import { buttonShadow } from '../../styles/muiTheme';

const BaseButton = ({ classes, children, ...otherProps }) => {
  const isCallToAction = (otherProps.color === 'primary' || otherProps.color === 'secondary')
    && otherProps.variant === 'contained'
    && !otherProps.disabled;
  return (
    <Button
      classes={{
        label: isCallToAction ? clsx(classes.callToActionButton) : undefined,
        startIcon: isCallToAction ? classes.callToActionIcon : undefined,
        endIcon: isCallToAction ? classes.callToActionIcon : undefined
      }}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

BaseButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default withStyles(() => ({
  callToActionButton: {
    fontWeight: 'bold',
    textShadow: buttonShadow
  },
  callToActionIcon: {
    filter: 'drop-shadow(0 0 8px #000000cc)'
  }
}))(BaseButton);
