import {
 ArrowDropDown, Clear, Mail, Search, Warning
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import {
  Box,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  InputAdornment,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { find, uniqBy } from 'lodash';
import { getActions, AsyncStatus } from '@enklu/server-api';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/styles';
import * as EmailValidator from 'email-validator';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import pluralize from 'pluralize';

import { clearcollaboratorssearch } from '../../store/actions/uiActions';
import { getAppInfo } from '../../store/selectors/appSelectors';
import {
  getCollaboratorsByAppId,
  getCollaboratorsByUserId,
  getCollaboratorsSearchResults
} from '../../store/selectors/collaboratorsSelectors';
import { getUserCache } from '../../store/selectors/userCacheSelectors';
import { getUserProfile } from '../../store/selectors/userSelectors';
import { loadcollaboratorsinfo } from '../../store/actions/comboActions';
import { mapCollabsToUsers } from '../../store/reducers/collaboratorsReducer';
import { muiPalette } from '../../styles/muiTheme';
import { newMonitor } from '../../util/util';
import { newProgressShape } from '../../interfaces/ProgressShape';
import BackButton from '../material-ui/BackButton';
import BaseAvatar from '../material-ui/BaseAvatar';
import BaseButton from '../material-ui/BaseButton';
import BaseStandardTextField from '../material-ui/BaseStandardTextField';
import CollaboratorTypes, { canDelete, canEdit, hasHigherRole } from '../../constants/CollaboratorTypes';
import ConfirmationModal from '../modals/MuiConfirmationModal';
import LoadedItemList from '../common/MuiLoadedItemList';
import MenuButton from '../material-ui/MenuButton';
import ModalHeader from '../material-ui/ModalHeader';
import UserShape from '../../interfaces/UserShape';

pluralize.addIrregularRule('an', '');

const {
  createappcollaborator,
  deleteappcollaborator,
  getappcollaborators,
  searchusersbyemail,
  updateappcollaborator,
  inviteuser
} = getActions('trellis');

const newUserRoles = [
  { title: 'Viewer', eventkey: CollaboratorTypes.VIEWER.join(',') },
  {
    title: 'Editor',
    eventkey: CollaboratorTypes.EDITOR.join(',')
  },
  {
    title: 'Admin',
    eventkey: CollaboratorTypes.ADMIN.join(',')
  }
];

class CollaboratorsDisplayNew extends Component {
  monitor;

  constructor(props) {
    super(props);

    this.state = {
      userToRemove: null,

      removeCollaboratorProgress: newProgressShape(),
      getCollaboratorsProgress: newProgressShape(),
      searchUsersByEmailProgress: newProgressShape(),

      combinedAddCollaboratorProgress: newProgressShape(),

      // unused but needed for monitor
      addCollaboratorProgress: newProgressShape(),
      updateCollaboratorProgress: newProgressShape(),

      // what is typed in the search
      searchInputValue: '',
      // what users are selected in the search
      searchValues: [],
      // whether the search bar shows options
      autocompleteOpen: false,
      // options that are derived from search results (but kept separate so we can manage the input options different from the search results)
      autocompleteOptions: [],

      newUserRoleIndex: 0
    };

    this.monitor = newMonitor(this);
  }

  componentDidMount() {
    this.getCollaborators(this.props.appId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.appId !== this.props.appId) {
      this.getCollaborators(nextProps.appId);
    }

    if (nextProps.collaboratorsSearchResults !== this.props.collaboratorsSearchResults) {
      this.setState({ autocompleteOptions: nextProps.collaboratorsSearchResults });

      // replace an invite with a search result if that email is found
      let needsReplace = false;
      const newSearchValues = this.state.searchValues.map((searchValue) => {
        if (searchValue.isInvite && searchValue.isValid) {
          const toReplace = find(
            nextProps.collaboratorsSearchResults,
            searchResult => searchResult.email === searchValue.email
          );
          if (toReplace) {
            needsReplace = true;
            return toReplace;
          }
        }
        return searchValue;
      });
      if (needsReplace) {
        this.setState({ searchValues: newSearchValues });
      }
    }
  }

  handleBack = () => {
    this.setState({ searchValues: [], searchInputValue: '', autocompleteOptions: [] });
    this.props.clearCollaboratorSearch();
  };

  getCollaborators(appId) {
    const { getCollaborators } = this.props;

    this.monitor({
      name: 'getCollaborators',
      actionCreator: getCollaborators,
      params: [{ appId }]
    });
  }

  renderRemoveModal() {
    const { appId, collaboratorsByUserId, removeCollaborator } = this.props;
    const { userToRemove, removeCollaboratorProgress } = this.state;
    const clearUserToRemove = () => this.setState({ userToRemove: null });
    return (
      <ConfirmationModal
        open={!!userToRemove}
        title={'Remove'}
        message={`Are you sure you want to remove' ${
          userToRemove ? userToRemove.displayName : ''
        }' from this experience?`}
        isLoading={removeCollaboratorProgress.status === AsyncStatus.IN_PROGRESS}
        confirmIsDanger
        onClose={clearUserToRemove}
        onConfirm={() => this.monitor({
            name: 'removeCollaborator',
            actionCreator: removeCollaborator,
            params: [
              {
                appId,
                collaboratorId: collaboratorsByUserId[userToRemove.id].id
              }
            ],
            onFinally: clearUserToRemove
          })
        }
      />
    );
  }

  renderSearchBar() {
    const {
 collaboratorsSearchResults, searchUsersByEmail, userId, collaboratorsByUserId, classes
} = this.props;
    const userWithRole = collaboratorsByUserId[userId];

    const {
      autocompleteOptions,
      autocompleteOpen,
      searchValues,
      searchInputValue,
      searchUsersByEmailProgress,
      newUserRoleIndex
    } = this.state;

    const loading = searchUsersByEmailProgress.status === AsyncStatus.IN_PROGRESS;
    const showBackButton = searchValues.length > 0;
    const showPermissionsMenuButton = searchValues.length > 0 && find(searchValues, searchValue => !searchValue.isInvite);

    return (
      <Box mb={2} display="flex" flexDirection="row" alignItems="center">
        {showBackButton && (
          <Box mr={1}>
            <BackButton onBack={this.handleBack} />
          </Box>
        )}

        <Autocomplete
          fullWidth
          multiple
          open={autocompleteOpen}
          onOpen={() => {
            this.setState({
              autocompleteOpen: true
            });
            // triggers componentWillReceiveProps to update autocompleteOptions
            if (this.state.searchInputValue) {
              this.monitor({
                name: 'searchUsersByEmail',
                actionCreator: searchUsersByEmail,
                params: [{ email: encodeURIComponent(this.state.searchInputValue) }]
              });
            }
          }}
          onClose={() => {
            this.setState({
              autocompleteOpen: false,
              autocompleteOptions: []
            });
          }}
          filterOptions={(options, params) => {
            const filtered = options.slice();

            // Suggest the creation of a new value
            if (
              params.inputValue !== ''
              && !find(collaboratorsSearchResults, searchResult => searchResult.email === params.inputValue.toLowerCase())
            ) {
              filtered.push({
                isValid: EmailValidator.validate(params.inputValue),
                isInvite: true,
                email: params.inputValue,
                displayName: params.inputValue
              });
            }

            return filtered;
          }}
          freeSolo
          renderOption={option => (
            <React.Fragment>
              {option.isInvite ? (
                <ListItemText primary={`Invite "${option.email}" to join Enklu`} />
              ) : (
                <React.Fragment>
                  <ListItemAvatar>
                    <BaseAvatar alt={option.displayName} src={option.thumbUrl || 'broken'} />
                  </ListItemAvatar>
                  <ListItemText primary={option.displayName} secondary={option.email} />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          options={autocompleteOptions}
          value={searchValues}
          onChange={async (event, newValues) => {
            // are there duplicate emails?
            if (uniqBy(newValues, value => value.email).length !== newValues.length) {
              return;
            }
            this.setState({ searchValues: newValues });
            // attempt to replace the newly added value with a search result
            if (newValues.length > this.state.searchValues.length) {
              const addedValue = newValues[newValues.length - 1];
              if (addedValue.isInvite && addedValue.isValid) {
                this.monitor({
                  name: 'searchUsersByEmail',
                  actionCreator: searchUsersByEmail,
                  params: [{ email: encodeURIComponent(addedValue.email) }]
                });
              }
            }
          }}
          inputValue={searchInputValue}
          onInputChange={async (event, newInputValue) => {
            const trimmedValue = newInputValue ? newInputValue.trim() : '';
            this.setState({ searchInputValue: trimmedValue });
            if (EmailValidator.validate(trimmedValue)) {
              this.monitor({
                name: 'searchUsersByEmail',
                actionCreator: searchUsersByEmail,
                params: [{ email: encodeURIComponent(trimmedValue) }]
              });
            } else {
              this.setState({ autocompleteOptions: [] });
            }
          }}
          loading={loading}
          renderInput={params => (
            <BaseStandardTextField
              {...params}
              noshadow="true"
              darkbackground="true"
              placeholder="Enter Email"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <React.Fragment>
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </React.Fragment>
                ),
                endAdornment: (
                  <React.Fragment>{loading && <CircularProgress color="inherit" size={20} />}</React.Fragment>
                )
              }}
            />
          )}
          renderTags={(value, getTagProps) => value.map((option, index) => {
              const emailIcon = option.isValid ? (
                <Mail />
              ) : (
                <Tooltip title={'Invalid Email'} placement="top">
                  <Warning />
                </Tooltip>
              );
              return (
                <Chip
                  // color="secondary"
                  variant="outlined"
                  icon={option.isInvite ? emailIcon : undefined}
                  avatar={
                    option.isInvite ? (
                      undefined
                    ) : (
                      <BaseAvatar alt={option.displayName} src={option.thumbUrl || 'broken'} />
                    )
                  }
                  key={index}
                  label={option.displayName}
                  {...getTagProps({ index })}
                  deleteIcon={<Clear style={{ color: muiPalette.grayscale.white }} />}
                />
              );
            })
          }
        />
        {showPermissionsMenuButton && (
          <MenuButton
            color="secondary"
            variant="contained"
            className={classes.roleButton}
            size="large"
            endIcon={<ArrowDropDown />}
            items={[
              newUserRoles[0],
              {
                ...newUserRoles[1],
                disabled: !canEdit(userWithRole.role)
              },
              {
                ...newUserRoles[2],
                disabled: !canDelete(userWithRole.role)
              }
            ]}
            onItemClick={(_, index) => {
              this.setState({
                newUserRoleIndex: index
              });
            }}
          >
            {newUserRoles[newUserRoleIndex].title}
          </MenuButton>
        )}
      </Box>
    );
  }

  renderNormalMode() {
    const {
      appId,
      userId,
      collaborators,
      // getCollaborators,
      classes,
      appInfo,
      updateCollaborator,
      collaboratorsByUserId
    } = this.props;

    const { getCollaboratorsProgress } = this.state;

    const userWithRole = collaboratorsByUserId[userId];

    return (
      <div>
        <LoadedItemList
          listProps={{ dense: true }}
          color="default"
          customClass={classes.itemList}
          items={collaborators}
          progress={getCollaboratorsProgress}
          renderCustomItemContent={(userToEdit) => {
            const isOwner = userToEdit.id === appInfo.owner;
            const collaboratorWithRole = collaboratorsByUserId[userToEdit.id];

            if (!userToEdit || !collaboratorWithRole) {
              return null;
            }

            const collaboratorRole = collaboratorWithRole.role.split(',');

            const menuButtonText = isOwner
              ? 'Owner'
              : canDelete(collaboratorRole)
              ? 'Admin'
              : canEdit(collaboratorRole)
              ? 'Editor'
              : 'Viewer';

            const actions = [
              // viewer
              {
                ...newUserRoles[0],
                disabled: hasHigherRole(collaboratorRole, userWithRole.role)
              },
              // editor
              {
                ...newUserRoles[1],
                disabled: !canEdit(userWithRole.role) || hasHigherRole(collaboratorRole, userWithRole.role)
              },
              // admin
              {
                ...newUserRoles[2],
                includeDivider: true,
                disabled: !canDelete(userWithRole.role)
              },
              {
                title: 'Remove',
                customOnItemClick: () => {
                  this.setState({ userToRemove: userToEdit });
                },
                disabled: !canDelete(userWithRole.role)
              }
            ];

            return (
              <React.Fragment>
                <ListItemAvatar>
                  <BaseAvatar size={30} alt={userToEdit.displayName} src={userToEdit.thumbUrl || 'broken'} />
                </ListItemAvatar>
                <ListItemText primary={userToEdit.displayName} secondary={userToEdit.email} />
                <ListItemSecondaryAction>
                  <MenuButton
                    color="inherit"
                    disabled={isOwner}
                    endIcon={<ArrowDropDown />}
                    items={actions}
                    onItemClick={(item: any) => {
                      const role = item.eventkey;
                      this.monitor({
                        name: 'updateCollaborator',
                        actionCreator: updateCollaborator,
                        params: [
                          { role },
                          {
                            appId,
                            collaboratorId: collaboratorsByUserId[userToEdit.id].id
                          }
                        ]
                      });
                    }}
                  >
                    {menuButtonText}
                  </MenuButton>
                </ListItemSecondaryAction>
              </React.Fragment>
            );
          }}
        />
        {/* <Box display="flex" justifyContent="flex-end">
          <IconButton
            onClick={() => this.monitor({
                name: 'getCollaborators',
                actionCreator: getCollaborators,
                params: [{ appId }]
              })
            }
          >
            <Refresh />
          </IconButton>
        </Box> */}
      </div>
    );
  }

  renderAddingMode() {
    const {
 appId, inviteUser, classes, addCollaborator
} = this.props;

    const { combinedAddCollaboratorProgress } = this.state;

    const { searchValues, newUserRoleIndex } = this.state;
    const addUsers = searchValues.filter(searchValue => !searchValue.isInvite);
    const inviteUsers = searchValues.filter(searchValue => searchValue.isInvite && searchValue.isValid);
    // const errorEmails = searchValues.filter(searchValue => searchValue.isInvite && !searchValue.isValid);

    const handleAddUsers = async () => {
      try {
        this.setState({
          combinedAddCollaboratorProgress: {
            ...combinedAddCollaboratorProgress,
            status: AsyncStatus.IN_PROGRESS
          }
        });

        await Promise.all(
          inviteUsers.map(async ({ email }) => {
            await inviteUser({ email, appId });
          })
        );

        await Promise.all(
          addUsers.map(async (user) => {
            await this.monitor({
              name: 'addCollaborator',
              actionCreator: addCollaborator,
              params: [
                {
                  userId: user.id,
                  role: newUserRoles[newUserRoleIndex].eventkey
                },
                { appId }
              ]
            });
          })
        );

        if (addUsers.length > 0) {
          this.getCollaborators(appId);
          this.props.enqueueSnackbar(`Users added: ${addUsers.map(user => user.displayName).join(', ')}`, {
            variant: 'success'
          });
        }

        if (inviteUsers.length > 0) {
          this.props.enqueueSnackbar(`Invites sent: ${inviteUsers.map(user => user.email).join(', ')}`, {
            variant: 'success'
          });
        }

        this.handleBack();
        this.setState({ combinedAddCollaboratorProgress: { status: AsyncStatus.SUCCESS, error: '' } });
      } catch (error) {
        this.props.enqueueSnackbar('Something went wrong. Want to try again?', { variant: 'error' });
        this.setState({ combinedAddCollaboratorProgress: { status: AsyncStatus.FAILURE, error } });
      }
    };

    const loading = combinedAddCollaboratorProgress.status === AsyncStatus.IN_PROGRESS;

    return (
      <React.Fragment>
        <Box mt={1}>
          {inviteUsers.length > 0 && (
            <Typography>
              <span className={classes.boldText}>{inviteUsers.map(user => user.email).join(', ')}</span>{' '}
              {pluralize('is', inviteUsers.length)} not currently {pluralize('an', inviteUsers.length)} Enklu{' '}
              {pluralize('user', inviteUsers.length)}. An invite email will be sent to them. When they accept, they will
              be added to this project.
            </Typography>
          )}
          {loading && (
            <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
        <DialogActions className={classes.dialogActions}>
          <BaseButton color="primary" variant="contained" size="large" onClick={handleAddUsers} disabled={loading}>
            Invite
          </BaseButton>
        </DialogActions>
      </React.Fragment>
    );
  }

  render() {
    const { onClose, classes } = this.props;
    const addingMode = this.state.searchValues.length > 0;
    return (
      <React.Fragment>
        <ModalHeader title={addingMode ? 'Invite People to Edit' : 'Share With People'} onClose={onClose} />
        <DialogContent className={classes.dialogContent}>
          <Box display="flex" flex={1} flexDirection="column">
            {this.renderSearchBar()}
            {addingMode ? this.renderAddingMode() : this.renderNormalMode()}
            {this.renderRemoveModal()}
          </Box>
        </DialogContent>
      </React.Fragment>
    );
  }
}

CollaboratorsDisplayNew.propTypes = {
  enqueueSnackbar: PropTypes.func,
  appId: PropTypes.string.isRequired,

  // connect()
  userId: PropTypes.string.isRequired,

  collaborators: PropTypes.arrayOf(UserShape).isRequired,
  collaboratorsByUserId: PropTypes.object.isRequired,
  collaboratorsByAppId: PropTypes.object.isRequired,
  collaboratorsSearchResults: PropTypes.arrayOf(UserShape),

  getCollaborators: PropTypes.func.isRequired,
  searchUsersByEmail: PropTypes.func.isRequired,
  addCollaborator: PropTypes.func.isRequired,
  removeCollaborator: PropTypes.func.isRequired,
  updateCollaborator: PropTypes.func.isRequired,
  clearCollaboratorSearch: PropTypes.func.isRequired,

  appInfo: PropTypes.object.isRequired,

  // withRouter
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,

  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  inviteUser: PropTypes.func
};

const mapStateToProps = (
  state,
  { appId } // Don't get this from the store because it might not be the current appId.
) => {
  const userId = getUserProfile(state).id;
  const userCache = getUserCache(state);
  const collaboratorsByAppId = getCollaboratorsByAppId(state);
  const collaborators = Object.entries(collaboratorsByAppId[appId] || {}).map(([, collaborator]) => collaborator);

  return {
    userId,
    appInfo: getAppInfo(state),
    collaboratorsByAppId,
    collaboratorsByUserId: getCollaboratorsByUserId(state),
    collaborators: mapCollabsToUsers(collaborators, userCache)
      .sort((c1, c2) => c1.displayName.localeCompare(c2.displayName))
      .sort((c1, c2) => {
        if (c1.id === userId) return -1;
        if (c2.id === userId) return 1;
        return 0;
      }),
    collaboratorsSearchResults: getCollaboratorsSearchResults(state)
  };
};
const mapDispatchToProps = dispatch => ({
  // async
  getCollaborators: ({ appId }) => dispatch(getappcollaborators({ appId })).then(() => dispatch(loadcollaboratorsinfo())),
  searchUsersByEmail: ({ email }) => dispatch(searchusersbyemail({ email })).then(() => dispatch(loadcollaboratorsinfo())),
  addCollaborator: ({ userId, role }, { appId }) => dispatch(
      createappcollaborator(
        {
          userId,
          role
        },
        { appId }
      )
    )
      .then(() => dispatch(getappcollaborators({ appId })))
      .then(() => dispatch(loadcollaboratorsinfo())),
  removeCollaborator: ({ appId, collaboratorId }) => dispatch(
      deleteappcollaborator({
        appId,
        collaboratorId
      })
    )
      .then(() => dispatch(getappcollaborators({ appId })))
      .then(() => dispatch(loadcollaboratorsinfo())),
  updateCollaborator: ({ role }, { appId, collaboratorId }) => dispatch(
      updateappcollaborator(
        { role },
        {
          appId,
          collaboratorId
        }
      )
    ).then(() => dispatch(getappcollaborators({ appId }))),
  // sync
  clearCollaboratorSearch: () => dispatch(clearcollaboratorssearch()),
  inviteUser: ({ email, appId }) => dispatch(inviteuser({ email, appId }))
});

export default withSnackbar(
  withStyles(theme => ({
    itemList: {
      maxHeight: 500,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    roleButton: {
      marginLeft: theme.spacing(1)
    },
    boldText: { fontWeight: 'bold' },
    dialogActions: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      paddingBottom: theme.spacing(0)
    },
    dialogContent: {
      paddingBottom: theme.spacing(3)
    }
  }))(withRouter(connect(mapStateToProps, mapDispatchToProps)(CollaboratorsDisplayNew)))
);
