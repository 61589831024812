const initialState = {
  openAssetModal: false,
  openScriptModal: false
};

const handlers = {};

function content(state = initialState, action) {
  const handler = handlers[action.type];
  if (!handler) {
    return state;
  }

  return handler(state, action);
}

export default content;
