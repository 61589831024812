import { log } from '../../util/log';
import { SUBSCRIBE_FAILURE, SUBSCRIBE_SUCCESS } from './initializeActions';
import { Actions } from '@enklu/server-api';
const { replace } = Actions;

/**
 * Handles the socket connection for getting app updates.
 */
class AppSocketManager {

    _dispatch;
    _currentId;

    /**
     * Initializes the class with its dependencies.
     * @param dispatch
     */
    initialize(dispatch) {
        this._dispatch = dispatch;
    }

    /**
     * Starts the connection. Disconnects first if previously connected.
     * @param appId
     */
    connect(appId) {
        if (this._currentId) {
            this.disconnect();
        }

        log.info(`Connecting app socket.`);
        io.socket.request(
            {
                method: 'POST',
                url: replace('/v1/editor/app/:appId/subscribe', { appId }),
                data: {},
                headers: {
                    Authorization: `Bearer ${io.sails.token}`
                }
            },
            (responseBody) => {
                if (responseBody.success) {
                    log.info('Subscribed to app updates.');
                    this._currentId = appId;
                    this._dispatch({ type: SUBSCRIBE_SUCCESS });
                } else {
                    log.error('Could not subscribe!', responseBody.error);
                    this._dispatch({ type: SUBSCRIBE_FAILURE });
                }
            }
        );
    }

    /**
     * Disconnects if the socket is active.
     */
    disconnect() {
        if (this._currentId) {
            log.info(`Disconnecting app socket.`);
            io.socket.request(
                {
                    method: 'POST',
                    url: replace('/v1/editor/app/:appId/unsubscribe', { appId: this._currentId }),
                    data: {},
                    headers: {
                        Authorization: `Bearer ${io.sails.token}`
                    }
                },
                (responseBody) => {
                    if (responseBody.success) {
                        log.info('Unsubscribed from app.', { appId: this._currentId });
                    } else {
                        log.error('Could not unsubscribe!', responseBody.error);
                    }
                }
            );

            this._currentId = null;
        }
    }
}

const appSocketManager = new AppSocketManager();

export default appSocketManager;
