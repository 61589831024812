import { log } from './util/log';
import MessageTypes from './constants/MessageTypes';

/**
 * Creates a bridge to the iframe.
 */
export const createBridge = () => {
  if (window.bridge) {
    throw new Error('iFrame bridge already exists.');
  }

  window.bridge = {
    _iframe: undefined,
    _isReady: false,
    _onReady: [],
    _listeners: [],
    _messages: [],

    _handleEvent: (evt) => {
      let message = {};
      try {
        message = JSON.parse(evt.data);
      } catch (err) {
        return;
      }

      if (message.type === 'ready') {
        // call onready methods
        window.bridge._isReady = true;
        for (let i = 0, len = window.bridge._onReady.length; i < len; i += 1) {
          window.bridge._onReady[i]();
        }
        window.bridge._onReady = [];

        // TODO: send any queued messages
      }

      // send to listeners
      for (let listener of window.bridge._listeners) {
        listener(message);
      }
    },

    /**
     * Listens for player events.
     */
    listen: cb => window.bridge._listeners.push(cb),

    /**
     * Called when player is ready.
     */
    onReady: (cb) => {
      if (window.bridge._isReady) {
        cb();
      } else {
        window.bridge._onReady.push(cb);
      }
    },

    /**
     * Sends events to player.
     */
    send: (type, message) => {
      if (window.bridge._iframe) {
        log.debug('Posting message to iframe.');

        window.bridge._iframe.contentWindow.postMessage(
          JSON.stringify({
            type,
            message
          }),
          '*'
        );
      } else {
        log.debug('Queueing message to send later.');

        // queue for later
        window.bridge._messages.push({
          type,
          message
        });
      }
    },

    reset: () => {
      window.bridge._isReady = false;
      window.bridge._onReady = [];

      window.bridge.send(MessageTypes.PLAYER_RESTART);
    },

    registerFrame: (id) => {
      window.bridge._iframe = document.getElementById(id);
      if (!window.bridge._iframe) {
        return false;
      }

      return true;
    },

    unregisterFrame: () => {
      window.bridge._iframe = null;
      window.bridge._isReady = false;
    }
  };

  // listen for events from child window
  if (window.addEventListener) {
    window.addEventListener('message', window.bridge._handleEvent, false);
  } else if (window.attachEvent) {
    window.attachEvent('onmessage', window.bridge._handleEvent);
  }
};
