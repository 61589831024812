import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { getAllAssets } from '../../store/selectors/assetLibrariesSelectors';
import { getAllScripts } from '../../store/selectors/scriptLibrariesSelectors';
import { getAppInfo } from '../../store/selectors/appSelectors';
import { getGlobalSettingsIsLoaded, getRenderSettings } from '../../store/selectors/globalSettingsSelectors';
import { getIframeId } from '../../store/selectors/playerSelectors';
import { getUIIsResizing } from '../../store/selectors/uiSelectors';
import { hidemodal } from '../../store/actions/modalActions';
import { informPlayer } from '../../store/actions/initializeActions';
import OverlayDropLayer from '../OverlayDropLayer';

const iFrameVersion = Math.random();

class WebGLView extends React.Component {
  /**
   * Creates a new WebGLView.
   */
  constructor(props) {
    super(props);

    this.state = {
      bridgeIsReady: false,
      settingsSent: false
    };
  }

  UNSAFE_componentWillReceiveProps() {
    const { settingsSent } = this.state;

    if (!settingsSent) {
      this.attemptSendSettings();
    }
  }

  /**
   * Renders the component.
   */
  render() {
    const {
      debug: {
        context: {
 disabled, local, ip, port
}
      },
      isResizing,
      style,
      iframeId,
      classes
    } = this.props;

    // eslint-disable-next-line no-nested-ternary
    const contextString = disabled
      ? 'context=passthrough'
      : local
      ? 'context=local'
      : `context=remote&ip=${ip}&port=${port}`;

    return (
      <div className={classes.container} style={style}>
        <OverlayDropLayer />
        <iframe
          className={classes.webGlContainer}
          style={{
            display: !isResizing ? 'block' : 'none'
          }}
          id={iframeId}
          src={`./js/components/webgl/iframe.html?env=${window.env.build}&${contextString}&v=${iFrameVersion}`}
        />

        <div
          id="blocker"
          className={classes.webGlContainer}
          style={{
            display: isResizing ? 'block' : 'none'
          }}
        ></div>
      </div>
    );
  }

  /**
   * Called right after the object has been created.
   */
  componentDidMount() {
    if (this.props.informPlayer) {
      const { onInformPlayer } = this.props;
      onInformPlayer();
    }
  }

  /**
   * Called when the component is going to unmount.
   */
  // eslint-disable-next-line class-methods-use-this
  componentWillUnmount() {
    window.bridge.unregisterFrame();
  }

  attemptSendSettings() {
    const { globalSettingsIsLoaded, renderSettings } = this.props;

    const { bridgeIsReady } = this.state;

    if (bridgeIsReady && globalSettingsIsLoaded) {
      window.bridge.send(
        4001,
        renderSettings.settings.reduce(
          (accum, { name, value }) => ({
            ...accum,
            [name]: value
          }),
          {}
        )
      );

      this.setState({ settingsSent: true });
    }
  }
}

WebGLView.propTypes = {
  style: PropTypes.object,
  debug: PropTypes.object,
  modal: PropTypes.object,
  isResizing: PropTypes.bool.isRequired,
  user: PropTypes.object,
  appId: PropTypes.string,
  scripts: PropTypes.array,
  assets: PropTypes.array,
  renderSettings: PropTypes.object,
  globalSettingsIsLoaded: PropTypes.bool,
  onInformPlayer: PropTypes.func.isRequired,
  iframeId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  informPlayer: PropTypes.bool
};

WebGLView.defaultProps = {
  style: {},
  informPlayer: true
};

const mapStateToProps = (state) => {
  const { user, debug, modal } = state;

  return {
    appId: getAppInfo(state).id,
    user,
    scripts: getAllScripts(state),
    assets: getAllAssets(state),
    globalSettingsIsLoaded: getGlobalSettingsIsLoaded(state),
    renderSettings: getRenderSettings(state),
    isResizing: getUIIsResizing(state),
    debug,
    modal,
    iframeId: getIframeId(state)
  };
};

// const mapDispatchToProps = dispatch => ({
//   // hides the modal
//   hideModal: () => dispatch(hidemodal())
// });

const dispatchMap = {
  onInformPlayer: informPlayer
};

export default withStyles(theme => ({
  container: {
    display: 'flex',
    position: 'relative'
  },
  webGlContainer: {
    flex: 1,
    height: '100%',
    padding: 0,
    borderWidth: 0,
    backgroundColor: theme.palette.webgl.main
  }
  //
  // remoteInfo: {
  //   textAlign: 'center',
  //   lineHeight: '200px',
  //   fontSize: 'large'
  // }
}))(connect(mapStateToProps, dispatchMap)(WebGLView));
