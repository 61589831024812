import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import WebGLView from '../components/webgl/WebGLView';
import { loadApp, loadPublicApp } from '../store/actions/initializeActions';
import { setAuth } from '../store/actions/userActions';
import { log } from '../util/log';

class PlayMode extends React.Component {
  componentDidMount() {
    log.info('PlayMode ready. Awaiting authentication.');
    window.postMessage({ event: 'ready' }, window.opener);
    window.addEventListener('message', evt => {
      if (evt.data.event === 'authenticate') {
        log.info('PlayMode received authentication.');
        this.props.setAuth(evt.data.payload.auth, evt.data.payload.user);

        const params = queryString.parse(this.props.location.search);
        this.props.loadApp(params.app);
      }
    });

    // For published apps
    const params = queryString.parse(this.props.location.search);
    log.info('PlayMode.js appId: ' + params.app);
    this.props.loadPublicApp(params.app);
  }

  render() {
    return (
      <WebGLView
        informPlayer={false}
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    );
  }
}

PlayMode.propTypes = {
  setAuth: PropTypes.func,
  loadApp: PropTypes.func,
  loadPublicApp: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  loadApp: async (appId) => {
    await dispatch(loadApp({ appId }));
  },

  loadPublicApp: async (publicAppId) => {
    await dispatch(loadPublicApp({ publicAppId }));
  },

  setAuth: (auth, userId) => {
    dispatch(setAuth(auth, userId));
  }
});

const mapStoreToProps = store => ({

});

export default withRouter(connect(mapStoreToProps, mapDispatchToProps)(PlayMode));
