import {
  Box, CircularProgress, Dialog, DialogContent, Typography
 } from '@material-ui/core';
 import { connect } from 'react-redux';
 import { getActions } from '@enklu/server-api';
 import PropTypes from 'prop-types';
 import React from 'react';
 
 import { getHoloAuthCode } from '../../store/selectors/userSelectors';
 import Qr from '../MuiQr';
 import ModalHeader from '../material-ui/ModalHeader';
 
 const { holoauthorize } = getActions('trellis');
 
 const ExpHoloLogin = ({ holocode, app, authorize, onClose, open }) => {
   React.useEffect(() => {
     if(app){
        authorize({ appId: app.id });
     }
   }, [app]);
 
 
   return (
    <Dialog onClose={onClose} open ={open}>
    <ModalHeader title={'HoloLogin'} onClose={onClose} />
     <DialogContent>
       <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" pb={3}>
         {holocode ? (
           <React.Fragment>
              <Qr payload={btoa(`${holocode}:bar`)} />
              <Typography align="center">
                Use this QR code to login into the HoloLens.
                <br />
                Point the holographic device camera directly at the QR code.
                <br />
                For best results, stay still.
              </Typography>
           </React.Fragment>
         ) : (
           <CircularProgress size={20} />
         )}
       </Box>
     </DialogContent>
     </Dialog>
   );
 };
 
 ExpHoloLogin.propTypes = {
   style: PropTypes.object,
   app: PropTypes.object,
 
   holocode: PropTypes.string,
   authorize: PropTypes.func.isRequired,
   onClose: PropTypes.func.isRequired,
   open: PropTypes.bool
 };
 
 ExpHoloLogin.defaultProps = {
   style: {}
 };
 
  const mapStateToProps = (state) => ({
    holocode: getHoloAuthCode(state)

  });
  const mapDispatchToProps = dispatch => ({
    /**
     * Generates a holologin code.
     */
    authorize: payload => dispatch(holoauthorize({ payload }))
  });
 
 export default connect(mapStateToProps, mapDispatchToProps)(ExpHoloLogin);
 