import PropTypes from 'prop-types';

/**
 * onClose: a function with the signature onClose(didCreate = false)
 */
export default {
    appId: PropTypes.string.isRequired,
    dispatch: PropTypes.func, // TODO Remove from CreateNewAsset
    onCreate: PropTypes.func,
    onClose: PropTypes.func.isRequired,

    style: PropTypes.object
};
