import React from 'react';
import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CircularProgress, FormLabel, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import { Publish, Close } from '@material-ui/icons';
import { getActions } from '@enklu/server-api';
import { sendFile } from '../../../util/sendFile';
import { updateThumb } from '../../../store/actions/thumbActions';

const { deletethumb } = getActions('trellis');

const InspectableThumbnail = ({
    classes,
    app,
    thumb,
    removeThumbnail,
    setThumbnail
}) => {
    const [loading, setLoading] = useState(false);
    const [hovering, setHovering] = useState(false);
    const [error, setError] = useState(null);
    
    const uploadRef = useRef();
    useEffect(() => {
        uploadRef.current.addEventListener('change', () => {
            const files = uploadRef.current.files;
            if (files.length === 0) return;
            uploadFile(files[0]);
        });
    }, []);
    
    // Prompt the browser to open its "Select a file" dialog.
    const selectFile = () => {
        setError(null);
        setHovering(false);
        uploadRef.current.click();
    }

    // Start the upload, handle its response.
    const uploadFile = async (file) => {
        if (!file.type.startsWith('image')) {
            setError('File must be an image.');
            return;
        }
        if (file.size >= 10000000) {
            setError('File must be smaller than 10mb.');
            return;
        }
        setLoading(true);
        try {
            const result = await sendFile(`${window.env.trellisBaseUrl}/v1/app/${app.id}/thumb`, file);
            setThumbnail(result);
        } finally {
            setLoading(false);
        }
    }
    
    const imageClass = hovering ? `${classes.image} ${classes.image_hover}` : classes.image;
    
    return (
        <div 
            className='MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth'
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            style={{ marginTop: '5px', marginBottom: '5px' }}
        >
            <FormLabel className='MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled'>Thumbnail</FormLabel>
            <input ref={uploadRef} type='file' style={{ display: 'none' }} />
            <img 
                className={imageClass} 
                src={thumb ? thumb.url : 'assets/img/Logo.Gray.png'}
            />
            {hovering && !loading &&
                <div className={classes.controls}>
                    {thumb && 
                        <IconButton onClick={() => removeThumbnail(app.id)}>
                            <Close />
                        </IconButton> 
                    }
                    <div style={{ flexGrow: 1 }} />
                    <IconButton onClick={selectFile}>
                        <Publish />
                    </IconButton>
                </div>
            }
            
            {loading &&
                <div className={classes.controls}>
                    <div style={{ flexGrow: 1 }} />
                    <CircularProgress style={{ alignSelf: 'center'}} />
                    <div style={{ flexGrow: 1 }} />
                </div>
            }
            
            {error &&
                <Typography 
                    variant="h6" 
                    color="error"
                    style={{ textAlign: 'center' }}
                >
                    {error}
                </Typography>
            }
        </div>
    );
};

InspectableThumbnail.propTypes = {
    style: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    thumb: PropTypes.object,
    removeThumbnail: PropTypes.func.isRequired,
    setThumbnail: PropTypes.func.isRequired
}

const mapStoreToProps = store => ({
    app: store.app.info,
    thumb: store.thumbs[store.app.info.thumb]
});

const mapDispatchToProps = dispatch => ({
    removeThumbnail: (appId) => dispatch(deletethumb({ appId })),
    setThumbnail: (thumb) => dispatch(updateThumb(thumb))
});

export default connect(mapStoreToProps, mapDispatchToProps)(withStyles(theme => ({
    controls: {
        position: 'absolute',
        display: 'flex',
        width: '100%',
        height: '100%',
        padding: '5px',
        alignItems: 'flex-start'
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#505566',
        transition: 'filter 0.25s'
    },
    image_hover: {
        filter: 'blur(5px) saturate(10%)',
        transition: 'filter 1s'
    }
}))(InspectableThumbnail));