import { AsyncStatus, getActions, UPDATEANASSET } from '@enklu/server-api';
import {
  Box,
  Button,
  Dialog,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
 CloudDownload, CloudUpload, Delete, Public, VpnLock
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { assetThumbUrl, getAssetTagsArray } from '../../../util/util';
import { getAppInfo } from '../../../store/selectors/appSelectors';
import { getCanManageStandardAssets, getUserProfile } from '../../../store/selectors/userSelectors';
import { getLibraries } from '../../../store/selectors/assetLibrariesSelectors';
import { getScenes } from '../../../store/selectors/scenesSelectors';
import { getUserCache } from '../../../store/selectors/userCacheSelectors';
import { removeAssetFromElements } from '../../../util/appHelpers';
import AssetStats from './MuiAssetStats';
import AssetThumb from './MuiAssetThumb';
import AssetUploader from './MuiAssetUploader';
import BaseModal from '../../modals/MuiBaseModal';
import ConfirmationModal from '../../modals/MuiConfirmationModal';
import ErrorList from '../common/MuiErrorList';
import FilledIconButton from '../../material-ui/FilledIconButton';
import InspectableIdentity from '../common/MuiInspectableIdentity';
import ItemSharing from '../MuiItemSharing';
import ItemTags from '../MuiItemTags';
import LibraryTypes from '../../../constants/LibraryTypes';

const {
  getuser,
  updateanasset,
  deletemyasset,
  getappassets,
  getpersonalassets,
  promoteassettostandard,
  demoteassettopersonal
} = getActions('trellis');

/**
 * Builds an array of share objects required by the ItemSharing component.
 */
const buildShares = (libraries, asset) => {
  const newShare = (name, shared) => ({
    name,
    shared: shared !== undefined
  });
  return [
    newShare('Experience', libraries[LibraryTypes.APP][asset.id]),
    newShare('User', libraries[LibraryTypes.USER][asset.id]),
    newShare('Public', libraries[LibraryTypes.PUBLIC][asset.id])
  ];
};

/**
 * Page that displays information about a single asset.
 */
class AssetInspector extends React.Component {
  static propTypes = {
    asset: PropTypes.object,
    appId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    assetImportErrors: PropTypes.object.isRequired,
    userCache: PropTypes.object.isRequired,
    libraries: PropTypes.object.isRequired,
    updateAsset: PropTypes.func.isRequired,
    deleteAsset: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    scenes: PropTypes.array.isRequired,
    promote: PropTypes.func.isRequired,
    demote: PropTypes.func.isRequired,
    canManageStandardAssets: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequred,

    // withRouter
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      downloadModal: false,
      deleteModal: false,
      updateThumbModal: false
    };
  }

  /**
   * Called when a tag has been requested to be added.
   */
  tagsOnAdd(tag) {
    const { asset } = this.props;

    // TODO We should be able to eliminate tags in local state now.
    const tags = asset.tags.split(',');
    if (tags.indexOf(tag) !== -1) {
      return;
    }

    tags.push(tag);

    this.props.updateAsset({
      id: asset.id,
      tags: tags.join(',')
    });
  }

  /**
   * Called when a tag has been requested to be removed.
   */
  tagsOnRemove(tag) {
    const { asset } = this.props;
    const tags = asset.tags.split(',');
    const index = tags.indexOf(tag);
    if (index === -1) {
      return;
    }

    tags.splice(index, 1);

    this.props.updateAsset({
      id: asset.id,
      tags: tags.join(',') || ''
    });
  }

  renderDownloadModal() {
    const { asset } = this.props;
    const { downloadModal } = this.state;

    // create download links for all versions
    const links = [];
    for (let i = asset.version; i >= 0; i -= 1) {
      links.push(
        <TableRow key={i}>
          <TableCell>{i === asset.version ? `${i} (latest)` : `${i}`}</TableCell>
          <TableCell>
            <img style={{ height: '40px' }} src={assetThumbUrl(asset, i)} alt="thumb" />
          </TableCell>
          <TableCell>
            <Link
              href={`${window.env.bundlesUrl.replace('bundles', 'src')}${asset.id}.${i}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </Link>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <Dialog open={downloadModal} onClose={() => this.setState({ downloadModal: false })}>
        <BaseModal
          title={'All Versions'}
          actions={
            <Button variant="outlined" onClick={() => this.setState({ downloadModal: false })}>
              Close
            </Button>
          }
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Version</TableCell>
                  <TableCell>Thumbnail</TableCell>
                  <TableCell>Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{links}</TableBody>
            </Table>
          </TableContainer>
        </BaseModal>
      </Dialog>
    );
  }

  renderUploadModal() {
    const { uploadModal } = this.state;
    const { asset, appId } = this.props;

    return (
      <Dialog open={uploadModal} onClose={() => this.setState({ uploadModal: false })}>
        <BaseModal
          title={'Update Asset'}
          actions={
            <Button variant="outlined" onClick={() => this.setState({ uploadModal: false })}>
              Close
            </Button>
          }
        >
          <AssetUploader
            asset={asset}
            appId={appId}
            endpoint={`${window.env.assetBaseUrl}/v1/asset`}
            customHeaders={{ app: appId }}
          />
        </BaseModal>
      </Dialog>
    );
  }

  renderDeleteModal() {
    const {
      asset: { id, name },
      scenes,
      appId,
      userId,
      deleteAsset,
      classes
    } = this.props;
    const { deleteModal } = this.state;

    return (
      <ConfirmationModal
        open={!!deleteModal}
        title="Delete"
        message={`Are you sure you want to permanently delete '${name}'?`}
        confirmIsDanger
        onClose={() => this.setState({ deleteModal: false })}
        onConfirm={() => {
          deleteAsset({
            appId,
            userId,
            scenes,
            assetId: id
          });

          this.setState({ deleteModal: false });
        }}
      />
    );
  }

  renderStandardModal() {
    const {
      asset: { id, name },
      promote,
      demote,
      classes
    } = this.props;
    const { demoteModal, promoteModal } = this.state;

    return (
      <React.Fragment>
        <ConfirmationModal
          open={promoteModal}
          title="Promote to Standard Asset"
          message={`Are you sure you want to promote '${name}' to a standard asset?`}
          onClose={() => this.setState({ promoteModal: false })}
          onConfirm={() => {
            promote({ assetId: id });

            this.setState({ promoteModal: false });
          }}
        />
        <ConfirmationModal
          open={demoteModal}
          title="Demote from Standard Asset"
          message={`Are you sure you want to demote '${name}' from the standard asset library?`}
          onClose={() => this.setState({ demoteModal: false })}
          confirmIsDanger
          onConfirm={() => {
            demote({ assetId: id });

            this.setState({ demoteModal: false });
          }}
        />
      </React.Fragment>
    );
  }

  renderUpdateThumbModal() {
    const { updateThumbModal } = this.state;
    const { asset, appId, updateAsset } = this.props;

    return (
      <Dialog open={updateThumbModal} onClose={() => this.setState({ updateThumbModal: false })}>
        <BaseModal
          title={'Update Thumbnail Image'}
          actions={
            <Button variant="outlined" onClick={() => this.setState({ updateThumbModal: false })}>
              Close
            </Button>
          }
        >
          <AssetUploader
            asset={asset}
            appId={appId}
            endpoint={`${window.env.trellisBaseUrl}/v1/asset/${asset.id}`}
            allowedExtensions={['jpg', 'jpeg', 'png']}
            method="PUT"
            fileName="thumb"
            onComplete={(response) => {
              updateAsset(response.body);
            }}
          />
        </BaseModal>
      </Dialog>
    );
  }

  /**
   * Renders controls.
   */
  render() {
    const {
      asset,
      assetImportErrors,
      userCache,
      libraries,
      loadUser,
      updateAsset,
      userId,
      canManageStandardAssets,
      classes
    } = this.props;

    if (!asset) {
      return (
        <Box p={1}>
          <Typography variant="h5">No asset found.</Typography>
        </Box>
      );
    }

    const { id, owner } = asset;
    const errors = assetImportErrors[id] || [];
    const shares = buildShares(libraries, asset);

    const isOwner = userId === owner;
    const isStandardAsset = owner === 'standard';

    const downloadable = !isStandardAsset || canManageStandardAssets;
    const uploadable = (isStandardAsset && canManageStandardAssets) || isOwner;
    const promotable = canManageStandardAssets && !isStandardAsset;
    const demotable = canManageStandardAssets && isStandardAsset;
    const deletable = isOwner;
    const showActions = downloadable || uploadable || promotable || demotable || deletable;

    return (
      <Box p={1} overflow="auto" className="scrollable-y">
        <InspectableIdentity
          inspectable={asset}
          onUpdate={({ name: key, value }) => updateAsset({ [key]: value, id })}
        />

        <Box my={1}>
          <AssetThumb asset={asset} canManage={uploadable} onUpdate={() => this.setState({ updateThumbModal: true })} />
        </Box>

        {showActions && (
          <Box>
            <Typography variant="h6">Actions</Typography>

            <Box flexDirection="row" alignItems="center" className={classes.actionsContainer}>
              {downloadable && (
                <Tooltip title={'Download'} placement="top" arrow>
                  <FilledIconButton
                    icon={CloudDownload}
                    color="primary"
                    onClick={() => this.setState({ downloadModal: true })}
                  />
                </Tooltip>
              )}

              {uploadable && (
                <Tooltip title={'Upload'} placement="top" arrow>
                  <FilledIconButton
                    icon={CloudUpload}
                    color="primary"
                    onClick={() => this.setState({ uploadModal: true })}
                  />
                </Tooltip>
              )}

              {promotable && (
                <Tooltip title={'Promote to Standard'} placement="top" arrow>
                  <FilledIconButton
                    icon={Public}
                    color="primary"
                    onClick={() => this.setState({ promoteModal: true })}
                  />
                </Tooltip>
              )}

              {demotable && (
                <Tooltip title={'Demote from Standard'} placement="top" arrow>
                  <FilledIconButton icon={VpnLock} color="error" onClick={() => this.setState({ demoteModal: true })} />
                </Tooltip>
              )}

              {deletable && (
                <Tooltip title={'Delete'} placement="top" arrow>
                  <FilledIconButton icon={Delete} color="error" onClick={() => this.setState({ deleteModal: true })} />
                </Tooltip>
              )}
            </Box>
          </Box>
        )}

        <Box mt={1}>
          <AssetStats asset={asset} userCache={userCache} loadUser={loadUser} version={asset.version} />
          <ErrorList errors={errors} />
          <ItemTags
            item={asset}
            canManageStandardAssets={canManageStandardAssets}
            tagResolver={tags => getAssetTagsArray(tags)}
            onAddTag={tag => this.tagsOnAdd(tag)}
            onRemoveTag={tag => this.tagsOnRemove(tag)}
          />
          <ItemSharing shares={shares} />
        </Box>

        {this.renderDownloadModal()}
        {this.renderUploadModal()}
        {this.renderDeleteModal()}
        {this.renderStandardModal()}
        {this.renderUpdateThumbModal()}
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    inspector: {
      asset: { assetImportErrors }
    }
  } = state;

  return {
    assetImportErrors,
    canManageStandardAssets: getCanManageStandardAssets(state),
    userId: getUserProfile(state).id,
    appId: getAppInfo(state).id,
    scenes: getScenes(state),
    userCache: getUserCache(state),
    libraries: getLibraries(state)
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  /**
   * Updates assert meta information, but not source.
   */
  updateAsset: ({
 id, name, description, tags
}) => dispatch(
      updateanasset(
        {
          name,
          description,
          tags
        },
        { assetId: id }
      )
    ),

  /**
   * Deletes an asset!
   */
  deleteAsset: ({
 appId, userId, scenes, assetId
}) => {
    removeAssetFromElements({
      appId,
      assetId,
      scenes,
      dispatch
    });

    dispatch(deletemyasset({ assetId })).then(() => Promise.all([dispatch(getpersonalassets({ userId })), dispatch(getappassets({ appId }))]));
  },

  /**
   * Promote asset to a standard asset.
   */
  promote: ({ assetId }) => dispatch(promoteassettostandard({ assetId })),

  /**
   * Demote asset from a standard asset to a personal one.
   */
  demote: ({ assetId }) => dispatch(demoteassettopersonal({ assetId })),

  /**
   * Loads a user into the user cache.
   */
  loadUser: ({ userId }) => dispatch(getuser({ userId }))
});

export default withStyles(theme => ({
  actionsContainer: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  }
}))(withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetInspector)));
