import { AsyncStatus, TrellisActions } from '@enklu/server-api';

import { INITIALIZE } from '../actions/initializeActions';

const { DELETEAPPCOLLABORATOR, GETAPPCOLLABORATORS, SEARCHUSERSBYEMAIL } = TrellisActions;

const initialState = {
  byAppId: {}, /* of CollaboratorInterface{} */
  byUserId: {}, /* of CollaboratorInterface{} */

  searchResults: [] /* of UserInterface */
};

// REDUCERS
export const mapCollabsToUsers = (collaborators = [], userMap = {}) => collaborators
  .filter(({ userId }) => !!userMap[userId])
  .map(({ userId }) => userMap[userId]);

const byAppId = (state = initialState.byAppId, action) => {
  switch (action.type) {
    // When the user selects an app, we makes sure we have space for the collaborators.
    case INITIALIZE: {
      const { payload: { appId } } = action;
      return state[appId]
        ? state
        : {
          ...state,
          [appId]: []
        };
    }

    case GETAPPCOLLABORATORS: {
      if (action.status === AsyncStatus.SUCCESS) {
        return {
          ...state,
          [action.request.replacements.appId]: action.body
        };
      }

      return state;
    }

    default:
      return state;
  }
};

const byUserId = (state = initialState.byUserId, action) => {
  switch (action.type) {
    // A new one blows away the cache.
    case GETAPPCOLLABORATORS: {
      if (action.status === AsyncStatus.SUCCESS) {
        return action.body.reduce((accum, collaborator) => ({
          ...accum,
          [collaborator.userId]: collaborator
        }), state);
      }

      return state;
    }

    // This also kills the cache.
    // We expect a GETAPPCOLLABORATORS to be called next.
    // TODO This might be dumb.
    case DELETEAPPCOLLABORATOR: {
      if (action.status === AsyncStatus.SUCCESS) {
        return {};
      }

      return state;
    }

    default:
      return state;
  }
};

/**
 * This array actually contains Users instead of Collaborators (@see ValueObjects).
 * That means they don't need transformed for use in the UI.
 *
 * TODO Is that really ideal?
 */
const searchResults = (state = initialState.searchResults, action) => {
  switch (action.type) {
    // case CLEAR_COLLABORATORS_SEARCH:
    //     return [];

    case SEARCHUSERSBYEMAIL: {
      switch (action.status) {
        case AsyncStatus.SUCCESS: {
          return action.body;
        }

        case AsyncStatus.FAILURE: {
          return [];
        }

        default:
          return state;
      }
    }

    default:
      return state;
  }
};

export default (state = initialState, action) => {
  const newState = {
    byAppId: byAppId(state.byAppId, action),
    byUserId: byUserId(state.byUserId, action),
    searchResults: searchResults(state.searchResults, action)
  };

  return newState;
};
