import { getBaseActions, initActions, ioLoader, TrellisActions } from '@enklu/server-api';
import * as Sentry from '@sentry/browser';
import { createUndoActions } from './undoHelper';
import undoManager from './UndoManager';
import ActionTypes from '../../constants/ActionTypes';

initActions('trellis', { loader: ioLoader, baseUrl: window.env.trellisBaseUrl, Actions: TrellisActions });
const { putify } = getBaseActions('trellis');

// create network action for updating scene
const UPDATESCENE_URI = '/v1/editor/app/:appId/scene/:sceneId';
export const UPDATESCENE = 'updatescene';
export const updateScene = putify(UPDATESCENE, UPDATESCENE_URI);

/**
 * Formats actions for passing to the client.
 */
const prepActions = (_actions) => {
  // deep copy
  const actions = JSON.parse(JSON.stringify(_actions));

  for (let i = 0, len = actions.length; i < len; i++) {
    if (typeof actions[i].value === 'object') {
      actions[i].value = JSON.stringify(actions[i].value);
    }

    // HACK
    if (actions[i].type === 'move') {
      actions[i].schemaType = ElementActions.SCHEMATYPE_VEC3;
    }
  }

  return actions;
};

/**
 * Manages previewing and requesting actions.
 */
class TxnManager {
  _store = null;

  _dispatch = null;

  initialize(store, dispatch) {
    this._store = store;
    this._dispatch = dispatch;
  }

  /**
   * Sends a preview to the webgl client.
   */
  preview(sceneId, ...actions) {
    for (const action in actions) {
      if (action.type !== ActionTypes.UPDATE) {
        return;
      }
    }

    window.bridge.send(
      3993,
      {
        sceneId,
        actions: prepActions(actions)
      }
    );
  }

  createUndos(actions) {
    const { 
      app: {
        scenes: { rawScenes }
      },
    } = this._store.getState();
    
    const scene = rawScenes[0];    
    return createUndoActions(scene, actions);
  }

  /**
   * Makes a request that will be authenticated by the server. This
   * automatically calls preview as well.
   */
  request(sceneId, ...actions) {
    const { 
      app: {
        info: { id: appId },
      },
    } = this._store.getState();

    // preview first
    this.preview(sceneId, ...actions);

    // create undos
    const undoActions = this.createUndos(actions);
    undoManager.register(
      updateScene({ actions }, { appId, sceneId }),
      updateScene({ actions: undoActions }, { appId, sceneId }));

    // send request
    return this.dispatchUpdate(sceneId, ...actions);
  }

  /**
   * Makes a request theat will be authenticated by server without
   * previewing or registering undo/redo actions.
   */
  dispatchUpdate(sceneId, ...actions) {
    // get app id
    const { 
      app: {
        info: { id: appId },
      },
    } = this._store.getState();

    // make network request
    return this._dispatch(updateScene({ actions }, {
        appId,
        sceneId
      })).catch(Sentry.captureException);
  }
}

const txns = new TxnManager();

export default txns;
