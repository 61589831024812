// Actions to be used, as part of editron's UX.
export const UPDATE_THUMB = 'thumb.update';
export const updateThumb = thumb => ({
    type: UPDATE_THUMB,
    thumb
});

// Actions to be used, in response to webgl events.
export const THUMB_GENERATED = 'thumb.generated';
export const notifyGeneratedThumb = thumb => ({
    type: THUMB_GENERATED,
    thumb
});