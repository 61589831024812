// TODO: Refactor all of this into less of a mess
import {
  Box,
  CircularProgress,
  DialogContentText,
  TextField,
  Typography
} from '@material-ui/core';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { getActions } from '@enklu/server-api';
import PropTypes from 'prop-types';
import React from 'react';
import uuid from 'uuid';

import {
  updateAction,
  createAction,
  createFullElementAction,
  createFromLinkedComponentAction,
  deleteAction
} from '../../../store/actions/elementActions';
import { loadLinkedComponent } from '../../../store/actions/initializeActions';
import { parentByChildId } from '../../../util/appHelpers';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import InspectableIdentity, { elementToInspectable } from '../common/MuiInspectableIdentity';
import InspectorInterface from '../common/InspectorInterface';
import Transform from '../MuiTransform';
import txns from '../../../store/actions/TxnManager';
import BaseButton from '../../material-ui/BaseButton';
import CheckboxField from '../../material-ui/CheckboxField';

const { getappassets, getappscripts, getscene } = getActions('trellis');


/**
 * Custom inspector for linked components.
 */
class LinkedComponentElementInspector extends React.Component {
  /**
   * Constructor.
   */
  constructor(props) {
    super(props);

    const { element } = props;
    const {
      id,
      schema: {
        strings: {
          label = '',
          linkedExperienceId = '',
          linkedExperienceUpdatedAt = '',
          linkedExperienceInfo = '',
          linkedExperienceElements = '',
          linkedExperienceName = '',
          linkedExperienceSchema = '',
          linkedExperienceChildren = ''
        } = {}
      } = {}
    } = element;

    this.state = {
      settingsTextValue: label,
      elementId: id,
      linkedExperienceId,
      linkedExperienceUpdatedAt,
      linkedExperienceInfoValue: linkedExperienceInfo,
      linkedExperienceElementsValue: linkedExperienceElements,
      linkedExperienceNameValue: linkedExperienceName,
      linkedExperienceSchemaValue: linkedExperienceSchema,
      linkedExperienceChildrenValue: linkedExperienceChildren,
      showLinkedComponentJSON: false,
      experienceIdErrorText: '',
      elementsToCreate: [],
      includeScriptIds: false,
      includeOriginalScriptIds: false,
      experienceHasUpdated: false,
      updatesHaveBeenCheckedFor: false,
      nextExperienceUpdatedAt: '',
      busy: false
    };
  }

  /**
   * Called before component receives new props.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { element } = nextProps;
    const {
      id,
      schema: {
        strings: {
          label = '',
          linkedExperienceId = '',
          linkedExperienceUpdatedAt = '',
          linkedExperienceInfo = '',
          linkedExperienceElements = '',
          linkedExperienceName = '',
          linkedExperienceSchema = '',
          linkedExperienceChildren = ''
        } = {}
      } = {}
    } = element;

    // element changed
    if (this.state.elementId !== id) {
      this.setState({
        ...this.state,
        elementId: id,
        settingsTextValue: label,
        linkedExperienceId,
        linkedExperienceUpdatedAt,
        linkedExperienceInfoValue: linkedExperienceInfo,
        linkedExperienceElementsValue: linkedExperienceElements,
        linkedExperienceNameValue: linkedExperienceName,
        linkedExperienceSchemaValue: linkedExperienceSchema,
        linkedExperienceChildrenValue: linkedExperienceChildren,
        showLinkedComponentJSON: false,
        experienceIdErrorText: '',
        elementsToCreate: [],
        includeScriptIds: false,
        includeOriginalScriptIds: false,
        experienceHasUpdated: false,
        nextExperienceUpdatedAt: '',
        updatesHaveBeenCheckedFor: false,
        busy: false
      });
    }
  }

  toggleLinkComponentJSON = () => {
    this.setState({ showLinkedComponentJSON: !this.state.showLinkedComponentJSON || false });
  }

  /**
   * Returns true iff text is valid input.
   */
  isLabelValid() {
    const val = this.state.settingsTextValue || '';
    if (val.length > 255) {
      return false;
    }

    try {
      // we have to be able to encode it
      btoa(val);

      return true;
    } catch (ex) {
      return false;
    }
  }

  // TODO: break getExperienceData, getSceneData, importAssetLibrary, importScriptLibrary functions
  //  out of this component, put into a util, and refactor all the messy and duplicate code
  async getExperienceData(experienceId) {
    let requestedExperienceId = this.linkedExperienceIdValue || experienceId;
    let method = 'id';
    if (requestedExperienceId.includes('?app=')) {
      const extractedId = requestedExperienceId.split('?app=').pop();
      requestedExperienceId = extractedId;
      method = 'URL';
    }

    const appControllerRoute = `/v1/editor/app/${requestedExperienceId}`;
    const endpoint = window.env.trellisBaseUrl + appControllerRoute;

    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${io.sails.token}`,
        'Content-Type': 'application/json'
      },
      body: null,
      replacements: {
        appId: requestedExperienceId,
        userId: io.sails.user.id
      }
    });

    const { status, statusText } = response;
    if (status === 200) {
      // Clear error text
      this.setState({ experienceIdErrorText: '' });
    } else {
      const errorText = `Error: ${statusText}.  Status: ${status}.  Unable to get experience data for ${experienceId}.  
        Please check that the input experience ${method} is correct and that you have permission to access its data.`;
      this.setState({ experienceIdErrorText: errorText });
    }

    return response.json();
  }

  async getSceneData(experienceId, sceneId) {
    let requestedExperienceId = this.linkedExperienceIdValue || experienceId;
    let method = 'id';
    if (requestedExperienceId.includes('?app=')) {
      const extractedId = requestedExperienceId.split('?app=').pop();
      requestedExperienceId = extractedId;
      method = 'URL';
    }
    const requestedSceneId = sceneId;
    const appControllerRouteScene = `/v1/editor/app/${requestedExperienceId}/scene/${requestedSceneId}`;
    const endpoint = window.env.trellisBaseUrl + appControllerRouteScene;

    const publicAppScenesResponse = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${io.sails.token}`,
        'Content-Type': 'application/json'
      },
      body: null,
      replacements: {
        publicAppId: requestedExperienceId,
        publicSceneId: requestedSceneId,
        userId: io.sails.user.id
      }
    });

    const { status, statusText } = publicAppScenesResponse;
    if (status === 200) {
      // Clear error text
      this.setState({ experienceIdErrorText: '' });
    } else {
      const errorText = `Error: ${statusText}.  Status: ${status}.  Unable to get scene data for ${experienceId}.  
        Please check that the input experience ${method} is correct and that you have permission to access its data.`;
      this.setState({ experienceIdErrorText: errorText });
    }

    return publicAppScenesResponse.json();
  }

  async importAssetLibrary(appId, experienceId) {
    let requestedExperienceId = this.linkedExperienceIdValue || experienceId;
    if (requestedExperienceId.includes('?app=')) {
      const extractedId = requestedExperienceId.split('?app=').pop();
      requestedExperienceId = extractedId;
    }

    const assetControllerRoute = `/v1/editor/app/${appId}/importLibrary/${requestedExperienceId}`;
    const endpoint = window.env.trellisBaseUrl + assetControllerRoute;

    const importAssetLibraryResponse = await fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${io.sails.token}`,
        'Content-Type': 'application/json'
      },
      body: null,
      replacements: {
        appId,
        requestedExperienceId,
        userId: io.sails.user.id
      }
    });

    const { status, statusText } = importAssetLibraryResponse;
    if (status === 200) {
      // console.log('importAssetLibrary status 200!');
    } else {
      const errorText = `importAssetLibrary status: ${status}.  Error: ${statusText}.`;
      console.log(errorText);
    }

    return importAssetLibraryResponse.json();
  }

  async importScriptLibrary(appId, experienceId) {
    let requestedExperienceId = this.linkedExperienceIdValue || experienceId;
    if (requestedExperienceId.includes('?app=')) {
      const extractedId = requestedExperienceId.split('?app=').pop();
      requestedExperienceId = extractedId;
    }

    const scriptLibControllerRoute = `/v1/script-library/app/${appId}/importLibrary/${requestedExperienceId}`;
    const endpoint = window.env.trellisBaseUrl + scriptLibControllerRoute;

    const importScriptLibraryResponse = await fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${io.sails.token}`,
        'Content-Type': 'application/json'
      },
      body: null,
      replacements: {
        appId,
        requestedExperienceId,
        userId: io.sails.user.id
      }
    });

    const { status, statusText } = importScriptLibraryResponse;
    if (status === 200) {
      // console.log('importScriptLibrary status 200!');
    } else {
      const errorText = `importScriptLibrary status: ${status}.  Error: ${statusText}.`;
      console.log(errorText);
    }

    return importScriptLibraryResponse.json();
  }

  prepareChildObjects(preparedElements, arrayOfChildren, sceneId, elementId) {
    if (arrayOfChildren.length > 0) {
      for (let i = 0; i < arrayOfChildren.length; i++) {
        const nodeId = uuid();
        const childToCreate = {
          sceneId,
          parentId: elementId,
          schema: arrayOfChildren[i].schema,
          type: arrayOfChildren[i].type,
          nodeId
        };

        // Update array with prepared child objects
        preparedElements.push(childToCreate);

        // Check if the element has any children of its own
        if (arrayOfChildren[i].children && arrayOfChildren[i].children.length > 0) {
          this.prepareChildObjects(preparedElements, arrayOfChildren[i].children, sceneId, nodeId);
        }
      }
    }
    return preparedElements;
  }

  // We need asset library update to be async because it is making requests to Trellis and
  // so all asset ids are ready to be used before element that needs them is created.
  async updateAssetLibrary(experienceId) {
    this.props.getUpdatedExperienceAssets(experienceId);
  }

  // We need script library update to be async because it is making requests to Trellis and
  // so all script ids are ready to be used before element that needs them is created.
  async updateScriptLibrary(experienceId) {
    this.props.getUpdatedExperienceScripts(experienceId);
  }

  initiateElementCreation = (arrayOfElementsToCreate, appId, sceneId, elementId, isInitialLink) => {
    this.props.loadLinkedComponent(appId);
    if (arrayOfElementsToCreate) {
      for (let i = 0; i < arrayOfElementsToCreate.length; i++) {
        this.createElements(arrayOfElementsToCreate[i])
          .then(() => {
            // console.log('element created: ', arrayOfElementsToCreate[i]);
          });
      }
    }

    if (this.state.includeScriptIds && !this.state.includeOriginalScriptIds) {
      this.importScriptLibrary(appId, this.state.linkedExperienceId)
        .then(() => {
          console.log('importScriptLibrary completed');
          this.updateScriptLibrary(appId)
            .then(() => {
              console.log('Script library updated!');
            });
        });
    }

    if (isInitialLink) {
      // Delete used linked component
      this.props.deleteElement(sceneId, elementId);
    } else {
      this.setState({ linkedExperienceUpdatedAt: this.state.nextExperienceUpdatedAt });
      this.setState({ experienceHasUpdated: false });
      // Add an extra second of loading feedback to give enkluplayer time on its end
      setTimeout(() => {
        this.setState({ updatesHaveBeenCheckedFor: false });
        this.setState({ busy: false });
      }, 1000);
    }
  }

  // We need element creation to be async because it is making requests to Trellis and
  // so an element's parent actually exists before the child is attempted to be created.
  async createElements(elementToCreate) {
    let includeScriptIds = false;
    if (this.state.includeOriginalScriptIds || this.state.includeScriptIds) {
      includeScriptIds = true;
    }
    this.props.createFullElements(
      elementToCreate.sceneId,
      elementToCreate.parentId,
      elementToCreate.schema,
      elementToCreate.type,
      elementToCreate.nodeId,
      includeScriptIds
    );
  }

  createNewElements(sceneId, element, currentScene, isInitialLink) {
    // Set up object for linked experience root element
    const parentId = element.parent.id;
    const arrayOfElementsToCreate = [];
    let nodeId = element.id;
    if (isInitialLink) {
      nodeId = uuid();
      const rootElementToCreate = {
        sceneId,
        parentId,
        schema: this.state.linkedExperienceSchemaValue,
        type: 'EXPERIENCE_ROOT_COPY',
        nodeId
      };

      // Prepare the array of objects required for element creation.
      arrayOfElementsToCreate.push(rootElementToCreate);
    }

    this.prepareChildObjects(arrayOfElementsToCreate, this.state.linkedExperienceChildrenValue, sceneId, nodeId);

    this.setState({ elementsToCreate: arrayOfElementsToCreate });
  }

  /**
   * Render while linked component is loading new data.
   */
  renderLoading = () => this.state.busy && (
    <Box
      zIndex={1}
      position="absolute"
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={40} />
    </Box>
  );

  didExperienceUpdate = (linkedExperienceId, linkedExperienceUpdatedAt, sceneId, elementId, scene, element) => {
    let experienceHasUpdated = false;
    this.getExperienceData(linkedExperienceId)
      .then((appDataResponse) => {
        this.getSceneData(linkedExperienceId, appDataResponse.body.scenes[0])
          .then((sceneDataResponse) => {
            const sceneData = JSON.stringify(sceneDataResponse);
            const sceneDataElements = JSON.parse(sceneDataResponse.body.elements);
            const experienceUpdatedAt = sceneDataResponse.body.updatedAt;

            if (linkedExperienceUpdatedAt !== experienceUpdatedAt) {
              experienceHasUpdated = true;
              this.setState({ experienceHasUpdated: true });
            }

            this.setState({ nextExperienceUpdatedAt: experienceUpdatedAt });
            this.setState({ linkedExperienceInfoValue: sceneData });
            this.setState({ linkedExperienceElementsValue: sceneDataElements });
            this.setState({ linkedExperienceSchemaValue: sceneDataElements.schema });
            this.setState({ linkedExperienceChildrenValue: sceneDataElements.children });

            this.setState(prevState => ({
              linkedExperienceSchemaValue: {
                ...prevState.linkedExperienceSchemaValue,
                strings: {
                  ...prevState.linkedExperienceSchemaValue.strings,
                  name: this.state.linkedExperienceNameValue,
                  linkedExperienceId: this.state.linkedExperienceId,
                  linkedExperienceUpdatedAt: sceneDataResponse.body.updatedAt
                }
              }
            }));

            this.props.updateElementString(
                sceneId, elementId, 'linkedExperienceInfo', sceneData || ''
            );
          })
            .finally(() => {
              // Regardless of if there are updates found, we still want to prepare elements
              // in case the user wants to manually regenerate their elements for whatever reason.
              this.createNewElements(sceneId, element, scene, false);
              this.setState({ updatesHaveBeenCheckedFor: true });
              this.setState({ busy: false });
            });
      });
  }

  async deleteChildElements(sceneId, childElements) {
    for (let i = 0; i < childElements.length; i++) {
      this.props.deleteElement(sceneId, childElements[i].id);
    }
  }

  /**
   * Custom inspector for already statically linked components.
   */
  renderStaticLinkedExperience = (linkedExperienceId, linkedExperienceUpdatedAt, sceneId, elementId, scene, element) => (
    <React.Fragment>
      <TextField
        multiline
        label="Linked Experience Id (READ ONLY)"
        value={linkedExperienceId}
        fullWidth
        variant="outlined"
        InputProps={{
          readOnly: true,
        }}
      />
      <DialogContentText>
        Last update: {linkedExperienceUpdatedAt}
      </DialogContentText>
      <BaseButton
        color="secondary" variant="contained" size="medium" title="Check for updates"
        onClick={() => {
          this.setState({ busy: true });
          this.didExperienceUpdate(linkedExperienceId, linkedExperienceUpdatedAt, sceneId, elementId, scene, element);
        }}
      >
        Check for experience updates
      </BaseButton>
      {this.state.updatesHaveBeenCheckedFor
        && <React.Fragment>
          {this.state.experienceHasUpdated
            ? <React.Fragment>
              <Typography variant="h6">Updates Available</Typography>
              <DialogContentText>
                Latest update: {this.state.nextExperienceUpdatedAt}
              </DialogContentText>
              <DialogContentText>
                Number of elements to be updated*: {this.state.elementsToCreate.length}
              </DialogContentText>
              <CheckboxField
                  label="Make new copies of scripts in this experience*"
                  value={this.state.includeScriptIds}
                  onChange={({ target: { checked } }) => {
                    this.setState({ includeScriptIds: checked });
                  }}
              />
              {this.state.includeScriptIds
              && <DialogContentText>
                *Note that the inclusion of script ids will take longer and result in warning logs when trying to load
                new scripts the first time.  Reloading the experience should allow the scripts to load as expected.
                The checkbox below will overwrite this one and no copies will be made if both are checked.
              </DialogContentText>
              }
              <CheckboxField
                  label="Include original script ids only; don't make copies.**"
                  value={this.state.includeOriginalScriptIds}
                  onChange={({ target: { checked } }) => {
                    this.setState({ includeOriginalScriptIds: checked });
                  }}
              />
              {this.state.includeOriginalScriptIds
              && <DialogContentText>
                **Note that the inclusion of original script ids will result in warning logs for each script that
                cannot load.  This is mainly for dev use.  If you are unsure if you need this, leave it unchecked.
                This checkbox will overwrite the first checkbox if both are checked.
              </DialogContentText>
              }
              <BaseButton
                color="secondary" variant="contained" size="medium" title="Apply latest updates"
                onClick={() => {
                  this.setState({ busy: true });
                  this.deleteChildElements(sceneId, element.children)
                    .then(() => {
                      const currentAppId = this.props.app.info.id;
                      // Get access to all the new assets we need
                      this.importAssetLibrary(currentAppId, this.state.linkedExperienceId)
                        .then(() => {
                          // Update editron's cached asset library so it knows it can use the new assets
                          this.updateAssetLibrary(currentAppId)
                            .then(() => {
                              // Create the new elements as children of the linked component
                              this.initiateElementCreation(this.state.elementsToCreate, currentAppId, sceneId, elementId);
                            });
                        });
                    })
                    .finally(() => {
                      this.props.updateElementString(
                        sceneId, elementId, 'linkedExperienceUpdatedAt', this.state.nextExperienceUpdatedAt || ''
                      );
                    });
                }}
              >
                Apply latest updates***
              </BaseButton>
              <DialogContentText>
                ***At this time &quot;applying updates&quot; means all elements nested under this
                linked component will be deleted and recreated from the updated experience data.
                Any local changes or additions made to the children within this linked component will be lost.
              </DialogContentText>
            </React.Fragment>
            : <React.Fragment>
              <Typography variant="h6">No Updates Detected</Typography>
              <DialogContentText>
                Latest update: {this.state.nextExperienceUpdatedAt}
              </DialogContentText>
              <DialogContentText>
                Number of elements in experience*: {this.state.elementsToCreate.length}
              </DialogContentText>
              <CheckboxField
                label="Make new copies of scripts in this experience*"
                value={this.state.includeScriptIds}
                onChange={({ target: { checked } }) => {
                  this.setState({ includeScriptIds: checked });
                }}
              />
              {this.state.includeScriptIds
              && <DialogContentText>
                *Note that the inclusion of script ids will take longer and result in warning logs when trying to load
                new scripts the first time.  Reloading the experience should allow the scripts to load as expected.
                The checkbox below will overwrite this one and no copies will be made if both are checked.
              </DialogContentText>
              }
              <CheckboxField
                label="Include original script ids only; don't make copies.**"
                value={this.state.includeOriginalScriptIds}
                onChange={({ target: { checked } }) => {
                  this.setState({ includeOriginalScriptIds: checked });
                }}
              />
              {this.state.includeOriginalScriptIds
              && <DialogContentText>
                **Note that the inclusion of original script ids will result in warning logs for each script that
                cannot load.  This is mainly for dev use.  If you are unsure if you need this, leave it unchecked.
                This checkbox will overwrite the first checkbox if both are checked.
              </DialogContentText>
              }
              <BaseButton
                color="secondary" variant="contained" size="medium" title="Force refresh"
                onClick={() => {
                  this.setState({ busy: true });
                  this.deleteChildElements(sceneId, element.children)
                    .then(() => {
                      const currentAppId = this.props.app.info.id;
                      // Get access to all the new assets we need
                      this.importAssetLibrary(currentAppId, this.state.linkedExperienceId)
                        .then(() => {
                          // Update editron's cached asset library so it knows it can use the new assets
                          this.updateAssetLibrary(currentAppId)
                            .then(() => {
                              // Create the new elements as children of the linked component
                              this.initiateElementCreation(this.state.elementsToCreate, currentAppId, sceneId, elementId);
                            });
                        });
                    })
                    .finally(() => {
                      this.props.updateElementString(
                        sceneId, elementId, 'linkedExperienceUpdatedAt', this.state.nextExperienceUpdatedAt || ''
                      );
                    });
                }}
              >
                Force Refresh***
              </BaseButton>
              <DialogContentText>
                ***At this time &quot;force refresh&quot; means all elements nested under this
                linked component will be deleted and recreated from the retrieved experience data.
                Any local changes or additions made to the children within this linked component will be lost.
              </DialogContentText>
            </React.Fragment>
          }
        </React.Fragment>
      }
    </React.Fragment>
  );

  /**
   * Renders controls.
   */
  render() {
    const {
      element, scene, onUpdateElement, classes
    } = this.props;
    const { id: sceneId } = scene;

    // element data
    const defaultLabel = '';
    const defaultLinkedExperienceId = '';
    const defaultLinkedExperienceUpdatedAt = '';
    const defaultLinkedExperienceInfo = { };

    const {
      id: elementId,
      schema: {
        strings: {
          label: elementLabel = defaultLabel,
          // Will we need to use these eventually?
          linkedExperienceId: elementLinkedExperienceId = defaultLinkedExperienceId,
          // linkedExperienceUpdatedAt: elementLinkedExperienceUpdatedAt = defaultLinkedExperienceUpdatedAt,
          linkedExperienceInfo: elementLinkedExperienceInfo = defaultLinkedExperienceInfo,
        } = {}
      } = {}
    } = element;

    const textInvalid = !this.isLabelValid();
    const isStaticLinkedExperienceRoot = element.overrideType === 'StaticLinkedExperienceRoot';

    return (
      <Box p={1}>
        <InspectableIdentity
          inspectable={element}
          inspectableTranslator={elementToInspectable}
          onUpdate={onUpdateElement}
        />

        <Transform hideTitle element={element} sceneId={sceneId} />

        {/* Settings */}
        <Box>
          <Typography variant="h6">Linked Component Settings</Typography>
          <form className={classes.inputFieldsContainer}>
            {this.renderLoading()}
            {isStaticLinkedExperienceRoot
            ? this.renderStaticLinkedExperience(
                this.state.linkedExperienceId,
                this.state.linkedExperienceUpdatedAt,
                sceneId, elementId, scene, element
            )

            : <React.Fragment>
              <TextField
                label="Id or URL of experience to link"
                value={this.state.linkedExperienceId}
                onChange={(evt) => {
                  this.setState({ linkedExperienceId: evt.target.value });

                  const requestedId = evt.target.value;
                  this.getExperienceData(requestedId)
                    .then((appDataResponse) => {
                      this.setState({ linkedExperienceNameValue: appDataResponse.body.name });
                      this.getSceneData(requestedId, appDataResponse.body.scenes[0])
                        .then((sceneDataResponse) => {
                          const sceneData = JSON.stringify(sceneDataResponse);
                          const sceneDataElements = JSON.parse(sceneDataResponse.body.elements);

                          this.setState({ linkedExperienceInfoValue: sceneData });
                          this.setState({ linkedExperienceElementsValue: sceneDataElements });
                          this.setState({ linkedExperienceSchemaValue: sceneDataElements.schema });
                          this.setState({ linkedExperienceChildrenValue: sceneDataElements.children });

                          this.setState(prevState => ({
                            linkedExperienceSchemaValue: {
                              ...prevState.linkedExperienceSchemaValue,
                              strings: {
                                ...prevState.linkedExperienceSchemaValue.strings,
                                name: this.state.linkedExperienceNameValue,
                                linkedExperienceId: this.state.linkedExperienceId,
                                linkedExperienceUpdatedAt: sceneDataResponse.body.updatedAt
                              }
                            }
                          }));

                          this.props.updateElementString(
                            sceneId, elementId, 'linkedExperienceId', requestedId || defaultLinkedExperienceId
                          );
                          this.props.updateElementString(
                            sceneId, elementId, 'linkedExperienceInfo', sceneData || defaultLinkedExperienceInfo
                          );
                          this.props.updateElementString(
                            sceneId, elementId, 'linkedExperienceUpdatedAt', sceneDataResponse.body.updatedAt || ''
                          );
                        })
                        .finally(() => {
                          this.createNewElements(sceneId, element, scene, true);
                        });
                    });
                }}
                fullWidth
                variant="outlined"
                error={this.state.experienceIdErrorText}
                helperText={this.state.experienceIdErrorText ? this.state.experienceIdErrorText : ''}
              />
              {this.state.linkedExperienceNameValue && !this.state.experienceIdErrorText
              && <DialogContentText>
                Experience found: {this.state.linkedExperienceNameValue}
              </DialogContentText>
              }
              {this.state.linkedExperienceNameValue && !this.state.experienceIdErrorText
              && <DialogContentText>
                Number of elements to be generated: {this.state.elementsToCreate.length}
              </DialogContentText>
              }
              {this.state.linkedExperienceNameValue && !this.state.experienceIdErrorText
                && <React.Fragment>
                  <CheckboxField
                    label="Make new copies of scripts in this experience*"
                    value={this.state.includeScriptIds}
                    onChange={({ target: { checked } }) => {
                      this.setState({ includeScriptIds: checked });
                    }}
                  />
                  {this.state.includeScriptIds
                  && <DialogContentText>
                    *Note that the inclusion of script ids will take longer and result in warning logs when trying to load
                    new scripts the first time.  Reloading the experience should allow the scripts to load as expected.
                    The checkbox below will overwrite this one and no copies will be made if both are checked.
                  </DialogContentText>
                  }
                  <CheckboxField
                    label="Include original script ids only; don't make copies.**"
                    value={this.state.includeOriginalScriptIds}
                    onChange={({ target: { checked } }) => {
                      this.setState({ includeOriginalScriptIds: checked });
                    }}
                  />
                  {this.state.includeOriginalScriptIds
                  && <DialogContentText>
                    **Note that the inclusion of original script ids will result in warning logs for each script that
                    cannot load.  This is mainly for dev use.  If you are unsure if you need this, leave it unchecked.
                    This checkbox will overwrite the first checkbox if both are checked.
                  </DialogContentText>
                  }
                </React.Fragment>
              }
              {this.state.linkedExperienceNameValue && !this.state.experienceIdErrorText
              && <BaseButton className={classes.updateHierarchyButton}
                 color="secondary" variant="contained" size="medium" title="Update element hierarchy"
                 onClick={() => {
                   this.setState({ busy: true });
                   const currentAppId = this.props.app.info.id;
                   // Get access to all the new assets we need
                   this.importAssetLibrary(currentAppId, this.state.linkedExperienceId)
                     .then(() => {
                       // Update editron's cached asset library so it knows it can use the new assets
                       this.updateAssetLibrary(currentAppId)
                         .then(() => {
                           // Create the new elements as children of the linked component
                           this.initiateElementCreation(this.state.elementsToCreate, currentAppId, sceneId, elementId, true);
                         });
                     });
                 }}
              >
                Create linked component
              </BaseButton>
              }
              {this.state.linkedExperienceNameValue && !this.state.experienceIdErrorText
              && <ListItemButton disableGutters onClick={this.toggleLinkComponentJSON}>
                <ListItemText primary="Show raw scene JSON"/>
                {this.state.showLinkedComponentJSON ? <ExpandLess/> : <ExpandMore/>}
              </ListItemButton>
              }
              {this.state.linkedExperienceNameValue && !this.state.experienceIdErrorText
              && <Collapse in={this.state.showLinkedComponentJSON} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <TextField
                    multiline
                    label="Linked Experience JSON (READ ONLY)"
                    value={this.state.linkedExperienceInfoValue}
                    onChange={(evt) => {
                      this.setState({ linkedExperienceInfoValue: evt.target.value });
                    }}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </List>
              </Collapse>
              }
            </React.Fragment>
            }
          </form>
        </Box>
      </Box>
    );
  }
}

LinkedComponentElementInspector.propTypes = {
  ...InspectorInterface,
  app: PropTypes.object.isRequired,
  createElements: PropTypes.func.isRequired,
  createFullElements: PropTypes.func.isRequired,
  createElementsFromLinkedComponent: PropTypes.func.isRequired,
  deleteElement: PropTypes.func.isRequired,
  getScene: PropTypes.func.isRequired,
  getUpdatedExperienceAssets: PropTypes.func.isRequired,
  getUpdatedExperienceScripts: PropTypes.func.isRequired,
  loadLinkedComponent: PropTypes.func.isRequired,
  updateElementString: PropTypes.func.isRequired,
  updateElementInt: PropTypes.func.isRequired,
  updateElementFloat: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(theme => ({
  inputFieldsContainer: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    position: 'relative',
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  },
  loadingIndicatorContainer: {
    zIndex: 1,
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1b2238aa'
  },
  updateHierarchyButton: {
    marginBottom: theme.spacing(1),
  }
}))(
  withRouter(
    connect(
      ({ app }) => ({ app }),
      dispatch => ({
        /**
         * Creates new elements.
         */
        createElements: (sceneId, parent, strings, type, id) => {
          txns.request(sceneId, createAction(parent, strings, type, id));
        },

        /**
         * Creates new elements with all their parameters.
         */
        createFullElements: (sceneId, parent, schema, type, id, includeScriptIds) => {
          txns.request(sceneId, createFullElementAction(parent, schema, type, id, includeScriptIds));
        },

        /**
         * Creates elements from linked component data.
         */
        createElementsFromLinkedComponent: (sceneId, parent, children, strings, name) => {
          txns.request(sceneId, createFromLinkedComponentAction(parent, children, strings, name));
        },

        /**
         * Deletes an element.
         */
        deleteElement: (sceneId, elementId) => txns.request(sceneId, deleteAction(elementId)),

        /**
         * Gets most up to date asset list.
         * @param appId - the id for the currently loaded experience.
         * @param sceneId - the id for the currently scene in the experience.
         */
        getScene: (appId, sceneId) => {
          dispatch(getscene({ appId, sceneId }));
        },

        /**
         * Gets most up to date asset list.
         * @param appId - the id for the currently loaded experience.
         */
        getUpdatedExperienceAssets: (appId) => {
          dispatch(getappassets({ appId }));
        },

        /**
         * Gets most up to date script list.
         * @param appId - the id for the currently loaded experience.
         */
        getUpdatedExperienceScripts: (appId) => {
          dispatch(getappscripts({ appId }));
        },

        loadLinkedComponent: async (appId) => {
          await dispatch(loadLinkedComponent({ appId }));
        },

        /**
         * Updates an element string value.
         */
        updateElementString: (sceneId, elementId, key, value) => {
          txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.STRING, key, value));
        },

        /**
         * Updates an element int value.
         */
        updateElementInt: (sceneId, elementId, key, value) => {
          txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.INT, key, value));
        },

        /**
         * Updates an element float value.
         */
        updateElementFloat: (sceneId, elementId, key, value) => {
          txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.FLOAT, key, value));
        }
      })
    )(LinkedComponentElementInspector)
  )
);
