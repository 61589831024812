import { AsyncStatus, Actions, TrellisActions } from '@enklu/server-api';

const { createAddHandler } = Actions;

const { GETMYFILES } = TrellisActions;
const initialState = {
  all: [],
  worldScans: []
};
const handlers = {};
const addHandler = createAddHandler(handlers);

addHandler(GETMYFILES, (state, action) => {
  if (action.status === AsyncStatus.SUCCESS) {
    const { body: all } = action;

    return {
      ...state,
      all,
      worldScans: all.filter(file => file.tags.indexOf('worldscan') !== -1)
    };
  }

  return state;
});

function files(state = initialState, action) {
  const handler = handlers[action.type];
  if (!handler) {
    return state;
  }

  return handler(state, action);
}

export default files;
