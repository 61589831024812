import {
 Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { rgbToHex, hexToRgb } from '../../util/util';
import ActionSchemaTypes from '../../constants/ActionSchemaTypes';
import CheckboxField from '../material-ui/CheckboxField';
import ColorPickerField from '../material-ui/ColorPickerField';

const useStyles = makeStyles(theme => ({
  fieldsContainer: {
    '& > *': {
      marginTop: theme.spacing(2)
    },
    '& > :first-child': {
      marginTop: theme.spacing(1)
    }
  }
}));

/**
 * Displays a control panel for a category of controls.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ControlPanel = (props) => {
  const classes = useStyles();

  const {
    isReadOnly,
    settingsCategory: { name: categoryName, displayName: categoryDisplayName, settings },
    onChange
  } = props;

  const createFormElement = (setting) => {
    const {
 type, value, possibleValues, defaultValue, displayName
} = setting;

    switch (type) {
      case ActionSchemaTypes.BOOL: {
        return (
          <CheckboxField
            key={displayName}
            label={displayName}
            value={value}
            onChange={({ target: { checked } }) => {
              onChange({
                category: categoryName,
                setting,
                value: checked,
                defaultValue
              });
            }}
          />
        );
      }

      case ActionSchemaTypes.INT:
      case ActionSchemaTypes.STRING: {
        return (
          <FormControl key={displayName} fullWidth>
            <InputLabel variant="outlined">{displayName}</InputLabel>
            <Select
              input={<OutlinedInput />}
              value={value}
              onChange={({ target: { value: _value } }) => onChange({
                  category: categoryName,
                  setting,
                  value: type === ActionSchemaTypes.STRING ? _value : Number(_value),
                  defaultValue
                })
              }
              variant="outlined"
            >
              {possibleValues.map(({ value: _value, displayName: optionDisplayName }, index) => (
                <MenuItem key={index} value={_value}>
                  {optionDisplayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }

      case ActionSchemaTypes.COL4: {
        return (
          <ColorPickerField
            key={displayName}
            label={displayName}
            value={rgbToHex(value)}
            onChange={(color) => {
              onChange({
                category: categoryName,
                setting,
                value: hexToRgb(color),
                defaultValue
              });
            }}
          />
        );
      }
      default: {
        return <Typography>{type} not supported</Typography>;
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      {isReadOnly && <Typography variant="h6">READ ONLY</Typography>}
      {categoryDisplayName && <Typography variant={props.largeTitle ? 'h5' : 'h6'}>{categoryDisplayName}</Typography>}
      <Box display="flex" flexDirection="column" className={classes.fieldsContainer}>
        {settings.map(setting => createFormElement(setting))}
      </Box>
    </Box>
  );
};

ControlPanel.propTypes = {
  isReadOnly: PropTypes.bool,
  settingsCategory: PropTypes.exact({
    name: PropTypes.string,
    displayName: PropTypes.string,
    settings: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        defaultValue: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
        possibleValues: PropTypes.array
      })
    ).isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  largeTitle: PropTypes.bool
};

ControlPanel.defaultProps = {
  title: 'Settings',
  isReadOnly: false
};

export default ControlPanel;
