import { createSelector } from 'reselect';
import { getAssetTagsArray as getTagsArray, newCategory } from '../../util/util';
import { getScene } from './scenesSelectors';
import ElementTypes from '../../constants/ElementTypes';
import LibraryTypes from '../../constants/LibraryTypes';
import { getSelectedAssetCategoryName, getUsageFilter } from './uiSelectors';
import { getUserProfile } from './userSelectors';
import { UsageTypes } from '../reducers/uiReducer';

export const getLibraries = state => state.libraries.libraries;

export const getAllAssetsById = createSelector(
  getLibraries,
  _libraries => Object.entries(_libraries)
    .filter(([key]) => key !== '__uploading__')
    .reduce((accum, [, library]) => ({ ...accum, ...library }), {})
);

export const getAllAssets = createSelector(
  getAllAssetsById,
  allAssets => Object.entries(allAssets)
    .map(([, asset]) => asset)
);

export const getDefaultCategoriesByUrl = createSelector(
  state => state.app.info,
  getUserProfile,
  (info, profile) => ({
    [LibraryTypes.APP]: newCategory(info.name || 'App', LibraryTypes.APP),
    [LibraryTypes.USER]: newCategory(profile.name || 'User', LibraryTypes.USER),
    [LibraryTypes.PUBLIC]: newCategory('Public', LibraryTypes.PUBLIC),
    [LibraryTypes.UPLOADING]: newCategory('Uploading', LibraryTypes.UPLOADING)
  })
);

export const getDefaultCategories = createSelector(
  getDefaultCategoriesByUrl,
  defaultCategoriesByUrl => Object.entries(defaultCategoriesByUrl)
    .map(([, category]) => category)
);

export const getTags = createSelector(
  getAllAssets,
  getDefaultCategoriesByUrl,
  (assets, defaultCategoriesByUrl) => Object.entries(assets.reduce((categoriesByUrl, { tags: tagsString }) => getTagsArray(tagsString)
    .reduce((_categoriesByUrl, tag) => ((!_categoriesByUrl[tag] && !defaultCategoriesByUrl[tag]) ? {
      ..._categoriesByUrl,
      [tag]: true
    } : _categoriesByUrl), categoriesByUrl), {}))
    .map(([tag]) => tag)
    .sort()
);

export const getCategories = createSelector(
  getDefaultCategories,
  getTags,
  (defaultCategories, tags) => tags.reduce((accum, tag) => [...accum, newCategory(tag)], defaultCategories)
);

export const getSelectedCategoryAssets = createSelector(
  getLibraries,
  getAllAssets,
  getSelectedAssetCategoryName,
  (_libraries, allAssets, selectedCategoryName) => {
    // Generally speaking, we can do a search of an asset's tags. However, there are also several special categories
    // that correspond directly to a specific library.
    if (Object.entries(LibraryTypes)
      .find(([, libraryType]) => libraryType === selectedCategoryName)) {
      return Object.entries(_libraries[selectedCategoryName])
        .map(([, item]) => item);
    }

    // If it's not a special tag, we can do a "simple" filter.
    const regex = new RegExp(`(^|,)${selectedCategoryName}(,|$)`, 'g');
    return allAssets.filter(asset => asset.tags.match(regex));
  }
);

export const getSelectedCategoryAssetsById = createSelector(
  getSelectedCategoryAssets,
  assets => assets.reduce((accum, item) => ({
    ...accum,
    [item.id]: item
  }), {})
);

export const getFilteredAssetsById = createSelector(
  getScene,
  getUsageFilter,
  getSelectedCategoryAssets,
  getSelectedCategoryAssetsById,
  (scene, usageFilter, assets, assetsById) => {
    if (usageFilter === UsageTypes.All) {
      return assetsById;
    }

    const usedAssetsById = {};
    const unusedAssetsById = { ...assetsById };

    // Traverse the scene.
    const findAsset = ({ type, schema, children }) => {
      if (type === ElementTypes.CONTENT) {
        if (schema.strings) {
          const { assetSrc: assetId } = schema.strings;
          if (assetsById[assetId]) {
            usedAssetsById[assetId] = assetsById[assetId];
            delete unusedAssetsById[assetId];
          }
        }
      }

      children.forEach(child => findAsset(child));
    };

    findAsset(scene.elements);

    return usageFilter === UsageTypes.InUse ? usedAssetsById : unusedAssetsById;
  }
);

export const getFilteredAssets = createSelector(
  getFilteredAssetsById,
  assetsById => Object.entries(assetsById)
    .map(([, asset]) => asset)
);
