export const UPDATE_SPACE = "space.update";
export const updateSpace = space => ({
    type: UPDATE_SPACE,
    space
});

export const DELETE_SPACE = "space.delete";
export const deleteSpace = id => ({
    type: DELETE_SPACE,
    id
});
