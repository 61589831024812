import * as React from 'react';
import { AppBar, Box, SvgIcon, Toolbar, Button, Typography, CardMedia, Icon, IconButton } from '@material-ui/core';
import enklu_logo_2DCCD3 from "../../../assets/img/enklu_logo_2DCCD3.svg"
import makeStyles from '@material-ui/core/styles/makeStyles';
import BaseButton from '../material-ui/BaseButton';
import { useHistory} from 'react-router';

export default function SignupHeader() {

    const useStyles = makeStyles(theme => ({
        buttonPadding: {
            height:'3em',
            width: '5.5em',
            fontSize: '2.5em',
            fontWeight: 'bold'},
        styledToolbar: {
            display:"flex",
            justifyContent:"space-between",
            paddingTop:theme.spacing(4)
        }
    }));

    const classes = useStyles();

    const {
        buttonPadding,
        styledToolbar
    } = classes;

    const history = useHistory();

    function handleClick() {
        history.push("/signup");
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" height="100px" style={{ background: 'transparent', boxShadow: 'none'}}>
                <Toolbar className={styledToolbar}>
                    <div>
                        <IconButton href="https://enklu.com/">
                            <img alt="edit" src="../../../assets/img/enklu_logo_2DCCD3.svg" height={100} width={100} />
                        </IconButton>
                    </div>
                    <div>
                        <BaseButton
                            variant="outlined"
                            disableElevation
                            color="light"
                            className={buttonPadding}
                            size="large"
                            onClick={handleClick}
                        >
                            SIGN UP
                        </BaseButton>
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
