import { createSelector } from 'reselect';

import { getCollaboratorsByAppId } from './collaboratorsSelectors';
import { getUserProfile } from './userSelectors';

export const getApp = ({ app }) => app;
export const getPlayer = ({ player }) => player;
export const getAppInfo = createSelector(
  getApp,
  getPlayer,
  ({ info }, { edit }) =>
  {
    return {
      ...info,
      edit
    };
  }
);
export const getAppUsers = createSelector(getApp, ({ users }) => users);
export const getAppIsReadOnly = createSelector(
  getUserProfile,
  getAppInfo,
  getCollaboratorsByAppId,
  ({ id: userId }, { id: appId }, collaborators) => {
    const appCollaborators = collaborators[appId] || [];
    const collaborator = appCollaborators.find(collab => collab.userId === userId);
    return collaborator ? collaborator.role === 'view' : false;
  }
);
