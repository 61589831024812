import { log } from '../../util/log';
import { getUserProfile } from '../selectors/userSelectors';

// export const GET_PREFERENCES = 'preferences.getPreferences';
// export const getpreferences = ({ userId }) => ({
//   type: GET_PREFERENCES,
//   payload: { userId }
// });

export const SET_USER_PREFERENCES = 'preferences.setUserPreferences';
export const getuserpreferences = () => (dispatch, getState) => {
  const state = getState();
  const { id: userId } = getUserProfile(state);
  let preferences = {};

  try {
    const prefsString = window.localStorage.getItem(`__${userId}__`);
    if (prefsString) {
      const prefsObj = JSON.parse(prefsString);
      if (prefsObj.version === window.config.localDataVersion && prefsObj.preferences) {
        ({ preferences } = prefsObj);
      }
    }
  } catch (err) {
    log.error(err);
  }

  dispatch({
    type: SET_USER_PREFERENCES,
    payload: { preferences }
  });
};
