import {
 Box, Checkbox, FormControlLabel, Select, OutlinedInput, MenuItem
} from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  logSetLevel as _logSetLevel,
  logSetFilter as _logSetFilter,
  logSetShowSystem as _logSetShowSystem
} from '../../store/actions/logActions';
import {
  getLogFilter,
  getLogLevel,
  getLogShowSystem
} from '../../store/selectors/logSelectors';
import DrawerItemHeader from '../../components/drawer/MuiDrawerItemHeader';
import LogMessageNames from '../../constants/LogMessageNames';
import LogMessageTypes from '../../constants/LogMessageTypes';
import LocalLog from './LocalLog';
import RemoteLog from './RemoteLog';
import { getPresences } from '../../store/selectors/comboSelectors';

const useStyles = makeStyles(theme => ({
  subMessage: {
    fontSize: '75%'
  },
  filledStyle: {
    width: 225,
    borderRadius: 100,
    backgroundColor: theme.palette.background.default,
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0
    },
    '&.Mui-focused': {
      boxShadow: 'none'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0
    }
  }
}));

const LOCAL_TARGET = {
  deviceInfo: {
    type: 'Cloud Editor',
    id: 'Cloud Editor',
    name: 'Cloud Editor'
  },
  displayName: 'Local'
};

const Log = ({
  style,
  level,
  showSystem,
  logSetLevel,
  logSetFilter,
  logSetShowSystem,
  users
}) => {

  const classes = useStyles();
  const [tempFilter, setTempFilter] = React.useState('');
  const [timeout, setTimeout] = React.useState('');
  const [logTarget, setLogTarget] = React.useState(LOCAL_TARGET);

  const logTargets = [
    LOCAL_TARGET,
    ...users.filter(
      user => user.deviceInfo
        && user.deviceInfo.type
        && user.deviceInfo.type !== 'WebGL')
  ];

  // Ensure the selected target remains an option, even as it may disconnect/reconnect
  if (logTarget &&
    !logTargets.find(target => target.deviceInfo.id === logTarget.deviceInfo.id)) {
    logTargets.push(logTarget);
  }

  return (
    <Box display="flex" flexDirection="column" flex={1} overflow="auto">
      <DrawerItemHeader
        title="Log"
        filterString={tempFilter}
        onFilterStringChanged={(value) => {
          clearTimeout(timeout);
          setTempFilter(value);
          setTimeout(setTimeout(logSetFilter, 500, value));
        }}
        categories={[
          { value: LogMessageTypes.ALL, name: LogMessageNames[LogMessageTypes.ALL] },
          { value: LogMessageTypes.UNKNOWN, name: LogMessageNames[LogMessageTypes.UNKNOWN] },
          { value: LogMessageTypes.DEBUG, name: LogMessageNames[LogMessageTypes.DEBUG] },
          { value: LogMessageTypes.INFO, name: LogMessageNames[LogMessageTypes.INFO] },
          { value: LogMessageTypes.WARN, name: LogMessageNames[LogMessageTypes.WARN] },
          { value: LogMessageTypes.ERROR, name: LogMessageNames[LogMessageTypes.ERROR] },
          { value: LogMessageTypes.CONNECTION, name: LogMessageNames[LogMessageTypes.CONNECTION] }
        ]}
        category={level}
        onCategoryChanged={value => logSetLevel(value)}
      >
        <Box ml={1}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={showSystem}
                onChange={({ target: { checked } }) => logSetShowSystem(checked)}
              />
            }
            label="System"
          />
        </Box>

        <Select
          value={logTarget.deviceInfo.id}
          onChange={({ target: { value } }) => setLogTarget(logTargets.find(target => target.deviceInfo.id === value))}
          input={<OutlinedInput margin="dense" className={classes.filledStyle} />}
        >
          {logTargets.filter(target => target.deviceInfo).map((target, index) => (
            <MenuItem key={index} value={target.deviceInfo.id}>
              {`${target.displayName} - ${target.deviceInfo.name}`}
            </MenuItem>
          ))}
        </Select>
      </DrawerItemHeader>

      {logTarget === LOCAL_TARGET
        ? <LocalLog/>
        : <RemoteLog targetUser={logTarget} />
      }

    </Box>
  );
};

Log.propTypes = {
  // react-redux connect
  level: PropTypes.number.isRequired,
  filter: PropTypes.string.isRequired,
  showSystem: PropTypes.bool.isRequired,
  logSetLevel: PropTypes.func.isRequired,
  logSetFilter: PropTypes.func.isRequired,
  logSetShowSystem: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  level: getLogLevel(state),
  filter: getLogFilter(state),
  showSystem: getLogShowSystem(state),

  users: getPresences(state)
});

const mapDispatchToProps = dispatch => ({
  logSetLevel: level => dispatch(_logSetLevel(level)),
  logSetFilter: filter => dispatch(_logSetFilter(filter)),
  logSetShowSystem: showSystem => dispatch(_logSetShowSystem(showSystem))
});

export default connect(mapStateToProps, mapDispatchToProps)(Log);
