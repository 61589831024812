export const SHOW_MODAL = 'modal.show';
export const showmodal = ({ message = '', spinnerVisible = false, blurVisible = true }) => ({
  type: SHOW_MODAL,
  message,
  spinnerVisible,
  blurVisible
});

export const HIDE_MODAL = 'modal.hide';
export const hidemodal = () => ({
  type: HIDE_MODAL
});
