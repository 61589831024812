import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import LogRow from './MuiLogRow';
import {
    getLogFilteredMessages,
    getLogNumMessages,
} from '../../store/selectors/logSelectors';
import { LogMessageShape } from '../../store/reducers/logReducer';
import LogFooter from './LogFooter';
import { logClear as _logClear } from '../../store/actions/logActions';

/**
 * Displays logs for the current Editron/WebGL instance.
 */
class LocalLog extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            filteredMessages,
            numTotalMessages
        } = this.props;

        return (
            <React.Fragment>
              <Box flex={1} overflow="auto" className="scrollable-y" px={1}>
                {filteredMessages.map((message, index) => (
                  <LogRow index={index} key={index} item={message} />
                ))}
              </Box>
                <LogFooter
                    totalMessageCount={numTotalMessages}
                    totalVisibleCount={filteredMessages.length}
                    logClear={this.props.logClear}
                />
            </React.Fragment>
        );
    }
}

LocalLog.propTypes = {
    filteredMessages: PropTypes.arrayOf(LogMessageShape).isRequired,
    numTotalMessages: PropTypes.number.isRequired,

    logClear: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    filteredMessages: getLogFilteredMessages(state),
    numTotalMessages: getLogNumMessages(state),
});

const mapDispatchToProps = dispatch => ({
    logClear: () => dispatch(_logClear())
});

export default connect(mapStateToProps, mapDispatchToProps)(LocalLog);
