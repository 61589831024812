import {
    REMOTE_ASSIST_CONNECTING,
    REMOTE_ASSIST_LEFT,
    REMOTE_ASSIST_LOG,
    REMOTE_ASSIST_TERMINATE,
    REMOTE_ASSIST_LOG_CLEAR
} from '../actions/remoteAssistActions';
import { LOG_SET_FILTER, LOG_SET_LEVEL, LOG_SET_SHOW_SYSTEM } from '../actions/logActions';
import LogMessageTypes from '../../constants/LogMessageTypes';

const SYSTEM_CATEGORY = 'System';

// Filter settings are duplicated/sync'd across logReducer/remoteAssistReducer.
//  Kind of gross, but I wanted to keep the separation for now, and not combine their state or make a nested reducer
const initialState = {
    messages: [],
    filteredMessages: [],
    level: LogMessageTypes.ALL,
    filter: null,
    showSystem: false
}

function filterMessages(logs, config) {
    return logs.filter(entry => filterMatch(entry, config));
}

function filterMatch(logEntry, { level, showSystem, filter }) {
    if (!showSystem && logEntry.category === SYSTEM_CATEGORY) return false;
    if (level !== LogMessageTypes.ALL && logEntry.level < level) return false;
    if (filter && logEntry.message.toLowerCase().indexOf(filter.toLowerCase()) === -1) return false;
    return true;
}

function createLog(msg) {
    return {
        level: 10,
        category: 'Connection',
        message: msg,
        timestamp: new Date().toISOString()
    };
}

function updateSetting(state, setting, value) {
    const tmpState = {
        ...state,
        [setting]: value
    };
    tmpState.filteredMessages = filterMessages(tmpState.messages, tmpState);
    return tmpState;
}

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOTE_ASSIST_LOG_CLEAR:
        case REMOTE_ASSIST_TERMINATE: {
            return {
                ...state,
                messages: [],
                filteredMessages: []
            };
        }

        case LOG_SET_LEVEL: {
            return updateSetting(state, 'level', Number(action.payload));
        }
        case LOG_SET_FILTER: {
            return updateSetting(state, 'filter', action.payload);
        }
        case LOG_SET_SHOW_SYSTEM: {
            return updateSetting(state, 'showSystem', action.payload);
        }

        case REMOTE_ASSIST_CONNECTING: {
            const log = createLog('Device is connecting.');
            return {
                ...state,
                messages: [ log, ...state.messages],
                filteredMessages: [ log, ...state.filteredMessages]
            }
        }

        case REMOTE_ASSIST_LEFT: {
            const log = createLog('Device is disconnecting.');
            return {
                ...state,
                messages: [ log, ...state.messages],
                filteredMessages: [ log, ...state.filteredMessages]
            }
        }

        case REMOTE_ASSIST_LOG: {
            let filteredMessages = [ ...state.filteredMessages ];
            if (filterMatch(action.payload, state)) {
                filteredMessages.unshift(action.payload);
            }
            return {
                ...state,
                messages: [ action.payload, ...state.messages],
                filteredMessages: filteredMessages
            }
        }

        default: {
            return state;
        }
    }
};
