import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import filesize from 'filesize';

import { assetBundleUrl, formatTimestamp } from '../../../util/util';
import CopyableText from '../../common/CopyableText';
import ExpandableList from '../../common/MuiExpandableList';

class AssetStats extends React.Component {
  static propTypes = {
    asset: PropTypes.object.isRequired,
    userCache: PropTypes.object.isRequired,
    loadUser: PropTypes.func.isRequired,
    version: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      ios: -1,
      webgl: -1,
      wsaplayer: -1,
      standalonewindows: -1
    };
  }

  updatePlatform(uri, version, platform) {
    const url = assetBundleUrl(uri, version, platform);
    const http = new XMLHttpRequest();

    http.onreadystatechange = () => {
      if (http.readyState === 4) {
        const bytes = http.getResponseHeader('content-length') || 0;

        this.setState({
          [platform]: filesize(bytes)
        });
      }
    };
    http.open('HEAD', url);
    http.send();
  }

  update() {
    const {
 asset: { uri, owner: ownerId } = {}, version, userCache, loadUser
} = this.props;

    this.setState({
      ios: -1,
      webgl: -1,
      wsaplayer: -1
    });

    this.updatePlatform(uri, version, 'ios');
    this.updatePlatform(uri, version, 'webgl');
    this.updatePlatform(uri, version, 'wsaplayer');
    this.updatePlatform(uri, version, 'standalonewindows');

    const user = userCache[ownerId];
    if (!user) {
      loadUser({ userId: ownerId });
    }
  }

  componentDidUpdate(prevProps) {
    const { asset: { id } = {} } = this.props;
    const { asset: { id: prevId } = {} } = prevProps;

    if (id !== prevId) {
      this.update();
    }
  }

  componentDidMount() {
    this.update();
  }

  render() {
    const {
      asset: {
        id,
        owner: ownerId,
        createdAt,
        updatedAt,
        stats: { vertCount, triCount },
        version
      },
      userCache
    } = this.props;
    const {
 ios, webgl, wsaplayer, standalonewindows
} = this.state;
    const cachedUser = userCache[ownerId];

    const dateOptions = {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      weekday: 'short'
    };
    return (
      <Box>
        <ExpandableList
          title="Info"
          startExpanded
          small={
            <React.Fragment>
              <li>
                <Typography>
                  ID: <CopyableText text={id} />
                </Typography>
              </li>
              <li>
                <Typography>{`Last Updated: ${formatTimestamp(updatedAt, {
                  includeDate: true
                })}`}</Typography>
              </li>
            </React.Fragment>
          }
        >
          <li>
            <Typography>{`Version: ${version}`} </Typography>
          </li>
          <li>
            <Typography>{`Created by: ${cachedUser ? cachedUser.displayName : ownerId}`} </Typography>
          </li>
          <li>
            <Typography>{`Created on: ${new Date(createdAt).toLocaleString('en-US', dateOptions)}`} </Typography>
          </li>

          <li>
            <Typography>{`Vertices: ${vertCount}`} </Typography>
          </li>
          <li>
            <Typography>{`Tris: ${triCount}`} </Typography>
          </li>
          <li>
            <Typography>{ios === -1 ? 'iOS: Loading...' : `iOS: ${ios}`} </Typography>
          </li>
          <li>
            <Typography>{webgl === -1 ? 'WebGL: Loading...' : `WebGL: ${webgl}`} </Typography>
          </li>
          <li>
            <Typography>{wsaplayer === -1 ? 'HoloLens: Loading...' : `HoloLens: ${wsaplayer}`} </Typography>
          </li>
          <li>
            <Typography>
              {standalonewindows === -1
                ? 'Standalone (Windows): Loading...'
                : `Standalone (Windows): ${standalonewindows}`}{' '}
            </Typography>
          </li>
        </ExpandableList>
      </Box>
    );
  }
}

export default AssetStats;
