import { Alert } from '@material-ui/lab';
import { Box, Paper, Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/styles';
import Dropzone from 'react-fine-uploader/dropzone';
import Filename from 'react-fine-uploader/filename';
import Filesize from 'react-fine-uploader/filesize';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import ProgressBar from 'react-fine-uploader/progress-bar';
import PropTypes from 'prop-types';
import React from 'react';

import { log } from '../../../util/log';
import { muiPalette } from '../../../styles/muiTheme';
import BaseButton from '../../material-ui/BaseButton';

class AssetUploader extends React.Component {
  static propTypes = {
    endpoint: PropTypes.string.isRequired,
    asset: PropTypes.object,
    appId: PropTypes.string.isRequired,
    allowedExtensions: PropTypes.array,
    method: PropTypes.string,
    customHeaders: PropTypes.object,
    onComplete: PropTypes.func,
    fileName: PropTypes.string,
    enqueueSnackbar: PropTypes.func,
    closeSnackbar: PropTypes.func,
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {
    allowedExtensions: [
      'fbx',
      'obj',
      'zip',
      'bmp',
      'exr',
      'gif',
      'hdr',
      'iff',
      'jpg',
      'pict',
      'png',
      'psd',
      'pga',
      'tiff',
      'mp3',
      'ogg',
      'wav',
      'aiff',
      'aif',
      'mod',
      'it',
      's3m',
      'xm',
      'stl',
      'pdf'
    ],
    method: 'POST'
  };

  uploader;

  constructor(props) {
    super(props);

    this.state = {
      enableUploadButton: true,
      status: 'drop'
    };

    const {
 endpoint, allowedExtensions, method, customHeaders, onComplete, fileName = 'qqfile'
} = props;

    this.uploader = new FineUploaderTraditional({
      options: {
        autoUpload: false,
        request: {
          endpoint,
          customHeaders: {
            Authorization: `Bearer ${io.sails.token}`,
            ...customHeaders
          },
          method,
          inputName: fileName
        },
        callbacks: {
          onValidate: ({ name }) => {
            // For some reason allowedExtensions doesn't seem to work, so we'll do it the hard way.
            const extension = name
              .split('.')
              .pop()
              .toLowerCase();
            if (!allowedExtensions.includes(extension)) {
              const key = this.props.enqueueSnackbar(
                'You have uploaded an unsupported asset type. Please use common image, audio, or model extensions.',
                {
                  variant: 'error',
                  onClick: () => {
                    this.props.closeSnackbar(key);
                  }
                }
              );
            }

            return allowedExtensions.includes(extension);
          },
          onComplete: (id, name, response /* xhr */) => {
            if (response.success) {
              if (onComplete) {
                onComplete(response);
              }
            } else {
              // TODO: new action for failed uploads
              log.error('Upload failed', name);
            }
          },
          onError: (id, name, errorReason /* xhr */) => log.info(id, name, errorReason),
          onAllComplete: () => this.setState({ status: 'complete' })
        }
      }
    });

    this.uploader.on('submitted', id => this.setState({
        fileId: id,
        status: 'waiting'
      }));
  }

  render() {
    const { status, fileId } = this.state;
    const {
      asset: { id, tags },
      classes
    } = this.props;

    return (
      <Box>
        {status === 'drop' && (
          <Paper elevation={5}>
            <Box p={1} height={200} width={200}>
              <Dropzone
                style={{
                  borderRadius: '0px',
                  border: `2px dashed ${muiPalette.grayscale.darkGray}`,
                  height: '100%',
                  width: '100%',
                  position: 'relative',
                  display: 'flex',
                  flexFlow: 'column nowrap',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                uploader={this.uploader}
                multiple={false}
              >
                <h1>Drop Here</h1>
              </Dropzone>
            </Box>
          </Paper>
        )}

        {status === 'waiting' && (
          <Box height={200} width={200} flexDirection="column" display="flex" className={classes.container}>
            <Typography>
              <strong>File:&nbsp;</strong>
              <Filename id={fileId} uploader={this.uploader} />
            </Typography>
            <Typography>
              <strong>Size:&nbsp;</strong>
              <Filesize id={fileId} uploader={this.uploader} />
            </Typography>
            <BaseButton
              variant="contained"
              color="primary"
              onClick={() => {
                this.uploader.methods.setParams({
                  decimation: JSON.stringify({ enabled: false }),
                  tags,
                  id: fileId,
                  replacementAssetId: id
                });

                this.uploader.methods.uploadStoredFiles();

                this.setState({ status: 'uploading' });
              }}
            >
              Upload
            </BaseButton>
          </Box>
        )}

        {status === 'uploading' && (
          <Box height={200} width={200} flexDirection="column" display="flex" className={classes.container}>
            <Typography>Uploading...</Typography>
            <ProgressBar uploader={this.uploader} id={fileId} />
          </Box>
        )}

        {status === 'complete' && (
          <Box height={200} width={200}>
            <Alert elevation={0} severity="success">
              <Typography variant="h6">Success</Typography>
            </Alert>
          </Box>
        )}
      </Box>
    );
  }
}

export default withStyles(theme => ({
  container: {
    '& > *': {
      marginBottom: theme.spacing(1)
    }
  }
}))(withSnackbar(AssetUploader));
