/**
 * A component to display a list of errors.
 *
 * @param errors - an array of error objects, of the form { Message }
 */

import { Alert } from '@material-ui/lab';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  errorsContainer: {
    '& > *': { marginTop: theme.spacing(1) }
  }
}));

const ErrorList = ({ errors }) => {
  const classes = useStyles();

  // uniqueness
  for (let i = errors.length - 1; i > 0; i -= 1) {
    const err = errors[i];
    for (let j = i - 1; j >= 0; j -= 1) {
      if (err.Message === errors[j].Message) {
        errors.splice(i, 1);
      }
    }
  }

  // generate elements
  let key = 0;
  const errorElements = errors.map(error => (
    <Alert elevation={0} key={(key += 1)} severity="error">
      <Typography variant="h6">{error.Message}</Typography>
    </Alert>
  ));

  return errors.length ? (
    <Box mt={1}>
      <Typography variant="h6">Import Errors</Typography>
      <Box mb={1} className={classes.errorsContainer}>
        {errorElements.length > 0 ? (
          errorElements
        ) : (
          <Alert elevation={0} severity="success">
            <Typography variant="h6">No errors.</Typography>
          </Alert>
        )}
      </Box>
    </Box>
  ) : null;
};

ErrorList.propTypes = {
  errors: PropTypes.array.isRequired
};

export default ErrorList;
