import { Box, Typography, CircularProgress } from '@material-ui/core';
import BottomScrollListener from 'react-bottom-scroll-listener';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import PropTypes from 'prop-types';
import React from 'react';

import ConfirmationModal from '../modals/MuiConfirmationModal';
import ItemTile from '../inspector/MuiItemTile';

const MultiInspector = ({
  items,
  itemsType,
  value,
  appId,

  numCols,
  cellHeight,

  onEdit,
  onDelete,
  onWrite,
  onCreate,

  // navState
  blocks
}) => {
  const [itemToDelete, setItemToDelete] = React.useState(null);

  const viewStep = 40;
  const [viewing, setViewing] = React.useState(viewStep);
  React.useEffect(() => {
    setViewing(viewStep);
  }, [blocks]);

  return (
    <Box display="flex" flex={1}>
      <ConfirmationModal
        confirmIsDanger
        open={!!itemToDelete}
        title={'Delete'}
        message={`Are you sure you want to permanently delete '${itemToDelete ? itemToDelete.name : ''}'?`}
        onClose={() => {
          setItemToDelete(null);
        }}
        onConfirm={() => {
          onDelete(itemToDelete);
          setItemToDelete(null);
        }}
      />

      {items === undefined && (
        <Box display="flex" flex={1} justifyContent="center" alignItems="center" flexDirection="column">
          <CircularProgress size={40} disableShrink />
          <Typography variant="h5" align="center" style={{ marginTop: '10px' }}>
            Loading...
          </Typography>
        </Box>
      )}

      {items
        && (items.length ? (
          <BottomScrollListener
            onBottom={() => {
              if (viewing < items.length) {
                setViewing(Math.min(viewing + viewStep, items.length));
              }
            }}
          >
            {scrollRef => (
              <Box flex={1} pr={1} className={'scrollable-y'} ref={scrollRef}>
                <GridList cols={numCols} cellHeight={cellHeight} spacing={8}>
                  {items.slice(0, viewing).map((item, index) => (
                    <GridListTile rows={1} cols={1} key={index}>
                      <ItemTile
                        cellHeight={cellHeight}
                        item={item}
                        version={item.version}
                        type={itemsType}
                        isSelected={item.id === value}
                        onEdit={() => onEdit(item)}
                        onDelete={
                          onDelete ? newItemToDelete => this.setState({ itemToDelete: newItemToDelete }) : undefined
                        }
                        onCreate={onCreate}
                        appId = {appId}
                        selectedCategoryName = {blocks}
                        onWrite={onWrite ? () => onWrite(item) : undefined}
                      />
                    </GridListTile>
                  ))}
                </GridList>
              </Box>
            )}
          </BottomScrollListener>
        ) : (
          <Box display="flex" flex={1} justifyContent="center" alignItems="center">
            <Typography variant="h5" align="center">
              There are no items found with this combination of filters.
            </Typography>
          </Box>
        ))}
    </Box>
  );
};

// {viewing < items.length && (
//   <Box display="flex" alignItems="start" justifyContent="center">
//     <Button
//       onClick={() => {
//         setViewing(Math.min(viewing + viewStep, items.length));
//       }}
//     >
//       View More
//     </Button>
//   </Box>
// )}

MultiInspector.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  itemsType: PropTypes.string.isRequired, // TODO We need a more robust DnD solution.
  value: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,

  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onWrite: PropTypes.func,
  onCreate: PropTypes.func,

  numCols: PropTypes.number,
  cellHeight: PropTypes.number,

  blocks: PropTypes.string
};

MultiInspector.defaultProps = {
  numCols: 4,
  cellHeight: 100
};

export default MultiInspector;
