import { AsyncStatus, TrellisActions } from '@enklu/server-api';

import {
  ADD_SHARED_ASSET,
  REMOVE_SHARED_ASSET
} from '../../actions/assetActions';
import LibraryTypes from '../../../constants/LibraryTypes';
import MessageTypes from '../../../constants/MessageTypes';
import { assetAdd } from '../../../util/bridgeMessages';

const { UPDATEANASSET, DELETEMYASSET, SHAREASSETWITHAPP } = TrellisActions;

/**
 * The initial state of the piece of state for which this reducer is responsible.
 * This reducer maintains a list of assets, keyed by their IDs.
 */
export const initialState = {};

/**
 * @param settings - { type, getActionName }
 */
export default ({ type: reducerType, getActionName }) => (state = initialState, action = {}) => {
  switch (action.type) {
    case getActionName: {
      if (action.status === AsyncStatus.SUCCESS) {
        return action.body.reduce((accum, asset) => ({
          ...accum,
          [asset.id]: asset
        }), {});
      }

      return state;
    }

    case DELETEMYASSET: {
      if (action.status === AsyncStatus.SUCCESS) {
        const { assetId } = action.request.replacements;
        const { [assetId]: deletedAsset, ...newState } = state;

        return newState;
      }

      return state;
    }

    case UPDATEANASSET: {
      if (action.status === AsyncStatus.SUCCESS) {
        const { body: updatedAsset } = action;
        const { [updatedAsset.id]: oldAsset, ...newState } = state;
        if (oldAsset) {
          return {
            ...state,
            [updatedAsset.id]: updatedAsset
          };
        }

        return newState;
      }

      return state;
    }

    // Shares an asset with a library.
    case ADD_SHARED_ASSET: {
      const tag = `__${action.shareType}__`;
      if (tag === reducerType) {
        return {
          ...state,
          [action.asset.id]: action.asset
        };
      }

      return state;
    }

    case REMOVE_SHARED_ASSET: {
      const { [action.assetId]: removedAsset, ...newState } = state;
      return newState;
    }

    // This one only applies to the app reducer.
    case SHAREASSETWITHAPP: {
      if (reducerType === LibraryTypes.APP) {
        if (action.status === AsyncStatus.SUCCESS) {
          const {
            assetsById
          } = action;

          const asset = assetsById[action.request.body.assetId];

          // send to webgl
          window.bridge.send(
            MessageTypes.ASSET_ADD,
            assetAdd({ asset }));

          return {
            ...state,
            [asset.id]: asset
          };
        }
      }

      return state;
    }

    default: {
      return state;
    }
  }
};
