import uuid from 'uuid';
import { log } from '../../../util/log';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import { ViewArray } from '@material-ui/icons';

/**
 * Returns a type based on tags.
 */
export function tagsToType(tags) {
  const substrings = tags.split(';')[0].split(',');
  // eslint-disable-next-line no-restricted-syntax
  for (const tag of substrings) {
    const lower = tag.toLowerCase();
    if (lower === 'vine') {
      return 'Vine';
    }
  }

  return 'Behavior';
}

/**
 * Retrieves prop information from tags.
 */
export function tagsToProps(tags) {
  // tabBlob;propBlob
  const [, propString = '[]'] = (tags || '').split(';');
  const props = JSON.parse(propString);
  return props;
}

/**
 * Figures out prop data from a script.
 */
export function updateTagsFromSource(tags, source) {
  let adjustedName;
  const [tagBlob] = tags.split(';');

  const propDefinitions = [];

  // parse source
  let start = source.indexOf('{[');
  while (start !== -1) {
    const end = source.indexOf(']}', start);
    if (end === -1) {
      return tagBlob;
    }

    // parse prop definition
    const slice = source.slice(start + 2, end);
    const [name, def = ActionSchemaTypes.STRING] = slice.split(':');
    adjustedName = name;

    // only accept the first def for a specific prop name
    if (!propDefinitions.find(_def => _def.name === name)) {
      // try to parse the definition
      // eslint-disable-next-line prefer-const
      let [type, defaultValue = null] = def.split('=')
        .map(subst => subst.trim());

      switch (type) {
        case ActionSchemaTypes.FLOAT: {
          defaultValue = parseFloat(defaultValue);

          if (Number.isNaN(defaultValue)) {
            defaultValue = 0;
          }
          break;
        }
        case ActionSchemaTypes.INT: {
          defaultValue = parseInt(defaultValue, 10);

          if (Number.isNaN(defaultValue)) {
            defaultValue = 0;
          }
          break;
        }
        case ActionSchemaTypes.BOOL: {
          defaultValue = !!defaultValue;
          break;
        }
        case ActionSchemaTypes.PHRASES: {
          type = ActionSchemaTypes.STRING;
          defaultValue = JSON.stringify({
            prompt: "p",
            text: "t",
            voice: "Ray",
            language: "English",
            phrases:{
              default: {
                prompt: "",
                localizations: {
                  English: {
                    text: "",
                    url: "",
                    audioID: "",
                    id: uuid()
                  }
                }
              }
            },
            selectedPhrase: "default"
          });
          adjustedName = `phrases_${name}`;
          break;
        }
        default: {
          if (defaultValue === null) {
            defaultValue = '';
          } else if (defaultValue && typeof defaultValue === 'string') {
            // trim quotes
            if (
              (defaultValue.startsWith('\'') && defaultValue.endsWith('\''))
              || (defaultValue.startsWith('"') && defaultValue.endsWith('"'))
            ) {
              defaultValue = defaultValue.slice(1, defaultValue.length - 1);
            }
          }

          defaultValue = String(defaultValue) || '';
        }
      }

      propDefinitions.push({
        name: adjustedName,
        type,
        defaultValue
      });
    }

    start = source.indexOf('{[', end);
  }

  return `${tagBlob};${JSON.stringify(propDefinitions)}`;
}

/**
 * Constructs the absolute url for a script.
 */
export const scriptUrl = (uri) => {
  const index = uri.indexOf('://');
  if (index === -1) {
    return '';
  }

  let version;
  try {
    version = Number.parseInt(uri.substr(1, index - 1), 10);
  } catch (_) {
    log.error(`Could not parse version from script uri: ${uri}.`);
    return '';
  }

  if (version !== 3) {
    log.error(`Unknown script version: ${uri}.`);
    return '';
  }

  const part = uri.substr(index + 3);
  return `${window.env.scriptsUrl}${part}`;
};
