import React, {useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { log } from '../util/log';
import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import BaseButton from '../components/material-ui/BaseButton';
import { getActions } from '@enklu/server-api';

import { muiPalette } from '../styles/muiTheme';
import { MATCHING_PASSWORDS } from '../constants/AccountActionTypes';

import {
  sendPasswordResetAction,
  validatePassword,
  matchingPasswords
} from '../store/actions/credentialActions';

const style = {
  headerContainer: {
    paddingTop: '2em',
    marginBottom: '3em'
  },
  headerLeft:{
    paddingLeft: '48px',
    textAlign: 'left'
  },
  headerRight: {
    paddingRight: '48px',
    textAlign: 'right'
  },
  headerLink: {
    color: muiPalette.common.white,
    cursor: 'pointer',
    verticalAlign: 'middle'
  },
  imageCorner: {
    height: '80px',
    paddingRight: '48px',
    position: 'absolute',
    right: '0'
  },
  imageCenter: {
    width: '100px',
    paddingBottom: '2em',
    margin: 'auto'
  },
  colCentered: {
    float: 'none',
    margin: '0 auto'
  },
  textContainer: {
    padding: '0 0 3em'
  }
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%'
  },
  shadowContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '36px',
    boxShadow: `5px 5px 35px 15px ${theme.palette.common.boxShadow}38`
  },
  welcome: {
    fontSize: '2.5em'
  },
  input: {
    '& > *': {
      marginBottom: theme.spacing(3)
    }
  },
  buttonPadding: {
    fontWeight: 'bold'
  }
}));

/**
 * Create Password component
 * Asks user to input a new password for their account.
 */
export const CreateNewPassword = props => {
  const {
    passwordReset,
    token,
    status,
    checkPasswords,
    validPassword
  } = props;

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordSubmitted, setNewPasswordSubmitted] = useState(false);
  const classes = useStyles();  

  const {
    headerContainer,
    headerLeft,
    headerRight,
    headerLink,
    imageCorner,
    imageCenter,
    colCentered,
    textContainer
  } = style;

  const {
    wrapper,
    shadowContainer,
    input,
    buttonPadding
  } = classes;

  const isDesktop = useMediaQuery('(min-width:600px)');
  const containerClass = isDesktop ? shadowContainer : null;
  const signupStyle = isDesktop ? headerLeft : headerRight;
  const linkVariant = isDesktop ? 'body2' : 'subtitle1';
  const passwordsMatch = password && password == confirmPassword && password.length >= 6; 
  const checknewPasswordSubmitted = newPasswordSubmitted ? passwordUpdatedConfirmation() : askUserForNewPassword();

  return (
    <Grid container direction="row" justify="center" alignItems="center" className={wrapper}>
      <Grid style={headerContainer} container>
        <Grid style={headerLeft} item xs={6}>
          <Link variant={linkVariant} style={headerLink}
            href='/#/signin'>
            <ArrowBackIosIcon fontSize="large">Filled</ArrowBackIosIcon>
          </Link>
          <Link variant={linkVariant}
            href='/#/signin'>
            Back to sign in
          </Link>
        </Grid>
        <Grid style={headerRight} item xs={1}>
          <img src='/assets/img/Logo.Medium.png' style={imageCorner}/>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={8} md={6} lg={5} xl={4} className={containerClass} style={{marginBottom: 'auto'}}>
        <Grid container justify='space-between'>
          {checknewPasswordSubmitted}
        </Grid>
      </Grid>
    </Grid>
  );

  /**
  * Renders create password input form
  */
  function askUserForNewPassword() {
    return (
      <Grid item xs={10} sm={9} md={8} lg={6} style={colCentered}>
        <Typography variant='h3' style={{textAlign: 'center', marginTop:'2em'}}>Create New Password</Typography>
        <Typography variant='h5' style={{textAlign: 'center', marginBottom: '1.5em'}}>Please enter a new password.  Passwords must contain at least 6 characters.</Typography>
        <form>
          <div className={input}>
            <TextField
              id="inputPassword"
              type='password'
              label='PASSWORD'
              autoComplete='current-password'
              variant="outlined"
              required
              fullWidth
              autoFocus
              value={password}
              onChange={({ target: { value } }) => {
                setPassword(value);
                validPassword({ password: value });
              }}
            />
            <TextField
              id="confirmPassword"
              type='password'
              label='CONFIRM PASSWORD'
              autoComplete='current-password'
              variant="outlined"
              required
              fullWidth
              value={confirmPassword} 
              onChange={({ target: { value } }) => {
                setConfirmPassword(value);
                checkPasswords({password, confirmPassword});
              }}
            />
          </div>
          <Box py={5} style={{textAlign: 'center'}}>
            <BaseButton
              variant='contained'
              disableElevation
              color='primary'
              type='submit'
              className={buttonPadding}
              size='large'
              disabled={!passwordsMatch}
              onClick={(event) => {
                event.preventDefault();
                if(password && passwordsMatch) {
                  passwordReset({ password, token });
                  setNewPasswordSubmitted(true);
                }
              }}
            >CHANGE PASSWORD
            </BaseButton>
          </Box>
        </form>
      </Grid>
    );
  }

  function passwordUpdatedConfirmation() {
    return (
      <Grid item xs={10} sm={9} md={8} lg={6} style={colCentered}>
        <Typography variant='h3' style={{textAlign: 'center', marginTop:'2em'}}>Your password has been reset!</Typography>
        <Grid item xs={5} md={4} style={colCentered}>
          <CardMedia
            component='img'
            image='/assets/img/sai.svg'
            style={imageCenter}
          />
        </Grid>
        <Box py={5} style={{textAlign: 'center'}}>
          <BaseButton
            variant='contained'
            disableElevation
            color='primary'
            type='submit'
            className={buttonPadding}
            size='large'
            href='/#/signin'
          >SIGN IN
          </BaseButton>
        </Box>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  token: state.passwordResetStatus.token,
  status: state.passwordResetStatus.status
});

const mapDispatchToProps = dispatch => ({
  passwordReset: ({ password, token }) => {
    try{
      dispatch(sendPasswordResetAction( { password, token } ))
    } catch(error) {
      log.error(error);
    }
  },
  validPassword: ({ password }) => {
    dispatch(validatePassword( { password } ))
  },
  checkPasswords: ({password, confirmPassword}) => {
    dispatch(matchingPasswords( { password, confirmPassword } ))
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateNewPassword));
