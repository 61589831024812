import { log } from '../../util/log';
import { AsyncStatus, TrellisActions } from '@enklu/server-api';

import {
  NOTIF_SCENEUPDATE,
  SET_QUALITY_SETTING,
  SET_RENDERING_SETTING,
  SET_SCENE_SCHEMA
} from '../actions/sceneActions';
import { elementById, parentByChildId } from '../../util/elementHelpers';
import ActionSchemaTypes from '../../constants/ActionSchemaTypes';
import SchemaContainersByType from '../../constants/SchemaContainersByType';
import ActionTypes from '../../constants/ActionTypes';
import { INITIALIZE } from '../actions/initializeActions';

const { GETSCENE } = TrellisActions;

/**
 * Returns a copy of the input node, which includes a reference to its parent.
 * Recursive for children in an attached array `children`.
 * All other properties remain the same, which could be a problem for objects.
 *
 * @param node - { children }
 * @param parent - node
 * @returns {{parent: *}}
 */
export const addParents = (node, parent = null) => {
  const parentedNode = {
    ...node,
    parent
  };

  if (!parentedNode.children) {
    parentedNode.children = [];
  }

  if (parentedNode.children.length) {
    parentedNode.children = parentedNode.children.map(n => addParents(n, parentedNode));
  }

  return parentedNode;
};

export const initialState = {
  // All scenes.
  rawScenes: [
    // This is what a scene object should look like. Replaced by the rawScenes() reducer.
    {
      app: 'NOT SET',
      createdAt: 'NOT SET',
      elements: {
        children: [],
        id: 'root',
        parent: null,
        type: 0
      },
      id: '-1',
      qualitySettings: {}, // Dynamically constructed
      sceneSettings: {},
      updatedAt: 'NOT SET'
    }
  ],

  // All transactions.
  txns: []
};

// Currently this cannot be changed.

/**
 * A complete settings object is constructed out of the default data below. The structure is:
 * ```
 *
 * [
 *    {
 *        name: 'WSAPlayerX86',
 *        displayName: 'WSAPlayer86',
 *        settings: [
 *            {
 *                name: 'all',
 *                displayName: 'All',
 *                settings: [<settingsDefaultsObjects>]
 *            }
 *        ]
 *    },
 *    ...
 * ]
 * ```
 * @type {*[]}
 */
export const platforms = [
  {
    name: 'WSAPlayerX86',
    displayName: 'WSAPlayerX86',
    settings: [ // subPlatforms
      // Keys that don't say otherwise (e.g. 'WSAPlayerX86.quality.textureQuality') are assumed to be 'all'.
      {
        name: 'all',
        displayName: 'All'
      }
    ]
  },

  {
    name: 'WebGLPlayer',
    displayName: 'WebGL',
    settings: [
      {
        name: 'all',
        displayName: 'All'
      }
    ]
  },

  {
    name: 'Android',
    displayName: 'Android',
    settings: [
      {
        name: 'all',
        displayName: 'All'
      }
    ]
  },

  {
    name: 'iPhonePlayer',
    displayName: 'iPhone',
    settings: [
      {
        name: 'all',
        displayName: 'All'
      }
    ]
  },
  {
    name: 'WindowsPlayer',
    displayName: 'Windows',
    settings: [
      {
        name: 'all',
        displayName: 'All'
      }
    ]
  }
];

export const qualitySettingsDefaults = {
  displayName: 'Quality Settings',
  name: 'qualitySettings',
  settings: [
    {
      displayName: 'Pixel Light Count',
      name: 'pixelLightCount',
      type: ActionSchemaTypes.INT,
      key: 'GENERATED',
      possibleValues: [
        {
          value: 0,
          displayName: 'None'
        },
        {
          value: 2,
          displayName: 'Low'
        },
        {
          value: 4,
          displayName: 'Medium'
        },
        {
          value: 6,
          displayName: 'High'
        }
      ],
      defaultValue: 2,
      value: 'GENERATED'
    },
    {
      displayName: 'Texture Resolution',
      name: 'textureQuality',
      type: ActionSchemaTypes.INT,
      key: 'GENERATED',
      possibleValues: [
        {
          value: 0,
          displayName: 'Full Res'
        },
        {
          value: 1,
          displayName: 'Half Res'
        },
        {
          value: 2,
          displayName: 'Quarter Res'
        },
        {
          value: 3,
          displayName: 'Eighth Res'
        }
      ],
      defaultValue: 0,
      value: 'GENERATED'
    },

    {
      displayName: 'Anisotropic Filtering',
      name: 'aniso',
      type: ActionSchemaTypes.STRING,
      key: 'GENERATED',
      possibleValues: [
        {
          value: 'ForceEnable',
          displayName: 'Forced On'
        },
        {
          value: 'Enable',
          displayName: 'Per Texture'
        },
        {
          value: 'Disable',
          displayName: 'Disabled'
        }
      ],
      defaultValue: 'Disable',
      value: 'GENERATED'
    },

    {
      displayName: 'Anti-Aliasing',
      name: 'aa',
      type: ActionSchemaTypes.INT,
      key: 'GENERATED',
      possibleValues: [
        {
          value: 0,
          displayName: 'Disabled'
        },
        {
          value: 2,
          displayName: '2x'
        },
        {
          value: 4,
          displayName: '4x'
        },
        {
          value: 8,
          displayName: '8x'
        }
      ],
      defaultValue: 0,
      value: 'GENERATED'
    },

    {
      displayName: 'Soft Particles',
      name: 'softParticles',
      type: ActionSchemaTypes.BOOL,
      key: 'GENERATED',
      defaultValue: false,
      value: 'GENERATED'
    },

    {
      displayName: 'Realtime Reflection Probes',
      name: 'realtimeReflectionProbes',
      type: ActionSchemaTypes.BOOL,
      key: 'GENERATED',
      defaultValue: false,
      value: 'GENERATED'
    },

    {
      displayName: 'Billboards',
      name: 'billboards',
      type: ActionSchemaTypes.BOOL,
      key: 'GENERATED',
      defaultValue: false,
      value: 'GENERATED'
    },

    {
      displayName: 'Shadow Quality',
      name: 'shadowQuality',
      type: ActionSchemaTypes.STRING,
      key: 'GENERATED',
      possibleValues: [
        {
          value: 'Disable',
          displayName: 'Disabled'
        },
        {
          value: 'HardOnly',
          displayName: 'Hard Only'
        },
        {
          value: 'All',
          displayName: 'Hard and Soft'
        }
      ],
      defaultValue: 'HardOnly',
      value: 'GENERATED'
    },

    {
      displayName: 'Shadow Mask',
      name: 'shadowMask',
      type: ActionSchemaTypes.STRING,
      key: 'GENERATED',
      possibleValues: [
        {
          value: 'DistanceShadowmask',
          displayName: 'Distance Only'
        },
        {
          value: 'ShadowMask',
          displayName: 'Shadowmask'
        }
      ],
      defaultValue: 'ShadowMask',
      value: 'GENERATED'
    },

    {
      displayName: 'Shadow Resolution',
      name: 'shadowResolution',
      type: ActionSchemaTypes.STRING,
      key: 'GENERATED',
      possibleValues: [
        {
          value: 'Low',
          displayName: 'Low'
        },
        {
          value: 'Medium',
          displayName: 'Medium'
        },
        {
          value: 'High',
          displayName: 'High'
        },
        {
          value: 'VeryHigh',
          displayName: 'VeryHigh'
        }
      ],
      defaultValue: 'Low',
      value: 'GENERATED'
    },

    {
      displayName: 'Shadow Projection',
      name: 'shadowProjection',
      type: ActionSchemaTypes.STRING,
      key: 'GENERATED',
      possibleValues: [
        {
          value: 'CloseFit',
          displayName: 'Close Fit'
        },
        {
          value: 'StableFit',
          displayName: 'Stable Fit'
        }
      ],
      defaultValue: 'StableFit',
      value: 'GENERATED'
    },

    {
      displayName: 'Blend Weights',
      name: 'blendWeights',
      type: ActionSchemaTypes.STRING,
      key: 'GENERATED',
      possibleValues: [
        {
          value: 'OneBone',
          displayName: '1 Bone'
        },
        {
          value: 'TwoBones',
          displayName: '2 Bones'
        },
        {
          value: 'FourBones',
          displayName: '4 Bones'
        }
      ],
      defaultValue: 'TwoBones',
      value: 'GENERATED'
    }
  ]
};

/**
 * Finds an option by its key.
 *
 * @param setting
 * @param key
 * @returns {setting, address} The requested setting and its address [platformIndex, subplatformIndex, settingIndex].
 */
export const findSetting = (setting, key) => {
  const splitKey = key.split('.');
  const platformIndex = setting.settings.findIndex(({ name: platformName }) => platformName === splitKey[0]);
  if (platformIndex === -1) {
    log.error(`No platform by name ${key[0]}`);
    return null;
  }

  const platform = setting.settings[platformIndex];
  const subPlatformIndex = 0; // When we have more platforms we'll need to search/error here.
  const subPlatform = platform.settings[subPlatformIndex];
  const settingIndex = subPlatform.settings.findIndex(({ name }) => name === splitKey[2]);
  if (settingIndex === -1) {
    log.error(`No setting by name ${splitKey[2]} in platform ${key[0]}`);
  }

  return {
    setting: subPlatform.settings[settingIndex],
    address: [platformIndex, subPlatformIndex, settingIndex]
  };
};

/**
 * Parses scene quality settings into a standard structure for use by the UI (e.g. ControlPanel).
 *
 * @param schema.floats
 * @param schema.strings
 * @param schema.bools
 */
export const settingsFromSchema = (defaults, schema = {}) => ({
  ...defaults,
  settings: platforms.map(({ name: platformName, displayName: platformDisplayName, settings: subPlatforms }) => ({
    name: platformName,
    displayName: platformDisplayName,
    settings: subPlatforms.map(({ name: subPlatformName, displayName: subPlatformDisplayName }) => ({
      name: subPlatformName,
      displayName: subPlatformDisplayName,
      settings: defaults.settings.map((defaultOption) => {
        const {
          name,
          type,
          defaultValue,
          possibleValues
        } = defaultOption;
        const key = `${platformName}${subPlatformName === 'all' ? '' : subPlatformName}.quality.${name}`;
        const { [SchemaContainersByType[type]]: schemaSection = {} } = schema;
        const { [key]: value } = schemaSection;

        const setOption = {
          ...defaultOption,
          key,
          value: defaultValue
        };

        if (value !== undefined) {
          // Special case: make sure that values intended to be booleans become so.
          let finalValue = value;
          if (typeof finalValue === 'string') {
            const possibleBoolean = finalValue.toLowerCase();
            if (possibleBoolean === 'true' || possibleBoolean === 'false') {
              finalValue = possibleBoolean === 'true';
            }
          }

          // Is value valid?
          if (possibleValues) {
            const possibleValue = possibleValues.find(candidate => finalValue === candidate.value);
            if (!possibleValue) {
              log.warn(`Value '${finalValue}' is not a possible value for ${key}`);
              finalValue = defaultValue;
            }
          }

          setOption.value = finalValue;
        }

        return setOption;
      })
    }))
  }))
});

/**
 * Applies a create action to a scene. Always returns a copy, even on failure.
 */
export function applyCreateAction(scene, action) {
  const { parentId, data } = action;
  const reducedScene = { ...scene };
  const parent = elementById(reducedScene.elements, parentId);
  if (parent) {
    parent.children = (parent.children ? [...parent.children] : []);
    parent.children.push(data);

    return reducedScene;
  }

  log.error(`Could not apply create action: parent ${parentId} not found.`, action);
  return reducedScene;
}

/**
 * Applies an update action to a scene. Always returns a copy, even on failure.
 * TODO This mutates state (specifically: the element).
 */
export function applyUpdateAction(scene, action) {
  const { elementId } = action;

  const reducedScene = { ...scene };
  const element = elementById(reducedScene.elements, elementId);
  if (element) {
    const { schemaType, key, value } = action;

    const schema = element.schema ? { ...element.schema } : {};
    const schemaContainerName = SchemaContainersByType[schemaType];
    const schemaContainer = schema[schemaContainerName] ? { ...schema[schemaContainerName] } : {};
    schemaContainer[key] = value;
    schema[schemaContainerName] = schemaContainer;
    element.schema = schema;

    return reducedScene;
  }

  log.error(`Could not apply update action: element ${elementId} not found.`, action);
  return reducedScene;
}

/**
 * Applies a delete action to a scene.
 * TODO This mutates state.
 */
export function applyDeleteAction(scene, action) {
  const { elementId } = action;
  const reducedScene = { ...scene };

  const element = parentByChildId(reducedScene.elements, elementId);
  if (element) {
    const newChildren = [...element.children];
    const childIndex = newChildren.findIndex(child => child.id === action.elementId);

    newChildren.splice(childIndex, 1);
    element.children = newChildren;

    return reducedScene;
  }

  log.error(`Could not apply delete action: parent of ${elementId} not found.`, action);
  return reducedScene;
}

/**
 * Applies a move action to a scene. Always returns a copy, even on failure.
 * TODO This mutates state.
 */
export function applyMoveAction(scene, action) {
  const reducedScene = { ...scene };
  const { elementId, parentId } = action;

  // find old parent first
  const currentParent = parentByChildId(reducedScene.elements, elementId);
  if (!currentParent) {
    log.error('Could not find current parent for move element action.', action);
    return reducedScene;
  }

  // then new parent
  const element = elementById(currentParent, elementId);
  const newParent = elementById(reducedScene.elements, parentId);
  if (!newParent) {
    log.error('Could not find new parent for move element action.', action);
    return reducedScene;
  }

  // remove from old
  currentParent.children = [...currentParent.children];
  currentParent.children.splice(currentParent.children.indexOf(element), 1);

  // add to new
  newParent.children = newParent.children ? [...newParent.children] : [];
  newParent.children.push(element);

  // update position
  element.schema = element.schema || {};
  element.schema.vectors = element.schema.vectors || {};
  element.schema.vectors.position = action.value;

  return reducedScene;
}

/**
 * Applies action to a scene.
 */
const applyActions = (scene, sceneActions) => {
  let newScene = scene;

  const actionHandlers = {
    [ActionTypes.CREATE]: applyCreateAction,
    [ActionTypes.UPDATE]: applyUpdateAction,
    [ActionTypes.DELETE]: applyDeleteAction,
    [ActionTypes.MOVE]: applyMoveAction
  };

  // Apply all actions on the scene.
  sceneActions.forEach((sceneAction) => {
    const handler = actionHandlers[sceneAction.type];
    if (handler) {
      newScene = handler(scene, sceneAction);
    } else {
      log.error(`Unhandled action type ${sceneAction.type}`);
    }
  });

  return newScene;
};

/**
 * Performs an action on the settings for a scene.
 *
 * @param state
 * @param action
 * @returns {{}}
 */
const qualitySettings = (state = settingsFromSchema(qualitySettingsDefaults), action) => {
  switch (action.type) {
    case GETSCENE: {
      const { schema } = action;
      return settingsFromSchema(qualitySettingsDefaults, schema);
    }

    case SET_QUALITY_SETTING: {
      const {
        setting: {
          type: settingType,
          key
        },
        value,
        schema = {}
      } = action;

      const schemaContainerName = SchemaContainersByType[settingType];
      const schemaContainer = schema[schemaContainerName] || {};

      return settingsFromSchema(
        qualitySettingsDefaults,
        {
          ...schema,
          [schemaContainerName]: {
            ...schemaContainer,
            [key]: value
          }
        }
      );
    }

    case NOTIF_SCENEUPDATE: {
      const { schema } = action;
      return settingsFromSchema(
        qualitySettingsDefaults,
        schema
      );
    }

    default: {
      return state;
    }
  }
};

const sceneSettings = state => state;

/**
 * Performs an action on a single scene.
 *
 * @param state
 * @param action
 */
const scene = (state = initialState.rawScenes[0], action) => {
  switch (action.type) {
    case GETSCENE: {
      if (action.status == AsyncStatus.SUCCESS) {
        const { body: sceneData } = action;
        const { elements: elementsData } = sceneData;
        const elements = JSON.parse(elementsData);

        return {
          ...sceneData,
          elements,
          qualitySettings: qualitySettings(state.qualitySettings, {
            ...action,
            schema: elements.schema
          }),
          sceneSettings: sceneSettings(state.sceneSettings, action)
        };
      }

      return state;
    }

    case SET_QUALITY_SETTING: {
      const {
        elements: { schema }
      } = state;
      return {
        ...state,
        qualitySettings: qualitySettings(state.qualitySettings, {
          ...action,
          schema
        }),
        sceneSettings: sceneSettings(state.sceneSettings, action)
      };
    }

    case SET_RENDERING_SETTING: {
      return {
        ...state,
        elements: {
          ...state.elements,
          schema: {
            ...state.elements.schema,
            [action.schemaType]: {
              ...state.elements.schema[action.schemaType],
              [action.key]: action.value
            }
          }
        }
      };
    }

    case NOTIF_SCENEUPDATE: {
      const { actions } = action;
      const newState = applyActions(state, actions);
      const { schema } = newState.elements;
      return {
        ...newState,
        qualitySettings: qualitySettings(state.qualitySettings, {
          ...action,
          schema
        }),
        sceneSettings: sceneSettings(state.sceneSettings, action)
      };
    }

    default: {
      return state;
    }
  }
};

/**
 * Performs an action on the list of scenes.
 *
 * @param state
 * @param action
 * @returns {*}
 */
const rawScenes = (state = initialState.rawScenes, action) => {
  switch (action.type) {
    case INITIALIZE: {
      return initialState.rawScenes;
    }

    case GETSCENE: {
      if (action.status === AsyncStatus.SUCCESS) {
        const scenes = state === initialState.rawScenes ? [] : state;
        return [...scenes, scene({}, action)];
      }

      return state;
    }

    case NOTIF_SCENEUPDATE: {
      const sceneIndex = state.findIndex(({ id }) => id === action.sceneId);
      if (sceneIndex === -1) {
        log.error(`Cannot find scene '${action.sceneId}' to update.`);
        return state;
      }

      const oldScene = state[sceneIndex];
      const newState = [...state];
      newState[sceneIndex] = scene(oldScene, action);

      return newState;
    }

    case SET_QUALITY_SETTING:
    case SET_RENDERING_SETTING: {
      // Since we don't currently use scene indices, we assume first scene for all actions.
      // TODO This should be held in store.
      return [scene(state[0], action)];
    }

    default: {
      return state;
    }
  }
};

/**
 * Performs an action on the list of transactions (scenes.txns).
 * NOTE currently unused.
 * Handles NOTIF_SCENEUPDATE actions.
 *
 * @param state
 * @param action
 * @returns {*[]}
 */
const txns = (state = initialState.txns, action) => {
  const { type, id } = action;
  switch (type) {
    case NOTIF_SCENEUPDATE: {
      const index = state.indexOf(id);
      if (index !== -1) {
        const newState = [...state];
        newState.splice(index, 1);

        return newState;
      }

      log.warn('Transaction not found', action);
      return state;
    }

    default: {
      return state;
    }
  }
};

/**
 * Performs actions on scenes and scene transactions.
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default (state = initialState, action) => {
  return {
    rawScenes: rawScenes(state.rawScenes, action),
    txns: txns(state.txns, action)
  };
};
