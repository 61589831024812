import api from "./api";

const autoCompleter = {
	/**
	 * Retrieves autocompletions.
	 * 
	 * TODO: Major cleanup. This is proof of concept only.
	 */
	getCompletions: function(editor, session, pos, prefix, callback) {
		var text = (function(full, pos) {

			function setIndex(position) {
				index = 0;

				// count rows first
				var lines = position.row;
				while (lines-- > 0) {
					index = full.indexOf("\n", index + 1);
				}

				// count columns
				index += position.column;
			}

			var index = 0;
			setIndex(pos);

			return {
				getIndex: function() {
					return index;
				},
				getCharacter: function(index) {
					if (index < 0 || index > full.length) {
						return null;
					}

					return full[index];
				},
				getSubstring: function(start, end) {
					return full.substring(start, end);
				}
			};
		})(editor.getValue(), pos);

		// move backward till semi-colon
		var startIndex = text.getIndex();
		var current = startIndex;
		var character = "";
		var regex = new RegExp(/[\w0-9\.]/, 'i');
		while (true) {
			character = text.getCharacter(--current);

			if (!character || !regex.test(character)) {
				break;
			}
		}
		
		var completion = text.getSubstring(current + 1, startIndex + 1);
		var objects = completion.split(".");

		// now work through objects
		function search(startNode, searches) {
			var search = searches[0];
			var node = startNode[search];
			if (node) {
				if (searches.length === 1) {
					return node;
				}

				return search(startNode[search], searches.slice(1));
			}

			return null;
		}

		var fuzzySearchStart = api;
		if (objects.length > 1) {
			fuzzySearchStart = search(
				api,
				objects.slice(0, objects.length - 1));
		}

		var set = FuzzySet();
		for (var name in fuzzySearchStart) {
			set.add(name);
		}

		var fuzzy = objects[objects.length - 1];
		var results = set.get(fuzzy);
		var matches = [];
		for (var match of (results || [])) {
			var name = match[1];
			var score = match[0];
			matches.push({
				name: name,
				value: name,
				score: score * 100,
				meta: fuzzySearchStart[name].meta
			});
		}

		callback(null, matches);
	}
};

export default autoCompleter;