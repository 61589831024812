/** ****************************************************************************
 * Share updates.
 **************************************************************************** */
export const ADD_SHARED_ASSET = 'asset_add_shared_asset';
export const addSharedAsset = (shareType, asset) => ({
  type: ADD_SHARED_ASSET,
  shareType,
  asset
});

export const REMOVE_SHARED_ASSET = 'asset_remove_shared_asset';
export const removeSharedAsset = assetId => ({
  type: REMOVE_SHARED_ASSET,
  assetId
});

/** ****************************************************************************
 * Add/remove uploading and processing assets.
 **************************************************************************** */
export const ADD_UPLOADING_ASSET = 'asset_add_uploading_asset';
export const addUploadingAsset = asset => ({
  type: ADD_UPLOADING_ASSET,
  asset
});

export const ADD_PROCESSING_ASSET = 'asset_add_processing_asset';
export const addProcessingAsset = asset => ({
  type: ADD_PROCESSING_ASSET,
  asset
});

export const REMOVE_PROCESSING_ASSET = 'asset_remove_processing_asset';
export const removeProcessingAsset = assetId => ({
  type: REMOVE_PROCESSING_ASSET,
  assetId
});

export const ASSET_ERROR = 'asset_error';
export const assetError = ({ assetId, errors }) => ({
  type: ASSET_ERROR,
  assetId,
  errors
});

export const NOFITY_ASSET_UPDATE = 'asset_update_notif';
export const notifyAssetUpdate = id => ({
  type: NOFITY_ASSET_UPDATE,
  id
});
