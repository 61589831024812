import { DropTarget } from 'react-dnd';
import {
 List, ListItem, ListItemText, Paper
} from '@material-ui/core';
import { getActions } from '@enklu/server-api';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import ChooserShape from '../../interfaces/ChooserShape';
import ItemTypes from '../../features/dragAndDrop/DragDropTypes';

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.default
  }
}));

const { updateanasset } = getActions('trellis');

const target = {
  canDrop({ dropRules, url }, monitor) {
    const { type, item } = monitor.getItem();

    if (type !== ItemTypes.ASSET) {
      // TODO Expand this when scripts can have categories.
      return false;
    }

    // Some categories can't be dropped in at all.
    if (dropRules[url] === false) {
      return false;
    }

    // If it's already in this category, reject.
    if (item.tags.includes(url)) {
      return false;
    }

    return true;
  },

  drop(props, monitor) {
    const { url } = props;
    const { item } = monitor.getItem();

    // Assign the url of the drop target as a tag.
    props.onDrop(item, url);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}

const ItemRenderer = DropTarget(
  'item',
  target,
  collect
)(({
 isSelected, label, connectDropTarget, onClick, isOver, canDrop
}) => connectDropTarget(
    <div>
      <ListItem
        button
        selected={isSelected}
        onClick={onClick}
        style={{ background: isOver ? (canDrop ? 'rgb(44, 168, 175, 0.1)' : 'rgb(255, 0, 0, 0.1)') : '' }}
      >
        <ListItemText primary={label} />
      </ListItem>
    </div>
  ));

const DragChooser = ({
 items, value, onChange, dispatch, dropRules, customClass
}) => {
  const classes = useStyles();
  return (
    <Paper className={clsx('scrollable-y', classes.paper, customClass)}>
      <List>
        {items.map(({ name, url }, index) => (
          <ItemRenderer
            key={index}
            label={name}
            url={url}
            isSelected={value === url}
            onClick={() => onChange(url)}
            onDrop={({ id: assetId, tags }, dropUrl) => {
              dispatch(updateanasset({ tags: `${tags},${dropUrl}` }, { assetId }));
              onChange(url);
            }}
            dropRules={dropRules}
          />
        ))}
      </List>
    </Paper>
  );
};

DragChooser.propTypes = {
  ...ChooserShape,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,

  dispatch: PropTypes.func.isRequired,

  // An object of rules that say whether items can be dropped.
  dropRules: PropTypes.shape({
    noDrop: PropTypes.arrayOf(PropTypes.string)
  }),
  customClass: PropTypes.string
};

DragChooser.defaultProps = {
  dropRules: {
    noDrop: []
  }
};

export default DragChooser;
