import {
 Box, Divider, IconButton, Menu, MenuItem, Paper, Typography
} from '@material-ui/core';
import { DropTarget } from 'react-dnd';
import { Lock, MoreVert, Visibility } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import { isValidRelationship } from '../../util/util';
import BaseIconCheckbox from '../material-ui/BaseIconCheckbox';
import ElementTypes from '../../constants/ElementTypes';

const useStyles = makeStyles(theme => ({
  title: props => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 160,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontWeight: props.node.isSelected || props.node.searchStatus === 'target: element' ? 'bold' : undefined,
    opacity: props.node.isLocked || props.node.ancestorIsLocked ? 0.3 : undefined
  }),
  icon: {
    // fontSize: 15,
    padding: 3
  },
  paper: props => ({
    paddingLeft: 3,
    display: 'flex',
    flex: 1,
    backgroundColor: props.node.isSelected ? 'rgba(255, 255, 255, 0.16)' : undefined
  }),
  container: {
    display: 'flex',
    flex: 1,
    height: props => props.rowHeight - theme.spacing(1) / 2
  },
  divider: {
    height: props => props.rowHeight - theme.spacing(1) / 2
  },
  assetWrapper: {
    alignItems: 'center',
    display: 'flex',
    backgroundColor: theme.palette.grayscale.black
  },
  assetTitle: props => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 60,
    marginLeft: 3,
    marginRight: 3,
    fontWeight: props.node.searchStatus === 'target: asset' ? 'bold' : undefined
  })
}));

const MenuTypes = {
  NONE: 0,
  ROOT: 1,
  CREATE: 2
};

const createMenu = [
  { title: 'Back', eventKey: 'back', includeDivider: true },
  { title: 'Asset', eventKey: ElementTypes.CONTENT },
  { title: 'Light', eventKey: ElementTypes.LIGHT },
  { title: 'Kinect', eventKey: ElementTypes.KINECT },
  { title: 'Image Anchor', eventKey: ElementTypes.IMAGE_ANCHOR },
  { title: 'Text', eventKey: ElementTypes.NETWORKED_TEXT },
  { title: 'Button', eventKey: ElementTypes.NETWORKED_BUTTON }
];

const linkedComponentMenuItem = {
  title: 'Linked Component', eventKey: ElementTypes.LINKED_COMPONENT
};

const ElementHierarchyItem = ({
  node,
  onSelect,
  onToggleLock,
  onToggleVisibility,
  onNew,
  onDuplicate,
  onDelete,
  connectDropTarget,
  rowHeight
}) => {
  const classes = useStyles({ node, rowHeight });
  const [menuType, setMenuType] = React.useState(MenuTypes.NONE);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const renderIconCheckboxes = () => {
    const { isVisible, isLocked } = node;

    return (
      <div>
        <BaseIconCheckbox
          className={clsx('MuiIconButton-sizeSmall', classes.icon)}
          size="small"
          checkedIcon={<Visibility />}
          icon={<Visibility />}
          checked={isVisible}
          onClick={(event) => {
            event.preventDefault();
            onToggleVisibility(!isVisible);
          }}
        />
        <BaseIconCheckbox
          className={clsx('MuiIconButton-sizeSmall', classes.icon)}
          size="small"
          checkedIcon={<Lock />}
          icon={<Lock />}
          checked={isLocked}
          onChange={(event) => {
            event.preventDefault();
            onToggleLock(!isLocked);
          }}
        />
      </div>
    );
  };

  const renderMenuButton = () => {
    const { isVisible, isLocked, isRemovable } = node;

    // Only display Linked Component creation option for internal Enklu accounts
    if (createMenu.length < 8) {
      if (io.sails.user.email.includes('@enklu.com') || io.sails.user.email.includes('@createar.co')) {
        createMenu.splice(3, 0, linkedComponentMenuItem);
      }
    }

    const onClicked = (key) => {
      switch (key) {
        case 'back':
          setMenuType(MenuTypes.ROOT);
          return;

        case 'create':
          setMenuType(MenuTypes.CREATE);
          return;

        case 'delete':
          onDelete(node);
          break;

        case 'duplicate':
          onDuplicate(node);
          break;

        case 'toggleLock':
          onToggleLock(!isLocked);
          break;

        case 'toggleVisibility':
          onToggleVisibility(!isVisible);
          break;

        default:
          onNew(node, key);
          break;
      }

      setMenuType(MenuTypes.NONE);
    };

    return (
      <Box display="flex">
        <IconButton
          size="small"
          onClick={(event) => {
            event.preventDefault();
            setMenuType(MenuTypes.ROOT);
            setAnchorEl(event.currentTarget);
          }}
        >
          <MoreVert />
        </IconButton>

        <Menu open={menuType !== MenuTypes.NONE} anchorEl={anchorEl} onClose={() => setMenuType(MenuTypes.NONE)}>
          {{
            [MenuTypes.NONE]: [],
            [MenuTypes.ROOT]: [
              { title: 'Create...', eventKey: 'create' },
              { title: 'Delete', eventKey: 'delete', disabled: !isRemovable },
              { title: 'Duplicate', eventKey: 'duplicate', disabled: !isRemovable },
              { title: isLocked ? 'Unlock' : 'Lock', eventKey: 'toggleLock' },
              { title: isVisible ? 'Hide' : 'Show', eventKey: 'toggleVisibility' }
            ],
            [MenuTypes.CREATE]: createMenu
          }[menuType].map((mi, i) => (
            <MenuItem
              disabled={mi.disabled}
              key={i}
              onClick={() => {
                onClicked(mi.eventKey);
              }}
              divider={mi.includeDivider}
            >
              {mi.title}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  };

  // const { containerStyle, selectedContainerStyle } = style;
  // const nodeStyle = node.isSelected ? selectedContainerStyle : containerStyle;

  return connectDropTarget(
    <div
      className={classes.container}
      onClick={() => {
        if (node.isEditable || node.elementId === 'root') {
          onSelect(node);
        }
      }}
      // Without this, Firefox tries to do a redirect
      onDrop={(event) => {
        event.preventDefault();
      }}
    >
      <Paper square className={classes.paper}>
        <Box flex={1} display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
          <Box flex={1} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
            {renderIconCheckboxes()}
            <Divider orientation="vertical" className={classes.divider} />
            <Typography variant="subtitle1" className={classes.title}>
              {node.title}
            </Typography>
          </Box>
          <Divider orientation="vertical" className={classes.divider} />
          {renderMenuButton()}
        </Box>
        {node.type === ElementTypes.CONTENT && (
          <Paper square className={classes.assetWrapper}>
            {node.asset &&
            <Typography variant="caption" className={classes.assetTitle}>
              {node.asset.name}
            </Typography>
            }
          </Paper>
        )}
      </Paper>
    </div>
  );
};

const target = {
  // eslint-disable-next-line no-unused-vars
  canDrop(props, monitor) {
    return true;
  },

  // eslint-disable-next-line no-unused-vars
  hover(props, monitor, component) {},

  // eslint-disable-next-line no-unused-vars
  drop(props, monitor, component) {
    const { item, type } = monitor.getItem();
    const { node } = props;

    props.onDrop({
      node,
      item,
      type
    });
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}

ElementHierarchyItem.propTypes = {
  node: PropTypes.object.isRequired,
  style: PropTypes.object,

  onNew: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onToggleLock: PropTypes.func.isRequired,
  onToggleVisibility: PropTypes.func.isRequired,

  connectDropTarget: PropTypes.func.isRequired,
  rowHeight: PropTypes.number.isRequired
};

ElementHierarchyItem.defaultProps = {};

export default DropTarget('item', target, collect)(ElementHierarchyItem);
