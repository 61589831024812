import {
  Box,
  DialogContent,
  DialogContentText,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Grid,
AppBar,
    Toolbar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Settings, Link, Code } from '@material-ui/icons';
import React, { Component, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CopyableText from '../common/CopyableText';
import BaseButton from '../material-ui/BaseButton';
import ModalHeader from '../material-ui/ModalHeader';
import { publishApp, unpublishApp } from '../../store/actions/appActions';
import { getAppInfo } from '../../store/selectors/appSelectors';
import { getUserProfile } from '../../store/selectors/userSelectors';

import PublishedAppQrCode from '../modals/MuiPublishedAppQrCodeModal';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: 700
  },
  bottomPaper: {
    marginTop: theme.spacing(4)
  },
  gridContainer: {
    justifyContent: 'space-between'
  },
  publishButton: {
    whiteSpace: 'nowrap',
    marginLeft: '12px',
    float: 'right'
  },
  publishDialog: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex'
  },
  settingsMenu: {
    float: 'right',
    position: 'absolute',
    bottom: '25%',
    right: '2%'
  }
}));

const PublishExperienceModal = (props, state) => {
  const {
    app,
    appId,
    publishApp,
    unpublishApp,
    userId
  } = props;

  const classes = useStyles();
  const [appIsPublic, setAppIsPublic] = useState(app.ispublic);
  const [urlIsCopied, setUrlIsCopied] = useState(false);
  const [embedIsCopied, setEmbedIsCopied] = useState(false);

  const[QrCodeIsShown, setQrCodeIsShown] = React.useState(null);

  const hostUrl = window.location.origin;
  const playModeUrlParams = '/#/app/?app=';
  const playModeUrlParamsNoHash = '/?app=';
  const shareableUrl = (hostUrl + playModeUrlParams + appId);
  const shareableUrlNoHash = (hostUrl + playModeUrlParamsNoHash + appId);
  const timeTakenForResponse = 2000;

  const embedCode = `<iframe width=\"853\" height=\"480\" src=${shareableUrl}  allowFullScreen title=\"Embedded Experience\"/>`;


  const requestAppPublish = async () => {
    try {
      setAppIsPublic(await publishApp(appId));
    } catch (error) {
      console.error(error);
    }

    renderPublishedAppQRCode();
  };

  const requestAppUnpublish = async () => {
    try {
      setAppIsPublic(await unpublishApp(appId));
    } catch (error) {
      console.error(error);
    }
  };

  const renderPublishDialogue = () => {
    return (
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12}>
            <Typography variant="white" component="subtitle1" sx={{ flexGrow: 1 }}>
              Share a playable version of your experience with anyone!
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography>
              {renderPublishButton()}
            </Typography>
          </Grid>
          <Grid xs={2}>
            {/* Intentionally Empty */}
          </Grid>
        </Grid>
    );
  };

  const renderPublishButton = () => (
    <DialogContentText class={classes.publishDialog}>
      <BaseButton onClick={requestAppPublish} class={classes.publishButton} color="primary" variant="contained" size="medium">
        Publish
      </BaseButton>
    </DialogContentText>
  );

  const renderUnpublishButton = () => (
    <DialogContentText class={classes.publishDialog}>
      <BaseButton onClick={requestAppUnpublish} class={classes.publishButton} color="secondary" variant="contained" size="medium">
        Unpublish
      </BaseButton>
    </DialogContentText>
  );

  const renderCopyableUrl = () => {
    async function copyURLHandler() {
      try {
        await navigator.clipboard.writeText(shareableUrlNoHash);
        console.log('Shareable copied to clipboard');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
      setUrlIsCopied(true);
      setTimeout(() => {setUrlIsCopied(false);}, timeTakenForResponse);
    }
    return (
        <DialogContentText class={classes.publishDialog}>
          <BaseButton
              onClick={copyURLHandler}
              color={urlIsCopied ? "primary" : "white"} size="medium"
              startIcon={<Link/>}
          >
            {urlIsCopied ? "URL Copied" : 'Copy Shareable URL'}
          </BaseButton>
        </DialogContentText>
    );
  };

  const renderEmbedCode = () => {
    async function copyEmbedHandler() {
      try {
        await navigator.clipboard.writeText(embedCode);
        console.log('Embed code copied to clipboard');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
      setEmbedIsCopied(true);
      setTimeout(() => {setEmbedIsCopied(false);}, timeTakenForResponse);
    }
    return (
        <DialogContentText class={classes.publishDialog}>
          <BaseButton
              onClick={copyEmbedHandler}
              color={embedIsCopied ? "primary" : "white"} size="medium"
              startIcon={<Code/>}
          >
            {embedIsCopied ? "Embed Code Copied" : 'Copy Embed Code'}
          </BaseButton>
        </DialogContentText>
    );
  };

  const renderPublishedAppQRCode = () => {
    console.log("want to open qr code here");
    setQrCodeIsShown(app);
  };

  // unused
  // const renderSettingsMenu = () => {
  //   return (
  //     <Box class={classes.settingsMenu} sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
  //       <Tooltip title="Publish Settings">
  //         <IconButton size="small" sx={{ ml: 2 }}>
  //           <Settings sx={{ width: 32, height: 32 }} />
  //         </IconButton>
  //       </Tooltip>
  //     </Box>
  //   );
  // };

  return (
    <React.Fragment>
      <Paper class={classes.bottomPaper}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" color="dark" elevation={0}>
            <Toolbar>
              <Typography variant="white" component="h2" sx={{ flexGrow: 1 }}>
                Publish Your Experience
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
        
        {/* This component handles state of rendering Published/Unpublished UI elements via the appIsPublic state bool */}
        <DialogContent>
          { !appIsPublic ? renderPublishDialogue() : (
              // these elements are rendered if the app is public (appIsPublic = true)
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item xs={12}>
                  <Typography variant="white" component="subtitle1" sx={{ flexGrow: 1 }}>
                    Your experience is now playable by anyone! Share it using the options below.
                  </Typography>
                </Grid>
                
                {/* publish/unpublish button */}
                <Grid item xs="auto">
                  <Typography>
                    { !appIsPublic ? renderPublishButton() : renderUnpublishButton() }
                  </Typography>
                </Grid>
                
                <Grid item xs={12}>
                  <Typography variant="white" component="h3" sx={{ flexGrow: 1 }}>
                    Share Over The Web
                  </Typography>
                </Grid>
                
                {/* buttons for copying url and embed code */}
                <Grid item xs="auto">
                  <span>{renderCopyableUrl()}</span>
                </Grid>
                <Grid item xs="auto">
                  <span>{renderEmbedCode()}</span>
                </Grid>
                
                <Grid item xs={12}>
                  <Typography variant="white" component="h3" sx={{ flexGrow: 1 }}>
                    Play In HoloLens
                  </Typography>
                </Grid>

                {/* qr code for scanning the published experience */}
                <Grid item xs={12}>
                  <Typography variant="white" component="subtitle1" sx={{ flexGrow: 1 }}>
                    This QR code allows anyone, not just the user who generated it, to play a Published Experience on the HoloLens. 
                    Scan this code in the Enklu HoloLens app and follow the onscreen prompts.
                    <br/>
                  </Typography>
                  <PublishedAppQrCode
                    app = {QrCodeIsShown}
                  />
                </Grid>
              </Grid> 
              ) } 
        </DialogContent>
      </Paper>
    </React.Fragment>
  );
};

PublishExperienceModal.propTypes = {
  app: PropTypes.object.isRequired,
  appId: PropTypes.string.isRequired,
  getApp: PropTypes.func,
  publishApp: PropTypes.func.isRequired,
  unpublishApp: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  app: getAppInfo(state),
  appId: getAppInfo(state).id,
  userId: getUserProfile(state).id
});

const mapDispatchToProps = dispatch => ({
  publishApp: async (appId) => {
    const publishRequest = await dispatch(publishApp({ appId }));
    return publishRequest;
  },

  unpublishApp: async (appId) => {
    const unpublishRequest = await dispatch(unpublishApp({ appId }));
    return unpublishRequest;
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublishExperienceModal));
