import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { GroupAdd } from '@material-ui/icons';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';

import { pushNavState as _pushNavState } from '../../store/actions/navActions';
import { getAppInfo } from '../../store/selectors/appSelectors';
import { initializeUntitledApp } from '../../store/actions/initializeActions';
import { log } from '../../util/log';
import BaseButton from '../../components/material-ui/BaseButton';
import MenuButton from '../../components/material-ui/MenuButton';
import MenuKeys from './MenuKeys';
import ModalTypes from '../../constants/ModalTypes';
import PresenceList from '../presence/MuiPresenceList';
import undoManager from '../../store/actions/UndoManager';

const useStyles = makeStyles(theme => ({
  image: {
    height: '28px',
    width: '28px',
    margin: '-3px 16px 0 -5px',
    cursor: 'pointer'
  },
  icon: {
    height: '20px',
    width: '20px',
    margin: '0 16px',
    cursor: 'pointer'
  },
  title: {
    marginRight: theme.spacing(2)
  },
  inviteButton: {
    fontWeight: 'bold',
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(2),
    borderRadius: 8
  }
}));

// extract staticContext so it's not forwarded down the chain
const MenuBar = ({
 pushNavState, info, staticContext, onInitializeUntitledApp, history, location, ...otherProps
}) => {
  const classes = useStyles();

  const navigateToMyExperiences = () => {
    history.push({
      ...location,
      pathname: '/init'
    });
  };

  const openQRModal = () => {
    pushNavState({
      wmodal: MenuKeys.HOLOLENS_LOGIN
    });
  };

  const handleSelect = ({ eventKey }) => {
    switch (eventKey) {
      // Modals:
      case MenuKeys.PREFERENCES:
      case MenuKeys.HOLOLENS_LOGIN:
      case MenuKeys.MANAGE_MY_ACCOUNT:
      case MenuKeys.SHARE: {
        pushNavState({
          wmodal: eventKey
        });
        break;
      }
      case MenuKeys.OPEN: {
        navigateToMyExperiences();
        break;
      }
      case MenuKeys.NEW: {
        onInitializeUntitledApp({ history });
        break;
      }
      case MenuKeys.UNDO: {
        undoManager.undo();

        break;
      }
      case MenuKeys.REDO: {
        undoManager.redo();
        break;
      }
      case MenuKeys.DEBUG:
      case MenuKeys.PLAY:
      case MenuKeys.STOP:
      case MenuKeys.PAUSE:
      case MenuKeys.RESUME:
        break;
      case MenuKeys.SUPPORT: {
        window.open('https://feedback.userreport.com/5dc5b61a-79b6-48b8-b78c-912adc29d1c7', '_blank');
        break;
      }
      case MenuKeys.DOCUMENTATION: {
        window.open('https://enklu.notion.site/Guides-Overview-cf67ad3a808645d1b54f3a2789f35237', '_blank');
        break;
      }
      case MenuKeys.JS_HELP: {
        window.open('https://enklu.notion.site/Scripting-Overview-aab67f104e3041e3b3591854f5072016', '_blank');
        break;
      }
      default: {
        log.error('Main Menu: Unhandled eventKey', eventKey);
        break;
      }
    }
  };

  const menuItems = [
    {
      title: 'Experience',
      options: [
        { title: 'New', eventKey: MenuKeys.NEW },
        { title: 'My Experiences', eventKey: MenuKeys.OPEN },
        { title: 'HoloLogin', eventKey: MenuKeys.HOLOLENS_LOGIN }
      ]
    },
    {
      title: 'Edit',
      options: [
        { title: 'Undo', eventKey: MenuKeys.UNDO },
        { title: 'Redo', eventKey: MenuKeys.REDO }
      ]
    },
    /*
    {
      title: 'Debug',
      options: [
        { title: 'Play', eventKey: MenuKeys.PLAY },
        { title: 'Pause', eventKey: MenuKeys.PAUSE }
      ]
    },
    { title: 'Tools', options: [{ title: 'HoloLens Login', eventKey: MenuKeys.HOLOLENS_LOGIN }] },
    */
    {
      title: 'Help',
      options: [
        { title: 'Documentation', eventKey: MenuKeys.DOCUMENTATION },
        { title: 'Script API Reference', eventKey: MenuKeys.JS_HELP },
        { title: 'Support', eventKey: MenuKeys.SUPPORT },
        { title: 'Manage My Account', eventKey: MenuKeys.MANAGE_MY_ACCOUNT }
      ]
    }
  ];

  return (
    <AppBar position="relative" color="inherit" {...otherProps}>
      <Toolbar style={{ alignItems: 'center' }}>
        <img onClick={navigateToMyExperiences} src={'assets/img/Logo.Small.png'} className={classes.image} />
        <Typography variant="h6" className={classes.title}>
          {info.name || 'Enklu Dark Matter'}
        </Typography>
        {menuItems.map(mi => (
          <MenuButton
            key={mi.title}
            color="inherit"
            items={mi.options}
            onItemClick={handleSelect}
            itemsDisabled={mi.disabled}
          >
            {mi.title}
          </MenuButton>
        ))}
        <img onClick={openQRModal} src={'assets/icons/qr-icon.png'} className={classes.icon} />
        <div style={{ flexGrow: 1 }} />
        <PresenceList />
        <BaseButton
          color="primary"
          variant="contained"
          size="large"
          startIcon={<GroupAdd />}
          className={classes.inviteButton}
          onClick={() => {
            pushNavState({
              wmodal: ModalTypes.SHARE
            });
          }}
        >
          Share
        </BaseButton>
      </Toolbar>
    </AppBar>
  );
};

MenuBar.propTypes = {
  pushNavState: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
  staticContext: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onInitializeUntitledApp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  info: getAppInfo(state)
});

const mapDispatchToProps = (dispatch, { history, location }) => ({
  pushNavState: navState => dispatch(_pushNavState(history, location, navState)),
  onInitializeUntitledApp: ({ history: appHistory }) => dispatch(initializeUntitledApp({ history: appHistory }))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuBar));
