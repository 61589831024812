import { Box, Paper, Typography } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';

import { addAssetToElement } from '../../../store/actions/elementActions';
import { getAllAssetsById, getLibraries } from '../../../store/selectors/assetLibrariesSelectors';
import { getAppInfo } from '../../../store/selectors/appSelectors';
import { parseAssetSrc, stringifyAssetSrc } from '../../../util/util';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import InspectableIdentity, { elementToInspectable } from '../common/MuiInspectableIdentity';
import InspectorInterface from '../common/InspectorInterface';
import ItemSocket from '../MuiItemSocket';
import ItemTypes from '../../../features/dragAndDrop/DragDropTypes';
import ScriptsInspector from '../script/MuiScriptsInspector';
import Transform from '../MuiTransform';
import VersionSelector from '../MuiVersionSelector';

const ContentElementInspector = ({
  location,
  history,
  assetsById,
  element,
  scene: { id: sceneId },
  onAddAssetToElement,
  onUpdateElement
}) => {
  /**
   * Called when an item should be edited.
   */
  const onEditItem = ({ item, url }) => {
    if (!item) {
      return;
    }

    const pathname = `/i/${url}/${item.id}`;

    // ignore if we're already there
    if (location.pathname === pathname) {
      return;
    }

    const { search } = location;
    history.push({
      pathname,
      search
    });
  };

  const { id: elementId, schema: { strings: { assetSrc = '' } = {} } = {} } = element;

  // get version and id
  const [assetId, assetVersion] = parseAssetSrc(assetSrc);

  // find asset
  const asset = assetsById[assetId];

  return (
    <React.Fragment>
      <Box>
        {/* Basics */}
        <InspectableIdentity
          wrapInPaper
          inspectable={element}
          inspectableTranslator={elementToInspectable}
          onUpdate={onUpdateElement}
        />

        <Transform hideTitle element={element} sceneId={sceneId} />

        {/* Asset. */}
        <Box display="flex" flexDirection="column" mt={1}>
          {/* <Typography variant="h6">Asset</Typography> */}
          {
            <Box display="flex" height={220}>
              <ItemSocket
                item={asset}
                version={asset && assetVersion === -1 ? asset.version : assetVersion}
                type={ItemTypes.ASSET}
                onDrop={(_asset, oldAsset) => onAddAssetToElement({
                    sceneId,
                    element,
                    asset: _asset,
                    oldAsset
                  })
                }
                onEdit={item => onEditItem(item)}
                onRemove={() => onUpdateElement({
                    name: 'assetSrc',
                    value: '',
                    type: ActionSchemaTypes.STRING
                  })
                }
                placeholder={'Drop an asset here.'}
              />
            </Box>
          }
          {asset && (
            <Box mb={1}>
              <Paper square>
                <Box display="flex" py={1} p={1}>
                  <VersionSelector
                    value={assetVersion}
                    max={asset.version}
                    onChange={v => onUpdateElement({
                        name: 'assetSrc',
                        value: stringifyAssetSrc(asset, v),
                        type: ActionSchemaTypes.STRING
                      })
                    }
                  />
                </Box>
              </Paper>
            </Box>
          )}
        </Box>
      </Box>
      <ScriptsInspector sceneId={sceneId} element={element} asset = {asset} />
    </React.Fragment>
  );
};

ContentElementInspector.propTypes = {
  ...InspectorInterface,
  app: PropTypes.object.isRequired,
  appId: PropTypes.string.isRequired,
  assetsById: PropTypes.object.isRequired,
  libraries: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,

  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const { app, inspector } = state;
  return {
    app,
    appId: getAppInfo(state).id,
    assetsById: getAllAssetsById(state),
    libraries: getLibraries(state),
    inspector: inspector.content // TODO Why change?
  };
};

const dispatchMap = {
  onAddAssetToElement: addAssetToElement
};

export default compose(connect(mapStateToProps, dispatchMap), withRouter)(ContentElementInspector);
