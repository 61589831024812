import { transformSpace, TRANSFORM_SPACE_CHECK } from '../actions/controlBarActions';

const initialState = {
	transformSpace: 'Global'
};

export default (state = initialState, action) => {
	switch (action.type) {
		case TRANSFORM_SPACE_CHECK: {
			  return { transformSpace: action.payload };
		}

		default: {
			return initialState;
		}
	}
};