export function someElement(element, condition) {
  if (condition(element)) {
    return element;
  }
  return element.children.reduce((acc, elt) => {
    if (acc) return acc;
    return someElement(elt, condition);
  },
  undefined);
}

export function findElement(element, condition) {
  if (condition(element)) {
    return element;
  }

  for (const child of (element.children || [])) {
    var el = findElement(child, condition);
    if (el) {
      return el;
    }
  }

  return null;
}

/**
 * Retrieves an element by id from an element hierarchy.
 */
export function elementById(element, elementId) {
  return findElement(element, el => el.id === elementId);
}

/**
 * Retrieves elements of a specified type from an element hierarchy
 */
export function elementsByType(element = {}, type) {
  let els = [];
  if (element.type === type) {
    els.push(element);
  }

  for (var child of (element.children || [])) {
    els = [...els, ...elementsByType(child, type)]; 
  }

  return els;
}

/**
 * Retrieves the parent of the specified element id.
 */
export function parentByChildId(element, elementId) {
  for (var child of (element.children || [])) {
    if (child.id === elementId) {
      return element;
    }

    var el = parentByChildId(child, elementId);
    if (el) {
      return el;
    }
  }

  return null;
}

/**
 * Compares sibling indeces of two elements.
 */
export const compareSiblings = (lhs, rhs) => {
  return lhs.siblingIndex - rhs.siblingIndex;
}

/**
 * Copies an element its children.
 */
export const elementDeepCopy = (element) => {
  const children = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const child of (element.children || [])) {
    children.push(elementDeepCopy(child));
  }

  children.sort(compareSiblings);

  const copy = {
    ...element,
    children
  };
  delete copy.parent;

  return copy;
};
