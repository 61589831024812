const Devices = {
  HOLOLENS: 'hololens',
  DESKTOP: 'desktop',
  MOBILE: 'mobile'
};

export default Devices;

export const getDeviceType = (type) => {
  if (!type) {
    return Devices.DESKTOP;
  }

  const lcType = type.toLowerCase();
  
  if (lcType.startsWith('hololens')) {
    return Devices.HOLOLENS;
  }

  if (lcType.startsWith('ios') || lcType.startsWith('android')) {
    return Devices.MOBILE;
  }

  return Devices.DESKTOP;
}
