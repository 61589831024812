import { combineReducers } from 'redux';
import { AsyncStatus, TrellisActions } from '@enklu/server-api';

import createAssetsReducer from './assetsReducer';
import { NOFITY_ASSET_UPDATE } from '../../actions/assetActions';
import assetsUploadingReducer from './assetsUploadingReducer';
import { getAllAssets, getAllAssetsById } from '../../selectors/assetLibrariesSelectors';
import LibraryTypes from '../../../constants/LibraryTypes';
import MessageTypes from '../../../constants/MessageTypes';
import { assetList, assetUpdate } from '../../../util/bridgeMessages';

const {
  DEMOTETOPERSONAL,
  GETAPPASSETS,
  GETPERSONALASSETS,
  GETPUBLICASSETS,
  PROMOTETOSTANDARD,
} = TrellisActions;

// /////////////////////////////////////////////////////////////////////////////
// / Initial State.
// /////////////////////////////////////////////////////////////////////////////
const initialState = {
  libraries: {},
  errors: {}
};

const libraries = combineReducers({
  [LibraryTypes.APP]: createAssetsReducer({
    type: LibraryTypes.APP,
    getActionName: GETAPPASSETS
  }),
  [LibraryTypes.USER]: createAssetsReducer({
    type: LibraryTypes.USER,
    getActionName: GETPERSONALASSETS
  }),
  [LibraryTypes.PUBLIC]: createAssetsReducer({
    type: LibraryTypes.PUBLIC,
    getActionName: GETPUBLICASSETS
  }),
  [LibraryTypes.UPLOADING]: assetsUploadingReducer
});

// eslint-disable-next-line no-unused-vars
const errors = (state = initialState.errors, action) => state; // TODO

// ROOT REDUCER
export default (state = initialState, action) => {
  const newState = {
    libraries: libraries(state.libraries, {
      ...action,
      assetsById: getAllAssetsById({ libraries: state })
    }),
    errors: errors(state.errors, action)
  };

  // TODO: This is NOT how we should be operating. WebGLView should push the asset list 1 time.
  // TODO: After that, we should only need to push add, remove, and updates.
  if (Object.entries(LibraryTypes)
    .find(([, value]) => value === action.type) && action.status === AsyncStatus.SUCCESS) {
    window.bridge.send(
      MessageTypes.ASSET_LIST,
      assetList({ assets: getAllAssets(newState) }));
  }

  if (NOFITY_ASSET_UPDATE === action.type) {
    // find asset
    const asset = getAllAssetsById({ libraries: newState })[action.id];
    if (asset) {
      // send over bridge to let player know it can load
      window.bridge.send(
        MessageTypes.ASSET_UPDATE,
        assetUpdate({ asset }));
    }
  }

  // handle promotion (remove from all libraries, add to public)
  let allAssetsById;
  let assetId;
  let asset;
  if (action.type === PROMOTETOSTANDARD && action.status === AsyncStatus.SUCCESS) {
    ({ assetId } = action.request.body);
    allAssetsById = getAllAssetsById({ libraries: newState });
    asset = allAssetsById[assetId];

    // remove from all libraries
    for (const key in newState.libraries) {
      if (Object.prototype.hasOwnProperty.call(newState.libraries, key)) {
        const libAssets = newState.libraries[key];
        delete libAssets[assetId];
        newState.libraries[key] = { ...libAssets };
      }
    }

    // add to public
    newState.libraries[LibraryTypes.PUBLIC] = {
      ...newState.libraries[LibraryTypes.PUBLIC],
      [assetId]: {
        ...asset,
        owner: 'standard'
      }
    };
  }

  // handle demotion (remove from public, add to user)
  if (action.type === DEMOTETOPERSONAL && action.status === AsyncStatus.SUCCESS) {
    ({ assetId } = action.request.body);
    allAssetsById = getAllAssetsById({ libraries: newState });
    asset = allAssetsById[assetId];

    // remove from public
    const publicAssets = newState.libraries[LibraryTypes.PUBLIC];
    delete publicAssets[assetId];
    newState.libraries[LibraryTypes.PUBLIC] = { ...publicAssets };

    // add to user
    newState.libraries[LibraryTypes.USER] = {
      ...newState.libraries[LibraryTypes.USER],
      [assetId]: {
        ...asset,
        owner: action.request.replacements.userId
      }
    };
  }

  return newState;
};
