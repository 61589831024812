import * as React from 'react';
import {
IconButton, Menu, MenuItem
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { getAllScriptsById } from '../../store/selectors/scriptLibrariesSelectors';
import ConfirmationModal from '../modals/MuiConfirmationModal';
import EditScriptModal from './MuiEditScriptModal';
import { downloadscript } from '../../store/actions/scriptActions';
import { deleteScript } from '../../store/actions/inspector/inspectorActions';
import { scriptUrl } from '../../components/inspector/script/ScriptUtilities';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';

//connected to MuiItemTile and holds button that leads to dropdown menu and their respective actions
const ScriptThreeDotDropDown = ({
  appId,
  copyName,
  categoryName,
  script,
  onDeleteScript,
  onCreate,
  onLoadScript
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [scriptToRename, setScriptToRename] = React.useState(null);
    const [scriptToDescribe, setScriptToDescribe] = React.useState(null);
    const [scriptToDelete, setScriptToDelete] = React.useState(null);
    const [scriptToDuplicate, setScriptToDuplicate] = React.useState(null);
    const [scriptToAdd, setScriptToAdd] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClose = (event) => {
      event.stopPropagation();
      setAnchorEl(null);

    };
    
    const{id, name, description, tags} = script;
    
    return (
      <div>
          <IconButton 
            size="small"
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(event) => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
              }}
          >
            <MoreVert></MoreVert>
          </IconButton>
          
          {// depending on selected category a different menu for changes to a script appear          
          categoryName !=="public" ? 
          //this Menu appears when script is not public
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem 
              onClick={(event) => {
                event.stopPropagation();
                setScriptToRename(script);
                setAnchorEl(null);
              }}
            >
              Rename
            </MenuItem>
            <MenuItem 
              onClick={(event) => {
                event.stopPropagation();
                setScriptToDescribe(script);
                setAnchorEl(null);
              }}
            >
              Edit Description
            </MenuItem> 
            <MenuItem 
              onClick={(event) => {
                event.stopPropagation();
                setScriptToDuplicate(script);
                setAnchorEl(null);
              }}
            >
              Duplicate
            </MenuItem>
            <MenuItem 
              onClick={(event) => {
                event.stopPropagation();
                setScriptToDelete(script);
                setAnchorEl(null);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
          : 
          //this Menu appears when script is public
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem 
              onClick={(event) => {
                event.stopPropagation();
                setScriptToAdd(script);
                setAnchorEl(null);
            }}
            >
              Add Script
            </MenuItem>
          </Menu>}

          {/* Modals below are displayed depending on items selected in the menu*/}
          <EditScriptModal
            script={scriptToRename}
            isRenameScript = {true}
            onClose={(event) => {
              event.stopPropagation();
              setScriptToRename(null);
            }}
          /> 
          <EditScriptModal
            script={scriptToDescribe}
            isRenameScript = {false}
            onClose={(event) => {
              event.stopPropagation();
              setScriptToDescribe(null);
            }}
          /> 
          <ConfirmationModal
            title={'Delete Script'}
            confirmIsDanger
            onClick={(event) => {
              event.stopPropagation();
            }}
            message={`Are you sure you want to delete '${scriptToDelete ? name : ''}'? This cannot be undone.`}
            onClose={(event) => {
              setScriptToDelete(null); 
              event.stopPropagation();}}
            onConfirm={(event) => {
              event.stopPropagation();
              onDeleteScript({id});
              setScriptToDelete(null);
            }}
            open={!!scriptToDelete}
          />
          <ConfirmationModal
            title={'Duplicate'}
            onClick={(event) => {
              event.stopPropagation();
            }}
            message={`Are you sure you want to duplicate '${scriptToDuplicate ? name : ''}'?`}
            onClose={(event) => {
              event.stopPropagation();
              setScriptToDuplicate(null);
            }}
            onConfirm={async (event) => {
              event.stopPropagation();
              onCreate({
                appId,
                name: copyName,
                description,
                source: btoa(await onLoadScript(id, scriptUrl(script.uri))),
                tags
              });
              setScriptToDuplicate(null);
            }}
            open={!!scriptToDuplicate}
          />
          <ConfirmationModal
            title={'Add Script'}
            onClick={(event) => {
              event.stopPropagation();
            }}
            message={`Are you sure you want to add the '${scriptToAdd ? name : ''}' script to this experience?`}
            onClose={(event) => {
              event.stopPropagation();
              setScriptToAdd(null);
            }}
            onConfirm={async (event) => {
              event.stopPropagation();
              onCreate({
                appId,
                name,
                description,
                source: btoa(await onLoadScript(id, scriptUrl(script.uri))),
                tags
              });
              setScriptToAdd(null);
            }}
            open={!!scriptToAdd}
          />            
        </div>
        );
      }

    const mapStateToProps = state => ({
      scriptsById: getAllScriptsById(state)
    });
      
    const dispatchMap = {
      onDeleteScript: deleteScript,
      onLoadScript: downloadscript
    };
      
    ScriptThreeDotDropDown.propTypes = {
      scriptId: PropTypes.string,
      onCreate: PropTypes.func.isRequired,
      onClose: PropTypes.func.isRequired,
      onDelete: PropTypes.func.isRequired,
    
      // connect
      scriptsById: PropTypes.object.isRequired,
      onLoadScript: PropTypes.func.isRequired,
      onDeleteScript: PropTypes.func.isRequired
    };
      
    export default compose(connect(mapStateToProps, dispatchMap))(ScriptThreeDotDropDown);