import {
 Box, IconButton, Tooltip, Typography
} from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { meshUrl } from '../spaces/spaceUtils';
import CopyableText from '../../common/CopyableText';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.75
    },
    '& .MuiIconButton-label': {
      color: theme.palette.common.white
    },
    marginTop: theme.spacing(1)
  }
}));

/**
 * Displays information about a scan mesh with a download link.
 */
const MeshInspector = ({ meshData: { id, name, url } = {}, showName }) => {
  const classes = useStyles();
  return (
    <Box mt={2}>
      {showName && <Typography>{name}</Typography>}
      <Tooltip title={'download'} placement="top" arrow>
        <IconButton href={meshUrl(url)} className={classes.buttonContainer}>
          <CloudDownload />
        </IconButton>
      </Tooltip>

      <Box mt={1}>
        <Typography variant="caption">
          ID: <CopyableText text={id} />
        </Typography>
      </Box>
    </Box>
  );
};

MeshInspector.propTypes = {
  meshData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string.isRequired
  }).isRequired,
  showName: PropTypes.bool
};

MeshInspector.defaultProps = {
  meshData: {
    id: '',
    name: 'Mesh',
    url: ''
  },
  showName: false
};

export default MeshInspector;
