/**
 * This component adds a "copy" icon to any string displayed in the UI. Clicking that icon will copy
 * the string to the clipboard.
 *
 * @param text  The string to display (and copy).
 */
import { Check } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

let feedbackTimeout;

const useStyles = makeStyles({
  iconWrapper: {
    fontSize: 11
  }
});

const CopyableText = ({ text }) => {
  const [icon, setIcon] = useState('copy');

  const classes = useStyles();

  return (
    <React.Fragment>
      {text}

      <IconButton
        size="small"
        className={classes.iconWrapper}
        onClick={() => {
          if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(text);

            clearInterval(feedbackTimeout);
            setIcon('ok');
            feedbackTimeout = setTimeout(() => setIcon('copy'), 500);
          }
        }}
      >
        {icon === 'copy' && <AssignmentReturnedIcon color="primary" fontSize="inherit" />}
        {icon === 'ok' && <Check color="primary" fontSize="inherit" />}
      </IconButton>
    </React.Fragment>
  );
};

CopyableText.propTypes = {
  text: PropTypes.string.isRequired
};

export default CopyableText;
