import { AsyncStatus, TrellisActions } from '@enklu/server-api';
import { newUser } from '../../interfaces/UserShape';

const { GETUSER, SEARCHUSERSBYEMAIL } = TrellisActions;

const initialState = {
  // Map of users by ID.
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETUSER: {
      if (action.status === AsyncStatus.IN_PROGRESS) {
        const { userId } = action.request.replacements;
        return {
          ...state,
          [userId]: newUser({
            displayName: userId
          })
        };
      }

      if (action.status === AsyncStatus.SUCCESS) {
        return {
          ...state,
          [action.body.id]: newUser(action.body)
        };
      }

      return state;
    }

    case SEARCHUSERSBYEMAIL: {
      if (action.status === AsyncStatus.SUCCESS) {
        const newState = { ...state };
        action.body.forEach((user) => {
          newState[user.id] = newUser(user);
        });

        return newState;
      }

      return state;
    }

    default:
      return state;
  }
};
