import PropTypes from 'prop-types';

export default PropTypes.shape({
  displayName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
});

// TODO Generate these from the interfaces.
export const newUser = ({
  createdAt = '',
  displayName = '',
  email = '',
  id = '',
  updatedAt = ''
}) => ({
  createdAt,
  displayName,
  email,
  id,
  updatedAt
});
