import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { updateAction } from '../../../store/actions/elementActions';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import CheckboxField from '../../material-ui/CheckboxField';
import InspectableIdentity, { elementToInspectable } from '../common/MuiInspectableIdentity';
import InspectorInterface from '../common/InspectorInterface';
import txns from '../../../store/actions/TxnManager';

/**
 * Custom inspector for float elements.
 */
class FloatElementInspector extends React.Component {
  /**
   * Constructor.
   */
  constructor(props) {
    super(props);
    const { element } = props;
    const defaultFovReorient = 1.5;

    const {
      id,
      schema: {
        vectors: {
          position: elementPosition = {
            x: 0,
            y: 0,
            z: 2
          }
        } = {},
        floats: { 'fov.reorient': elementFovReorient = defaultFovReorient } = {}
      } = {}
    } = element;

    this.state = {
      zValue: elementPosition.z,
      fov: elementFovReorient,
      elementId: id
    };
  }

  /**
   * Called before component receives new props.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { element } = nextProps;
    const defaultFovReorient = 1.5;

    const {
      id,
      schema: {
        vectors: {
          position: elementPosition = {
            x: 0,
            y: 0,
            z: 2
          }
        } = {},
        floats: { 'fov.reorient': elementFovReorient = defaultFovReorient } = {}
      } = {}
    } = element;
    if (this.state.elementId !== id) {
      this.setState({
        ...this.state,
        elementId: id,
        zValue: elementPosition.z,
        fov: elementFovReorient
      });
    }
  }

  /**
   * Renders controls.
   */
  render() {
    const {
 element, scene, app, onUpdateElement, classes
} = this.props;
    const appId = app.info.id;
    const { id: sceneId } = scene;

    // element data
    const defaultName = 'Text';
    const defaultDescription = 'New text element.';
    const defaultFace = 'Camera';
    const defaultFocusVisible = false;
    const { fov, zValue } = this.state;

    const {
      id: elementId,
      schema: {
        strings: {
          name: elementName = defaultName,
          description: elementDescription = defaultDescription,
          face: elementFace = defaultFace
        } = {},
        bools: { 'focus.visible': elementFocusVisible = defaultFocusVisible } = {}
      } = {}
    } = element;

    return (
      <Box p={1}>
        <InspectableIdentity
          inspectable={element}
          inspectableTranslator={elementToInspectable}
          onUpdate={onUpdateElement}
        />

        {/* Settings */}
        <Box>
          <Typography variant="h6">Settings</Typography>
          <form className={classes.inputFieldsContainer}>
            <TextField
              label="Z"
              type="number"
              value={zValue}
              onChange={({ target: { value } }) => {
                this.setState({ zValue: value !== '' ? value : 0 });
              }}
              onBlur={(evt) => {
                const val = Number.parseFloat(evt.target.value);
                if (Number.isNaN(val)) {
                  return;
                }

                this.props.updateElementVec(appId, sceneId, elementId, 'position', {
                  x: 0,
                  y: 0,
                  z: val
                });
              }}
              fullWidth
              variant="outlined"
            />

            <CheckboxField
              value={elementFocusVisible}
              onChange={(evt) => {
                this.props.updateElementBool(appId, sceneId, elementId, 'focus.visible', !!evt.target.checked);
              }}
              label="Focus Visible"
            />

            <TextField
              label="FoV Reorient"
              type="number"
              value={fov}
              onChange={({ target: { value } }) => {
                this.setState({ fov: value !== '' ? value : 0 });
              }}
              onBlur={(evt) => {
                const float = Number.parseFloat(evt.target.value);
                if (Number.isNaN(float)) {
                  return;
                }

                this.props.updateElementFloat(appId, sceneId, elementId, 'fov.reorient', float);
              }}
              fullWidth
              variant="outlined"
            />

            <FormControl fullWidth>
              <InputLabel variant="outlined">Face</InputLabel>
              <Select
                input={<OutlinedInput />}
                value={elementFace}
                onChange={evt => this.props.updateElementString(appId, sceneId, elementId, 'face', evt.target.value)}
                variant="outlined"
              >
                <MenuItem value="Horizontal">Horizontal</MenuItem>
                <MenuItem value="Absolute">Absolute</MenuItem>
                <MenuItem value="Camera">Camera</MenuItem>
              </Select>
            </FormControl>
          </form>
        </Box>
      </Box>
    );
  }
}
FloatElementInspector.propTypes = {
  ...InspectorInterface,
  app: PropTypes.object,
  updateElementString: PropTypes.func.isRequired,
  updateElementInt: PropTypes.func.isRequired,
  updateElementFloat: PropTypes.func.isRequired,
  updateElementBool: PropTypes.func.isRequired,
  updateElementVec: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(theme => ({
  inputFieldsContainer: {
    marginTop: theme.spacing(2),
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  }
}))(
  withRouter(
    connect(
      ({ app }) => ({ app }),
      dispatch => ({
        /**
         * Updates an element string value.
         */
        updateElementString: (appId, sceneId, elementId, key, value) => {
          txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.STRING, key, value));
        },

        /**
         * Updates an element int value.
         */
        updateElementInt: (appId, sceneId, elementId, key, value) => {
          txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.INT, key, value));
        },

        /**
         * Updates an element float value.
         */
        updateElementFloat: (appId, sceneId, elementId, key, value) => {
          txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.FLOAT, key, value));
        },

        /**
         * Updates an element's bool value.
         */
        updateElementBool: (appId, sceneId, elementId, key, value) => {
          txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.BOOL, key, value));
        },

        /**
         * Updates an element vec value.
         */
        updateElementVec: (appId, sceneId, elementId, key, value) => {
          txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.VEC3, key, value));
        }
      })
    )(FloatElementInspector)
  )
);
