import { Box, Button, Paper } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/styles';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { addUploadingAsset, addProcessingAsset } from '../../../store/actions/assetActions';
import { log } from '../../../util/log';
import { muiPalette } from '../../../styles/muiTheme';
import { newAsset } from '../../../util/util';
import { typesAll } from '../../../constants/FileTypes';
import BaseButton from '../../material-ui/BaseButton';
import CreatorShape from '../../../interfaces/CreatorShape';
import Gallery from './UploaderGallery';
import LibraryTypes from '../../../constants/LibraryTypes';

class CreateNewAsset extends Component {
  uploader = new FineUploaderTraditional({
    options: {
      autoUpload: false,
      paramsInBody: true,
      request: {
        endpoint: `${window.env.assetBaseUrl}/v1/asset`,
        customHeaders: {
          Authorization: `Bearer ${io.sails.token}`,
          app: this.props.appId
        }
      },
      callbacks: {
        onValidate: ({ name }) => {
          const lcName = name.toLowerCase();
          let found = false;

          for (const ext of typesAll) {
            if (lcName.endsWith(ext)) {
              found = true;
              break;
            }
          }

          if (!found) {
            const key = this.props.enqueueSnackbar(
              'You have uploaded an unsupported asset type. Please use common image, audio, video or model extensions.',
              {
                variant: 'error',
                onClick: () => {
                  this.props.closeSnackbar(key);
                }
              }
            );
          }

          return found;
        },
        onStatusChange: (id, oldStatus, newStatus) => {
          const { status } = this.uploader.qq;

          switch (newStatus) {
            case status.SUBMITTED: {
              this.setState({ enableUploadButton: true });
              break;
            }

            case status.CANCELED: {
              // eslint-disable-next-line no-underscore-dangle
              this.setState({ enableUploadButton: this.uploader.methods._netUploadedOrQueued > 0 });
              break;
            }

            case status.UPLOADING: {
              const { dispatch, selectedCategoryName } = this.props;

              this.setState({ enableUploadButton: false });
              dispatch(
                addUploadingAsset(
                  newAsset({
                    uploadId: `upload-${id}`,
                    tags: selectedCategoryName
                  })
                )
              );

              break;
            }

            default:
              break;
          }
        },
        onComplete: (id, name, { body, success } /* xhr */) => {
          if (success) {
            const asset = newAsset({
              id: body.id,
              uploadId: `upload-${id}`
            });
            const { dispatch } = this.props;

            dispatch(addProcessingAsset(asset));
          } else {
            // TODO: new action for failed uploads
            log.error('Upload failed', name);
          }
        },
        onError: (id, name, errorReason /* xhr */) => log.info(id, name, errorReason),
        onAllComplete: (succeededIds, failedIds) => {
          const { onClose } = this.props;
          if (failedIds.length === 0) {
            onClose(true);
          } else {
            log.error('Uploads failed', failedIds);
          }
        }
      }
    }
  });

  constructor(props) {
    super(props);

    this.state = {
      enableUploadButton: false
    };
  }

  upload() {
    const { assets, selectedCategoryName } = this.props;

    const tags = [LibraryTypes.APP, LibraryTypes.PUBLIC, LibraryTypes.USER, LibraryTypes.UPLOADING].includes(
      selectedCategoryName
    )
      ? ''
      : selectedCategoryName;

    this.uploader.methods.setParams({
      decimation: JSON.stringify({ enabled: false }),
      tags
    });

    // set replacement asset ids if necessary
    let uploads = this.uploader.methods.getUploads();
    if (!Array.isArray(uploads)) {
      uploads = [uploads];
    }

    uploads.forEach(({ name, id }) => {
      // chop off extension
      const parsedName = name.split('.')[0];

      // find matching asset by name
      const asset = assets.find(({ name: assetName }) => assetName === parsedName);
      if (asset) {
        // TODO: in this case, the icon should probably say it's replacing an asset
        this.uploader.methods.setParams({ replacementAssetId: asset.id }, id);
      }
    });

    this.uploader.methods.uploadStoredFiles();
  }

  render() {
    const { onClose, classes } = this.props;

    const { enableUploadButton } = this.state;

    return (
      <Box display='flex' flexDirection='column' flex={1} px={1} className='scrollable-y'>
        <Paper elevation={5} style={{ flexGrow: 1 }}>
          <Box style={{ height: '100%' }} p={1}>
            <Gallery
              uploader={this.uploader}
              fileInput-className={'hidden'}
              progressBar-hideBeforeStart={false}
              progressBar-hideOnComplete={false}
              progressBar-className={'hidden'}
              filesize-className={'hidden'}
              dropzone-style={{
                borderRadius: 0,
                overflowY: 'auto',
                border: `2px dashed ${muiPalette.grayscale.darkGray}`,
                height: '100%'
              }}
            />
          </Box>
        </Paper>

        <Box flexDirection="row" display="flex" alignItems="center" justifyContent="flex-end" mt={1} style={{ marginBottom: '7px' }}>
          <Button size="large" color="default" variant="outlined" onClick={onClose} className={classes.cancelButton}>
            Cancel
          </Button>
          <BaseButton
            size="large"
            color="primary"
            variant="outlined"
            disabled={!enableUploadButton}
            onClick={() => this.upload()}
          >
            Upload
          </BaseButton>
        </Box>
      </Box>
    );
  }
}

CreateNewAsset.propTypes = {
  ...CreatorShape,

  assets: PropTypes.array.isRequired,
  selectedCategoryName: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func,

  classes: PropTypes.object.isRequired
};

export default withStyles(theme => ({
  cancelButton: {
    marginRight: theme.spacing(1)
  }
}))(withSnackbar(CreateNewAsset));
