/**
 * If the action has a 'value' property, it came from outside the editor: parse it as JSON.
 * Otherwise, just return the action.
 *
 * @param action
 * @returns {*}
 */
function parseAction(action) {
  if (!action.value) {
    return action;
  }

  return {
    ...action,
    value: JSON.parse(action.value)
  };
}

export const NOTIF_SCENEUPDATE = 'scene.notif.sceneupdate';

export function notifSceneUpdate(id, sceneId, actions) {
  return {
    type: NOTIF_SCENEUPDATE,
    id,
    sceneId,
    actions: actions.map(parseAction)
  };
}

export const NOTIF_SCENECREATION = 'scene.notif.scenecreation';

export function notifSceneCreation(id, sceneId, actions) {
  return {
    type: NOTIF_SCENECREATION,
    id,
    sceneId,
    actions: actions.map(parseAction)
  };
}

/**
 * Sets a single setting to a value.
 */
export const SET_QUALITY_SETTING = 'settings.setSceneSetting';
export const setQualitySetting = ({ category, setting, value }) => ({
  type: SET_QUALITY_SETTING,
  category,
  setting,
  value
});

export const SET_RENDERING_SETTING = 'settings.setRenderingSetting';
export const setRenderingSetting = ({ sceneId, schemaType, key, value }) => ({
  type: SET_RENDERING_SETTING,
  sceneId,
  schemaType,
  key,
  value
});
