import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActions } from '@enklu/server-api';

import { appSubscribe } from '../../store/actions/initializeActions';
import { showmodal, hidemodal } from '../../store/actions/modalActions';

const { getapiversion, getwebversion } = getActions('trellis');

/**
 * Monitors connection to the server.
 */
class ConnectionMonitor extends React.Component {
  // interval
  intervalId = 0;

  // quippy messages
  messages = [
    'I seem to have misplaced my connection to the server, just a sec.',
    "D'oh, let me Google how to reconnect to the server...",
    "The Internet is misbehaving. This is probably not your fault, but either way I'll reconnect.",
    'Well this is embarrassing-- let me reconnect you.',
    'Whoops, an intern tripped over a power cord somewhere far far away. Hold tight whilst I reconnect.',
    'Your connection is having a hiccup. Hold on while we try drinking from the far side of the cup.'
  ];

  /**
   * Constructor.
   */
  constructor(props) {
    super(props);

    this.state = {
      connected: false
    };
  }

  /**
   * Retrieves a random reconnect message.
   */
  getRandomMessage() {
    const index = Math.floor(Math.random() * Math.floor(this.messages.length));
    return this.messages[index];
  }

  /**
   * Renders component.
   */
  render() {
    return null;
  }

  /**
   * Checks the connection.
   */
  checkConnection() {
    const connected = io.socket.isConnected();
    if (connected !== this.state.connected) {
      this.setState({
        ...this.state,
        connected
      });

      if (connected) {
        this.props.hideModal();

        // resubscribe to app updates
        this.props.subscribe();

        // recheck server version
        this.props.checkVersion();
      } else {
        this.props.showModal(this.getRandomMessage());
      }
    }
  }

  /**
   * Sets up the poll after first render.
   */
  componentDidMount() {
    this.intervalId = setInterval(this.checkConnection.bind(this), 250);
  }

  /**
   * Clears the poll on unmount.
   */
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }
}

ConnectionMonitor.propTypes = {
  // connect
  subscribe: PropTypes.func.isRequired,
  checkVersion: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

const mapStateToProps = ({ init: { versions } }) => ({ versions });
const mapDispatchToProps = dispatch => ({
  /**
   * Resubscribes.
   */
  subscribe: () => {
    dispatch(appSubscribe());
  },

  /**
   * Checks Trellis API version for compatibility.
   */
  checkVersion: () => {
    dispatch(getapiversion());
    dispatch(getwebversion());
  },

  /**
   * Shows fullscreen modal.
   */
  showModal: (message) => {
    dispatch(
      showmodal({
        message,
        blurVisible: true,
        spinnerVisible: true
      })
    );
  },

  /**
   * Hides fullscreen modal.
   */
  hideModal: () => {
    dispatch(hidemodal());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionMonitor);
