import { Badge } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import { getInitialsFromName } from '../../util/util';
import BaseAvatar from './BaseAvatar';
import Devices, { getDeviceType } from '../../constants/userList/Devices';
import StandardPopover from './StandardPopover';

const EnkluAvatar = ({
  user: { displayName = '', thumbUrl, deviceInfo = {} } = {},
  classes,
  badgeProps,
  ...otherProps
}) => {
  const img = {
    [Devices.HOLOLENS]: '/assets/img/device-hololens.svg',
    [Devices.DESKTOP]: '/assets/img/device-desktop.svg',
    [Devices.MOBILE]: '/assets/img/device-mobile.svg'
  }[getDeviceType(deviceInfo.type)];

  const longDisplayName = deviceInfo.type ? `${displayName} - ${deviceInfo.type}` : displayName;

  return (
    <Badge
      className={clsx(otherProps.className, classes.root)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      overlap="circle"
      badgeContent={
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 2,
            borderRadius: 100,
            backgroundColor: 'white'
          }}
        >
          <img
            src={img}
            style={{
              height: '10px',
              width: '10px'
            }}
          />
        </div>
      }
      {...badgeProps}
    >
      <StandardPopover popoverText={longDisplayName}>
        <BaseAvatar alt={displayName} src={thumbUrl} {...otherProps}>
          {getInitialsFromName(displayName)}
        </BaseAvatar>
      </StandardPopover>
    </Badge>
  );
};

EnkluAvatar.propTypes = {
  classes: PropTypes.object.isRequired,
  badgeProps: PropTypes.object,
  user: PropTypes.object
};

export default withStyles(() => ({
  root: {
    // zIndex: 100,
    '& .MuiBadge-anchorOriginBottomRightCircle': {
      zIndex: 100
    },
    border: 0
  }
}))(EnkluAvatar);
