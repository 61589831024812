import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { updateAction } from '../../../store/actions/elementActions';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import InspectableIdentity, { elementToInspectable } from '../common/MuiInspectableIdentity';
import InspectorInterface from '../common/InspectorInterface';
import Transform from '../MuiTransform';
import txns from '../../../store/actions/TxnManager';

const AlignmentToLabel = {
  MidRight: 'Middle Right',
  MidCenter: 'Middle Center',
  MidLeft: 'Middle Left',

  TopRight: 'Top Right',
  TopCenter: 'Top Center',
  TopLeft: 'Top Left',

  BotRight: 'Bottom Right',
  BotCenter: 'Bottom Center',
  BotLeft: 'Bottom Left'
};

/**
 * Custom inspector for text elements.
 */
class TextElementInspector extends React.Component {
  /**
   * Constructor.
   */
  constructor(props) {
    super(props);

    const { element } = props;
    const { id, schema: { strings: { label = '' } = {} } = {} } = element;

    this.state = {
      settingsTextValue: label,
      elementId: id
    };
  }

  /**
   * Called before component receives new props.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { element } = nextProps;
    const { id, schema: { strings: { label = '' } = {} } = {} } = element;

    // element changed
    if (this.state.elementId !== id) {
      this.setState({
        ...this.state,
        elementId: id,
        settingsTextValue: label
      });
    }
  }

  /**
   * Returns true iff text is valid input.
   */
  isLabelValid() {
    const val = this.state.settingsTextValue || '';
    if (val.length > 255) {
      return false;
    }

    try {
      // we have to be able to encode it
      btoa(val);

      return true;
    } catch (ex) {
      return false;
    }
  }

  /**
   * Renders controls.
   */
  render() {
    const {
 element, scene, onUpdateElement, classes
} = this.props;
    const { id: sceneId } = scene;

    // element data
    const defaultLabel = '';
    const defaultFontSize = 20;
    const defaultWidth = 100.0;
    const defaultAlignment = 'MidLeft';
    const defaultOverflow = 'Overflow';

    const {
      id: elementId,
      schema: {
        strings: {
          label: elementLabel = defaultLabel,
          alignment: elementAlignment = defaultAlignment,
          overflow: elementOverflow = defaultOverflow
        } = {},
        ints: { fontSize: elementFontSize = defaultFontSize } = {},
        floats: { width: elementWidth = defaultWidth } = {}
      } = {}
    } = element;

    const textInvalid = !this.isLabelValid();

    return (
      <Box p={1}>
        <InspectableIdentity
          inspectable={element}
          inspectableTranslator={elementToInspectable}
          onUpdate={onUpdateElement}
        />

        <Transform hideTitle element={element} sceneId={sceneId} />

        {/* Settings */}
        <Box>
          <Typography variant="h6">Settings</Typography>
          <form className={classes.inputFieldsContainer}>
            <TextField
              label="Text"
              value={this.state.settingsTextValue}
              onChange={evt => this.setState({ settingsTextValue: evt.target.value })}
              onBlur={(evt) => {
                if (this.isLabelValid()) {
                  this.props.updateElementString(sceneId, elementId, 'label', evt.target.value || defaultLabel);
                } else {
                  // reset
                  this.setState({ settingsTextValue: elementLabel });
                }
              }}
              fullWidth
              variant="outlined"
              error={textInvalid}
              helperText={textInvalid ? '255 character max. Unicode not supported.' : ''}
            />

            <TextField
              label="Font Size"
              type="number"
              value={elementFontSize}
              onChange={(evt) => {
                const int = Number.parseInt(evt.target.value, 10);
                if (Number.isNaN(int)) {
                  return;
                }

                this.props.updateElementInt(sceneId, elementId, 'fontSize', int);
              }}
              fullWidth
              variant="outlined"
            />

            <TextField
              label="Width"
              type="number"
              value={elementWidth}
              onChange={(evt) => {
                const float = Number.parseFloat(evt.target.value);
                if (Number.isNaN(float)) {
                  return;
                }

                this.props.updateElementFloat(sceneId, elementId, 'width', float);
              }}
              fullWidth
              variant="outlined"
            />

            <FormControl fullWidth>
              <InputLabel variant="outlined">Alignment</InputLabel>
              <Select
                input={<OutlinedInput />}
                value={elementAlignment}
                onChange={evt => this.props.updateElementString(sceneId, elementId, 'alignment', evt.target.value)}
                variant="outlined"
              >
                {Object.entries(AlignmentToLabel).map(([k, v]) => (
                  <MenuItem key={k} value={k}>
                    {v}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel variant="outlined">Overflow</InputLabel>
              <Select
                input={<OutlinedInput />}
                value={elementOverflow}
                onChange={evt => this.props.updateElementString(sceneId, elementId, 'overflow', evt.target.value)}
                variant="outlined"
              >
                <MenuItem value="Overflow">Overflow</MenuItem>
                <MenuItem value="Wrap">Wrap</MenuItem>
              </Select>
            </FormControl>
          </form>
        </Box>
      </Box>
    );
  }
}

TextElementInspector.propTypes = {
  ...InspectorInterface,
  app: PropTypes.object.isRequired,
  updateElementString: PropTypes.func.isRequired,
  updateElementInt: PropTypes.func.isRequired,
  updateElementFloat: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(theme => ({
  inputFieldsContainer: {
    marginTop: theme.spacing(2),
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  }
}))(
  withRouter(
    connect(
      ({ app }) => ({ app }),
      () => ({
        /**
         * Updates an element string value.
         */
        updateElementString: (sceneId, elementId, key, value) => {
          txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.STRING, key, value));
        },

        /**
         * Updates an element int value.
         */
        updateElementInt: (sceneId, elementId, key, value) => {
          txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.INT, key, value));
        },

        /**
         * Updates an element float value.
         */
        updateElementFloat: (sceneId, elementId, key, value) => {
          txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.FLOAT, key, value));
        }
      })
    )(TextElementInspector)
  )
);
