import { SET_USER_PREFERENCES } from '../actions/preferencesActions';
import { INITIALIZE } from '../actions/initializeActions';

const initialState = {
  lastAppId: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_PREFERENCES: {
      const { payload: { preferences } } = action;
      return preferences;
    }

    // This is the current method for setting startup app, but it could be anything.
    case INITIALIZE: {
      const { payload: { appId } } = action;

      return {
        ...state,
        lastAppId: appId
      };
    }

    default: {
      return state;
    }
  }
};
