import { AsyncStatus, getActions } from '@enklu/server-api';
import {
 Button, Dialog, DialogActions, DialogContent, TextField
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { funcNewMonitor } from '../../util/util';
import ModalHeader from '../material-ui/ModalHeader';

const { updateapp, getmyapps } = getActions('trellis');

const RenameExperienceModal = ({
 updateApp, classes, app, onClose
}) => {
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');

  const { status: updateStatus, runAction: runUpdateStatus } = funcNewMonitor(updateApp);

  React.useEffect(() => {
    if (!app) {
      setName('');
      setDescription('');
    } else {
      setName(app.name);
      setDescription(app.description);
    }
  }, [app]);

  const submit = (event) => {
    event.preventDefault();
    runUpdateStatus({
      params: [
        {
          name,
          description
        },
        { appId: app.id }
      ],
      onFinally: () => {
        onClose();
      }
    });
  };

  return (
    <Dialog onClose={onClose} open={!!app}>
      <ModalHeader title={'Experience Details'} onClose={onClose} />
      <form>
        <DialogContent>
          <div className={classes.inputFieldsContainer}>
            <TextField
              label="Name"
              id="appName"
              placeholder="Enter Name"
              value={name}
              fullWidth
              onChange={({ target: { value } }) => setName(value)}
              variant="outlined"
            />
            <TextField
              label="Description"
              id="appDescription"
              placeholder="Enter Description"
              value={description}
              fullWidth
              onChange={({ target: { value } }) => setDescription(value)}
              variant="outlined"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="default"
            variant="outlined"
            disabled={updateStatus === AsyncStatus.IN_PROGRESS}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={submit}
            color="primary"
            variant="outlined"
            disabled={!name || updateStatus === AsyncStatus.IN_PROGRESS}
          >
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

RenameExperienceModal.propTypes = {
  // withRouter
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  updateApp: PropTypes.func.isRequired,

  classes: PropTypes.object.isRequired,
  app: PropTypes.object,
  onClose: PropTypes.func.isRequired
};
RenameExperienceModal.defaultProps = {};

const mapDispatchToProps = dispatch => ({
  updateApp: ({ name, description }, { appId }) => dispatch(
      updateapp(
        {
          name,
          description
        },
        { appId }
      )
    ).then(() => dispatch(getmyapps()))
});

export default withStyles(theme => ({
  inputFieldsContainer: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }
}))(withRouter(connect(null, mapDispatchToProps)(RenameExperienceModal)));
