import {
 Box, CircularProgress, IconButton, Paper, Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import { getActions } from '@enklu/server-api';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import Qr from '../Qr';

const { holoauthorize } = getActions('trellis');

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    padding: theme.spacing(2)
  },
  closeButton: {
    alignSelf: 'flex-end'
  }
}));

const HoloLogin = ({
 authorize, showClose, showCaption, user: { holocode: code }, exit
}) => {
  const classes = useStyles();

  React.useEffect(() => {
    authorize();
  }, []);

  return (
    <Paper className={classes.container}>
      {showClose && (
        <IconButton onClick={exit} className={classes.closeButton}>
          <Close />
        </IconButton>
      )}
      {code ? (
        <React.Fragment>
          <Box mb={2}>
            <Qr payload={btoa(`${code}:bar`)} />
          </Box>
          {showCaption && (
            <Typography align="center">
              Point the holographic device camera directly at the QR code. For best results, stay still.
            </Typography>
          )}
        </React.Fragment>
      ) : (
        <CircularProgress size={20} />
      )}
    </Paper>
  );
};

HoloLogin.defaultProps = {
  showClose: true,
  showCaption: true,
  style: {}
};

HoloLogin.propTypes = {
  showClose: PropTypes.bool,
  showCaption: PropTypes.bool,
  style: PropTypes.object,
  user: PropTypes.object,
  exit: PropTypes.func,
  authorize: PropTypes.func
};

export default connect(
  state => ({
    user: state.user,
    app: state.app
  }),
  dispatch => ({
    authorize: () => {
      dispatch(holoauthorize());
    }
  })
)(HoloLogin);
