import { removeScriptFromElements } from '../../../util/appHelpers';
import { getActions } from '@enklu/server-api';

import { getScenes } from '../../selectors/scenesSelectors';
import { getAppInfo } from '../../selectors/appSelectors';
import txns from '../TxnManager';
import { updateAction } from '../elementActions';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';

const { updatescript, deletescript, getappscripts } = getActions('trellis');

export const updateScript = ({
  id, name, description, source, tags
}) => (dispatch) => {
  dispatch(updatescript(
    {
      name, description, source, tags
    },
    { scriptId: id }
  ));
};

export const deleteScript = ({ id: scriptId }) => (dispatch, getState) => {
  const state = getState();
  const scenes = getScenes(state);
  const appId = getAppInfo(state).id;

  removeScriptFromElements({
    appId, scriptId, scenes, dispatch
  });

  dispatch(deletescript({ scriptId }));
};

/**
 * Async action (thunk) for updating an element's schema.
 *
 * @param sceneId - The ID of the root scene.
 * @param elementId - The ID of the element to update.
 * @param update - The update to apply to the schema of the element in question. See @createSchemaUpdate.
 */
export const updateElement = ({ sceneId, elementId, update }) => (dispatch, getState) => {
  const { type, name, value } = update;

  return txns.request(
    sceneId,
    updateAction(elementId, type, name, value)
  );
};

export const getCurrentScriptValue = (element) => {
  const currentStringValue = element.schema && element.schema.strings && element.schema.strings.scripts ? element.schema.strings.scripts : '[]';
  let parsedScripts = [];
  try {
    parsedScripts = JSON.parse(currentStringValue);
  } catch (err) {
    log.error('Unable to parse scripts: ', err, element);
  }

  return parsedScripts;
};

export const addScript = async ({ appId, sceneId, element, scriptId, oldScriptId}) => {
  const currentScripts = getCurrentScriptValue(element);

  // Never duplicate scripts.
  if (currentScripts.find(script => script.id === scriptId)) {
    return;
  }

  // If we are replacing a script, stick it in the same slot.
  let index = currentScripts.length;
  if (oldScriptId) {
    index = currentScripts.findIndex(script => script.id === oldScriptId);
  }

  currentScripts[index] = { id: scriptId };

  await txns.request(
    sceneId,
    updateAction(element.id, ActionSchemaTypes.STRING, 'scripts', JSON.stringify(currentScripts))
  );

  dispatch(getappscripts({ appId }));
}