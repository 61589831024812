/**
 * This is a component for displaying a list of information that is loaded asynchronously. It will also list a number
 * of potential actions on each item.
 */

import { AsyncStatus } from '@enklu/server-api';
import {
 CircularProgress, IconButton, List, ListItem, ListItemText, Paper, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import ListableShape from '../../interfaces/ListableShape';
import ProgressShape, { defaults as progressShapeDefaults } from '../../interfaces/ProgressShape';

const ELEVATION = 6;

const useStyles = makeStyles(theme => ({
  paper: props => ({
    background: props.color === 'paper' ? theme.palette.background.paper : theme.palette.background.default
  }),
  message: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1)
  }
}));

const LoadedItemList = ({
  items,
  selectedItemId,
  onSelect,
  progress: { error, status },
  editable,
  actions,
  customClass,
  color,
  renderCustomItemContent,
  listProps
  // itemStyle,
  // useMouseover
}) => {
  const classes = useStyles({ color });

  switch (status) {
    case AsyncStatus.IN_PROGRESS: {
      return (
        <Paper className={clsx(classes.paper, classes.message, customClass)} elevation={ELEVATION}>
          <CircularProgress size={20} />
        </Paper>
      );
    }

    case AsyncStatus.FAILURE: {
      return (
        <Paper className={clsx(classes.paper, classes.message, customClass)} elevation={ELEVATION}>
          <Typography align="center"> {error || "Couldn't load items for some reason."}</Typography>
        </Paper>
      );
    }

    case AsyncStatus.UNSENT: {
      return null;
    }

    default: {
      return items.length === 0 ? (
        <Paper className={clsx(classes.paper, classes.message, customClass)} elevation={ELEVATION}>
          <Typography align="center"> {error || 'Sorry, nothing found.'}</Typography>
        </Paper>
      ) : (
        <Paper className={clsx('scrollable-y', classes.paper, customClass)} elevation={ELEVATION}>
          <List {...listProps}>
            {items.map((item, index) => {
              const title = item.displayName || (item.email ? `\u00A0(${item.email})` : '');
              return (
                <ListItem
                  key={index}
                  button={!!onSelect}
                  selected={selectedItemId ? item.id === selectedItemId : false}
                  onClick={() => editable && onSelect && onSelect(item)}
                >
                  {renderCustomItemContent ? (
                    renderCustomItemContent(item)
                  ) : (
                    <React.Fragment>
                      <ListItemText primary={title} />
                      {actions.map(({ icon, onAction, showAction = () => true }, aIndex) => {
                        if (editable && showAction(item)) {
                          return (
                            <IconButton
                              key={aIndex}
                              size="small"
                              onClick={(event) => {
                                event.stopPropagation();
                                onAction(item);
                              }}
                            >
                              {React.createElement(icon)}
                            </IconButton>
                          );
                        }
                        return null;
                      })}
                    </React.Fragment>
                  )}
                </ListItem>
              );
            })}
          </List>
        </Paper>
      );
    }
  }
};

LoadedItemList.propTypes = {
  items: PropTypes.arrayOf(ListableShape).isRequired,
  onSelect: PropTypes.func,
  selectedItemId: PropTypes.string,
  progress: ProgressShape,
  editable: PropTypes.bool,
  customClass: PropTypes.string,
  actions: PropTypes.array,
  color: PropTypes.string.isRequired,
  renderCustomItemContent: PropTypes.func,
  listProps: PropTypes.object
  // useMouseover: PropTypes.bool
  // itemStyle: PropTypes.object,
};

LoadedItemList.defaultProps = {
  progress: progressShapeDefaults,
  actions: [],
  editable: true,
  color: 'paper'
  // useMouseover: true
};

export default LoadedItemList;
