import { HTTP_ACTION } from '../../constants/AccountActionTypes';


function logResponse(response) {
  console.log(response.headers.get('Content-Type'));
  console.log(response.headers.get('Date'));

  console.log(response.status);
  console.log(response.statusText);
  console.log(response.type);
  console.log(response.url)
}

const httpMiddleware = store => next => action => {
  if (action.type === HTTP_ACTION) {
    const actionInfo = action.actionInfo;
    const fetchOptions = {
    method: actionInfo.verb,
    headers: actionInfo.headers,
    body: actionInfo.payload || null
    };
    next({
    type: actionInfo.type + "_REQUEST"
    });

    return fetch(actionInfo.endpoint, fetchOptions)
    .then(response => {
      if (fetchOptions.dump) {
        logResponse(response);
      }
      return response.json(); })
    .then(data => {
      if(data.success) {
        next({type: actionInfo.type + "_SUCCESS", payload: data});
      } else {
        next({type: actionInfo.type + "_FAILED", payload: data});
      }
      })
    .catch(error => next({
      type: actionInfo.type + "_FAILED",
      payload: error
    }));
  } else {
    return next(action);
  }
}

export default httpMiddleware;
