import uuid from 'uuid';
import { elementById, parentByChildId } from '../../util/elementHelpers';
import ActionTypes from '../../constants/ActionTypes';
import ActionSchemaTypes from '../../constants/ActionSchemaTypes';

const getValue = (dict = {}, key, defaultValue) => {
  if (key in dict) {
    return dict[key];
  }

  return defaultValue;
};

const getSchemaValue = ({ schema = {} } = {}, type, key, defaultValue) => {
  switch (type) {
    case ActionSchemaTypes.STRING: {
      return getValue(schema.strings, key, defaultValue || '');
    }
    case ActionSchemaTypes.INT: {
      return getValue(schema.ints, key, defaultValue || 0);
    }
    case ActionSchemaTypes.FLOAT: {
      return getValue(schema.floats, key, defaultValue || 0);
    }
    case ActionSchemaTypes.BOOL: {
      return getValue(schema.bools, key, !!defaultValue);
    }
    case ActionSchemaTypes.VEC3: {
      return getValue(schema.vectors, key, defaultValue || { x: 0, y: 0, z: 0 });
    }
    case ActionSchemaTypes.COL4: {
      return getValue(schema.colors, key, defaultValue || { r: 1, g: 1, b: 1, a: 1 });
    }
    case ActionSchemaTypes.PHRASES: {
      return getValue(schema.phrases, key, defaultValue || {
        prompt: "p",
        text: "t",
        voice: "Ray",
        language: "English",
        phrases:{
          default: {
            prompt: "",
            localizations: {
              English: {
                text: "",
                url: "",
                audioID: "",
                id: uuid()
              }
            }
          }
        },
        selectedPhrase: "default"
      });
    }
    default:
      throw new Error(`Invalid schema type: ${type}`);
  }
};

const undoUpdate = (scene, action) => {
  const { elementId, schemaType, key } = action;
  const newAction = {
    ...action
  };

  // todo: get current value
  const root = scene.elements;
  const { defaultValue } = action;
  const element = elementById(root, elementId) || {};
  newAction.value = getSchemaValue(element, schemaType, key, defaultValue);

  return [newAction];
};

const undoCreate = (_, action) => [
  {
    type: ActionTypes.DELETE,
    elementId: action.data.id
  }
];

const undoDelete = (scene, action) => {
  const { elementId } = action;
  const element = elementById(scene.elements, elementId);
  const parent = parentByChildId(scene.elements, elementId);
  const data = JSON.parse(JSON.stringify(element));

  return [
    {
      type: ActionTypes.CREATE,
      parentId: parent.id,
      data
    }
  ];
};

const createUndoAction = {
  [ActionTypes.CREATE]: undoCreate,
  [ActionTypes.DELETE]: undoDelete,
  [ActionTypes.UPDATE]: undoUpdate
};

export const createUndoActions = (scene, actions) => {
  // generate undo actions
  let undoActions = [];
  for (const sceneAction of actions) {
    undoActions = undoActions.concat(createUndoAction[sceneAction.type](scene, sceneAction));
  }

  return undoActions;
};
