export const LOG_MESSAGE = 'log.message';
export const logMessage = message => ({
  type: LOG_MESSAGE,
  payload: message
});

export const LOG_SET_LEVEL = 'log.setLevel';
export const logSetLevel = level => ({
  type: LOG_SET_LEVEL,
  payload: level
});

export const LOG_SET_FILTER = 'log.setFilter';
export const logSetFilter = filter => ({
  type: LOG_SET_FILTER,
  payload: filter
});

export const LOG_SET_SHOW_SYSTEM = 'log.setShowSystem';
export const logSetShowSystem = showSystem => ({
  type: LOG_SET_SHOW_SYSTEM,
  payload: showSystem
});

export const LOG_CLEAR = 'log.clear';
export const logClear = () => ({
  type: LOG_CLEAR
});
