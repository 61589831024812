import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Dragger extends Component {
  style = {
    main: {
      cursor: 'ew-resize',
      flex: '1 1 auto',
      marginRight: '3px',
      textDecoration: 'underline'
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      isGrabbed: false,
      downPos: {
        x: 0,
        y: 0
      }
    };
  }

  onMouseMove = ({ clientX, clientY }, commit = false) => {
    const { downPos } = this.state;
    const { scale, onChange, range } = this.props;
    const deltaX = clientX - downPos.x;
    const deltaY = downPos.y - clientY;
    const value = (deltaX + deltaY) * scale;

    this.setState({ value });
    onChange(value, commit);

    if (Math.abs(deltaX) > range.x || Math.abs(deltaY) > range.y) {
      this.onMouseUp();
    }
  };

  onMouseLeave = ({ target }) => {
    if (target !== $('body')[0]) {
      return;
    }

    this.onMouseUp();
  };

  onMouseUp = () => {
    this.setState({ isGrabbed: false });
    this.removeEventListeners();
    this.props.onChange(this.state.value, true);
  };

  componentWillUnmount() {
    this.removeEventListeners();
  }

  // eslint-disable-next-line class-methods-use-this
  removeEventListeners() {
    const $document = $(document);
    $document.off('mousemove.dragger');
    $document.off('mouseup.dragger');
    $document.off('mouseleave.dragger');
    $document.off('mouseout.dragger');
  }

  render() {
    const {
      children,
      disabled
    } = this.props;
    const { isGrabbed } = this.state;
    const { main: mainStyle } = this.style;

    return (
      <span
        style={mainStyle}
        onMouseDown={({ clientX, clientY }) => {
          if (isGrabbed) {
            return;
          }

          if (disabled) {
            return;
          }

          const $document = $(document);

          $document.on('mousemove.dragger', event => this.onMouseMove(event));
          $document.on('mouseup.dragger', event => this.onMouseUp(event));
          $document.on('mouseleave.dragger mouseout.dragger mousedown.dragger', event => this.onMouseLeave(event));
          $document.on('dragleave.dragger', () => {});

          this.setState({
            value: 0,
            isGrabbed: true,
            downPos: {
              x: clientX,
              y: clientY
            }
          });
        }}
      >
        {children}
      </span>
    );
  }
}

Dragger.propTypes = {
  onChange: PropTypes.func.isRequired,
  range: PropTypes.object,
  scale: PropTypes.number,
  disabled: PropTypes.bool,
  children: PropTypes.any
};

Dragger.defaultProps = {
  scale: 0.1,
  range: {
    x: 70,
    y: 200
  },
  disabled: false
};

export default Dragger;
