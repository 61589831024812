import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
  Route,
  Switch
} from 'react-router-dom';
import { connect } from 'react-redux';
import { muiPalette, muiTypography, controlBarItem } from '../styles/muiTheme';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import {
  Checkbox,
  FormGroup,
  Tooltip,
  FormControlLabel, 
  Button,
  Box,
  Typography
} from '@material-ui/core';
import MessageTypes from '../constants/MessageTypes'
import { setPlayerEdit } from '../store/actions/playerActions';
import { getGeneralSettings } from '../store/selectors/globalSettingsSelectors';

import { useState } from 'react';
import { set } from 'lodash';

const globalSpace = 'Global';

/**
 * Renders the control bar above the WebGL window, which includes Transform Space toggle, transform gizmos, fullscreen, etc. 
 */
class ControlBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cachedTransformSpace: globalSpace,
      checked: true,
      text: 'World'
    };
  }

  // Updates checkbox state to confirm it matches transformSpace
  static getDerivedStateFromProps(nextProps, prevState) {
    // TODO: Clean up checkbox logic (note that the fix might actually be in the player)
    if (nextProps.transformSpace === prevState.cachedTransformSpace) {
      return prevState;
    }
    else {
      return {
        cachedTransformSpace: nextProps.transformSpace,
        checked: nextProps.transformSpace.transformSpace === globalSpace
      }
    }
  }

  changeText = (text) => {
    this.setState({ text }); 
  } 

  render() {
    const {
      style,
      isEditMode,
      setPlayerEdit
    } = this.props;

    // text used to change text of world/local switch button
    // TODO: Will need to figure out state management of the world/local state in webGL if we want to do dynamic switching. Currently it doesn't seem like this is the case
    // const { text } = this.state;

    return (
      <div className="control-bar" style={{ ...style, height: '37px', zIndex: 1}}>
        <div style={{
          backgroundColor: muiPalette.background.default,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          paddingLeft: '5px',
          paddingRight: '5px'
        }}>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Tooltip title={'Change Transform Space'} placement="top" arrow>
              
              <Typography 
                  onClick={() => {
                    this.sendControlBarMsg(MessageTypes.BRIDGE_HELPER_TRANSFORM_SPACE);
                  }}
                  style={{ cursor: 'pointer', margin: '0 16px 0 16px', textAlign: 'center', whiteSpace: 'nowrap' }}
                >
                  {/* {text} */}
                  World | Local
              </Typography>

              {/* Unused variants for the switching button */}
              {/* <Box style={{ width: '70px' }}> */}
                {/* <Typography 
                  onClick={() => {
                    this.sendControlBarMsg(MessageTypes.BRIDGE_HELPER_TRANSFORM_SPACE);

                    // this.sendControlBarMsg(MessageTypes.BRIDGE_HELPER_TRANSFORM_SPACE_CHECK);
                    
                    // console.log(this.props.transformSpace);

                    // this.changeText(text === 'World' ? 'Local' : 'World');
                  }}
                  style={{ cursor: 'pointer', margin: '0 16px 0 16px', textAlign: 'center' }}
                > */}
                  {/* {text} */}
                  {/* World | Local */}
                {/* </Typography>
              </Box> */}

              {/* <Button
                onClick={() => {
                  this.sendControlBarMsg(MessageTypes.BRIDGE_HELPER_TRANSFORM_SPACE);
                  this.changeText(text === 'World' ? 'Local' : 'World');
                }}
                style={{ fontWeight: 'bold' }}
              > 
                {text}
              </Button> */}

              {/* This was the original labelled checkbox used */}
              {/* <FormControlLabel
                control={<Checkbox checked={this.state.checked}/>}
                onClick={() => this.sendControlBarMsg(MessageTypes.BRIDGE_HELPER_TRANSFORM_SPACE)}
                style={{ margin: '0 16px 0 0' }}
                label={
                  <h3 //TODO: Use Typography here instead
                    onClick={() => this.sendControlBarMsg(MessageTypes.BRIDGE_HELPER_TRANSFORM_SPACE)}
                    // TODO: Find better solution for clicking on label to trigger bridge messages
                    style={{ color: muiPalette.grayscale.white, fontWeight: muiTypography.h1.fontWeight, margin: '0px' }}>
                    World/Local
                  </h3>
                }
                labelPlacement="end"
              /> */}
            </Tooltip>

            <Tooltip title={'Edit Position (Q)'} placement="top" arrow>
              <img
                onClick={() => this.sendControlBarMsg(MessageTypes.BRIDGE_HELPER_GIZMO_TRANSLATION)}
                style={controlBarItem}
                src={'../../assets/img/position.svg'}
              />
            </Tooltip>
            <Tooltip title={'Edit Rotation (W)'} placement="top" arrow>
              <img
                onClick={() => this.sendControlBarMsg(MessageTypes.BRIDGE_HELPER_GIZMO_ROTATION)}
                style={controlBarItem}
                src={'../../assets/img/rotation.svg'}
              />
            </Tooltip>
            <Tooltip title={'Edit Scale (E)'} placement="top" arrow>
              <img
                onClick={() => this.sendControlBarMsg(MessageTypes.BRIDGE_HELPER_GIZMO_SCALE)}
                style={controlBarItem}
                src={'../../assets/img/scale.svg'}
              />
            </Tooltip>
          </div>

          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Tooltip title={'Play Experience'} placement="top" arrow>
            <PlayArrowIcon
              onClick={() => setPlayerEdit(!isEditMode)}
              style={controlBarItem}
              color={isEditMode ? 'inherit' : 'primary'}
            />
          </Tooltip>
          </div>

          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Tooltip title={'Go Fullscreen'} placement="top" arrow>
              <img
                onClick={() => this.sendControlBarMsg(MessageTypes.BRIDGE_HELPER_FULLSCREEN)}
                style={controlBarItem}
                src={'../../assets/img/expand.svg'}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }

  sendControlBarMsg = (eventId) => {
    console.log("sending control bar message");

    window.bridge.send(eventId, {});
    if (eventId === MessageTypes.BRIDGE_HELPER_FULLSCREEN) {
      // Semi-hackish. Dig into Unity's iframe and request fullscreen since it can't without focus.
      const iframe = document.getElementsByTagName('iframe')[0];
      iframe.contentWindow.document.getElementById("#canvas").requestFullscreen();
    }
  }
}

ControlBar.propTypes = {
  style: PropTypes.object.isRequired,

  // mapStateToProps
  transformSpace: PropTypes.string,
  isEditMode: PropTypes.bool,
  appId: PropTypes.string,
  userId: PropTypes.string,
  auth: PropTypes.string,
  generalSettings: PropTypes.array,

  // mapDispatchToProps
  setPlayerEdit: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  setPlayerEdit: (edit) => {
    dispatch(setPlayerEdit(edit));
  }
});

const mapStateToProps = (state) => ({
  transformSpace: state.transformSpace,
  isEditMode: state.player.edit,
  appId: state.app.info.id,
  userId: state.user.profile.id,
  auth: state.user.credentials.token,
  generalSettings: getGeneralSettings(state)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ControlBar));
