import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import autoCompleter from './autocompleter';
import { getSizing } from '../../store/selectors/uiSelectors';

/**
 * Provides a script editor.
 */
class ScriptTextEditor extends React.Component {
  style = {
    scriptEditor: {
      flex: 1
    }
  };

  /**
   * Editor object.
   */
  editor = null;

  /**
   * Constructor.
   */
  constructor(props) {
    super(props);

    this.state = {
      wasResizing: false,
      screenHeight: 0
    };
  }

  /**
   * Renders.
   */
  render() {
    const { scriptEditor } = this.style;

    return <div id="scriptEditor" style={scriptEditor}></div>;
  }

  /**
   * Invoked after update
   */
  componentDidUpdate() {
    this.checkResize();
  }

  /**
   * Post first render.
   */
  componentDidMount() {
    // setup ace
    this.editor = ace.edit('scriptEditor');
    this.editor.setTheme('ace/theme/dracula');
    this.editor.setAutoScrollEditorIntoView(true);
    const session = this.editor.getSession();

    this.editor.setOptions({
      useSoftTabs: true,
      selectionStyle: 'text',
      highlightSelectedWord: true,
      tabSize: 2,
      showPrintMargin: false,
      wrap: 'free',
      readOnly: this.props.inPublicScripts
    });

    if (this.props.type === 'Vine') {
      this.editor.getSession().setMode('ace/mode/xml');
    } else {
      const language = ace.require('ace/ext/language_tools');
      this.editor.getSession().setMode('ace/mode/javascript');
      this.editor.setOption('enableBasicAutocompletion', true);

      // auto complete
      language.addCompleter(autoCompleter);
    }

    this.editor.setValue(this.props.source);

    // reset the undo state
    const undoManager = session.getUndoManager();
    undoManager.reset();
    session.setUndoManager(undoManager);

    this.editor.gotoLine(0);
    this.props.onMountEditor(this.editor);
  }

  componentWillUnmount() {
    this.editor.destroy();
    this.editor = null;
    this.props.onUnmountEditor();
  }

  checkResize() {
    const { sizing } = this.props;

    const { isResizing, offsets, screen } = sizing;

    const { wasResizing } = this.state;

    const didPanelResize = !isResizing && wasResizing;
    const didScreenResize = screen.height !== this.state.screenHeight;
    if (didPanelResize || didScreenResize) {
      this.editor.resize();
      this.editor.renderer.updateFull();
    }

    this.state.wasResizing = isResizing;
    this.state.screenHeight = screen.height;
  }
}

ScriptTextEditor.propTypes = {
  source: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isResizing: PropTypes.bool,
  inPublicScripts: PropTypes.bool
};

const mapStateToProps = state => ({
  sizing: getSizing(state)
});

export default connect(mapStateToProps)(ScriptTextEditor);
