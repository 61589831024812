import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.grayscale.white,
      color: theme.palette.background.paper,
      '& .MuiButton-label': {
        color: theme.palette.background.paper
      }
    },
    '& .MuiButton-label': {
      color: theme.palette.grayscale.white,
    }
  }
}));

const CancelButton = ({ children, onCancel }) => {
  const classes = useStyles();
  return (
    <Button variant="outlined" size="large" onClick={onCancel} className={classes.container}>
      {children}
    </Button>
  );
};

CancelButton.propTypes = {
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default CancelButton;
