import {
 Box, Button, Dialog, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { muiPalette } from '../../styles/muiTheme';
import BaseModal from '../../components/modals/MuiBaseModal';
import MenuButton from '../../components/material-ui/MenuButton';

const useStyles = makeStyles(theme => ({
  fileBtn: {
    padding: '5px 0 0'
  },
  scriptName: {
    color: muiPalette.grayscale.lighterGray,
    maxWidth: 256,
    display: 'inline-block'
  }
}));

/**
 * Toolbar for script editor.
 */
const ScriptToolbar = ({
 onAutoSaveChanged, onSave, onClose, onDelete, autoSave, onTemplate, enabled, name, inPublicScripts
}) => {
  const [pendingAction, setPendingAction] = React.useState('');
  const { fileBtn, scriptName } = useStyles();

  const selectFileMenu = (key) => {
    switch (key) {
      case 'save': {
        onSave();
        break;
      }
      case 'save-auto': {
        if (!autoSave) {
          onSave();
        }
        onAutoSaveChanged();
        break;
      }
      case 'close-save': {
        onSave();
        onClose();
        break;
      }
      case 'close': {
        onClose();
        break;
      }
      case 'delete': {
        onDelete();
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <Box display="flex">
      <Dialog open={!!pendingAction} onClose={() => setPendingAction(null)}>
        <BaseModal
          title={'All Versions'}
          actions={
            <React.Fragment>
              <Button variant="outlined" onClick={() => setPendingAction(null)}>
                Close
              </Button>

              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  // load
                  const req = new XMLHttpRequest();
                  req.open('GET', `data/templates/${pendingAction}.template`);
                  req.responseType = 'text';
                  req.onload = () => {
                    onTemplate(req.response);
                    setPendingAction(null);
                  };
                  req.send();
                }}
              >
                Insert Template
              </Button>
            </React.Fragment>
          }
        >
          <Typography>This will replace everything in this script. Are you sure?</Typography>
        </BaseModal>
      </Dialog>

      {inPublicScripts ? 
      <Box p={1}>
        <Typography
          variant="subtitle1">
           Read-Only 
        </Typography>
      </Box> 
      : 
      <Box class={fileBtn}>
        <MenuButton
          itemsDisabled={!enabled}
          component="span"
          onItemClick={({ eventKey }) => {
            selectFileMenu(eventKey);
          }}
          items={[
            { title: 'Save', eventKey: 'save' },
            { title: autoSave ? 'Turn Autosave Off' : 'Turn Autosave On', includeDivider: true, eventKey: 'save-auto' },
            { title: 'Save and Close', eventKey: 'close-save' },
            { title: 'Close without Saving', includeDivider: true, eventKey: 'close' },
            { title: 'Delete', eventKey: 'delete' }
          ]}
        >
          File
        </MenuButton>
      </Box>}
      <Box flexGrow={1}/>
      {name &&
        <Box p={1}>
          <Typography
            class={scriptName}
            noWrap={true}
            component="span"
            variant="subtitle1">
            {name}
          </Typography>
        </Box>
      }
    </Box>
  );
};

ScriptToolbar.propTypes = {
  enabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  autoSave: PropTypes.bool.isRequired,
  onAutoSaveChanged: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onTemplate: PropTypes.func.isRequired,
  inPublicScripts: PropTypes.bool
};

export default ScriptToolbar;
