import * as Sentry from '@sentry/browser/dist/index';
import jwt_decode from 'jwt-decode';
import { log } from '../../util/log';
import { AsyncStatus, TrellisActions } from '@enklu/server-api';

import { RESET_REQUESTS } from '../actions/initializeActions';
import { SET_AUTH } from '../actions/userActions';

// Note: SEARCHPUBLISHEDAPPS is currently used to fetch templates. Down the road,
// this could point to a endpoint that also includes those for an organization, etc
const { EMAILSIGNIN, GETMYAPPS, UPDATEAPP, HOLOAUTHORIZE, SEARCHPUBLISHEDAPPS } = TrellisActions;

export const initialState = {
  isAuthenticated: false,
  apps: [],
  templates: [],
  canManageStandardAssets: false,
  credentials: {
    //
  },
  profile: {
    displayName: '',
    email: '',
    id: ''
  },
  account: {
    //
  },
  requestInProgress: false,
  signUpComplete: false
};

const hasRole = (token, role) => {
  const { role: userRoles = '' } = jwt_decode(token);

  return userRoles.split(',')
    .includes(role);
};

const setupQueues = (token) => {
  let { eventQueue } = jwt_decode(token);

  eventQueue = eventQueue.split('_')[0].toLowerCase();

  window.env.bundlesUrl = window.env.bundlesUrl.replace('{{QueueName}}', eventQueue);
  window.env.thumbsUrl = window.env.thumbsUrl.replace('{{QueueName}}', eventQueue);
  window.env.scriptsUrl = window.env.scriptsUrl.replace('{{QueueName}}', eventQueue);
  window.env.anchorsUrl = window.env.anchorsUrl.replace('{{QueueName}}', eventQueue);
};

const getError = response => (response.error
  ? response.error.message
    ? response.error.message
    : response.error
  : response.message
    ? response.message
    : response);

function handleSignin(state, action) {
  switch (action.status) {
    case AsyncStatus.IN_PROGRESS: {
      return state;
    }
    case AsyncStatus.SUCCESS: {
      const { token, user, account } = action.body;
      io.sails.token = token;
      io.sails.user = user;

      localStorage.setItem('token', token);
      console.log('User:', user);
      console.log('Token:', token);

      const {
        email = '',
        id = '',
        displayName = ''
      } = user;

      const context = {
        email: email.trim(),
        id: id.trim(),
        displayName: displayName.trim()
      };

      Sentry.configureScope(scope => scope.setUser(context));
      if ('gtag' in window) {
        gtag('set', { user_id: context.id });
      }

      setupQueues(token);

      return {
        ...state,
        canManageStandardAssets: hasRole(token, 'standardassets'),
        canManageStandardScripts: hasRole(token, 'standardscripts'),
        isAuthenticated: true,
        credentials:
          {
            token
          },
        profile: user,
        account,
        requestError: null
      };
    }
    case AsyncStatus.FAILURE: {
      return {
        ...state,
        isAuthenticated: false,
        requestError: getError(action.response)
      };
    }
    default: {
      log.error(`Reached default case statement for ${action}`);
      return state;
    }
  }
}

function handleHoloAuthorize(state, action) {
  const { status } = action;

  if (status === AsyncStatus.SUCCESS) {
    return {
      ...state,
      holocode: action.body
    };
  }
  if (status === AsyncStatus.FAILURE) {
    return {
      ...state,
      holocode: 'Could not get holo auth code.',
      requestError: getError(action.response)
    };
  }

  return state;
}

// eslint-disable-next-line no-unused-vars
function handleResetRequests(state, action) {
  return {
    ...state,
    requestError: null
  };
}

const handleGetMyApps = (state, action) => {
  const { status } = action;

  if (status === AsyncStatus.SUCCESS) {
    return {
      ...state,
      apps: action.body
    };
  }

  return state;
};

const handleGetMyTemplates = (state, action) => {
  const { status } = action;

  if (status === AsyncStatus.SUCCESS) {
    return {
      ...state,
      templates: action.body
    };
  }

  return state;
};

const handleUpdateApp = (state, action) => {
  const { status } = action;

  if (status === AsyncStatus.SUCCESS) {
    const app = action.response.body;
    const newApps = [...state.apps];
    for (let i = 0, len = newApps.length; i < len; i++) {
      if (newApps[i].id === app.id) {
        newApps[i] = app;
        break;
      }
    }

    return {
      ...state,
      apps: newApps
    };
  }

  return state;
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_REQUESTS: {
      return handleResetRequests(state, action);
    }
    case EMAILSIGNIN: {
      return handleSignin(state, action);
    }
    case HOLOAUTHORIZE: {
      return handleHoloAuthorize(state, action);
    }
    case GETMYAPPS: {
      return handleGetMyApps(state, action);
    }
    case SEARCHPUBLISHEDAPPS: {
      return handleGetMyTemplates(state, action);
    }
    case UPDATEAPP: {
      return handleUpdateApp(state, action);
    }
    case SET_AUTH: {
      io.sails.token = action.auth;
      return {
        ...state,
        isAuthenticated: true,
        credentials: {
          token: io.sails.token
        },
        profile: {
          id: action.userId
        }
      }
    }
    default: {
      return state;
    }
  }
}

export default userReducer;
