const api = {
	"log": {
		"debug": {
			meta: "function"
		},
		"info": {
			meta: "function"
		},
		"warn": {
			meta: "function"
		},
		"error": {
			meta: "function"
		}
	},
	"transform": {
		"position": {
			meta: "vec3"
		},
		"rotation": {
			meta: "quat"
		},
		"scale": {
			meta: "vec3"
		}
	},
	"time": {
		"now": {
			meta: "float"
		},
		"dt": {
			meta: "float"
		}
	},
	"quat": {
		"meta": "function"
	},
	"q": {
		"create": {
			"meta": "function"
		},
		"euler": {
			meta: "function"
		},
		"eul": {
			meta: "function"
		},
		"identity": {
			meta: "quat"
		}
	},
	"vec3": {
		meta: "function"
	},
	"v": {
		"one": {
			"meta": "vec3"
		},
		"zero": {
			"meta": "vec3"
		},
		"up": {
			"meta": "vec3"
		},
		"forward": {
			"meta": "vec3"
		},
		"right": {
			"meta": "vec3"
		},
		"create": {
			meta: "function"
		},
		"scale": {
			meta: "function"
		},
		"add": {
			meta: "function"
		},
		"sub": {
			meta: "function"
		},
		"dot": {
			meta: "function"
		},
		"cross": {
			meta: "function"
		},
		"len": {
			meta: "function"
		},
		"lenSqr": {
			meta: "function"
		},
		"normalize": {
			meta: "function"
		},
		"distance": {
			meta: "function"
		},
		"distanceSqr": {
			meta: "function"
		},
		"distanceXZ": {
			meta: "function"
		},
		"distanceXZSqr": {
			meta: "function"
		}
	},
	// elements
	"id": {
		meta: "string"
	},
	"type": {
		meta: "string"
	},
	"parent": {
		meta: "element"
	},
	"children": {
		meta: "array"
	},
	"addChild": {
		meta: "function"
	},
	"removeChild": {
		meta: "function"
	},
	"isChildOf": {
		meta: "function"
	},
	"find": {
		meta: "function"
	},
	"findOne": {
		meta: "function"
	},
	"destroy": {
		meta: "function"
	},
	"send": {
		meta: "function"
	},
	"on": {
		meta: "function"
	},
	"off": {
		meta: "function"
	},
	"positionRelativeTo": {
		meta: "function"
	},

	// Schema
	"schema": {
		"getNumber": {
			meta: "function"
		},
		"getOwnNumber": {
			meta: "function"
		},
		"setNumber": {
			meta: "function"
		},
		"getString": {
			meta: "function"
		},
		"getOwnString": {
			meta: "function"
		},
		"setString": {
			meta: "function"
		},
		"getBool": {
			meta: "function"
		},
		"getOwnBool": {
			meta: "function"
		},
		"setBool": {
			meta: "function"
		},
		"watchString": {
			meta: "function"
		},
		"watchStringOnce": {
			meta: "function"
		},
		"unwatchString": {
			meta: "function"
		},
		"watchBool": {
			meta: "function"
		},
		"watchBoolOnce": {
			meta: "function"
		},
		"unwatchBool": {
			meta: "function"
		},
		"watchNumber": {
			meta: "function"
		},
		"watchNumberOnce": {
			meta: "function"
		},
		"unwatchNumber": {
			meta: "function"
		}
	},

	// App
	"app": {
		"scenes": {
			"all": {
				meta: "array"
			},
			"root": {
				meta: "element"
			}
		},
		"elements": {
			"create": {
				meta: "function"
			},
			"createFromVine": {
				meta: "function"
			},
			"destroy": {
				meta: "function"
			},
			"byId": {
				meta: "function"
			}
		},
		"player": {
			meta: "player"
		}
	},

	// System
	"system": {
		"reorient": {
			meta: "function"
		}
	},

	// Proximity
	"proximity": {

	},

	// Timers
	"timers": {
		"setTimeout": {
			meta: "function"
		}
	},

	// Tweening
	"tw": {
		"number": {
			meta: "function"
		},
		"vec3": {
			meta: "function"
		},
		"col4": {
			meta: "function"
		}
	},
	"to": {
		meta: "function"
	},
	"from": {
		meta: "function"
	},
	"easing": {
		meta: "function"
	},
	"duration": {
		meta: "function"
	},
	"delay": {
		meta: "function"
	},
	"onComplete": {
		meta: "function"
	},
	"onStart": {
		meta: "function"
	},
	"start": {
		meta: "function"
	},
	"stop": {
		meta: "function"
	},
	"pause": {
		meta: "function"
	},
	"resume": {
		meta: "function"
	}
};

export default api;
