import {
 AppBar, Box, InputAdornment, ListItem, ListItemAvatar, ListItemText, Toolbar
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { getUserProfile } from '../../store/selectors/userSelectors';
import BaseAvatar from '../../components/material-ui/BaseAvatar';
import BaseStandardTextField from '../../components/material-ui/BaseStandardTextField';

const useStyles = makeStyles(() => ({
  image: {
    height: '28px',
    width: '28px',
    margin: '0px 16px 0 -5px'
  },

  // filterBar: {
  //   width: '50%'
  // },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex'
  }
}));

const MyExperiencesBar = ({
 user, filterValue, onFilterChange, ...otherProps
}) => {
  const classes = useStyles();

  return (
    <AppBar position="relative" color="inherit" {...otherProps}>
      <Toolbar className={classes.toolbar}>
        <Box flex={1}>
          <img src={'assets/img/Logo.Small.png'} className={classes.image} />
        </Box>
        <Box flex={3}>
          <BaseStandardTextField
            fullWidth
            margin="dense"
            darkbackground="true"
            noshadow="true"
            className={classes.filterBar}
            placeholder={'Search'}
            value={filterValue}
            onChange={(event) => {
              onFilterChange(event);
            }}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Box flex={1} display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
          <div>
            <ListItem>
              <ListItemAvatar>
                <BaseAvatar color="info" size={30} alt={user.displayName} src={user.thumbUrl || 'broken'} />
              </ListItemAvatar>
              <ListItemText primary={user.displayName} secondary={user.email} />
            </ListItem>
          </div>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

MyExperiencesBar.propTypes = {
  filterValue: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: getUserProfile(state)
});

export default connect(mapStateToProps, null)(MyExperiencesBar);
