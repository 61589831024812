import { TrellisActions } from '@enklu/server-api';
import { DELETE_SPACE, UPDATE_SPACE } from '../actions/spaceActions';
import { log } from '../../util/log';

const { FINDSPACES, GETSPACE } = TrellisActions;

const initialState = {
    all: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FINDSPACES: {
            if (action.status !== 'success') {
                return state;
            }

            return {
                ...state,
                all: action.body
            };
        }

        case GETSPACE: {
            if (action.status !== 'success') {
                return state;
            }

            return {
                ...state,
                all: [
                    ...state.all,
                    action.body
                ]
            };
        }

        case UPDATE_SPACE: {
            const spaceIndex = state.all.findIndex(space => space.id === action.space.id);
            if (spaceIndex === -1) {
                log.error('Received space update for unknown space.');
                return state;
            }

            const newList = [ ...state.all ];
            newList[spaceIndex] = action.space;

            return {
                ...state,
                all: newList
            };
        }

        case DELETE_SPACE: {
            const spaceIndex = state.all.findIndex(space => space.id === action.id);
            if (spaceIndex === -1) {
                log.error('Received space update for unknown space.');
                return state;
            }

            const newList = [ ...state.all ];
            newList.splice(spaceIndex, 1);

            return {
                ...state,
                all: newList
            };
        }

        default: {
            return state;
        }
    }
}
