import { Box, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';

import { spaceIdFromRoot } from './spaceUtils';
import BaseButton from '../../material-ui/BaseButton';
import CopyableText from '../../common/CopyableText';

/**
 * Displays brief information about a linked space. Allows access into Space Management.
 */

const SpacesLinkInspector = ({ root, spaces, onShowSpaces }) => {
  const spaceLink = spaceIdFromRoot(root);
  const linkedSpace = spaces.find(space => space.id === spaceLink);

  return (
    <Box mt={2}>
      <Typography variant="h6">Space</Typography>

      <Box mb={1}>
        <ul>
          {linkedSpace && (
            <React.Fragment>
              <li>
                <Typography variant="caption">{`Using Space: ${linkedSpace.name}`}</Typography>
              </li>
              <li>
                <Typography variant="caption">
                  ID: <CopyableText text={linkedSpace.id} />
                </Typography>
              </li>
            </React.Fragment>
          )}

          {!linkedSpace && (
            <Typography variant="caption">
              <li>No space linked.</li>
            </Typography>
          )}
        </ul>
      </Box>

      <BaseButton color="secondary" size="large" variant="contained" onClick={onShowSpaces}>
        Manage Spaces
      </BaseButton>
    </Box>
  );
};

SpacesLinkInspector.propTypes = {
  // Upstream
  // Base Element to get space link from
  root: PropTypes.object.isRequired,
  // Invoked when the "Manage Spaces" button is pressed.
  onShowSpaces: PropTypes.func.isRequired,

  // Store
  spaces: PropTypes.array
};

const mapStateToProps = state => ({
  spaces: state.spaces.all
});

export default withRouter(connect(mapStateToProps)(SpacesLinkInspector));
