import {
 Box, Checkbox, Input, MenuItem, Select, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { filterOptions } from './options';
import BaseStandardTextField from '../../material-ui/BaseStandardTextField';

const FONT_SIZE = 9.5;

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.grayscale.darkestGray,
    fontSize: FONT_SIZE
  },
  numberField: {
    marginLeft: theme.spacing(1),
    '& .MuiInput-root': {
      fontSize: FONT_SIZE
    },
    '& .MuiInputBase-input': {
      color: theme.palette.grayscale.darkestGray
    }
  },
  select: {
    marginLeft: theme.spacing(1),
    fontSize: FONT_SIZE,
    '& .MuiInputBase-input': {
      color: theme.palette.grayscale.darkestGray
    },
    '& .MuiSelect-icon': {
      color: theme.palette.grayscale.darkestGray
    }
  }
}));

const GeneratedInput = ({ option, onChange }) => {
  const {
 name, type, value, possibleValues
} = option;

  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography className={classes.label}>{name}</Typography>
      {type === 'select' && (
        <Select
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          input={<Input className={classes.numberField} disableUnderline />}
          className={classes.select}
        >
          {possibleValues.map((possibleValue, index) => (
            <MenuItem key={index} value={possibleValue}>
              {possibleValue}
            </MenuItem>
          ))}
        </Select>
      )}
      {type === 'checkbox' && <Checkbox id={name} name={name} size="small" checked={value} onChange={onChange} />}
      {type === 'number' && (
        <BaseStandardTextField
          id={name}
          name={name}
          type="number"
          value={value}
          step="any"
          onChange={onChange}
          className={classes.numberField}
          InputProps={{
            disableUnderline: true
          }}
        />
      )}
    </Box>
  );
};

GeneratedInput.propTypes = {
  option: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

const UploadOptions = ({ options, onChange }) => {
  const filteredOptions = filterOptions(options);
  return (
    <Box overflow="hidden" py={1} mt={1} borderTop={1} borderColor="grayscale.darkestGray">
      {filteredOptions.map((option, index) => (
        <GeneratedInput
          option={option}
          key={index}
          onChange={({
 target: {
 name, type, value, checked
}
}) => {
            const foundIndex = options.findIndex(_option => _option.name === name);
            const newOptions = [...options];
            newOptions[foundIndex].value = type === 'checkbox' ? checked : value;
            onChange(newOptions);
          }}
        />
      ))}
    </Box>
  );
};

UploadOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired
};

export default UploadOptions;
