import { Box, TextField, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import uuid from 'uuid';

import { updateAction } from '../../../store/actions/elementActions';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import BaseButton from '../../material-ui/BaseButton';
import InspectableIdentity, { elementToInspectable } from '../common/MuiInspectableIdentity';
import InspectorInterface from '../common/InspectorInterface';
import Qr from '../../MuiQr';
import Transform from '../MuiTransform';
import txns from '../../../store/actions/TxnManager';

const useStyles = makeStyles(theme => ({
  qrContainer: {
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  },
  textField: {
    marginTop: theme.spacing(1)
  }
}));

const QrAnchorBehaviorForm = ({ value, onValueChanged }) => {
  const classes = useStyles();

  const invalid = !value || value.length <= 12;

  return (
    <Box display="flex" flexDirection="column" alignItems="center" pt={1} className={classes.qrContainer}>
      <Qr payload={value} />
      <TextField
        className={classes.textField}
        fullWidth
        label="Qr Code Value"
        value={value}
        onChange={evt => onValueChanged(evt.target.value)}
        variant="outlined"
        error={invalid}
        helperText={invalid ? 'Invalid Code, Must be at Least 13 Characters' : ''}
      />
      <BaseButton variant="contained" color="primary" onClick={() => onValueChanged(uuid())}>
        Generate Unique Value
      </BaseButton>
    </Box>
  );
};

QrAnchorBehaviorForm.propTypes = {
  value: PropTypes.string.isRequired,
  onValueChanged: PropTypes.func.isRequired
};

/**
 * Custom inspector for Qr Anchor elements.
 */
const QrAnchorElementInspector = ({
 onUpdateElement, element, scene, updateElementString
}) => {
  const { id: sceneId } = scene;

  // element data
  const { id: elementId, schema: { strings: { qr_value: qrValue = '' } = {} } = {} } = element;

  return (
    <Box p={1}>
      <InspectableIdentity
        inspectable={element}
        inspectableTranslator={elementToInspectable}
        onUpdate={onUpdateElement}
      />

      <Transform hideTitle element={element} sceneId={sceneId} />

      {/* Behaviour */}
      <Box>
        <Typography variant="h6">Behavior</Typography>
        <QrAnchorBehaviorForm
          value={qrValue}
          onValueChanged={value => updateElementString(sceneId, elementId, 'qr_value', value)}
        />
      </Box>
    </Box>
  );
};

QrAnchorElementInspector.propTypes = {
  ...InspectorInterface,
  app: PropTypes.object,
  updateElementString: PropTypes.func
};

export default withRouter(
  connect(
    ({ app }) => ({ app }),
    () => ({
      /**
       * Updates an element string value.
       */
      updateElementString: (sceneId, elementId, key, value) => {
        txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.STRING, key, value));
      }
    })
  )(QrAnchorElementInspector)
);
