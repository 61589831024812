/**
 * Manages undo/redo.
 */
class UndoManager {

  _dispatch;
  _actions = [];
  _index = -1;

  /**
   * Preps the manager for use. This must be called prior to using.
   * 
   * @param {function} dispatch Redux dispatch function.
   */
  initialize(dispatch) {
    this._dispatch = dispatch;
  }

  /**
   * Registers a new do/undo pair.
   * 
   * @param {object} doAction An action to be dispatched on redo.
   * @param {object} undoAction An action to be dispatched on undo.
   */
  register(doAction, undoAction) {
    // trim
    this._actions.length = this._index + 1;

    // push
    this._actions.push({
      do: doAction,
      undo: undoAction
    });
    this._index += 1;
  }

  reset() {
    this._actions = [];
    this._index = -1;
  }

  undo() {
    if (this._actions.length === 0) {
      return;
    }

    if (this._index < 0) {
      return;
    }

    const action = this._actions[this._index--];
    let undos = action.undo;
    if (!Array.isArray(undos)) {
      undos = [undos];
    }

    for (const undoAction of undos) {
      this._dispatch(undoAction);
    }
  }

  redo() {
    if (this._actions.length === 0) {
      return;
    }

    if (this._index >= this._actions.length - 1) {
      return;
    }

    const action = this._actions[++this._index];
    let redos = action.do;
    if (!Array.isArray(redos)) {
      redos = [redos];
    }

    for (const doAction of redos) {
      this._dispatch(doAction);
    }
  }
}

const undoManager = new UndoManager();

export default undoManager;
