/**
 * Called when the user starts or stops resizing anything.
 */
export const UI_RESIZE = 'ui.resize';
export const resizeUI = ({ isResizing }) => ({
  type: UI_RESIZE,
  payload: { isResizing }
});

export const SET_SCREEN_SIZE = 'ui.setScreenSize';
export const setScreenSize = ({ width, height }) => ({
  type: SET_SCREEN_SIZE,
  payload: {
    width,
    height
  }
});

export const SET_OFFSET = 'ui.setOffset';
export const setOffset = ({ id, value }) => ({
  type: SET_OFFSET,
  payload: { id, value }
});

/**
 * Since collaborator searches are stored in redux, we need to clear them occasionally.
 * TODO Refactor.
 */
export const CLEAR_COLLABORATORS_SEARCH = 'ui.clearCollaboratorsSearch';
export const clearcollaboratorssearch = () => ({
  type: CLEAR_COLLABORATORS_SEARCH
});

export const SET_UI_VALUE = 'ui.setValue';
export const setuivalue = ({ name, value }) => ({
  type: SET_UI_VALUE,
  payload: {
    name,
    value
  }
});

export const SET_LIBRARY_CATEGORY = 'ui.setCategory'
export const setLibraryCategory = ({ mode, category }) => ({
  type: SET_LIBRARY_CATEGORY,
  payload: {
    mode,
    category
  }
});
