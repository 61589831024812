// TODO: DEPRECATED FOR MATERIAL_UI
import {
 Box, Button, TextField, Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';

import BackButton from '../../components/material-ui/BackButton';
import CheckboxField from '../../components/material-ui/CheckboxField';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(1)
  },
  inputContainer: {
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  }
}));

/**
 * Renders all debug options.
 * Desktop and Mobile have different options reflecting what debug options can be used on each device.
 */
const PrivacyPolicy = ({ history }) => {
  /**
   * Render the controls.
   */

  const classes = useStyles();

  return (
    isMobile ? renderMobile(history) : renderBrowser(history)    
  );
};

const renderBrowser = (history) => {
  return (
    <Box p={1}>
      <BackButton
        onBack={() => {
          history.goBack();
        }}
      />
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box mt={4} mb={2} ml={10} mr={10} display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="h3"> {mainTitle} PC Edition </Typography>
          <Typography variant="h6" align="left"> {intro} </Typography>

          <Typography variant="h3">1. What information do we collect?</Typography>
          <Typography variant="h6" align="left">
          Personal information you disclose to us

          
          In Short: We collect personal information that you provide to us such as name, address,
          contact information, passwords and security data, and payment information.

          Information automatically collected
          In Short: Some information – such as IP address and/or browser and device characteristics
          – is collected automatically when you visit our website.
          We collect personal information that you voluntarily provide to us when registering at the
          expressing an interest in obtaining information about us or our products and services, when
          participating in activities on the (such as posting messages in our online forums or entering
          competitions, contests or giveaways) or otherwise contacting us.
          Name and Contact Data. We collect your first and last name, email address, postal address,
          phone number, and other similar contact data.
          Credentials. We collect passwords, password hints, and similar security information used for
          authentication and account access.
          Payment Data. We collect data necessary to process your payment if you make purchases,
          such as your payment instrument number (such as a credit card number), and the security
          code associated with your payment instrument. All payment data is stored by. You may find
          their privacy policy link(s) here:.
          All personal information that you provide to us must be true, complete and accurate, and
          you must notify us of any changes to such personal information.
          We automatically collect certain information when you visit, use or navigate the . This
          information does not reveal your specific identity (like your name or contact information)
          but may include device and usage information, such as your IP address, browser and device
          characteristics, operating system, language preferences, referring URLs, device name,
          country, location, information about how and when you use our and other technical
          information. This information is primarily needed to maintain the security and operation of
          our , and for our internal analytics and reporting purposes.
          Like many businesses, we also collect information through cookies and similar technologies
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const renderMobile = (history) => (
  <Box p={1}>
      <BackButton
        onBack={() => {
          history.goBack();
        }}
      />
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box mt={4} mb={2} display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="h3"> {mainTitle} Mobile Edition </Typography>
          <Typography variant="h6" align="left"> {intro} </Typography>
        </Box>
      </Box>
    </Box>
);

// Let's make this a text file and read from it, will make our life way easier
const mainTitle = 'Enklu Cloud Privacy Policy';
const intro = 'Thank you for choosing to be part of our community at CreateAR Corp (“Company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at . When you visit our and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy policy, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree... TO BE CONTINUED'

export default PrivacyPolicy;
