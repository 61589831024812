import CameraElementInspector from './MuiCameraElementInspector';
import ContainerElementInspector from './MuiContainerElementInspector';
import ContentElementInspector from './MuiContentElementInspector';
import NetworkedTextElementInspector from './MuiNetworkedTextElementInspector';
import NetworkedButtonElementInspector from './MuiNetworkedButtonElementInspector';
import ElementTypes from '../../../constants/ElementTypes';
import ExperienceElementInspector from './ExperienceElementInspector';
import FloatElementInspector from './MuiFloatElementInspector';
import ImageAnchorElementInspector from './ImageAnchorElementInspector';
import KinectElementInspector from './MuiKinectElementInspector';
import LightElementInspector from './MuiLightElementInspector';
import LinkedComponentElementInspector from './MuiLinkedComponentElementInspector';
import QrAnchorElementInspector from './MuiQrAnchorElementInspector';
import ScanElementInspector from './MuiScanElementInspector';
import TextElementInspector from './MuiTextElementInspector';
import WorldAnchorElementInspector from './MuiWorldAnchorElementInspector';

const typeToFactory = {
  [ElementTypes.CONTENT]: () => ContentElementInspector,
  [ElementTypes.NETWORKED_TEXT]: () => NetworkedTextElementInspector,
  [ElementTypes.NETWORKED_BUTTON]: () => NetworkedButtonElementInspector,
  [ElementTypes.QR_ANCHOR]: () => QrAnchorElementInspector,
  [ElementTypes.CAPTION]: () => TextElementInspector,
  [ElementTypes.FLOAT]: () => FloatElementInspector,
  [ElementTypes.GROUP]: ({ id }) => (id === 'root' ? ExperienceElementInspector : ContainerElementInspector),
  [ElementTypes.LINKED_COMPONENT]: ({ id, overrideType }) => {
    if (id === 'root') {
      return ExperienceElementInspector;
    }

    if (overrideType === 'StaticLinkedExperienceRoot') {
      // return ContainerElementInspector;
    }

    return LinkedComponentElementInspector;
  },
  [ElementTypes.LIGHT]: () => LightElementInspector,
  [ElementTypes.WORLD_ANCHOR]: () => WorldAnchorElementInspector,
  [ElementTypes.IMAGE_ANCHOR]: () => ImageAnchorElementInspector,
  [ElementTypes.SCAN]: () => ScanElementInspector,
  [ElementTypes.KINECT]: () => KinectElementInspector,
  [ElementTypes.CAMERA]: () => CameraElementInspector
};

export const getInspectorClass = (element) => {
  const { type } = element;
  const inspectorFactory = typeToFactory[type];

  return inspectorFactory ? inspectorFactory(element) : ContainerElementInspector;
};
