import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MultiControlPanel from '../common/MuiMultiControlPanel';
import { setQualitySetting } from '../../store/actions/sceneActions';
import txns from '../../store/actions/TxnManager';
import { updateAction } from '../../store/actions/elementActions';
import { getScene } from '../../store/selectors/scenesSelectors';

class QualitySettings extends React.Component {

  render() {
    const {
      scene: { id: sceneId, qualitySettings },
      onUpdateSceneSetting,
    } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <MultiControlPanel
          title={'Quality Options'}
          settings={qualitySettings}
          onChange={({
                       category, setting, value, defaultValue
                     }) => {
            onUpdateSceneSetting({
              sceneId,
              category,
              setting,
              value,
              defaultValue
            });
          }}
        />
      </div>);
  }
}

QualitySettings.propTypes = {
  scene: PropTypes.object.isRequired,
  onUpdateSceneSetting: PropTypes.func.isRequired,
}

const mapStoreToProps = store => ({
  scene: getScene(store)
});

const mapDispatchToProps = dispatch => ({
  onUpdateSceneSetting: ({
    sceneId, category, setting, value, defaultValue
  }) => {
    // Set the scene setting in redux.
    dispatch(setQualitySetting({ category, setting, value }));

    // Sets the scene setting for real.
    const { type: schemaType, key } = setting;
    txns.request(sceneId, updateAction('root', schemaType, key, value, defaultValue));
  }
});

export default connect(mapStoreToProps, mapDispatchToProps)(QualitySettings);
