import { Box, IconButton } from '@material-ui/core';
import { Build } from '@material-ui/icons';
import React from 'react';

const DebugIcon = () => (
  <Box position="absolute" bottom={0} left={0}>
    <IconButton href="#/debug">
      <Build />
    </IconButton>
  </Box>
);

export default DebugIcon;
