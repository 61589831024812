import {
 Box, Button, CircularProgress, Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { hexToRgb, rgbToRgba } from '../../util/util';
import { hidemodal } from '../../store/actions/modalActions';

const useStyles = makeStyles((theme) => {
  const bg = hexToRgb(theme.palette.grayscale.darkGray);
  const backgroundColor = rgbToRgba(bg, 0.5);

  return {
    logo: {
      display: 'table',
      margin: '0 auto',
      height: '300px'
    },

    reconnectDiv: {
      backgroundColor
    },

    continueBtn: {
      '& > :first-child': {
        marginRight: theme.spacing(2)
      }
    },

    spinner: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  };
});

const FullScreenModal = ({
 modal: {
 visible, message, spinnerVisible, continueVisible = false
}, hide
}) => {
  const classes = useStyles();

  return (
    <Box
      top={0}
      right={0}
      bottom={0}
      left={0}
      zIndex={1000}
      overflow="auto"
      position="fixed"
      display={visible ? 'block' : 'none'}
    >
      <Box
        position="absolute"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className={classes.reconnectDiv}
      >
        <img className={classes.logo} src="assets/img/Logo.png" alt="logo" />
        {spinnerVisible && <CircularProgress size={50} className={classes.spinner} />}
        {message && message.length > 0 && (
          <Typography variant="h4" align="center">
            {message}
          </Typography>
        )}
        {continueVisible && (
          <Box mt={2} className={classes.continueBtn}>
            <Button onClick={() => hide()} variant="contained">
              Continue
            </Button>

            <Button onClick={() => window.location.reload(true)} color="primary" variant="contained">
              Refresh
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

FullScreenModal.propTypes = {
  modal: PropTypes.shape({
    visible: PropTypes.bool,
    message: PropTypes.string,
    blurVisible: PropTypes.bool,
    spinnerVisible: PropTypes.bool,
    continueVisible: PropTypes.bool
  }),
  hide: PropTypes.func.isRequired
};

export default connect(
  ({ modal }) => ({ modal }),
  dispatch => ({
    /**
     * Hides the modal.
     */
    hide: () => dispatch(hidemodal())
  })
)(FullScreenModal);
