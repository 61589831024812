import {
 Box, FormControl, InputLabel, Link, MenuItem, OutlinedInput, Select, TextField
} from '@material-ui/core';
import { connect } from 'react-redux';
import { getActions } from '@enklu/server-api';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';

import { getAllAssetsById, getLibraries } from '../../../store/selectors/assetLibrariesSelectors';
import { getAppInfo } from '../../../store/selectors/appSelectors';
import { updateAction } from '../../../store/actions/elementActions';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import CheckboxField from '../../material-ui/CheckboxField';
import InspectableIdentity, { elementToInspectable } from '../common/MuiInspectableIdentity';
import InspectorInterface from '../common/InspectorInterface';
import ItemSocket from '../MuiItemSocket';
import ItemTypes from '../../../features/dragAndDrop/DragDropTypes';
import LibraryTypes from '../../../constants/LibraryTypes';
import Transform from '../MuiTransform';
import txns from '../../../store/actions/TxnManager';

const { shareassetwithapp } = getActions('trellis');

const ITEM_SOCKET_WIDTH = '60px';
const ITEM_SOCKET_HEIGHT = '60px';

const SHOULDER_TOP = 110;
const CENTER_LEFT = 105;
const WRIST_TOP = 290;

const useStyles = makeStyles(theme => ({
  inputFieldsContainer: {
    marginTop: theme.spacing(2),
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  },
  head: {
    top: 0,
    left: CENTER_LEFT,
    width: ITEM_SOCKET_WIDTH,
    height: ITEM_SOCKET_HEIGHT
  },
  leftShoulder: {
    top: SHOULDER_TOP,
    left: 175,
    width: ITEM_SOCKET_WIDTH,
    height: ITEM_SOCKET_HEIGHT
  },
  rightShoulder: {
    top: SHOULDER_TOP,
    left: 35,
    width: ITEM_SOCKET_WIDTH,
    height: ITEM_SOCKET_HEIGHT
  },
  spineShoulder: {
    top: SHOULDER_TOP,
    left: CENTER_LEFT,
    width: ITEM_SOCKET_WIDTH,
    height: ITEM_SOCKET_HEIGHT
  },
  leftWrist: {
    top: WRIST_TOP,
    left: 205,
    width: ITEM_SOCKET_WIDTH,
    height: ITEM_SOCKET_HEIGHT
  },
  rightWrist: {
    top: WRIST_TOP,
    left: 5,
    width: ITEM_SOCKET_WIDTH,
    height: ITEM_SOCKET_HEIGHT
  }
}));

const KinectElementInspector = ({
  appId,
  assetsById,
  libraries,
  element,
  shareAssetWithApp,
  scene: { id: sceneId },
  onUpdateElement
}) => {
  const classes = useStyles();

  const {
    id: elementId,
    schema: {
      strings: {
        'kinect.id': kinectId = 'Kinect Id',
        'kinect.body.head.assetId': headAssetId = '',
        'kinect.body.spineShoulder.assetId': spineShoulderAssetId = '',
        'kinect.body.shoulderLeft.assetId': leftShoulderAssetId = '',
        'kinect.body.shoulderRight.assetId': rightShoulderAssetId = '',
        'kinect.body.wristLeft.assetId': leftWristAssetId = '',
        'kinect.body.wristRight.assetId': rightWristAssetId = ''
      } = {},
      ints: {
        'kinect.bodies': kinectBodies = 6,
        'kinect.body.head.assetVersion': headAssetVersion = -1,
        'kinect.body.spineShoulder.assetVersion': spineShoulderAssetVersion = -1,
        'kinect.body.shoulderleft.assetVersion': leftShoulderAssetVersion = -1,
        'kinect.body.shoulderRight.assetVersion': rightShoulderAssetVersion = -1,
        // eslint-disable-next-line no-unused-vars
        'kinect.body.wristLeft.assetVersion': leftWristAssetVersion = -1,
        'kinect.body.wristRight.assetVersion': rightWristAssetVersion = -1
      } = {},
      bools: { 'kinect.local': local = false } = {}
    } = {}
  } = element;

  const headAsset = assetsById[headAssetId];
  const spineShoulderAsset = assetsById[spineShoulderAssetId];
  const leftShoulderAsset = assetsById[leftShoulderAssetId];
  const rightShoulderAsset = assetsById[rightShoulderAssetId];
  const leftWristAsset = assetsById[leftWristAssetId];
  const rightWristAsset = assetsById[rightWristAssetId];

  const placeholder = 'Drop asset';

  const handleUpdate = key => (asset) => {
    const updateScene = () => txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.STRING, key, asset.id));

    // is this asset shared with app?
    if (libraries[LibraryTypes.USER][asset.id] && !libraries[LibraryTypes.APP][asset.id]) {
      shareAssetWithApp(appId, asset.id)
        .then(updateScene)
        // eslint-disable-next-line no-console
        .catch(err => console.log(`Error: ${err}`));
    } else {
      updateScene();
    }
  };

  const handleRemove = key => () => txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.STRING, key, ''));

  return (
    <Box p={1}>
      <InspectableIdentity
        inspectable={element}
        inspectableTranslator={elementToInspectable}
        onUpdate={onUpdateElement}
      />

      <Transform hideTitle element={element} sceneId={sceneId} />

      <Link color="primary" href="https://enklu.github.io/docs/API/Kinect" target="_blank" rel="noopener noreferrer">
        Read more about Kinect integration.
      </Link>

      <form className={classes.inputFieldsContainer}>
        <TextField
          label="Kinect Id"
          value={kinectId}
          onChange={evt => txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.STRING, 'kinect.id', evt.target.value))
          }
          fullWidth
          variant="outlined"
        />

        <CheckboxField
          value={local}
          onChange={evt => txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.BOOL, 'kinect.local', !!evt.target.checked))
          }
          label="Player Controlled"
        />

        <FormControl fullWidth>
          <InputLabel variant="outlined">Bodies</InputLabel>
          <Select
            input={<OutlinedInput />}
            value={kinectBodies}
            onChange={({ target: { value } }) => txns.request(
                sceneId,
                updateAction(elementId, ActionSchemaTypes.INT, 'kinect.bodies', parseInt(value, 10))
              )
            }
            variant="outlined"
          >
            {[...Array(7).keys()].map(i => (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>

      <Box position="relative">
        <img src="./assets/img/humanoid.png" alt="humanoid" />

        {/* Head */}
        <Box position="absolute" className={classes.head}>
          <ItemSocket
            small
            item={headAsset}
            version={headAssetVersion}
            type={ItemTypes.ASSET}
            onDrop={handleUpdate('kinect.body.head.assetId')}
            onRemove={handleRemove('kinect.body.head.assetId')}
            placeholder={placeholder}
          />
        </Box>

        {/* SpineShoulder */}
        <Box position="absolute" className={classes.spineShoulder}>
          <ItemSocket
            small
            item={spineShoulderAsset}
            version={spineShoulderAssetVersion}
            type={ItemTypes.ASSET}
            onDrop={(asset) => {
              handleUpdate('kinect.body.spineShoulder.assetId')(asset);
              handleUpdate('kinect.body.spineChest.assetId')(asset);
            }}
            onRemove={() => {
              handleRemove('kinect.body.spineShoulder.assetId')();
              handleRemove('kinect.body.spineChest.assetId')();
            }}
            placeholder={placeholder}
          />
        </Box>

        {/* Right Shoulder */}
        <Box position="absolute" className={classes.rightShoulder}>
          <ItemSocket
            small
            item={rightShoulderAsset}
            version={rightShoulderAssetVersion}
            type={ItemTypes.ASSET}
            onDrop={handleUpdate('kinect.body.shoulderRight.assetId')}
            onRemove={handleRemove('kinect.body.shoulderRight.assetId')}
            placeholder={placeholder}
          />
        </Box>

        {/* Left Shoulder */}
        <Box position="absolute" className={classes.leftShoulder}>
          <ItemSocket
            small
            item={leftShoulderAsset}
            version={leftShoulderAssetVersion}
            type={ItemTypes.ASSET}
            onDrop={handleUpdate('kinect.body.shoulderLeft.assetId')}
            onRemove={handleRemove('kinect.body.shoulderLeft.assetId')}
            placeholder={placeholder}
          />
        </Box>

        {/* Right Wrist */}
        <Box position="absolute" className={classes.rightWrist}>
          <ItemSocket
            small
            item={rightWristAsset}
            version={rightWristAssetVersion}
            type={ItemTypes.ASSET}
            onDrop={handleUpdate('kinect.body.wristRight.assetId')}
            onRemove={handleRemove('kinect.body.wristRight.assetId')}
            placeholder={placeholder}
          />
        </Box>

        {/* Left Wrist */}
        <Box position="absolute" className={classes.leftWrist}>
          <ItemSocket
            small
            item={leftWristAsset}
            version={leftShoulderAssetVersion}
            type={ItemTypes.ASSET}
            onDrop={handleUpdate('kinect.body.wristLeft.assetId')}
            onRemove={handleRemove('kinect.body.wristLeft.assetId')}
            placeholder={placeholder}
          />
        </Box>
      </Box>
    </Box>
  );
};

KinectElementInspector.propTypes = {
  ...InspectorInterface,
  appId: PropTypes.string,
  assetsById: PropTypes.object,
  libraries: PropTypes.object,
  shareAssetWithApp: PropTypes.func
};

const mapStateToProps = state => ({
  appId: getAppInfo(state).id,
  assetsById: getAllAssetsById(state),
  libraries: getLibraries(state)
});

const mapDispatchToProps = dispatch => ({
  /**
   * Shares an asset with an app.
   */
  shareAssetWithApp: (appId, assetId) => dispatch(shareassetwithapp({ assetId }, { appId }))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KinectElementInspector));
