import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { getActions } from '@enklu/server-api';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getMyApps } from '../../../store/selectors/userSelectors';
import { newMonitor, formatTimestamp } from '../../../util/util';
import { newProgressShape } from '../../../interfaces/ProgressShape';
import CopyableText from '../../common/CopyableText';
import InspectableIdentity from '../common/MuiInspectableIdentity';
import NoInspectable from '../common/NoInspectable';

const { updateapp } = getActions('trellis');

/**
 * Custom inspector for Experience elements.
 */
class ExperienceInspectorCore extends Component {
  monitor;

  /**
   * Constructor.
   */
  constructor(props) {
    super(props);

    this.state = {
      updateAppProgress: newProgressShape()
    };

    this.monitor = newMonitor(this);
  }

  /**
   * Renders controls.
   */
  render() {
    const { appId, apps, updateApp } = this.props;

    // TODO Move externally.
    const app = apps.find(candidate => candidate.id === appId);

    if (!app) {
      return <NoInspectable />;
    }

    const { createdAt } = app;

    return (
      <React.Fragment>
        <InspectableIdentity
          inspectable={app}
          onUpdate={({ name: key, value }) => this.monitor({
              name: 'updateApp',
              actionCreator: updateApp,
              params: [
                {
                  [key]: value
                },
                { appId }
              ]
            })
          }
        />

        {/* <CollaboratorsDisplay appId={appId} urlPrefix={'m'} /> */}

        <Typography variant="caption">
          ID: <CopyableText text={appId} />
        </Typography>

        <Typography variant="caption">{`Created On: ${formatTimestamp(createdAt, {
          includeDate: true
        })}`}</Typography>
      </React.Fragment>
    );
  }
}

ExperienceInspectorCore.propTypes = {
  // external
  appId: PropTypes.string,
  currentAppId: PropTypes.string,
  urlPrefix: PropTypes.string.isRequired,
  onLoad: PropTypes.func,

  // connect
  apps: PropTypes.array,
  updateApp: PropTypes.func.isRequired,

  // withRouter
  history: PropTypes.object,
  location: PropTypes.object,

  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired
};

ExperienceInspectorCore.defaultProps = {
  editable: true,
  color: 'paper'
};

const mapStateToProps = state => ({
  apps: getMyApps(state)
});

const mapDispatchToProps = dispatch => ({
  updateApp: ({ name, description }, { appId }) => dispatch(
      updateapp(
        {
          name,
          description
        },
        { appId }
      )
    )
});

export default withStyles(theme => ({}))(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ExperienceInspectorCore))
);
