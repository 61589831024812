import { AsyncStatus } from '@enklu/server-api';
import {
 Button, DialogActions, DialogContent, TextField
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { initializeNewApp } from '../../store/actions/initializeActions';
import { newMonitor } from '../../util/util';
import { newProgressShape } from '../../interfaces/ProgressShape';
import ModalShape from '../../interfaces/ModalShape';

class CreateExperienceModal extends Component {
  monitor;

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      description: '',
      onInitializeNewAppProgress: newProgressShape()
    };

    this.monitor = newMonitor(this);
  }

  render() {
    const {
 onClose, onInitializeNewApp, classes, history
} = this.props;

    const { name, description, onInitializeNewAppProgress } = this.state;

    const submit = (event) => {
      event.preventDefault();
      this.monitor({
        name: 'onInitializeNewApp',
        actionCreator: onInitializeNewApp,
        params: [{ name, description }, { history }],
        onFinally: () => {}
      });
      // await onInitializeNewApp({ name, description }, { history });
    };

    return (
      <React.Fragment>
        <form>
          <DialogContent>
            <div className={classes.inputFieldsContainer}>
              <TextField
                label="Experience Name"
                id="appName"
                placeholder="Enter Name"
                value={name}
                fullWidth
                onChange={({ target: { value } }) => this.setState({ name: value })}
                variant="outlined"
              />
              <TextField
                label="Description"
                id="appDescription"
                placeholder="Enter Description"
                value={description}
                fullWidth
                onChange={({ target: { value } }) => this.setState({ description: value })}
                variant="outlined"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              color="default"
              variant="outlined"
              disabled={onInitializeNewAppProgress.status === AsyncStatus.IN_PROGRESS}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={submit}
              color="primary"
              variant="outlined"
              disabled={!this.state.name || onInitializeNewAppProgress.status === AsyncStatus.IN_PROGRESS}
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </React.Fragment>
    );
  }
}

CreateExperienceModal.propTypes = {
  ...ModalShape,

  // connect
  onInitializeNewApp: PropTypes.func.isRequired,

  // withRouter
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  classes: PropTypes.object.isRequired
};
CreateExperienceModal.defaultProps = {};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
  onInitializeNewApp: ({ name, description }, { history }) => dispatch(initializeNewApp({ name, description }, { history }))
});

export default withStyles(theme => ({
  inputFieldsContainer: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }
}))(withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateExperienceModal)));
