import { Box, Typography } from '@material-ui/core';
import React from 'react';

import InspectableIdentity, { elementToInspectable } from '../common/MuiInspectableIdentity';
import InspectorInterface from '../common/InspectorInterface';
import Transform from '../MuiTransform';

/**
 * Custom inspector for world anchor elements.
 */
const WorldAnchorElementInspector = ({ element, scene, onUpdateElement }) => {
  const { id: sceneId } = scene;

  // element data
  const { schema: { strings: { 'export.time': exportTime, src } = {} } = {} } = element;

  return (
    <Box p={1}>
      <InspectableIdentity
        inspectable={element}
        inspectableTranslator={elementToInspectable}
        onUpdate={onUpdateElement}
      />

      {// eslint-disable-next-line no-nested-ternary
      src && src.length > 0 ? (
        <Typography>World anchor has been exported and locked.</Typography>
      ) : exportTime && exportTime.length > 0 ? (
        <Typography>World anchor is currently being exported.</Typography>
      ) : (
        <Transform hideTitle element={element} sceneId={sceneId} />
      )}
    </Box>
  );
};

WorldAnchorElementInspector.propTypes = {
  ...InspectorInterface
};

export default WorldAnchorElementInspector;
