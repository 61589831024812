import { combineReducers } from 'redux';
import debug from './debugReducer';
import appSelection from './appSelectionReducer';
import user from './userReducer';
import app from './appReducer';
import inspector from './inspector/inspectorReducers';
import init from './initializeReducer';
import collaborators from './collaboratorsReducer';
import userCache from './userCacheReducer';
import files from './filesReducer';
import modal from './modalReducer';
import globalSettings from './globalSettingsReducer';
import preferences from './preferencesReducer';
import libraries from './assets/assetLibrariesReducer';
import scripts from './scriptLibrariesReducer';
import help from './helpReducer';
import ui from './uiReducer';
import log from './logReducer';
import error from './errorReducer';
import player from './playerReducer';
import spaces from './spacesReducer';
import transformSpace from './controlBarReducer';
import remoteAssist from './remoteAssistReducer';
import passwordResetStatus from './passwordResetReducer';
import thumbs from './thumbsReducer';


// SELECTORS
// These go here rather than in their respective files because only here do we know what they are called in the store.

// REDUCER
export default combineReducers({
  error,
  debug,
  appSelection,
  user,
  app,
  inspector,
  init,
  collaborators,
  userCache,
  files,
  modal,
  globalSettings,
  preferences,
  libraries,
  help,
  ui,
  scripts,
  log,
  player,
  spaces,
  transformSpace,
  remoteAssist,
  passwordResetStatus,
  thumbs
});
