/**
 * Sends a POST request to a specified endpoint, with data as a FormData file.
 */
export function sendFile(endpoint, data) {
    return new Promise((res, rej) => {
        const form = new FormData();
        form.append('file', data);

        const xhr = new XMLHttpRequest();
        xhr.responseType = 'json';
        xhr.onload = () => {
            const response = xhr.response;
            if (response.success) {
                res(response.body);
            } else {
                rej(response.error);
            }
        };

        xhr.open('POST', endpoint);
        xhr.setRequestHeader('Authorization', `Bearer ${io.sails.token}`);
        xhr.send(form);
    });
}