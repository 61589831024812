import { Box, DialogTitle, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

// item is string or object
const ModalHeader = ({ title, onClose }) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent={onClose ? 'space-between' : 'flex-start'}
    alignItems="center"
    pr={1}
  >
    <DialogTitle>{title}</DialogTitle>
    {onClose && (
      <IconButton onClick={onClose}>
        <Close />
      </IconButton>
    )}
  </Box>
);

ModalHeader.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

export default ModalHeader;
