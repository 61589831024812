import { MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import ChooserShape from '../../interfaces/ChooserShape';

const useStyles = makeStyles(theme => ({
  filledStyle: {
    minWidth: 80,
    borderRadius: 8,
    backgroundColor: theme.palette.background.default,
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0
    },
    '&.Mui-focused': {
      boxShadow: 'none'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0
    }
  }
}));

const GenericChooser = (props) => {
  const classes = useStyles();

  const {
 items, value, onChange, ...otherProps
} = props;

  return (
    <Select
      value={value}
      onChange={({ target: { value: newValue } }) => onChange(newValue)}
      input={<OutlinedInput margin="dense" className={classes.filledStyle} />}
      {...otherProps}
    >
      {items.map(({ name, value }, index) => (
        <MenuItem value={value} key={index}>
          {name || value}
        </MenuItem>
      ))}
    </Select>
  );
};

GenericChooser.propTypes = {
  ...ChooserShape,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string
    })
  ).isRequired
};

export default GenericChooser;
