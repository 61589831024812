import { Actions } from '@enklu/server-api';

const { send, receive } = Actions;

export const CLEAR_SCRIPT = 'script.clearScript';
export const clearScript = id => ({
  type: CLEAR_SCRIPT,
  payload: id
});

/**
 * Download's a scripts contents.
 */
export const DOWNLOADSCRIPT = 'script.downloadscript';
const sender = send(DOWNLOADSCRIPT);
const receiver = receive(DOWNLOADSCRIPT);
export const downloadscript = (scriptId, url) => dispatch => new Promise((resolve, reject) => {
  // dispatch send
  dispatch(sender());

  const http = new XMLHttpRequest();
  http.onreadystatechange = () => {
    if (http.readyState === 4) {
      const { responseText } = http;
      if (http.status === 200) {
        return resolve(responseText);
      }

      return reject(responseText);
    }
  };
  http.open('GET', url, true);
  http.send(null);
});

/**
 * Called when a script has been added.
 */
export const SCRIPTCREATED_NOTIF = 'script.scriptcreated_notif';
export const scriptcreated_notif = script => ({
  type: SCRIPTCREATED_NOTIF,
  script
});

/**
 * Called when a script has been updated.
 */
export const SCRIPTUPDATED_NOTIF = 'script.scriptupdated_notif';
export const scriptupdated_notif = script => ({
  type: SCRIPTUPDATED_NOTIF,
  script
});

/**
 * Called when a script has been deleted.
 */
export const SCRIPTDELETED_NOTIF = 'script.scriptdeleted_notif';
export const scriptdeleted_notif = id => ({
  type: SCRIPTDELETED_NOTIF,
  id
});

/**
 * Called when a script has been shared.
 */
export const SCRIPTSHARED_NOTIF = 'script.scriptshared_notif';
export const scriptshared_notif = script => ({
  type: SCRIPTSHARED_NOTIF,
  script
});
