import { Add, Search } from '@material-ui/icons';
import {
 Box, IconButton, InputAdornment, Link, Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { getActions } from '@enklu/server-api';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import { withSize } from 'react-sizeme';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from 'react-tooltip';
import queryString from 'query-string';

import { pushNavState as _pushNavState } from '../../store/actions/navActions';
import { createTag } from './libraryUtil';
import { filter, isProbablyGuid } from '../../util/util';
import { getAppInfo } from '../../store/selectors/appSelectors';
import { getCategories as getAssetCategories, getFilteredAssets } from '../../store/selectors/assetLibrariesSelectors';
import { getUsageFilter } from '../../store/selectors/uiSelectors';
import {
  getCategories as getScriptCategories,
  getFilteredScripts
} from '../../store/selectors/scriptLibrariesSelectors';
import { setLibraryCategory, setuivalue } from '../../store/actions/uiActions';
import BackButton from '../material-ui/BackButton';
import BaseStandardTextField from '../material-ui/BaseStandardTextField';
import CreateNewAsset from '../inspector/asset/MuiCreateNewAsset';
import CreateNewScript from '../inspector/script/MuiCreateNewScript';
import DragChooser from '../common/MuiDragChooser';
import GenericChooser from '../common/MuiGenericChooser';
import ItemTypes from '../../features/dragAndDrop/DragDropTypes';
import LibraryModes from './LibraryModes';
import LibraryTypes from '../../constants/LibraryTypes';
import MultiInspector from '../common/MuiMultiInspector';
import createNavSelector from '../../store/selectors/navSelector';
import DrawerModes from '../drawer/DrawerModes';
import { UsageTypes } from '../../store/reducers/uiReducer';

const { createscript, getappscripts } = getActions('trellis');

const useStyles = makeStyles(theme => ({
  iconButton: {
    marginLeft: theme.spacing(1),
    borderRadius: 8,
    backgroundColor: theme.palette.background.default
  },
  dragChooser: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxWidth: '180px'
  },
  headerText: {
    marginLeft: theme.spacing(1)
  }
}));

const Library = ({
  mode,
  usageFilter,
  setUsageFilter,
  location,
  categories,
  selectedCategoryName,
  setSelectedCategory,
  content,
  info: { name: appName, id: appId },
  size: { width },

  columnWidth,
  itemWidth,

  history,
  dispatch,
  updateScriptLibrary,
  createScript,
  navState: { blocksMode },
  pushNavState,
}) => {
  const classes = useStyles();

  const [filterString, setFilterString] = React.useState('');

  const renderHeader = () => (
    <React.Fragment>
      {blocksMode !== LibraryModes.LIST && (
        <Box px={1} py={1} display="flex" flexDirection="row" alignItems="center">
          <BackButton
            onBack={(event) => {
              event.preventDefault();
              pushNavState({ blocksMode: LibraryModes.LIST });
            }}
          />
          <Typography variant="h5" className={classes.headerText}>
            <Link
              color="primary"
              onClick={(event) => {
                event.preventDefault();
                pushNavState({ blocksMode: LibraryModes.LIST });
              }}
            >
              Library
            </Link>
            {` > ${mode === DrawerModes.SCRIPTS ? 'Create New Script' : 'Upload New Asset'}`}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );

  const renderBlocks = () => {
    // We render in one of two modes: LIST or ADD (see @Modes).
    let data;
    let itemType;
    let urlString;
    switch (mode) {
      case DrawerModes.ASSETS:
        itemType = ItemTypes.ASSET;
        urlString = '/i/asset/';
        break;
      case DrawerModes.SCRIPTS:
        itemType = ItemTypes.SCRIPT;
        urlString = '/i/script/'
        break;
    }

    const items = filter({
      items: content,
      tags: [],
      filterString
    });

    const openInspector = ({ id }) => history.push({
      ...location,
      pathname: `${data.urlString}${id}`
    });

    data = {
      items,
      itemsType: itemType,
      urlString,
      // This is a bit gross, be nice to refactor at some point.
      onEdit: mode === DrawerModes.ASSETS
        ? openInspector
        : ({ id }) => {
          Tooltip.hide();
          const search = queryString.parse(location.search);
          history.push({
            ...location,
            search: queryString.stringify({ ...search, script: id, sidebar: 'script' })
          })
      },
      onWrite: mode === DrawerModes.SCRIPTS ? openInspector : undefined
    };

    const categoryTags = categories.map(item => createTag(item, { appName, appId }));

    switch (blocksMode) {
      case LibraryModes.LIST: {
        return (
          <Box display="flex" flex={1} overflow="auto" pt={1}>
            <Box display="flex" flexDirection="column" px={1}>
              <BaseStandardTextField
                style={{ width: 180 }}
                darkbackground="true"
                noshadow="true"
                margin="dense"
                placeholder={'Filter'}
                value={filterString}
                onChange={({ target: { value } }) => setFilterString(value)}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
              />

              {/* Main category (usability) */}
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="stretch" mt={1}>
                <GenericChooser
                  fullWidth
                  items={[{ value: UsageTypes.All }, { value: UsageTypes.InUse }, { value: UsageTypes.Unused }]}
                  value={usageFilter || UsageTypes.All }
                  onChange={(value) => {
                    setUsageFilter(value);
                  }}
                />
                <IconButton
                  className={classes.iconButton}
                  onClick={() => pushNavState({ blocksMode: LibraryModes.ADD })}
                >
                  <Add />
                </IconButton>
              </Box>

              {/* Subcategory */}

              <DragChooser
                customClass={classes.dragChooser}
                value={selectedCategoryName}
                items={categoryTags}
                onChange={(value) => {
                  setSelectedCategory(mode, value);
                  if (mode === DrawerModes.SCRIPTS) {
                    // Script libraries from other experiences do
                    // not get updated automagically. We should
                    // refresh those every time we select one.
                    if (isProbablyGuid(value)) {
                      updateScriptLibrary(value);
                    }
                  }
                }}
                dispatch={dispatch}
                // Cannot drop in __user__ at all.
                // Cannot drop a user item in a non-user tag.
                dropRules={{ __user__: false, __public__: false, __app__: false }}
              />
            </Box>

            <MultiInspector
              items={
                data.items
                && data.items.sort((a, b) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
              }
              blocks={selectedCategoryName}
              itemsType={data.itemsType}
              value={location.pathname.split('/').pop()}
              numCols={Math.floor((width - columnWidth) / itemWidth)}
              onEdit={data.onEdit}
              onDelete={data.onDelete}
              onWrite={data.onWrite}
              onCreate={createScript}
              appId = {appId}
            />
          </Box>
        );
      }

      case LibraryModes.ADD: {
        return mode === DrawerModes.SCRIPTS ? (
          <CreateNewScript
            onCreate={createScript}
            onClose={() => pushNavState({ blocksMode: LibraryModes.LIST })}
            appId={appId}
          />
        ) : (
          <CreateNewAsset
            onClose={() => pushNavState({ blocksMode: LibraryModes.LIST })}
            appId={appId}
            assets={content}
            dispatch={dispatch} // TODO Remove.
            selectedCategoryName
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" overflow="auto" flex={1}>
      {renderHeader()}
      {renderBlocks()}
    </Box>
  );
};

Library.propTypes = {
  // withSize
  size: PropTypes.object.isRequired,

  // props
  mode: PropTypes.string.isRequired,

  // redux store
  content: PropTypes.array.isRequired,
  selectedCategoryName: PropTypes.string.isRequired,
  navState: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,

  usageFilter: PropTypes.string.isRequired,
  info: PropTypes.object.isRequired,

  // dispatch
  setSelectedCategory: PropTypes.func.isRequired,
  pushNavState: PropTypes.func.isRequired,
  updateScriptLibrary: PropTypes.func.isRequired,
  createScript: PropTypes.func.isRequired,
  setUsageFilter: PropTypes.func.isRequired,

  // optional
  itemWidth: PropTypes.number,
  columnWidth: PropTypes.number,
};

Library.defaultProps = {
  itemWidth: 100,
  columnWidth: 150,
  style: {}
};

const createMapStateToProps = () => {
  const getNavState = createNavSelector({
    blocks: LibraryTypes.APP,
    blocksMode: LibraryModes.LIST,
    library: [DrawerModes.ASSETS]
  });

  return ((state, ownProps) => {
    return {
      selectedCategoryName: state.ui.library.selectedCategoryName[ownProps.mode],
      content: ownProps.mode === DrawerModes.ASSETS
          ? getFilteredAssets(state)
          : getFilteredScripts(state),
        categories: ownProps.mode === DrawerModes.ASSETS
          ? getAssetCategories(state)
          : getScriptCategories(state),

        info: getAppInfo(state),
        usageFilter: getUsageFilter(state),
        navState: getNavState(state, ownProps)
    };
  });
};

const mapDispatchToProps = (dispatch, { history, location }) => ({
  setSelectedCategory: (mode, category) => dispatch(setLibraryCategory({ mode, category })),


  setUsageFilter: value => dispatch(
      setuivalue({
        name: 'library.usageFilter',
        value
      })
    ),
  createScript: async ({
 appId, name, description, source, tags
}) => {
    await dispatch(
      createscript({
        appId,
        name,
        description,
        source,
        tags
      })
    );
    await dispatch(getappscripts({ appId }));
  },
  updateScriptLibrary: appId => dispatch(getappscripts({ appId })),
  pushNavState: navState => dispatch(_pushNavState(history, location, navState)),
  dispatch
});

export default withRouter(withSize()(connect(createMapStateToProps, mapDispatchToProps)(Library)));
