const typesPdf = ['pdf'];
const typesObject = ['3dm', 'dfx', 'dwg', 'fbx', 'gltf', 'obj', 'prt', 'prt.1', 'pxz', 'sldprt', 'sldprt.1', 'stl', 'zip'];
const typesImage = ['bmp', 'exr', 'gif', 'hdr', 'iff', 'jpg', 'pict', 'png', 'psd', 'pga', 'tiff'];
const typesAudio = ['mp3', 'ogg', 'wav', 'aiff', 'aif', 'mod', 'it', 's3m', 'xm'];
const typesVideo = [ 'asf', 'avi', 'dv', 'm4v', 'mov', 'mp4', 'mpg', 'mpeg', 'ogv', 'vp8', 'webm', 'wmv' ];
const typesAll = [...typesPdf, ...typesObject, ...typesImage, ...typesAudio, ...typesVideo];

const FileTypeCategories = {
  PDF: 'PDF',
  IMAGE: 'IMAGE',
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO',
  OBJECT: 'OBJECT'
};

const reduce = (types, category) => types.reduce((accum, type) => ({ ...accum, [type]: category }), {});
const CategoryByType = {
  ...reduce(typesPdf, FileTypeCategories.PDF),
  ...reduce(typesObject, FileTypeCategories.OBJECT),
  ...reduce(typesImage, FileTypeCategories.IMAGE),
  ...reduce(typesAudio, FileTypeCategories.AUDIO),
  ...reduce(typesVideo, FileTypeCategories.VIDEO)
};

export {
  typesPdf, typesObject, typesImage, typesAudio, typesVideo, typesAll, reduce, CategoryByType, FileTypeCategories
};
