export const KEY_SPACE_ID = 'spaces.id';

/**
 * Extracts the linked space id from a given scene root.
 * @param root
 * @returns {string}
 */
export function spaceIdFromRoot(root) {
    const {
        schema: {
            strings: {
                [KEY_SPACE_ID]: spaceLink = ''
            } = {},
        } = {}
    } = root;

    return spaceLink;
}

/**
 * Downloads and returns the built element tree from a space.
 * @param space
 * @returns {Promise<any>}
 */
export async function loadSpaceJson(space) {
    const url = `${window.env.spacesUrl}${space.id}_${space.hash}.json`;
    const response = await fetch(url);

    if (!response.ok) {
        throw new Error(`Unable to download mesh: ${response.status} ${response.statusText}`);
    }
    return await response.json();
}

/**
 * Constructs the absolute url for a mesh scan
 */
export const meshUrl = (uri) => {
    return `${window.env.trellisBaseUrl}${uri}`;
};
