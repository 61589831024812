import ModalTypes from '../../constants/ModalTypes';

export default {
  EXPERIENCE: 'experience',
  NEW: ModalTypes.NEW,
  OPEN: ModalTypes.OPEN,

  EDIT: 'edit',
  UNDO: 'undo',
  REDO: 'redo',
  PREFERENCES: ModalTypes.PREFERENCES,

  DEBUG: 'debug',
  PLAY: 'play',
  STOP: 'stop',
  PAUSE: 'pause',
  RESUME: 'resume',

  TOOLS: 'tools',
  HOLOLENS_LOGIN: ModalTypes.HOLOLENS_LOGIN,

  HELP: 'help',
  ONLINE_HELP: 'onlineHelp',
  SUPPORT: 'support',
  JS_HELP: 'jshelp',
  MANAGE_MY_ACCOUNT: ModalTypes.MANAGE_MY_ACCOUNT,
  SHARE: ModalTypes.SHARE
};
