import {
  INITIALIZE, INITIALIZE_RESET_STATUS,
  INITIALIZE_COMPLETE,
  INITIALIZE_ERROR,
} from '../actions/initializeActions';
import InitializationStatus from '../../constants/InitializationStatus';

const initialState = {
  status: InitializationStatus.UNINITIALIZED,
  error: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_RESET_STATUS: {
      return { ...state, status: InitializationStatus.UNINITIALIZED };
    }

    case INITIALIZE: {
      return { ...state, status: InitializationStatus.INITIALIZING };
    }

    case INITIALIZE_COMPLETE: {
      return { ...state, status: InitializationStatus.INITIALIZATION_COMPLETE };
    }

    case INITIALIZE_ERROR: {
      return { ...state, error: action.payload };
    }

    default: {
      return state;
    }
  }
};
