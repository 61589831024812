import { TrellisActions, AsyncStatus } from '@enklu/server-api';

import {
  SCRIPTCREATED_NOTIF,
  SCRIPTDELETED_NOTIF,
  SCRIPTSHARED_NOTIF,
  SCRIPTUPDATED_NOTIF
} from '../actions/scriptActions';
import { INITIALIZE } from '../actions/initializeActions';
import MessageTypes from '../../constants/MessageTypes';

const {
  CREATESCRIPT,
  DELETESCRIPT,
  GETAPPSCRIPTS,
  GETPUBLICSCRIPTS,
  UPDATESCRIPT
} = TrellisActions;

export const LibraryTypes = {
  PUBLIC: '__public__'
};

// object to start reducer with
const initialState = {
  appId: '',
  libraries: {}
};

// /////////////////////////////////////////////////////////////////////////////
// / Selectors.
// /////////////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////////////////////
// / Reducers
// /////////////////////////////////////////////////////////////////////////////

// sends events to webgl player
const handlePlayerUpdates = (state, action) => {
  switch (action.type) {
    case CREATESCRIPT: {
      const script = action.body;

      window.bridge.send(MessageTypes.SCRIPT_ADD, { script });

      return;
    }
    case UPDATESCRIPT: {
      const script = action.body;

      window.bridge.send(MessageTypes.SCRIPT_UPDATE, { script });

      return;
    }
    case DELETESCRIPT: {
      const { scriptId: id } = action.request.replacements;

      window.bridge.send(MessageTypes.SCRIPT_REMOVE, { id });

      return;
    }
    case SCRIPTCREATED_NOTIF: {
      const { script } = action;

      window.bridge.send(MessageTypes.SCRIPT_ADD, { script });

      return;
    }
    case SCRIPTUPDATED_NOTIF: {
      const { script } = action;

      window.bridge.send(MessageTypes.SCRIPT_UPDATE, { script });

      return;
    }
    case SCRIPTSHARED_NOTIF: {
      const { script } = action;

      window.bridge.send(MessageTypes.SCRIPT_ADD, { script });

      return;
    }
    case SCRIPTDELETED_NOTIF: {
      const { id } = action;

      window.bridge.send(MessageTypes.SCRIPT_REMOVE, { id });
    }
  }
};

// reducer for all libraries
export default (state = initialState, action) => {
  // always intercept and dispatch applicable events to player
  handlePlayerUpdates(state, action);

  if (action.status && action.status !== AsyncStatus.SUCCESS) {
    return state;
  }

  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        appId: action.payload.appId
      };

    // Handle script library fetching.
    case GETAPPSCRIPTS:
    case GETPUBLICSCRIPTS: {
      const libId = action.request.replacements.appId || LibraryTypes.PUBLIC;
      return {
        ...state,
        libraries: {
          ...state.libraries,
          [libId]: action.body.reduce(
            (accum, script) => ({
              ...accum,
              [script.id]: script
            }),
            {}
          )
        }
      };
    }

    // Handle library modifications, user or collaborator driven.
    case SCRIPTCREATED_NOTIF:
    case SCRIPTUPDATED_NOTIF:
    case SCRIPTSHARED_NOTIF: {
      const script = action.script;
      const library = { ...state.libraries[state.appId], [script.id]: script };

      return {
        ...state,
        libraries: {
          ...state.libraries,
          [state.appId]: library
        }
      };
    }

    case SCRIPTDELETED_NOTIF: {
      const library = { ...state.libraries[state.appId] };
      delete library[action.id];

      return {
        ...state,
        libraries: {
          ...state.libraries,
          [state.appId]: library
        }
      };
    }

    default:
      return state;
  }
};
