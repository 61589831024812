export default {
    PLAYER_RESTART: -1000,

    WEB_UPDATE: -3,

    CREDENTIALS: 2,
    APP_INFO: 3,
    ENV_INFO: 4,

    ASSET_LIST: 10,
    ASSET_ADD: 11,
    ASSET_REMOVE: 12,
    ASSET_UPDATE: 13,
    ASSET_STATS: 14,

    SCRIPT_LIST: 20,
    SCRIPT_ADD: 21,
    SCRIPT_REMOVE: 22,
    SCRIPT_UPDATE: 23,

    SCENE_CREATE: 3100,
    SCENE_UPDATE: 3101,
    SCENE_DELETE: 3102,

    SELECT: 3991,

    PRESENCE_LIST: 3200,
    PRESENCE_JOINED: 3201,
    PRESENCE_LEFT: 3202,

    REMOTE_ASSIST_CONNECTING: 3302,
    REMOTE_ASSIST_LEFT: 3303,
    REMOTE_ASSIST_LOG: 3304,
    REMOTE_ASSIST_CONTROL: 3305,

    SPACE_UPDATE: 3120,
    SPACE_DELETE: 3121,

    // Control bar
    BRIDGE_HELPER_GIZMO_TRANSLATION: 3995,
    BRIDGE_HELPER_GIZMO_ROTATION: 3996,
    BRIDGE_HELPER_GIZMO_SCALE: 3997,
    BRIDGE_HELPER_TRANSFORM_SPACE: 3998,
    BRIDGE_HELPER_FULLSCREEN: 4011,
    BRIDGE_HELPER_TRANSFORM_SPACE_CHECK: 4012,

    BRIDGE_HELPER_PLAYEDIT: 4013,

    BRIDGE_HELPER_THUMBNAIL_GENERATED: 4014,
    BRIDGE_HELPER_THUMBNAIL_SCHEDULE: 4015,

    LOG: 3999,

    SET_SETTING_BOOL: 4000,
    INITIAL_EDITOR_SETTINGS: 4001,
    SET_SETTING_COL4: 4002,

    UPDATE_ACTIONS: 4003,
    UNDO_REQUEST: 4004,
    REDO_REQUEST: 4005,

    METRICS_REQUEST: 4006,

    SPACE_LIST_SEND: 4007,
    SPACE_SEND: 4008,
    SPACE_SELECT: 4009,
    SPACE_LISTEN_REQUEST: 4010,
};
