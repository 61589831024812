import PropTypes from 'prop-types';

import {
  LOG_CLEAR,
  LOG_MESSAGE, LOG_SET_FILTER, LOG_SET_LEVEL, LOG_SET_SHOW_SYSTEM
} from '../actions/logActions';
import LogMessageTypes from '../../constants/LogMessageTypes';

const SYSTEM_CATEGORY = 'System';

export const LogMessageShape = PropTypes.shape({
  level: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired
});

const initialState = {
  messages: [],
  filteredMessages: [],
  level: LogMessageTypes.ALL,
  filter: '',
  showSystem: false
};

/**
 * Deteremines whether a message object meets all filters.
 *
 * @param messageObj - { level, message, isSystem }
 * @param _level - the result of the level reducer
 * @param _showSystem - the result of the showSystem reducer
 * @param _filter - the result of the filter reducer
 * @returns {boolean|*} - whether the message meets all filters
 */
const meetsFilters = (messageObj, { level: _level, showSystem: _showSystem, filter: _filter }) => {
  const formattedFilter = _filter.toLowerCase();
  const { level: messageLevel, message, category } = messageObj;
  const matchesFilter = formattedFilter === '' || message.toLowerCase()
    .indexOf(formattedFilter) !== -1;
  const matchesLevel = _level === LogMessageTypes.ALL || _level <= messageLevel;
  const matchesSystem = _showSystem || category !== SYSTEM_CATEGORY;

  return matchesFilter && matchesLevel && matchesSystem;
};

/**
 * Log message reducer.
 * @param action.payload - { level, message, timestamp, isSystem }
 */
const messages = (state = initialState.messages, action) => {
  switch (action.type) {
    case LOG_MESSAGE: {
      return [action.payload, ...state];
    }

    case LOG_CLEAR: {
      return [];
    }

    default: {
      return state;
    }
  }
};

/**
 * Returns only messages that meet the various filters.
 * This reducer receives the full state so it can filter appropriately.
 *
 * @param state - state.filteredMessages
 * @param action - various
 * @param fullState - state
 * @returns {any[]|Array}
 */
const filteredMessages = (state = initialState.filteredMessages, action, fullState) => {
  switch (action.type) {
    case LOG_MESSAGE: {
      const message = action.payload;
      if (meetsFilters(message, fullState)) {
        return [message, ...state];
      }

      return state;
    }

    case LOG_SET_LEVEL: {
      return fullState.messages
        .filter(_message => meetsFilters(_message, {
          ...fullState,
          level: Number(action.payload)
        }));
    }

    case LOG_SET_SHOW_SYSTEM: {
      return fullState.messages
        .filter(_message => meetsFilters(_message, {
          ...fullState,
          showSystem: action.payload
        }));
    }

    case LOG_SET_FILTER: {
      return fullState.messages
        .filter(_message => meetsFilters(_message, {
          ...fullState,
          filter: action.payload
        }));
    }

    case LOG_CLEAR: {
      return [];
    }

    default: {
      return state;
    }
  }
};

/**
 * Log level reducer.
 * @param action.payload - string debug|info|warn|error
 */
const level = (state = initialState.level, action) => {
  switch (action.type) {
    case LOG_SET_LEVEL: {
      return Number(action.payload);
    }

    default: {
      return state;
    }
  }
};

/**
 * Log system message flag. If true, shows system messages.
 * @param action.payload - bool
 */
const showSystem = (state = initialState.showSystem, action) => {
  switch (action.type) {
    case LOG_SET_SHOW_SYSTEM: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

/**
 * Log system message filter.
 * @param action.payload - string
 */
const filter = (state = initialState.filter, action) => {
  switch (action.type) {
    case LOG_SET_FILTER: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

/**
 * Replacement for combineReducers since one of our reducers is a special case.
 *
 * @param state
 * @param action
 * @returns new state
 */
export default (state = initialState, action) => ({
  messages: messages(state.messages, action),
  filteredMessages: filteredMessages(state.filteredMessages, action, state),
  level: level(state.level, action),
  filter: filter(state.filter, action),
  showSystem: showSystem(state.showSystem, action)
});
