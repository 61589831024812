// src/js/store/reducers/passwordResetReducer.js

import { INITIALIZATION } from "../../constants/AccountActionTypes";

import { UPDATE_EMAIL, SET_EMAIL } from "../../constants/AccountActionTypes";
import { VALID_EMAIL } from "../../constants/AccountActionTypes";
import { INVALID_EMAIL } from "../../constants/AccountActionTypes";

import { VALID_PASSWORD } from "../../constants/AccountActionTypes";
import { INVALID_PASSWORD } from "../../constants/AccountActionTypes";
import { MATCHING_PASSWORDS } from "../../constants/AccountActionTypes";

import { SET_TOKEN } from "../../constants/AccountActionTypes";

import { GET_TOKEN_REQUEST } from "../../constants/AccountActionTypes";
import { GET_TOKEN_SUCCESS } from "../../constants/AccountActionTypes";
import { GET_TOKEN_FAILED } from "../../constants/AccountActionTypes";

import { VALIDATE_TOKEN_REQUEST } from "../../constants/AccountActionTypes";
import { VALIDATE_TOKEN_SUCCESS } from "../../constants/AccountActionTypes";
import { VALIDATE_TOKEN_FAILED } from "../../constants/AccountActionTypes";

import { PASSWORD_RESET_REQUEST } from "../../constants/AccountActionTypes";
import { PASSWORD_RESET_SUCCESS } from "../../constants/AccountActionTypes";
import { PASSWORD_RESET_FAILED } from "../../constants/AccountActionTypes";

const initialState = {
  email: '',
  token: '',
  status: INITIALIZATION,
  statusDescription: '',
  success: false,
  error: ''
};

function requestToken(state = initialState, action) {
  switch (action.type) {
    case GET_TOKEN_REQUEST:
      state = Object.assign({}, state, {
        statusDescription: 'Loading…'
      });
      break;
    case GET_TOKEN_SUCCESS:
      state = Object.assign({}, state, {
        success: action.payload.success,
        error: action.payload.error,
        status: GET_TOKEN_SUCCESS,
        statusDescription: 'Password Reset Email Sent!'
      });
      break;
    case GET_TOKEN_FAILED:
      state = Object.assign({}, state, {
        success: action.payload.success,
        error: action.payload.error,
        status: "failed",
        statusDescription: action.payload.error
      });
      break;

    default:
      break;
  }
  return state;
}

function validateToken(state = initialState, action) {
  switch (action.type) {
    case VALIDATE_TOKEN_REQUEST:
      state = Object.assign({}, state, {
        statusDescription: ''
      });
      break;
    case VALIDATE_TOKEN_SUCCESS:
      state = Object.assign({}, state, {
        success: action.payload.success,
        error: action.payload.error,
        status: VALIDATE_TOKEN_SUCCESS,
        statusDescription: 'Please enter a new password'
      });
      break;
    case VALIDATE_TOKEN_FAILED:
      state = Object.assign({}, state, {
        success: action.payload.success,
        error: action.payload.error,
        status: VALIDATE_TOKEN_FAILED,
        statusDescription: 'Your token is bad or expired. Please try again!'
      });
    break;

    default:
    break;
  }
  return state;
}

function resetPassword(state = initialState, action) {
  switch (action.type) {
    case PASSWORD_RESET_REQUEST:
      state = Object.assign({}, state, {
        statusDescription: 'Loading…'
      });
      break;
    case PASSWORD_RESET_SUCCESS:
      state = Object.assign({}, state, {
        success: action.payload.success,
        error: action.payload.error,
        status: PASSWORD_RESET_SUCCESS,
        statusDescription: ''
      });
      break;
    case PASSWORD_RESET_FAILED:
      state = Object.assign({}, state, {
        success: action.payload.success,
        error: action.payload.error,
        status: PASSWORD_RESET_FAILED,
        statusDescription: action.payload.error
      });
    break;

    default:
    break;
  }
  return state;
}

function getFirstErrorMessage(errorMessages) {
  const { errors } = errorMessages;
  if (!errors) return [];
  return Object.values(errors)[0][0];
}

function rootReducer(state = initialState, action) {

  state = requestToken(state, action);

  state = validateToken(state, action);

  state = resetPassword(state, action);

  if (action.type === INITIALIZATION) {
    return Object.assign({}, state, {
      status: INITIALIZATION
    });
  }

  if (action.type === SET_EMAIL) {
    return Object.assign({}, state, {
      email: action.payload.email,
      status: VALID_EMAIL,
      statusDescription: ''
    });
  }

  if (action.type === VALID_EMAIL) {
    return {
      ...state,
      email: action.payload.email,
      status: VALID_EMAIL,
      statusDescription: ''
    };
  }

  if (action.type === INVALID_EMAIL) {
    return Object.assign({}, state, {
      status: INVALID_EMAIL,
      statusDescription: getFirstErrorMessage(action.payload, 'emailAddress')
    });
  }

  if (action.type === VALID_PASSWORD) {
    return Object.assign({}, state, {
      status: VALID_PASSWORD,
      statusDescription: ''
    });
  }

  if (action.type === MATCHING_PASSWORDS) {
    return Object.assign({}, state, {
      status: MATCHING_PASSWORDS,
      statusDescription: ''
    });
  }

  if (action.type === INVALID_PASSWORD) {
    return Object.assign({}, state, {
      status: INVALID_PASSWORD,
      statusDescription: getFirstErrorMessage(action.payload, 'password')
    });
  }

  if (action.type === SET_TOKEN) {
    return Object.assign({}, state, {
      status: SET_TOKEN,
      token: action.payload.token
    });
  }

  return state;
}

export default rootReducer;
