import { Box, Typography } from '@material-ui/core';
import { Check, Clear } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  name: {
    marginLeft: theme.spacing(1)
  }
}));

/**
 * Renders controls for viewing permissions.
 */
const ItemSharing = ({ shares }) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="h6">Sharing</Typography>
      {shares.map(share => (
        <Box key={share.name} display="flex" flexDirection="row" alignItems="center">
          {share.shared ? <Check /> : <Clear />}
          <Typography className={classes.name}>{share.name}</Typography>
        </Box>
      ))}
    </Box>
  );
};

ItemSharing.propTypes = {
  shares: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      shared: PropTypes.bool.isRequired
    })
  ).isRequired
};

export default ItemSharing;
