import { Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const BaseIconCheckbox = withStyles(theme => ({
  root: {
    color: theme.palette.grayscale.darkerGray,
    '&$checked': {
      color: theme.palette.grayscale.lighterGray
    },
    '&:hover': {
      color: theme.palette.grayscale.darkerGray,
      '&$checked': {
        color: theme.palette.grayscale.lighterGray
      }
    }
  },
  checked: {}
}))(Checkbox);

export default BaseIconCheckbox;
