import { Alert } from 'react-bootstrap';
import { AsyncStatus, getActions } from '@enklu/server-api';
import {
 Box, CircularProgress, Link, TextField, Typography
} from '@material-ui/core';
import { CloudDownload, Delete } from '@material-ui/icons';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';

import {
 KEY_SPACE_ID, loadSpaceJson, spaceIdFromRoot, meshUrl
} from './spaceUtils';
import { createSchemaUpdate } from '../common/inspectorUtils';
import { downloadUrl, formatTimestamp } from '../../../util/util';
import { elementsByType } from '../../../util/elementHelpers';
import { newProgressShape } from '../../../interfaces/ProgressShape';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import BackButton from '../../material-ui/BackButton';
import BaseButton from '../../material-ui/BaseButton';
import ConfirmationModal from '../../modals/MuiConfirmationModal';
import CopyableText from '../../common/CopyableText';
import ElementTypes from '../../../constants/ElementTypes';
import LoadedItemList from '../../common/MuiLoadedItemList';
import MessageTypes from '../../../constants/MessageTypes';
import spaceSocketManager from '../../../store/actions/SpaceSocketManager';

const { deletespace, findspaces, updatespace } = getActions('trellis');

const SPACE_LINK = 'space.link';
const SPACE_UNLINK = 'space.unlink';
const HELP_DOCS_URL = 'https://enklu.notion.site/Creating-a-Space-3b6bdf16a94c41dd8fb71e883dfa65be';

const useStyles = makeStyles(theme => ({
  itemList: {
    flex: 1,
    width: '100%',
    maxHeight: 500,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
}));

const SpacesInspector = ({
  onBack: onBackHandle,
  onUpdateElement,
  root,

  // Store
  spaces,
  user,

  // Dispatch
  deleteSpace,
  renameSpace
}) => {
  const classes = useStyles();

  // const spaces = [
  //   {
  //     name: 'test',
  //     id: 1,
  //     createdAt: '2020-01-20',
  //     updatedAt: '2020-01-20'
  //   },
  //   {
  //     name: 'test2',
  //     id: 2,
  //     createdAt: '2020-01-20',
  //     updatedAt: '2020-01-20'
  //   }
  // ];

  const defaultSpaceId = spaceIdFromRoot(root);
  const initialDefaultSpace = spaces.find(space => space.id === defaultSpaceId);

  const initialProgress = newProgressShape();
  initialProgress.status = AsyncStatus.SUCCESS;

  const [progress] = React.useState(initialProgress);
  const [defaultSpace] = React.useState(initialDefaultSpace);
  const [selectedSpace, setSelectedSpace] = React.useState(initialDefaultSpace);
  const [displayedSpaceName, setDisplayedSpaceName] = React.useState('');
  const [downloadWarning, setDownloadWarning] = React.useState(false);
  const [deleteWarning, setDeleteWarning] = React.useState(false);
  const [unlinkWarning, setUnlinkWarning] = React.useState(false);
  const [warnedSpace, setWarnedSpace] = React.useState(null);
  const [busy, setBusy] = React.useState(false);

  const { name = '', id: selectedId = '' } = selectedSpace || {};
  const { profile: { id: userId = '' } = {} } = user || {};

  const forceLoadingState = (delay) => {
    setBusy(true);

    let timeout = null;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setBusy(false);
    }, delay);
  };

  /**
   * Render while the list is loading.
   */
  const renderLoading = () => busy && (
      <Box
        zIndex={1}
        position="absolute"
        height="100%"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={40} />
      </Box>
    );

  /**
   * Deletes a space
   */
  const onDelete = async () => {
    const shouldClearSpace = selectedSpace && selectedSpace.id === warnedSpace.id;

    setSelectedSpace(shouldClearSpace ? null : selectedSpace);
    setDeleteWarning(false);
    setBusy(true);

    if (shouldClearSpace) {
      onUpdateElement(
        createSchemaUpdate({
          type: ActionSchemaTypes.STRING,
          name: KEY_SPACE_ID,
          value: ''
        })
      );
    }

    await deleteSpace({ spaceId: warnedSpace.id });

    setBusy(false);
  };

  /**
   * Displays to confirm space deletion.
   * @returns {boolean|*}
   */
  const renderDeleteWarning = () => (
    <ConfirmationModal
      open={deleteWarning}
      title="Delete"
      message={`Are you sure you want to permanently delete '${warnedSpace ? warnedSpace.name : ''}'?`}
      onClose={() => setDeleteWarning(false)}
      onConfirm={onDelete}
      confirmIsDanger
    />
  );

  /**
   * Displays if there is an issue with downloading a mesh.
   */
  const renderDownloadWarning = () => (
    <ConfirmationModal
      open={downloadWarning}
      title="Download Unavailable"
      message={'The mesh scan for this space is currently unavailable.'}
      onClose={() => setDownloadWarning(false)}
      onConfirm={() => setDownloadWarning(false)}
    />
  );

  /**
   * Unlinks the current space from this experience.
   */
  const unlinkSpace = () => {
    onUpdateElement(
      createSchemaUpdate({
        type: ActionSchemaTypes.STRING,
        name: KEY_SPACE_ID,
        value: ''
      })
    );

    spaceSocketManager.disconnect();

    if (window.mixpanel) {
      window.mixpanel.track(SPACE_UNLINK, {
        spaceId: selectedSpace.id,
        appId: root.id
      });
    }

    onBackHandle();
  };

  /**
   * Displays a warning confirmation before unlinking a space.
   * @returns {boolean|*}
   */
  const renderUnlinkWarning = () => (
    <ConfirmationModal
      open={unlinkWarning}
      title="Unlink Space"
      message={'This will unload the current space for all active sessions. Are you sure?'}
      onClose={() => setUnlinkWarning(false)}
      onConfirm={() => {
        setUnlinkWarning(false);
        unlinkSpace();
      }}
      confirmIsDanger
    />
  );

  /**
   *  Cancel - cleanup player and leave
   */
  const onBack = () => {
    window.bridge.send(MessageTypes.SPACE_SELECT, {
      id: (defaultSpace && defaultSpace.id) || ''
    });
    onBackHandle();
  };

  /**
   * Previews a space. Forwards id change to the player
   * @param id
   */
  const onPreview = (space) => {
    setSelectedSpace(space);
    setDisplayedSpaceName(space.name);
    window.bridge.send(MessageTypes.SPACE_SELECT, { id: space.id });
  };

  /**
   * Links a new space to this experience.
   */
  const linkSpace = () => {
    onUpdateElement(
      createSchemaUpdate({
        type: ActionSchemaTypes.STRING,
        name: KEY_SPACE_ID,
        value: selectedSpace.id
      })
    );

    spaceSocketManager.connect(selectedSpace.id);

    if (window.mixpanel) {
      window.mixpanel.track(SPACE_LINK, {
        spaceId: selectedSpace.id,
        appId: root.id
      });
    }

    onBackHandle();
  };

  /**
   * Attempts to download a mesh for a space.
   */
  const onDownloadMesh = async (space) => {
    const spaceRoot = await loadSpaceJson(space);
    const mesh = (elementsByType(spaceRoot, ElementTypes.SCAN) || [])[0];
    const { schema: { strings: { srcUrl = '' } = {} } = {} } = mesh;

    if (!srcUrl) {
      setDownloadWarning(true);
      return;
    }

    downloadUrl(meshUrl(srcUrl), `${space.name}.mesh`);
  };

  /**
   * Rename a space
   */
  const onRename = (inputName) => {
    // Don't allow null or empty name to be used
    if (!inputName) return;

    // Send update to Trellis
    renameSpace(selectedSpace.id, inputName);
    forceLoadingState(500);

    // TODO: It would be awesome if we could figure out a solution for onChange.
    // TODO: This ends up tripping over itself and getting prev state and next state mixed up.
    // const inputField = document.getElementById(inputId);
    // let timeout = null;

    // inputField.addEventListener('keyup', (e) => {
    //   // Clear the timeout if it has already been set to prevent execution
    //   // if it has been less than 300ms
    //   clearTimeout(timeout);
    //
    //   // Ony update space name after the user has stopped typing for 300ms
    //   timeout = setTimeout(() => {
    //     const newName = e.target.value;
    //
    //     // Don't allow null or empty name to be used
    //     if (!newName) return;
    //
    //     const space = selectedSpace;
    //     space.name = newName;
    //     // Send update to Trellis
    //     renameSpace(space.id, newName);
    //     forceLoadingState(500);
    //   }, 300);
    // });
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" position="relative">
      {renderLoading()}
      <Box mb={2}>
        <BackButton onBack={onBack} />
      </Box>

      {spaces.length > 0 && (
        <React.Fragment>
          <Typography>Space</Typography>
          <LoadedItemList
            customClass={classes.itemList}
            items={spaces.map(space => ({
              ...space,
              displayName: space.name,
              icon: space.owner === userId ? '' : 'lock'
            }))}
            onSelect={space => onPreview(space)}
            selectedItemId={selectedId}
            progress={progress}
            actions={[
              {
                icon: CloudDownload,
                onAction: space => onDownloadMesh(space)
              },
              {
                icon: Delete,
                onAction: (space) => {
                  setWarnedSpace(space);
                  setDeleteWarning(true);
                },
                showAction: space => space.owner === userId
              }
            ]}
          />

          <TextField
            id="space-name"
            label="Name"
            disabled={selectedId === ''}
            placeholder={displayedSpaceName}
            fullWidth
            // TODO: add either a confirm button or figure out how to use onChange better
            // onChange={evt => onRename(evt.target.id)}
            onBlur={(evt) => {
              // Unclear why we can't use nullish coalescing (evt.target.value ?? displayedSpaceName)
              // ...because we need to update react version maybe?
              onRename(evt.target.value);
              setDisplayedSpaceName(evt.target.value ? evt.target.value : displayedSpaceName);

              // ESLint says not to do this, but this appears to be the easiest way to ensure the
              // correct space name is shown in the field when the user isn't actively using it.
              evt.target.value = '';
            }}
            variant="outlined"
          />

          <Box my={1}>
            <ul>
              <li>
                <Typography variant="caption">{`Created On: ${
                  selectedSpace
                    ? formatTimestamp(selectedSpace.createdAt, {
                        includeDate: true
                      })
                    : ''
                }`}</Typography>
              </li>
              <li>
                <Typography variant="caption">{`Updated On: ${
                  selectedSpace
                    ? formatTimestamp(selectedSpace.updatedAt, {
                        includeDate: true
                      })
                    : ''
                }`}</Typography>
              </li>

              <li>
                <Typography variant="caption">
                  ID: {selectedSpace ? <CopyableText text={selectedSpace.id} /> : ''}
                </Typography>
              </li>
            </ul>
          </Box>

          {defaultSpace && defaultSpace.owner !== userId && (
            <Alert elevation={0} severity="error">
              <Typography variant="h6">
                <strong>{"You don't own the currently assigned Space."}</strong>
                <br />
                Once a Space has been removed, it can only be activated again by its owner.
              </Typography>
            </Alert>
          )}

          <Box alignSelf="center">
            <BaseButton
              color="primary"
              size="large"
              variant="contained"
              onClick={() => {
                const linked = defaultSpace && selectedSpace.id === defaultSpace.id;
                if (linked) {
                  setUnlinkWarning(true);
                } else {
                  linkSpace();
                }
              }}
              disabled={!selectedSpace}
            >
              {defaultSpace && defaultSpace.id === selectedId ? 'Unassign Space' : 'Use Space'}
            </BaseButton>
          </Box>
          <Typography style={{marginTop: '2em'}}>
            More information about spaces can be found on our{' '}
            <Link href={HELP_DOCS_URL} target="_blank" rel="noopener noreferrer" color="primary" style={{textDecoration:'underline'}}>
              help docs
            </Link>
            .
          </Typography>
        </React.Fragment>
      )}
      {spaces && spaces.length === 0 && (
        <React.Fragment>
          <Typography>
            {"You don't have any spaces available."}
            <br />
            <br />
            Spaces scanned from a HoloLens will appear here.
            <br />
            <br />
            More information about spaces can be found on our{' '}
            <Link href={HELP_DOCS_URL} target="_blank" rel="noopener noreferrer" color="primary" style={{textDecoration:'underline'}}>
              help docs
            </Link>
            .
          </Typography>
        </React.Fragment>
      )}

      {renderDeleteWarning()}
      {renderDownloadWarning()}
      {renderUnlinkWarning()}
    </Box>
  );
};

SpacesInspector.propTypes = {
  // Upstream
  onBack: PropTypes.func.isRequired,
  onUpdateElement: PropTypes.func.isRequired,
  root: PropTypes.object.isRequired,

  // Store
  spaces: PropTypes.array,
  user: PropTypes.object,

  // Dispatch
  deleteSpace: PropTypes.func,
  renameSpace: PropTypes.func
};

const mapStateToProps = state => ({
  spaces: state.spaces.all,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  deleteSpace: ({ spaceId }) => dispatch(deletespace({ spaceId })).then(() => dispatch(findspaces())),
  renameSpace: (spaceId, name) => dispatch(updatespace({ name }, { spaceId })).then(() => dispatch(findspaces()))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpacesInspector));
