import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isMobile } from 'react-device-detect';
import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import BaseButton from '../components/material-ui/BaseButton';
import { getActions } from '@enklu/server-api';

import { muiPalette } from '../styles/muiTheme';
import { resetrequests } from '../store/actions/initializeActions';
import { getuserpreferences } from '../store/actions/preferencesActions';
import * as EmailValidator from 'email-validator';
import { setEmail, getTokenAction} from '../store/actions/credentialActions';

const { emailsignin } = getActions('trellis');

const style = {
  headerContainer: {
    paddingTop: '2em',
    marginBottom: '3em'
  },
  headerLeft:{
    paddingLeft: '48px',
    textAlign: 'left'
  },
  headerRight: {
    paddingRight: '48px',
    textAlign: 'right'
  },
  headerLink: {
    color: muiPalette.common.white,
    cursor: 'pointer',
    verticalAlign: 'middle'
  },
  imageCorner: {
    height: '80px',
    paddingRight: '48px',
    position: 'absolute',
    right: '0'
  },
  imageCenter: {
    width: '100px',
    paddingBottom: '2em',
    margin: 'auto'
  },
  colCentered: {
    float: 'none',
    margin: '0 auto'
  },
  textContainer: {
    padding: '0 0 3em'
  }
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%'
  },
  shadowContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '36px',
    boxShadow: `5px 5px 35px 15px ${theme.palette.common.boxShadow}38`
  },
  welcome: {
    fontSize: '2.5em'
  },
  input: {
    '& > *': {
      marginBottom: theme.spacing(3)
    }
  },
  buttonPadding: {
    fontWeight: 'bold'
  }
}));

/**
 * Password Reset component
 * Asks user for email and sends it to trellis for a reset token email.
 */
export const RequestPasswordReset = (props) => {
  const [email, setEmail] = useState(''); 
  const [resetEmailSent, setResetEmailSent] = useState(false); 

  const {
    requestTokenEmail,
    history
  } = props;  
  
  const classes = useStyles();  
  const isValidEmail = EmailValidator.validate(email);
  
  const {
    headerContainer,
    headerLeft,
    headerRight,
    headerLink,
    imageCorner,
    imageCenter,
    colCentered,
    textContainer
  } = style;

  const {
    wrapper,
    shadowContainer,
    input,
    buttonPadding
  } = classes;

  const isDesktop = useMediaQuery('(min-width:600px)'); 
  const containerClass = isDesktop ? shadowContainer : null;
  const linkVariant = isDesktop ? 'body2' : 'subtitle1';
  const checkResetEmailSent = resetEmailSent ? emailSentConfirmation() : askUserForEmail();

  return (
    <Grid container direction="row" justify="center" alignItems="center" className={wrapper}>
      <Grid style={headerContainer} container>
        <Grid style={headerLeft} item xs={6}>
          <Link variant={linkVariant} style={headerLink}
            href='/#/signin'>
            <ArrowBackIosIcon fontSize="large">Filled</ArrowBackIosIcon>
          </Link>
          <Link variant={linkVariant}  
            href='/#/signin'>
            Back to sign in
          </Link>
        </Grid>
        <Grid style={headerRight} item xs={1}>
          <img src='/assets/img/Logo.Medium.png' style={imageCorner}/>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={8} md={6} lg={5} xl={4} className={containerClass} style={{marginBottom: 'auto'}}>
        <Grid container justify='space-between'>
          {checkResetEmailSent}
        </Grid>
      </Grid>
    </Grid>
  );

  /**
  * Renders email input form
  */
  function askUserForEmail() {
    return (
      <Grid item xs={10} sm={9} md={8} lg={6} style={colCentered}>
        <Typography variant='h3' style={{textAlign: 'center', marginTop:'2em'}}>
          Forgot your password?
        </Typography>
        <form>
          <div className={input}>
            <TextField
              id="email"
              type='email'
              label='EMAIL'
              autoComplete='username email'
              variant="outlined"
              fullWidth
              autoFocus
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}/>
          </div>
          <Box py={5} style={{textAlign: 'center'}}>
            <BaseButton
              variant='contained'
              disableElevation
              color='primary'
              type='submit'
              className={buttonPadding}
              size='large'
              disabled={!isValidEmail}
              onClick={(event) => {
                event.preventDefault();
                requestTokenEmail({ email });
                setResetEmailSent(true);
              }}
            >RESET PASSWORD
            </BaseButton>
          </Box>
        </form>
      </Grid>
    );
  }

  function emailSentConfirmation() {
    return (
      <Grid item xs={10} sm={9} md={8} lg={6} style={colCentered}>
        <Typography variant='h3' style={{textAlign: 'center', marginTop:'2em'}}>Email Sent!</Typography>
        <Grid item xs={5} md={4} style={colCentered}>
          <CardMedia
            component='img'
            image='/assets/img/emailsent.svg'
            style={imageCenter}
          />
        </Grid>
        <Grid container style={textContainer}>
          <Grid item xs={12} style={{textAlign: 'center', margin: '1em 0'}}>
            <Typography variant='h5' style={{textAlign: 'center'}}>Instructions to reset your password have been sent to the email provided. You should receive this email within the next 5 minutes.</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}



RequestPasswordReset.propTypes = {
  // withRouter
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  credentials: state.user.credentials,
  requestError: state.user.requestError,
});

const mapDispatchToProps = dispatch => ({
  requestTokenEmail: ({ email }) => {
    dispatch(getTokenAction({ email }));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestPasswordReset));
