import {
  PRESENCELIST,
  PRESENCEJOINED,
  PRESENCELEFT
} from '../actions/presenceActions';

import { newPresence } from '../../interfaces/PresenceShape';

export default function presence(state = [], action) {
  switch (action.type) {
    // full list
    case PRESENCELIST: {
      return action.users.map(presence => {
        const obj = newPresence({
          socketId: presence.socketId,
          userId: presence.userId,
          name: presence.name,
          deviceInfo: presence.deviceInfo
        });
        return obj;
      });
    }

    // someone new joined
    case PRESENCEJOINED: {
      const { user: presence } = action;

      for (let i = 0, len = state.length; i < len; i += 1) {
        if (presence.socketId === state[i].socketId) {
          return state;
        }
      }

      return state.concat(newPresence({
        socketId: presence.socketId,
        userId: presence.userId,
        name: presence.name,
        deviceInfo: presence.deviceInfo
      }));
    }

    // someone left
    case PRESENCELEFT: {
      const cpy = [].concat(state);

      for (let i = 0, len = cpy.length; i < len; i += 1) {
        if (cpy[i].socketId === action.socketId) {
          cpy.splice(i, 1);
          break;
        }
      }

      return cpy;
    }
  }

  return state;
}
