import { DropTarget } from 'react-dnd';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  emptySpace: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
    display: 'flex',
    position: 'relative',
    height: '100%',
    width: '100%',
    zIndex: '1'
  }
}));
/*
 * The Eleent Hierarchy Empty Space class handles the detection of dropping on itself.
 * It is intended to represent the empty space below the element hierarchy. It allow users to directly
 * drag an asset from the library, drop it on the empty space, then create a new element with the dropped asset.
 */
const ElementHierarchyEmptySpace = ({ connectDropTarget, children }) => {
  const classes = useStyles();
  return connectDropTarget(
    <div className={classes.emptySpace}
      // Without this, Firefox tries to do a redirect
      onDrop={(event) => {
       event.preventDefault();
      }}
    >
      {children}
    </div>
  );
};

const target = {
  canDrop(props, monitor) {
    return true;
  },

  // eslint-disable-next-line no-unused-vars
  hover(props, monitor, component) {
    // TODO: Add UI feedback
  },

  // eslint-disable-next-line no-unused-vars
  drop(props, monitor, component) {
    props.onDrop(monitor.getItem().item, monitor.getItem().type);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}

ElementHierarchyEmptySpace.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  item: PropTypes.object,
  onDrop: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default DropTarget('item', target, collect)(ElementHierarchyEmptySpace);
