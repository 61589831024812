import {
 Dialog, DialogContent, DialogContentText, Paper, Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';

import { getAppInfo } from '../../store/selectors/appSelectors';
import CollaboratorsDisplay from '../collaborators/MuiCollaboratorsDisplayNew';
import PublishExperienceModal from '../collaborators/PublishExperienceModal';
import ModalHeader from '../material-ui/ModalHeader';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: 700
  },
  bottomPaper: {
    marginTop: theme.spacing(4)
  }
}));

const InviteCollaboratorsModal = ({ app: { id }, onClose, shareable }) => {
  const classes = useStyles();
  return (
    <Dialog
      maxWidth={false}
      PaperComponent={'div'}
      onClose={onClose}
      open={true}
      PaperProps={{ className: classes.dialog }}
    >
      <Paper>
        <CollaboratorsDisplay appId={id} />
      </Paper>
      {shareable && (
        <Paper class={classes.bottomPaper}>
          <ModalHeader title="Share" />
          <DialogContent>
            <DialogContentText>
              <Typography variant="h5">Invite people to play your experience</Typography>
            </DialogContentText>
          </DialogContent>
        </Paper>
      )}
      <PublishExperienceModal appId={id} />
    </Dialog>
  );
};

InviteCollaboratorsModal.propTypes = {
  style: PropTypes.object,
  app: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  shareable: PropTypes.bool
};

InviteCollaboratorsModal.defaultProps = {
  style: {}
};

const mapStateToProps = state => ({
  app: getAppInfo(state)
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InviteCollaboratorsModal));
