import { Box, Typography } from '@material-ui/core';
import React from 'react';

/**
 * Displays full page NOT SUPPORTED.
 */
const NotSupported = () => (
  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
    <Box mt={4} mb={2}>
      <Typography variant="h3">Your browser is unsupported.</Typography>
      <Typography>
        While we try to support every brower, we can’t support yours. To use Enklu, a browser that supports WebGL is
        required. Please try Firefox, Chrome, Safari, or Edge.
      </Typography>
    </Box>
  </Box>
);

export default NotSupported;
