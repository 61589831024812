const ModalTypes = {
  NEW: 'modal.new',
  OPEN: 'modal.open',
  SHARE: 'modal.share',
  HOLOLENS_LOGIN: 'modal.hololensLogin',
  MANAGE_MY_ACCOUNT: 'modal.manageMyAccount',
  PREFERENCES: 'modal.preferences',
  INTRO: 'modal.intro'
};

const ModalParams = {};

export const getParams = type => ModalParams[type] || {};

export default ModalTypes;
