import { log } from '../../util/log';
import { SET_SETTING } from '../actions/settingsActions';
import { GET_LOCAL_BLOB } from '../actions/initializeActions';
import MessageTypes from '../../constants/MessageTypes';
import ActionSchemaTypes from '../../constants/ActionSchemaTypes';

export const initialGeneralSettings = [
  {
    displayName: 'Use Embedded Play Mode',
    name: 'EmbeddedPlayMode',
    type: ActionSchemaTypes.BOOL,
    key: 'GENERATED',
    defaultValue: false,
    value: false
  }
]

export const initialRenderSettings = [
  {
    displayName: 'Mesh Scan',
    name: 'MeshScan',
    type: ActionSchemaTypes.BOOL,
    key: 'GENERATED',
    defaultValue: true,
    value: true
  },
  {
    displayName: 'Grid',
    name: 'Grid',
    type: ActionSchemaTypes.BOOL,
    key: 'GENERATED',
    defaultValue: true,
    value: true
  },
  {
    displayName: 'Element Gizmos',
    name: 'ElementGizmos',
    type: ActionSchemaTypes.BOOL,
    key: 'GENERATED',
    defaultValue: true,
    value: true
  },
  {
    displayName: 'Background Color',
    name: 'BackgroundColor',
    type: ActionSchemaTypes.COL4,
    key: 'GENERATED',
    defaultValue: {
      r: 0.05490196,
      g: 0.05490196,
      b: 0.09019608
    },
    value: {
      r: 0.05490196,
      g: 0.05490196,
      b: 0.09019608
    }
  },
  {
    displayName: 'Debug Mode',
    name: 'DebugMode',
    type: ActionSchemaTypes.BOOL,
    key: 'GENERATED',
    defaultValue: false,
    value: false
  }
];

export const initialState = {
  name: 'global',
  displayName: 'Global Settings',
  isLoaded: false,
  settings: [
    {
      name: 'render',
      displayName: 'Render Settings',
      settings: [...initialRenderSettings]
    },
    {
      name: 'general',
      displayName: 'General Settings',
      settings: [...initialGeneralSettings]
    }
  ]
};

const cloneCategory = category => ({
  ...category,
  settings: [...category.settings]
});

const getSettingMessageType = ({ type }) => {
  if (type === ActionSchemaTypes.COL4) {
    return MessageTypes.SET_SETTING_COL4;
  }

  return MessageTypes.SET_SETTING_BOOL;
};

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case GET_LOCAL_BLOB: {
      // TODO This should be in an action.
      const strValue = window.localStorage.getItem(`__global__`);
      if (strValue) {
        // TODO Consider whether we want the blob to be parsed multiple times by various reducers.
        const blob = JSON.parse(strValue);
        if (blob.version === window.config.localDataVersion) {
          // override reducers
          const { globalSettings: savedState = initialState } = blob;
          return {
            ...savedState,
            isLoaded: true
          };
        }
      }

      return {
        ...state,
        isLoaded: true
      };
    }

    case SET_SETTING: {
      const {
        category: categoryName,
        setting: { name },
        value
      } = action;
      const categoryIndex = state.settings.findIndex(candidate => candidate.name === categoryName);
      if (categoryIndex === -1) {
        log.error(`${type}: Invalid settings category '${categoryName}'.`);
        return state;
      }

      const category = state.settings[categoryIndex];
      const settingIndex = category.settings.findIndex(candidate => candidate.name === name);
      if (settingIndex === -1) {
        log.error(`${type}: Invalid setting name '${name}' in existing category '${categoryName}'.`);
        return state;
      }

      const setting = category.settings[settingIndex];
      window.bridge.send(getSettingMessageType(setting), {
        category: categoryName,
        name,
        value
      });

      const newState = {
        ...state,
        settings: [...state.settings]
      };
      const newCategory = cloneCategory(category);

      newCategory.settings[settingIndex] = {
        ...newCategory.settings[settingIndex],
        value
      };
      newState.settings[categoryIndex] = newCategory;

      return newState;
    }

    default: {
      return state;
    }
  }
};
