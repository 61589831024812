import { Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

// size: 'large', 'medium', 'small'
// color: 'primary', 'secondary', 'inherit'

const BaseAvatar = (
  {
 size, color, classes, children, className, ...otherProps
} = {
    size: 'large',
    color: 'primary'
  }
) => (
  <Avatar {...otherProps} className={clsx(classes.avatar, className)}>
    {children}
  </Avatar>
);

BaseAvatar.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  className: PropTypes.string
};

BaseAvatar.defaultProps = {
  size: 'large',
  color: 'primary'
};

const sizeToPixelMap = {
  large: 48,
  medium: 32,
  small: 24
};

export default withStyles(theme => ({
  badge: {
    border: 0
  },
  avatar: {
    backgroundColor: (props) => {
      if (props.color === 'primary' || props.color === 'secondary') {
        return theme.palette[props.color].main;
      }
      return props.color;
    },
    width: props => (props.className ? undefined : sizeToPixelMap[props.size]),
    height: props => (props.className ? undefined : sizeToPixelMap[props.size]),
    fontSize: props => (props.className ? undefined : sizeToPixelMap[props.size] / 2)
  }
}))(BaseAvatar);
