import {
 FormControl, InputLabel, MenuItem, OutlinedInput, Select
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const VersionSelector = (props) => {
  const { value, max, onChange } = props;

  const options = [
    <MenuItem key={'latest'} value={'latest'}>
      Latest
    </MenuItem>
  ];

  for (let i = max; i >= 0; i -= 1) {
    options.push(
      <MenuItem key={i} value={i}>
        {i === max ? `${i} (current)` : `${i}`}
      </MenuItem>
    );
  }

  return (
    <FormControl fullWidth>
      <InputLabel variant="outlined">Version</InputLabel>
      <Select
        input={<OutlinedInput />}
        value={value === -1 ? 'latest' : value}
        onChange={evt => onChange(evt.target.value)}
        variant="outlined"
      >
        {options}
      </Select>
    </FormControl>
  );
};

VersionSelector.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default VersionSelector;
