import { getActions } from '@enklu/server-api';
import {
 Box, Paper, Typography, isWidthDown, withWidth
} from '@material-ui/core';
import { connect } from 'react-redux';
import { find, orderBy } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';

import {
  initializeUntitledApp,
  initializeFromTemplate
} from '../store/actions/initializeActions';
import { getMyTemplates } from '../store/selectors/userSelectors';
import AddNew from '../../assets/icons/addnew.svg';
import MenuButton from '../components/material-ui/MenuButton';
import MyExperiencesBar from '../features/menus/MyExperiencesBar';
import MyExperiencesGrid from '../components/my-experiences/MyExperiencesGrid';
import SortBy from '../../assets/icons/sortby.svg';
import SvgFileIcon from '../components/material-ui/SvgFileIcon';
import TemplateCarousel from '../components/my-experiences/TemplateCarousel';

const { searchpublishedapps } = getActions('trellis');

const useStyles = makeStyles(theme => ({
  icon: {
    height: 80,
    width: 80
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  sortByIcon: {
    height: 25,
    width: 25
  },
  bottomWrapper: {
    flex: 1,
    display: 'flex',
    overflow: 'auto',
    paddingBottom: theme.spacing(2)
  }
}));

const sortByOptions = [
  { title: 'Recent', eventKey: 'updatedAt' },
  { title: 'Name', eventKey: 'name' }
];

const MyExperiences = ({
  width,
  history,
  templates,
  onInitializeUntitledApp,
  getTemplates,
  duplicateTemplate
}) => {
  const classes = useStyles();

  const sortedTemplates = orderBy(
        templates,
        [template => template.description.toLowerCase()]
  );

  React.useEffect(() => {
    getTemplates();
  }, []);

  const allTemplates = [
    {
      name: 'Blank',
      onClick: () => {
        onInitializeUntitledApp({ history });
      },
      icon: <SvgFileIcon className={classes.icon} svgFile={AddNew} />
    }, ...sortedTemplates.map((template) => {
      /*
       * TODO: Properly grab experience thumbnails for templates.
       * Hard coding this part for now, sorry
       * Eventually should try to make use of getThumb(thumbId) to get the latest thumbnail for any template
       */
      let thumbUrl = 'assets/img/Logo.White.png';
      switch (template.id) {
        // Chapter 1
        case '127c6729-28cc-41fe-ba44-59ad5ee430c9':
          thumbUrl = 'https://s3-us-west-2.amazonaws.com/cloud.thumbs/bbd13a8c-3e7f-48e9-8ef7-33b698887f18.png';
          break;
        // Chapter 2
        case 'ee60300d-87ef-423c-90d6-14d1e62d24ca':
          thumbUrl = 'https://s3-us-west-2.amazonaws.com/cloud.thumbs/431b8d6d-1bf6-43c9-9a46-9b21ed498122.png';
          break;
        // Chapter 3
        case 'e2b353fe-0520-4713-b5ef-1395a3849c10':
          thumbUrl = 'https://s3-us-west-2.amazonaws.com/cloud.thumbs/2e608364-6bc7-4cca-97f6-780483c5117f.png';
          break;
        // Chapter 4
        case '8d30e121-d037-453e-8204-1f8594ddbf1e':
          thumbUrl = 'https://s3-us-west-2.amazonaws.com/cloud.thumbs/0dcd3e43-a74c-44ed-a879-0da9c35ee913.png';
          break;
        default:
      }
      return {
        name: template.name,
        onClick: () => duplicateTemplate({ template }, { history }),
        thumb: thumbUrl,
        icon: null
      };
    })
  ];

  const getPerSlide = () => {
    let perSlide;
    if (isWidthDown('sm', width)) {
      perSlide = 2;
    } else if (isWidthDown('md', width)) {
      perSlide = 4;
    } else {
      perSlide = 6;
    }
    return perSlide;
  };

  const [filterValue, setFilterValue] = React.useState('');
  const [sortByValue, setSortByValue] = React.useState('updatedAt');

  return (
    <Box display="flex" flexDirection="column" height={'100%'}>
      <MyExperiencesBar
        filterValue={filterValue}
        onFilterChange={(event) => {
          setFilterValue(event.target.value);
        }}
      />
      <Box px={16} py={2}>
        <Typography variant="h6" gutterBottom>
          Create a New Experience
        </Typography>
        <TemplateCarousel perSlide={getPerSlide()} templates={allTemplates} />
      </Box>
      <Paper className={classes.bottomWrapper}>
        <Box className="scrollable-y" px={16} flex={1} display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" py={1}>
            <Typography variant="h6" gutterBottom>
              Recent Experiences
            </Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
              <MenuButton
                onItemClick={({ eventKey }) => {
                  setSortByValue(eventKey);
                }}
                items={sortByOptions}
                size="large"
                startIcon={<SvgFileIcon svgFile={SortBy} className={classes.sortByIcon} />}
              >
                {find(sortByOptions, option => option.eventKey === sortByValue).title}
              </MenuButton>
            </Box>
          </Box>
          <MyExperiencesGrid filterValue={filterValue} sortByValue={sortByValue} />
        </Box>
      </Paper>
    </Box>
  );
};

MyExperiences.propTypes = {
  templates: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  onInitializeUntitledApp: PropTypes.func.isRequired,
  getTemplates: PropTypes.func.isRequired,
  thumb: PropTypes.object,
  getThumb: PropTypes.func,
  duplicateTemplate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  templates: getMyTemplates(state)
});

const mapDispatchToProps = dispatch => ({
  onInitializeUntitledApp: ({ history: appHistory }) => dispatch(initializeUntitledApp({ history: appHistory })),
  getTemplates: () => dispatch(searchpublishedapps({ access: 'template' })),
  duplicateTemplate: ({ template }, { history }) => dispatch(
    initializeFromTemplate({ template }, { history })
  )
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withWidth()(MyExperiences)));
