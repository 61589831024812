// SELECTORS
import { initialState } from '../reducers/uiReducer';
import { createSelector } from 'reselect';
import DrawerModes from '../../components/drawer/DrawerModes';

// Library
export const getUI = ({ ui }) => ui;
export const getUsageFilter = state => state.ui.library.usageFilter;
export const getFilterIsChanged = state => Object.entries(state.ui.library)
  .find(([key, value]) => initialState.library[key] !== value) !== undefined;

// Resizing
export const getSizing = createSelector(getUI, ({ sizing }) => sizing);
export const getUIIsResizing = createSelector(getSizing, ({ isResizing }) => isResizing);
export const getUIOffsets = createSelector(getSizing, ({ offsets }) => offsets);
export const getScreenSize = createSelector(getSizing, ({ screen }) => screen);

// Deprecated in Library component. Dependency of assetLibrariesSelectors.
export const getSelectedAssetCategoryName = state => state.ui.library.selectedCategoryName[DrawerModes.ASSETS];
export const getSelectedScriptCategoryName = state => state.ui.library.selectedCategoryName[DrawerModes.SCRIPTS];
