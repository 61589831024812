import { Alert } from '@material-ui/lab';
import {
  Box,
  Button,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  OutlinedInput,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { connect } from 'react-redux';
import { getActions } from '@enklu/server-api';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { getuserpreferences } from '../store/actions/preferencesActions';
import { resetrequests } from '../store/actions/initializeActions';
import BaseButton from '../components/material-ui/BaseButton';
import SigninHeader from '../components/common/SigninHeader';

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%'
  },
  wrapperInner: {
    height: '0%'
  },
  welcome: {
    fontSize: '4em',
    fontWeight: 'bold'
  },
  welcomeSubtitle: {
    fontSize: '2em',
    fontWeight: 'lighter'
  },
  image: {
    height: '100px',
    width: '100px',
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7)
  },
  input: {
    '& > *': {
      marginBottom: theme.spacing(0)
    }
  },
  styledInput: {
    m: 1,
    width: '100%',
    minHeight: '4px'
  },
  inputField: {
    minHeight:'4px',
    fontSize: 14
  },
  styledLabel: {
    fontSize: '1em',
    paddingBottom: theme.spacing(0.5)
  },
  buttonPadding: {
    height:'2em',
    width: '7em',
    fontSize: '2em',
    fontWeight: 'bold'
  },
  footerContainer: {
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  footerLeft: {
    paddingLeft: theme.spacing(6),
    textAlign: 'left'
  },
  footerRight: {
    paddingRight: theme.spacing(6),
    textAlign: 'right'
  }
}));

const { emailsignin } = getActions('trellis');

/**
 * Sign in page.
 */
export const Signin = (props) => {
  const isDesktop = useMediaQuery('(min-width:600px)');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [localError, setLocalError] = useState('');
  const {
    history, location, resetRequests, requestError, signIn
  } = props;

  const classes = useStyles();

  useEffect(() => {
    if (window.autoLogin) {
      signIn(
        {
          email: window.autoLogin.email,
          password: window.autoLogin.password
        },
        {
          history,
          location
        }
      );
    }

    if (requestError) {
      setLocalError(requestError);
      resetRequests();
    }
  });

  const {
    wrapper,
    wrapperInner,
    welcome,
    input,
    styledInput,
    inputField,
    styledLabel,
    buttonPadding,
    buttonContainer,
    alert,
  } = classes;

  return (
      <Grid className={wrapper}>
        <SigninHeader />
        <Grid container direction="row" justify="center" alignItems="center" className={wrapperInner}>
          <Grid item xs={12} sm={12} md={10} lg={9} xl={8} >
            <Grid container direction="column" alignItems="center" justify="space-between">
              <Grid item xs={10} sm={9} md={6}>
                <Typography variant="h3" className={welcome}>
                  Sign in to your Enklu Cloud account
                </Typography>
                <form>
                  <div className={input}>
                    <FormControl variant="outlined" className={styledInput}>
                      <Typography variant='subtitle1' className={styledLabel}>Email</Typography>
                      <OutlinedInput
                          className={inputField}
                          variant="outlined"
                          id="email"
                          type="email"
                          autoComplete="username email"
                          autoFocus
                          value={email}
                          onChange={({ target: { value } }) => setEmail(value)}
                      />
                    </FormControl>

                    <FormControl variant="outlined" className={styledInput}>
                      <Typography variant='subtitle1' className={styledLabel}>Password</Typography>
                      <OutlinedInput
                          className={inputField}
                          variant="outlined"
                          id="password"
                          type="password"
                          autoComplete="current-password"
                          value={password}
                          onChange={({ target: { value } }) => setPassword(value)}
                      />
                    </FormControl>
                  </div>

                  {localError && (
                      <Alert className={alert} elevation={0} severity="error" onClose={() => setLocalError('')}>
                        {localError}
                      </Alert>
                  )}

                  <Box py={5} display="flex" justifyContent="space-between" className={buttonContainer}>                
                  <BaseButton
                      variant="contained"
                      disableElevation
                      color="primary"
                      type="submit"
                      className={buttonPadding}
                      size="large"
                      onClick={(event) => {
                        event.preventDefault();
                        signIn({ email, password }, { history, location });
                      }}
                  >
                    Login
                  </BaseButton>
                    <Button
                        href="/requestpasswordreset"
                        onClick={(event) => {
                          event.preventDefault();
                          history.push({ ...location, pathname: '/requestpasswordreset' });
                        }}
                    >
                      <Typography variant='h6'> Forgot password?</Typography>
                    </Button>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
};



Signin.propTypes = {
  // connect
  credentials: PropTypes.object.isRequired,
  requestError: PropTypes.string,

  // action creators - async
  signIn: PropTypes.func.isRequired,

  // action creators - sync
  resetRequests: PropTypes.func.isRequired,

  // withRouter
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  credentials: state.user.credentials,
  requestError: state.user.requestError
});

const mapDispatchToProps = dispatch => ({
  signIn: ({ email, password }, { history, location }) => {
    // intellum_identifier can only be a number at the moment, so check that the user entered a number
    let intellum_identifier = '';
    if(window.intellumAppId && !isNaN(Number(window.intellumAppId))) { 
      intellum_identifier = window.intellumAppId;
    }
    dispatch(emailsignin({ email, password, intellum_identifier }))
      // .then(({ body: { user: { id: userId } } }) => dispatch(getpreferences({ userId })))
      .then(() => dispatch(getuserpreferences()))
      .then(() => history.push({
          ...location,
          pathname: isMobile ? '/mobile-code' : '/init'
        }))
      // eslint-disable-next-line no-console
      .catch(error => console.log(error));
  },

  resetRequests: () => dispatch(resetrequests())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signin));
