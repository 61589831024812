import { log } from '../../util/log';
import { Actions } from '@enklu/server-api';
const { replace } = Actions;

/**
 * Handles the socket connection for getting remote assist updates.
 */
class RemoteAssistSocketManager {

    _dispatch;
    _currentSubscription;

    /**
     * Initializes the class with its dependencies.
     * @param dispatch
     */
    initialize(dispatch) {
        this._dispatch = dispatch;
    }

    /**
     * Starts the connection. Disconnects first if previously connected.
     * @param appId
     */
    connect(userId, deviceId) {
        if (this._currentSubscription) {
            this.disconnect();
        }

        log.info(`Connecting remote assist socket.`);
        this._currentSubscription = { userId, deviceId };

        io.socket.request(
            {
                method: 'POST',
                url: replace('/v1/assist/:userId/:deviceId/subscribe', { userId, deviceId }),
                data: {},
                headers: {
                    Authorization: `Bearer ${io.sails.token}`
                }
            },
            (responseBody) => {
                // TODO: Why doesn't this return?!
                if (responseBody.success) {
                    log.info('Subscribed to remote assist updates.');
                    this._currentSubscription = { userId, deviceId };
                } else {
                    log.error('Could not subscribe!', responseBody.error);
                }
            }
        );
    }

    /**
     * Disconnects if the socket is active.
     */
    disconnect() {
        log.info('Disconnect?!', this._currentSubscription);
        if (this._currentSubscription) {
            log.info(`Disconnecting remote assist socket.`);
            io.socket.request(
                {
                    method: 'POST',
                    url: replace('/v1/assist/:userId/:deviceId/unsubscribe', this._currentSubscription),
                    data: {},
                    headers: {
                        Authorization: `Bearer ${io.sails.token}`
                    }
                },
                (responseBody) => {
                    if (responseBody.success) {
                        log.info('Unsubscribed from remote assist.', this._currentSubscription);
                    } else {
                        log.error('Could not unsubscribe!', responseBody.error);
                    }
                }
            );

            this._currentSubscription = null;
        }
    }
}

const remoteAssistSocketManager = new RemoteAssistSocketManager();

export default remoteAssistSocketManager;
