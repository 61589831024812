import { DragSource } from 'react-dnd';
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getSizing, getUIIsResizing } from '../../store/selectors/uiSelectors';
import { setuivalue } from '../../store/actions/uiActions';
import DragBarDirection, { dragDirectionById } from './DragBarDirection';
import DragDropTypes from '../dragAndDrop/DragDropTypes';

const SIZE = 5;

// This is the drag source contract. Here we describe the object being resized,
// plus let the system know we are resizing.
const source = {
  // This is also our opportunity to let the system know we are resizing.
  beginDrag: (props) => {
    const { id, setIsResizing } = props;
    setIsResizing(true);

    return { id };
  },
  endDrag: (props) => {
    const { setIsResizing } = props;
    setIsResizing(false);
  }
};

// These are the props injected into the component.
const collect = (connectObj, monitor) => ({
  // This function will wrap part of the DOM and handle its drag events.
  connectDragSource: connectObj.dragSource(),
  connectDragPreview: connectObj.dragPreview(),
  isDragging: monitor.isDragging(),
  dragOffset: monitor.getDifferenceFromInitialOffset()
});

class DragBar extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    connectDragPreview(getEmptyImage());
  }

  render() {
    const { connectDragSource, classes } = this.props;

    return connectDragSource(
      <div className={classes.root}>
        <Paper square className={classes.paper} />
      </div>
    );
  }
}

DragBar.propTypes = {
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, { id }) => ({
  offset: getSizing(state).offsets[id],
  isResizing: getUIIsResizing(state)
});
const mapDispatchToProps = dispatch => ({
  setIsResizing: isResizing => dispatch(setuivalue({ name: 'sizing.isResizing', value: isResizing }))
});

export default withStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: SIZE,
    minWidth: SIZE,
    cursor: props => (dragDirectionById[props.id] === DragBarDirection.VERTICAL ? 'ns-resize' : 'ew-resize')
  },
  paper: {
    flex: 1,
    borderRadius: 100,
    // darken default
    backgroundColor: '#0d101b',
    opacity: 0.5
  }
}))(connect(mapStateToProps, mapDispatchToProps)(DragSource(DragDropTypes.DRAGBAR, source, collect)(DragBar)));
