import { AsyncStatus, TrellisActions } from '@enklu/server-api';

import { SHOW_MODAL, HIDE_MODAL } from '../actions/modalActions';

const { GETAPIVERSION, GETWEBVERSION } = TrellisActions;

const initialState = {
  visible: false,
  message: '',
  blurVisible: false,
  spinnerVisible: false
};

const modal = (state = initialState, action) => {
  if (action.type === SHOW_MODAL) {
    return {
      visible: true,
      message: action.message,
      blurVisible: action.blurVisible,
      spinnerVisible: action.spinnerVisible
    };
  }
  if (action.type === HIDE_MODAL) {
    return {
      visible: false
    };
  }
  if (action.type === GETAPIVERSION) {
    if (AsyncStatus.SUCCESS === action.status) {
      const [maj, min, meta] = window.env.apiVersion.split('.');
      const [reqMaj, reqMin, reqMeta] = action.body.version.split('.');

      if (reqMaj !== maj) {
        return {
          visible: true,
          message: 'A mandatory update has been released. Please refresh your browser window.',
          blurVisible: true,
          spinnerVisible: false
        };
      }
    }
  } else if (action.type === GETWEBVERSION) {
    if (AsyncStatus.SUCCESS === action.status) {
      if (window.env.build === 'production') {
        const [maj, min, meta] = window.config.version.split(/[-.]/);
        const [reqMaj, reqMin, reqMeta] = action.body.version.split(/[-.]/);

        if (reqMaj > maj) {
          return {
            visible: true,
            message: 'A mandatory update has been released. Please refresh your browser window.',
            blurVisible: true,
            spinnerVisible: false
          };
        }

        const sameMaj = reqMaj === maj;
        const newerMin = sameMaj && reqMin > min;
        const sameMin = sameMaj && reqMin === min;
        const newerMeta = sameMin && reqMeta > meta;

        if (newerMin || newerMeta) {
          return {
            visible: true,
            // eslint-disable-next-line max-len
            message: 'A new version of the web tools has been released. Please refresh your browser window when convenient.',
            blurVisible: true,
            spinnerVisible: false,
            continueVisible: true
          };
        }

        return state;
      }
    }
  }

  return state;
};

export default modal;
