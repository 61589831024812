import { Route, Redirect, Switch } from 'react-router-dom';
import { ShortcutManager } from 'react-shortcuts';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import React from 'react';
import queryString from 'query-string';

import { getError } from './store/selectors/errorSelectors';
import { getModal } from './store/selectors/modalSelectors';
import { getUserIsAuthenticated } from './store/selectors/userSelectors';
import { setToken, validateTokenAction } from './store/actions/credentialActions';
import ConnectionMonitor from './components/misc/ConnectionMonitor';
import CreateNewPassword from './pages/CreateNewPassword';
import DebugIcon from './features/debug/DebugIcon';
import DebugOptions from './features/debug/MuiDebugOptions';
import DragBarDragLayer from './features/dragBar/DragBarDragLayer';
import FatalError from './pages/FatalError';
import FullScreenModal from './components/misc/MuiFullScreenModal';
import HoloLogin from './components/sidebar/MuiHoloLogin';
import KeyboardShortcuts from './components/misc/KeyboardShortcuts';
import MetricsListener from './components/misc/MetricsListener';
import ModalWatcher from './components/modals/MuiModalWatcher';
import MyExperiences from './pages/MyExperiences';
import NotSupported from './pages/NotSupported';
import NotificationHandler from './components/misc/NotificationHandler';
import PlayMode from './pages/PlayMode';
import RequestPasswordReset from './pages/RequestPasswordReset';
import MobileAppStore from './pages/MobileAppStore';
import Signin from './pages/Signin';
import Workspace from './pages/Workspace';
import globalStyles from './styles/muiGlobalStyles';
import txns from './store/actions/TxnManager';
import Signup from './pages/Signup';

import Link from '@mui/material/Link';

import MuiPrivacyPolicy from './features/docs/MuiPrivacyPolicy';
import MuiPrivacyPolicyLink from './features/docs/MuiPrivacyPolicyLink';

/**
 * Returns true iff this browser is supported.
 */
const browserSupported = () => {
  const canvas = document.createElement('canvas');
  const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

  return gl && gl instanceof WebGLRenderingContext;
};

/**
 * Determines if debug icon should be shown.
 */
const showDebugMenu = (location) => {
  if (false && window.env.build === 'production') {
    return false;
  }

  return location.indexOf('signin') !== -1 || location.indexOf('signupheader') !== -1 || location.indexOf('apps') !== -1;
};

const showPrivacyPolicy = (location) => {
  if (false && window.env.build === 'production') {
    return false;
  }

  return location.indexOf('signin') !== -1 || location.indexOf('signupheader') !== -1 || location.indexOf('apps') !== -1 || location.indexOf('debugoptions') !== -1;
};

/**
 * Main application.
 * This is the landing page when you open cloud.enklu.com - the sign in page. This file renders all the different components you see on that page, such as the signin fields, the debug settings menu, signup, forgot password, etc.
 * It also differentiates between mobile and browser versions of the page. The respective components rendered can be found under the renderMobile() and renderBrowser() functions.
 */
class App extends React.Component {
  shortcutManager;

  /**
   * Constructor!
   */
  constructor(props) {
    super(props);

    this.state = {
      supported: browserSupported()
    };

    // initialize transactions api
    txns.initialize(props.store, props.dispatch);

    this.shortcutManager = new ShortcutManager();
    this.shortcutManager.setKeymap(KeyboardShortcuts);
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if (params.token) {
      const { token } = params;
      this.props.checkToken({ token });
    }
  }

  /**
   * Children use this for access to keyboard shortcuts.
   *
   * @returns {{shortcuts: *}}
   */
  getChildContext() {
    return {
      shortcuts: this.shortcutManager
    };
  }

  renderMobile() {
    const { userIsAuthenticated, history, location } = this.props;

    return (
      <div>
        <ConnectionMonitor />
        <FullScreenModal />
        <Switch>
          {/* Signin Page */}
          <Route path="/signin" render={() => <div><Signin /><br/>
          <DebugIcon /><br/><MuiPrivacyPolicyLink history = {history}/></div>} /> {/* on mobile we need to render the debug icon and privacy policy buttons like this */}

          {/* Debug page */}
          <Route path="/debug" render={() => <DebugOptions />} />    

          {/* UNUSED: React webpage for privacy policy, we just link to notion right now */}
          {/* <Route path="/privacypolicy" render={() => <MuiPrivacyPolicy history = {history}/>} /> */}

          {/* Request Password Reset Page */}
          <Route path="/requestpasswordreset" render={() => <RequestPasswordReset />} />

          {/* Create New Password Page */}
          <Route path="/createnewpassword" render={() => <CreateNewPassword />} />

          {/* Signup Page */}
          <Route path="/signup" render={() => <Signup />} />

          {/* Mobile Code Page */}
          {/* When you log in on mobile, you'll only see a HoloLogin QR Code. */}
          <Route
            path="/mobile-code"
            render={() => {
              if (!userIsAuthenticated) {
                return <Redirect to="/signin" />;
              }

              return <HoloLogin showClose={false} />;
            }}
          />

          {/* Mobile App Store Page */}
          <Route path="/app" render={() => <MobileAppStore />} />

          <Route render={() => <Redirect to="/signin" />} />
        </Switch>
      </div>
    );
  }

  renderBrowser() {
    const {
 location, history, userIsAuthenticated, blurVisible
} = this.props;
    const { blur } = globalStyles;
    const fullScreen = { height: '100vh', width: '100vw' };

    return (
      <div>
        <div style={blurVisible ? { ...fullScreen, ...blur } : fullScreen}>
          {/* Handles push events from Trellis. */}
          <NotificationHandler />

          {/* Handles metrics sent from the player */}
          <MetricsListener />

          {/* Monitors socket connection. */}
          <ConnectionMonitor />

          {/* Watches for modals in the URL. */}
          <ModalWatcher />

          <FullScreenModal />

          {/* Custom layer for resize drag rendering. */}
          <DragBarDragLayer />

          <Switch>
            {/* UNUSED: React webpage for privacy policy, we just link to notion right now */}
            {/* <Route path="/privacypolicy" render={() => <MuiPrivacyPolicy history = {history}/>} /> */}

            {/* Debug Page */}
            <Route path="/debug" render={() => <DebugOptions />} />

            {/* Signin Page */}
            <Route path="/signin" render={() => <Signin />} />

            {/* Signup Page */}
            <Route path="/signup" render={() => <Signup />} />

            {/* Request Password Reset Page */}
            <Route path="/requestpasswordreset" render={() => <RequestPasswordReset />} />

            {/* Create New Password Page */}
            <Route path="/createnewpassword" render={() => <CreateNewPassword />} />

            {/* Initialization */}
            <Route
              path="/init"
              render={() => {
                if (!userIsAuthenticated) {
                  return <Redirect to="/signin" />;
                }

                return <MyExperiences />;
              }}
            />

            <Route path="/app" render={() => <PlayMode />} />

            {/* Workspace Page */}
            <Route
              path="/"
              render={() => {
                if (!userIsAuthenticated) {
                  return <Redirect to="/signin" />;
                }

                return <Workspace />;
              }}
            />

            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </div>

        {// debug icon
        showDebugMenu(location.pathname) ? <DebugIcon /> : null}

        {showPrivacyPolicy(location.pathname) ? <MuiPrivacyPolicyLink history = {history}/> : null}
        
      </div>
    );
  }

  /**
   * Renders controls.
   */
  render() {
    if (!this.state.supported) {
      return <NotSupported />;
    }

    const {
      error: { fatalError }
    } = this.props;

    if (fatalError) {
      return <FatalError eventId={fatalError} />;
    }

    if (isMobile) {
      return this.renderMobile();
    }

    return <DndProvider backend={HTML5Backend}>{this.renderBrowser()}</DndProvider>;
  }
}

App.childContextTypes = {
  shortcuts: PropTypes.object.isRequired
};

App.propTypes = {
  store: PropTypes.object.isRequired,

  // connect()
  error: PropTypes.object.isRequired,
  userIsAuthenticated: PropTypes.bool.isRequired,
  blurVisible: PropTypes.bool,

  dispatch: PropTypes.func.isRequired,

  // withRouter
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  error: getError(state),
  userIsAuthenticated: getUserIsAuthenticated(state),
  blurVisible: getModal(state).blurVisible,
  status: state.status,
  token: state.token
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  checkToken: ({ token }) => {
    dispatch(setToken({ token }));
    dispatch(validateTokenAction({ token }));
  }
});

export default withStyles(globalStyles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
);
