import {
 Box, FormControl, Paper, Tooltip, Typography
} from '@material-ui/core';
import { ControlCamera, RotateLeft, ZoomOutMap } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { findAncestorSchema } from '../../util/util';
import { updateAction } from '../../store/actions/elementActions';
import ActionSchemaTypes from '../../constants/ActionSchemaTypes';
import VectorInput from './MuiVectorInput';
import txns from '../../store/actions/TxnManager';

const useStyles = makeStyles(theme => ({
  formControl: { flexDirection: 'row', alignItems: 'center', paddingTop: 0 },
  icon: { marginTop: 24, fontSize: 30, marginRight: theme.spacing(2) }
}));

/**
 * Updates an element string value.
 */
const updateElementVec = (sceneId, elementId, key, value) => {
  txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.VEC3, key, value));
};

/**
 * Sends actions straight to player rather than authenticating them.
 */
const previewUpdateElementVec = (sceneId, elementId, key, value) => {
  txns.preview(sceneId, updateAction(elementId, ActionSchemaTypes.VEC3, key, value));
};

/**
 * Controls for a transform.
 */
const Transform = ({ sceneId, element, hideTitle }) => {
  const {
    id: elementId,
    schema: {
      vectors: {
        position = {
          x: 0,
          y: 0,
          z: 0
        },
        rotation = {
          x: 0,
          y: 0,
          z: 0
        },
        scale = {
          x: 1,
          y: 1,
          z: 1
        }
      } = {}
    } = {}
  } = element;

  const isLocked = !!findAncestorSchema(element, 'bools', 'locked', true);

  const onPositionChange = _position => updateElementVec(sceneId, elementId, 'position', _position);
  const onPositionPreview = _position => previewUpdateElementVec(sceneId, elementId, 'position', _position);
  const onRotationChange = _rotation => updateElementVec(sceneId, elementId, 'rotation', _rotation);
  const onRotationPreview = _rotation => previewUpdateElementVec(sceneId, elementId, 'rotation', _rotation);
  const onScaleChange = _scale => updateElementVec(sceneId, elementId, 'scale', _scale);
  const onScalePreview = _scale => previewUpdateElementVec(sceneId, elementId, 'scale', _scale);

  const classes = useStyles();

  return (
    <Box mt={0} mb={1}>
      <Paper square>
        {!hideTitle && <Typography variant="h6">Transform</Typography>}
        <Box px={1} pb={1}>
          <FormControl className={classes.formControl}>
            <Tooltip title={'Position'} placement="top" arrow>
              <ControlCamera className={classes.icon} />
            </Tooltip>
            <VectorInput
              label="Position"
              value={position}
              onChange={onPositionChange}
              onPreview={onPositionPreview}
              draggerScale={0.25}
              disabled={isLocked}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Tooltip title={'Rotation'} placement="top" arrow>
              <RotateLeft className={classes.icon} />
            </Tooltip>
            <VectorInput
              label="Rotation"
              value={rotation}
              onChange={onRotationChange}
              onPreview={onRotationPreview}
              draggerScale={2.0}
              mod={360}
              disabled={isLocked}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Tooltip title={'Scale'} placement="top" arrow>
              <ZoomOutMap className={classes.icon} />
            </Tooltip>
            <VectorInput value={scale} onChange={onScaleChange} onPreview={onScalePreview} disabled={isLocked} />
          </FormControl>
        </Box>
      </Paper>
    </Box>
  );
};

/**
 * PropTypes.
 */
Transform.propTypes = {
  hideTitle: PropTypes.bool,
  element: PropTypes.object.isRequired,
  sceneId: PropTypes.string.isRequired
};

export default Transform;
