import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const HEIGHT = 18;
const PADDING = 2;

const BaseSwitch = withStyles(theme => ({
  root: {
    width: 40,
    height: HEIGHT,
    padding: 0,
    margin: 9
  },
  switchBase: {
    padding: PADDING,
    paddingLeft: 3,
    color: theme.palette.grayscale.white,
    '&$checked:not(.Mui-disabled)': {
      transform: 'translateX(20px)',
      color: theme.palette.grayscale.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.info.main,
        borderColor: theme.palette.info.main
      }
    }
  },

  thumb: {
    width: HEIGHT - PADDING * 2,
    height: HEIGHT - PADDING * 2,
    boxShadow: 'none'
  },
  track: {
    // border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: HEIGHT / 2,
    opacity: 1,
    backgroundColor: theme.palette.icon.light
  },
  disabled: {},
  checked: {}
}))(Switch);

export default BaseSwitch;
