import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragLayer } from 'react-dnd';
import DragDropTypes from '../dragAndDrop/DragDropTypes';
import { getScreenSize, getUIOffsets } from '../../store/selectors/uiSelectors';
import { getGrid, getGridValues } from './dragBarUtil';

class DragBarDragLayer extends Component {
  getItemStyle() {
    const { currentOffset } = this.props;

    if (!currentOffset) {
      return { display: 'none' };
    }

    const {
      initialOffset,
      item: { id },
      screenSize,
      uiOffsets
    } = this.props;

    const {
 x, y, height, width
} = getGridValues({
      screenSize,
      uiOffsets,
      id,
      currentOffset,
      initialOffset
    });

    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
      border: '1px solid lightgray',
      width,
      height
    };
  }

  renderItem() {
    const { itemType } = this.props;

    switch (itemType) {
      case DragDropTypes.DRAGBAR: {
        return (
          <div
            style={{
              ...this.getItemStyle(),
              position: 'absolute'
            }}
          />
        );
      }

      default: {
        return null;
      }
    }
  }

  renderGrid() {
    const {
      uiOffsets: { current, min, max },
      screenSize,
      itemType
    } = this.props;

    // TODO minimized drawer
    const style = getGrid({
      screenSize,
      menu: current.menu,
      current,
      min,
      max
    });

    return (
      <div
        style={{
          border: '1px solid lightgray',
          position: 'absolute',
          ...style.container
        }}
      >
        <div
          style={{
            ...style.menu,
            border: '1px solid lightgray'
          }}
        />
        <div
          style={{
            ...style.left,
            border: '1px solid lightgray'
          }}
        />
        <div
          style={{
            ...style.right,
            border: '1px solid lightgray'
          }}
        />
        <div
          style={{
            ...style.drawer,
            border: '1px solid lightgray'
          }}
        />
        <div
          style={{
            ...style.player,
            border: '1px solid lightgray'
          }}
        />
      </div>
    );
  }

  render() {
    const { isDragging, itemType } = this.props;
    if (!isDragging || itemType !== DragDropTypes.DRAGBAR) {
      return null;
    }

    return (
      <div
        style={{
          position: 'fixed',
          pointerEvents: 'none',
          zIndex: 10001,
          left: 0,
          top: 0
        }}
      >
        {this.renderGrid()}
        {this.renderItem()}
      </div>
    );
  }
}

DragBarDragLayer.propTypes = {
  item: PropTypes.object,
  itemType: PropTypes.string,
  initialOffset: PropTypes.object,
  currentOffset: PropTypes.object,
  isDragging: PropTypes.bool.isRequired,

  // connect
  uiOffsets: PropTypes.object.isRequired,
  screenSize: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  uiOffsets: getUIOffsets(state),
  screenSize: getScreenSize(state)
});
const mapDispatchToProps = () => ({});

const collect = monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging()
});

export default DragLayer(collect)(connect(mapStateToProps, mapDispatchToProps)(DragBarDragLayer));
