import {
 Box, ButtonBase, Card, Grid, Typography
} from '@material-ui/core';
import { chunk } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import Carousel from 'react-material-ui-carousel';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import ChevronLeft from '../../../assets/icons/chevron-left.svg';
import ChevronRight from '../../../assets/icons/chevron-right.svg';

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(1),
    height: 180,
    [theme.breakpoints.down('lg')]: {
      height: 125
    }
  },
  buttonBase: {
    height: '100%',
    width: '100%'
  },
  disabled: {
    opacity: 0.4
  },
  disabledButtonBase: {
    // opacity: 0.5,
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled
  },
  carousel: {
    flex: 1
  },
  leftIconButton: {
    marginTop: 22,
    marginRight: theme.spacing(2)
  },
  rightIconButton: {
    marginTop: 22,
    marginLeft: theme.spacing(2)
  },
  chevronIcon: {
    height: 80,
    width: 40,
    objectFit: 'cover'
  },
  image: {
    objectFit: 'cover',
    height: 'auto',
    width: '100%'
  },
  placeholderImage: {
    height: '75%',
    width: '75%',
    objectFit: 'contain'
  }
}));

const TemplateCarousel = ({ templates, perSlide, ...otherProps }) => {
  const classes = useStyles();

  const [currSlide, setCurrSlide] = React.useState(0);

  // resize resets currSlide
  React.useEffect(() => {
    setCurrSlide(0);
  }, [perSlide]);

  const templateChunks = chunk(templates, perSlide);

  const leftDisabled = currSlide === 0;
  const rightDisabled = (currSlide + 1) * perSlide > templates.length;

  const decrementSlide = () => {
    setCurrSlide(Math.max(currSlide - 1, 0));
  };

  const incrementSlide = () => {
    setCurrSlide(Math.min(currSlide + 1, templateChunks.length - 1));
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="start" ml={-7}>
      <ButtonBase
        disabled={leftDisabled}
        style={{ visibility: leftDisabled ? 'hidden' : undefined }}
        onClick={decrementSlide}
        className={classes.leftIconButton}
      >
        <img src={ChevronLeft} className={classes.chevronIcon} />
      </ButtonBase>
      <Carousel
        indicators={false}
        className={classes.carousel}
        index={currSlide}
        navButtonsAlwaysInvisible
        autoPlay={false}
        {...otherProps}
      >
        {templateChunks.map((templateChunk, i) => (
          <Grid key={i} container spacing={4}>
            {templateChunk.map((template) => {
              console.log('template map: ', template);
              const disabled = !template.onClick;
              const thumbnail = <img className={classes.image} src={template.thumb} />;
              const graphic = template.icon ? template.icon : thumbnail;
              console.log('graphic: ', graphic);
              return (
                <Grid key={template.name} item xs={12 / perSlide}>
                  <Card className={clsx(classes.card, disabled ? classes.disabled : '')}>
                    <ButtonBase
                      disabled={disabled}
                      classes={{ root: classes.buttonBase, disabled: classes.disabledButtonBase }}
                      onClick={template.onClick}
                    >
                      {graphic}
                    </ButtonBase>
                  </Card>

                  <Typography className={disabled ? classes.disabled : ''}>{template.name}</Typography>
                </Grid>
              );
            })}
          </Grid>
        ))}
      </Carousel>
      <ButtonBase
        onClick={incrementSlide}
        disabled={rightDisabled}
        style={{ visibility: rightDisabled ? 'hidden' : undefined }}
        className={classes.rightIconButton}
      >
        <img src={ChevronRight} className={classes.chevronIcon} />
      </ButtonBase>
    </Box>
  );
};

TemplateCarousel.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  perSlide: PropTypes.number.isRequired
};

export default TemplateCarousel;
