export const TOUR_STAGE_SET_MAX = 'tour.maxStage.set';
export const setTourMaxStage = limit => ({
  type: TOUR_STAGE_SET_MAX,
  payload: limit
});

export const TOUR_STAGE_SET = 'tour.stage.set';
export const setTourStage = stage => ({
  type: TOUR_STAGE_SET,
  payload: stage
});
