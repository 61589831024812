import uuid from 'uuid';
import { SET_PLAYER_EDIT } from '../actions/playerActions';
import playmodeLauncher from '../actions/PlaymodeLauncher';
import { TrellisActions } from '@enklu/server-api';
const { GETAPP } = TrellisActions;

const forcePlayMode = window.location.hash.startsWith('#/app');

const initialState = {
  iframeId: `iframe-${uuid()}`,
  edit: !forcePlayMode
};

export default (state = initialState, action) => {
  if (action.type === GETAPP) {
    // Only exit if we're using the popup. Needless to reload edit mode otherwise if the app is switching.
    if (playmodeLauncher.popupActive) {
      playmodeLauncher.exit();
    }

    return {
      ...state,
      edit: !forcePlayMode
    };
  }

  if (action.type === SET_PLAYER_EDIT) {
    if (!action.edit) {
      playmodeLauncher.enter();
    } else {
      playmodeLauncher.exit();
    }

    return {
      ...state,
      edit: action.edit
    };
  }
  return state;
};
