/**
 * Displays a thumbnail of an asset and allows the user to replace it.
 *
 * @param asset - the asset to display
 * @param canManage - whether the current user is allowed to manage the thumbnail
 * @param onUpdate - callback for when the user wishes to update the asset thumbnail
 */

import {
 Box, Paper, Tooltip, Typography
} from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { assetThumbUrl } from '../../../util/util';
import FilledIconButton from '../../material-ui/FilledIconButton';

const useStyles = makeStyles(theme => ({
  container: {
    // border: '1px solid lightgray',
    position: 'relative',
    overflow: 'hidden',
    minHeight: 232.5
    // backgroundSize: 'cover'
  },
  image: {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 4,
    objectFit: 'cover',
    boxShadow: `inset 0 0 10px ${theme.palette.common.boxShadow}`,
    '-moz-box-shadow': `inset 0 0 10px ${theme.palette.common.boxShadow}`,
    '-webkit-box-shadow': `inset 0 0 10px ${theme.palette.common.boxShadow}`
  }
}));

const AssetThumb = ({ asset, canManage, onUpdate }) => {
  const classes = useStyles();

  return (
    <Paper elevation={3}>
      <Box display="flex" justifyContent="center" alignItems="center" className={classes.container} p={2}>
        {!asset && <Typography>(No thumbnail)</Typography>}
        {asset && (
          <React.Fragment>
            <img src={assetThumbUrl(asset)} className={classes.image} />
            {canManage && (
              <Box position="absolute" top={4} right={4}>
                <Tooltip title={'Upload'} placement="top" arrow>
                  <FilledIconButton icon={CloudUpload} color="primary" onClick={onUpdate} />
                </Tooltip>
              </Box>
            )}
          </React.Fragment>
        )}
      </Box>
    </Paper>
  );
};

AssetThumb.propTypes = {
  asset: PropTypes.object,
  canManage: PropTypes.bool,
  onUpdate: PropTypes.func
};

AssetThumb.defaultProps = {
  canManage: false
};

export default AssetThumb;
