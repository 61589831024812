import { ChevronLeft } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    border: `1px ${theme.palette.grayscale.white} solid`,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.grayscale.white,
      color: theme.palette.background.paper,
      '& .MuiButton-label': {
        color: theme.palette.background.paper
      }
    },
    '& .MuiButton-label': {
      color: theme.palette.grayscale.white,
    }
  }
}));

const BackButton = ({ onBack }) => {
  const classes = useStyles();
  return (
    <IconButton onClick={onBack} className={classes.container}>
      <ChevronLeft />
    </IconButton>
  );
};

BackButton.propTypes = {
  onBack: PropTypes.func.isRequired
};

export default BackButton;
