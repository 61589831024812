import {
 Box, MenuItem, OutlinedInput, Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import ControlPanel from './MuiControlPanel';

const useStyles = makeStyles(theme => ({
  selectsContainer: {
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  }
}));

const MultiControlPanel = ({ settings, onChange }) => {
  const classes = useStyles();
  const [selectedPrimaryValue, setSelectedPrimaryValue] = React.useState(0);
  const [selectedSecondaryValue, setSelectedSecondaryValue] = React.useState(0);

  const renderSelects = () => {
    const { settings: primarySettings } = settings;
    const { settings: secondarySettings } = primarySettings[selectedSecondaryValue];
    return (
      <Box flexDirection="column" className={classes.selectsContainer} mt={1}>
        {[
          {
            value: selectedPrimaryValue,
            change: setSelectedPrimaryValue,
            options: primarySettings
          },
          {
            value: selectedSecondaryValue,
            change: setSelectedSecondaryValue,
            options: secondarySettings
          }
        ].map(({ value, change, options }, index) => (
          <Select
            fullWidth
            key={index}
            input={<OutlinedInput />}
            value={value}
            onChange={({ target: { value: newValue } }) => change(newValue)}
            variant="outlined"
          >
            {options.map(({ displayName }, i) => (
              <MenuItem key={i} value={i}>
                {displayName}
              </MenuItem>
            ))}
          </Select>
        ))}
      </Box>
    );
  };

  const primaryCategory = settings.settings[selectedPrimaryValue];
  const secondaryCategory = primaryCategory.settings[selectedSecondaryValue];

  return (
    <Box>
      {renderSelects()}
      <ControlPanel
        settingsCategory={secondaryCategory}
        onChange={({ setting, value, defaultValue }) => onChange({
            category: primaryCategory.name,
            setting,
            value,
            defaultValue
          })
        }
      />
    </Box>
  );
};

MultiControlPanel.propTypes = {
  // This should exactly mirror the a nested options object, as seen in scenesReducer
  settings: PropTypes.exact({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    settings: PropTypes.array.isRequired // Can be nested.
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

MultiControlPanel.defaultProps = {
  title: 'Options'
};

export default MultiControlPanel;
