import { Alert } from '@material-ui/lab';
import {
    Box,
    Button,
    CardMedia,
    Grid,
    Link,
    TextField,
    Typography,
    useMediaQuery,
    Checkbox,
    FormControlLabel,
    FormControl,
    InputLabel, Input, OutlinedInput, CircularProgress
} from '@material-ui/core';
import { connect } from 'react-redux';
import { getActions } from '@enklu/server-api';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';


import { value } from 'lodash/seq';
import { getuserpreferences } from '../store/actions/preferencesActions';
import { resetrequests } from '../store/actions/initializeActions';
import BaseButton from '../components/material-ui/BaseButton';
import SignupHeader from '../components/common/SignupHeader';
import { signup } from '../store/actions/signupActions';


const useStyles = makeStyles(theme => ({
    wrapper: {
        height: '100%'
    },
    wrapperInner: {
        height: '0%'
    },
    shadowContainer: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '36px',
        boxShadow: `5px 5px 35px 15px ${theme.palette.common.boxShadow}38`
    },
    welcome: {
        fontSize: '3em',
        fontWeight: 'bold'
    },
    welcomeSubtitle: {
        fontSize: '2em',
        fontWeight: 'lighter'
    },
    image: {
        height: '100px',
        width: '100px',
        marginTop: theme.spacing(7),
        marginBottom: theme.spacing(7)
    },
    input: {
        '& > *': {
            marginBottom: theme.spacing(0)
        }
    },
    styledInput: {
        m: 1,
        width: '100%',
        minHeight: '4px'
    },
    inputField: {
        minHeight: '4px',
        fontSize: 14
    },
    styledLabel: {
        fontSize: '1em',
        paddingBottom: theme.spacing(0.5)
    },
    buttonPadding: {
        height: '2em',
        width: '7em',
        fontSize: '2em',
        fontWeight: 'bold'
    },
    footerContainer: {
        paddingTop: theme.spacing(3),
        marginBottom: theme.spacing(4)
    },
    footerLeft: {
        paddingLeft: theme.spacing(6),
        textAlign: 'left'
    },
    footerRight: {
        paddingRight: theme.spacing(6),
        textAlign: 'right'
    }
}));

const { emailsignin } = getActions('trellis');

/**
 * Sign up page.
 */
export const Signup = (props) => {
    const isDesktop = useMediaQuery('(min-width:600px)');
    const [handle, setHandle] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [checked, setChecked] = React.useState(false);
    const [localError, setLocalError] = useState('');
    const [busy, setBusy] = React.useState(false);
    const {
        history, location, resetRequests, requestError, signUp
    } = props;

    const classes = useStyles();

    useEffect(() => {
        if (window.autoLogin) {
            signUp(
                {
                    email: window.autoLogin.email,
                    password: window.autoLogin.password
                },
                {
                    history,
                    location
                }
            );
        }

        if (requestError) {
            setLocalError(requestError);
            resetRequests();
        }
    });


    const {
        wrapper,
        wrapperInner,
        shadowContainer,
        welcome,
        welcomeSubtitle,
        image,
        input,
        styledInput,
        inputField,
        styledLabel,
        textField,
        buttonPadding,
        buttonContainer,
        alert,
        footerContainer,
        footerLeft,
        footerRight
    } = classes;

    const containerClass = isDesktop ? shadowContainer : null;
    const signupClass = isDesktop ? footerLeft : footerRight;
    const linkVariant = isDesktop ? 'body2' : 'subtitle1';


    const onChangeHandle = (e) => {
        const handle = e.target.value;
        setHandle(handle);
    };

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const onChangeCheckbox = (event) => {
        setChecked(event.target.checked);
    };


    const validRegistration = ((password.length > 6) && (checked == true) && (handle.length > 0));


    const signupAutoLogin = (event) => {
        event.preventDefault();
        this.props.signUp({ email, password, handle }, { history, location })
            .then((response) => {
                // console.log('signup response: ', response);
            });
    };

    // Display Name Error
    const [hasDisplayNameError, setDisplayNameError] = useState(false);

    const displayNameInvalid = () => {
        if (handle.length == 0) {
            setDisplayNameError(true);
            return;
        }
        setDisplayNameError(false);
    };

    // Email Error
    const [hasEmailError, setEmailError] = useState(false);

    const emailInvalid = () => {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setEmailError(false);
            return;
            }
        setEmailError(true);
    };

    // Password Error
    const [hasPasswordError, setPasswordError] = useState(false);

    const passwordInvalid = () => {
        if (password.length < 6) {
            setPasswordError(true);
            return;
        }
        setPasswordError(false);
    };

    const renderLoading = () => busy && (
        <Box
            zIndex={1}
            position="absolute"
            height="100%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <CircularProgress size={60} />
        </Box>
    );


    return (
        <Grid className={wrapper}>
            <SignupHeader />
        <Grid container direction="row" justify="center" alignItems="center" className={wrapperInner}>
            <Grid item xs={16} sm={12} md={10} lg={9} xl={8} >
                <Grid container direction="column" alignItems="center" justify="space-between">
                    <Grid item xs={10} sm={9} md={6}>
                        <Typography variant="h5" className={welcome}>
                            Build in any reality, together
                        </Typography>
                        <Typography variant="h2" className={welcomeSubtitle}>
                            Create an account and start your 30-day free trial today!
                        </Typography>
                        <form >
                            <div className={input}>
                                <FormControl variant="outlined" className={styledInput}>
                                    <Typography variant='subtitle1' className={styledLabel}>Display Name</Typography>
                                    <OutlinedInput
                                        className={inputField}
                                        placeholder="Jane Smith"
                                        id="username"
                                        type="username"
                                        autoComplete="username"
                                        autoFocus
                                        value={handle}
                                        onChange={onChangeHandle}
                                        onBlur={displayNameInvalid}
                                    />
                                    {hasDisplayNameError ? <Typography style={{ color: 'red' }}>Please enter a display name</Typography> : null}
                                </FormControl>

                                <FormControl variant="outlined" className={styledInput}>
                                    <Typography variant='subtitle1' className={styledLabel}>Email</Typography>
                                    <OutlinedInput
                                        className={inputField}
                                        placeholder='janesmith@email.com'
                                        id="email"
                                        type="email"
                                        autoComplete="username email"

                                        value={email}
                                        onChange={onChangeEmail}
                                        onBlur={emailInvalid}
                                    />
                                    {hasEmailError ? <Typography style={{ color: 'red' }}>Please enter a valid email</Typography> : null}
                                </FormControl>

                                <FormControl variant="outlined" className={styledInput}>
                                    <Typography variant='subtitle1' className={styledLabel}>Password</Typography>
                                    <OutlinedInput
                                        className={inputField}
                                        placeholder="Must be 6+ characters"
                                        id="password"
                                        type="password"
                                        autoComplete="current-password"

                                        value={password}
                                        onChange={onChangePassword}
                                        onBlur={passwordInvalid}
                                    />
                                    {hasPasswordError ? <Typography style={{ color: 'red' }}>Please use 6+ characters for a secure password</Typography> : null}
                                </FormControl>

                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={onChangeCheckbox}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />}
                                    label={
                                        <div>I agree to Enklu's{' '}
                                            <Link
                                                href="https://enklu.com/terms-of-service/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                underline="always">
                                                {'Terms'}
                                            </Link>
                                            {' '}&{' '}
                                            <Link
                                                href="https://drive.google.com/file/d/1jA1jdutA2ur1PGpnTVv38GW8RaDc-yUa/view?usp=sharing"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                underline="always">
                                                {'Privacy Policy'}
                                            </Link>
                                        </div>}
                                />
                            </div>
                            <Box py={1} display="flex" justifyContent="space-between" className={buttonContainer} style={{ position: 'relative' }}>
                                <BaseButton
                                    variant="contained"
                                    disableElevation
                                    color="primary"
                                    className={buttonPadding}
                                    size="large"
                                    disabled={!validRegistration}
                                    onClick={ (event) => {
                                        event.preventDefault();
                                        setLocalError('');
                                        setBusy(true);
                                        Promise.resolve(signUp({ email, password, handle }, { history, location }))
                                            .then((res) => {
                                                // console.log('promise res: ', res);
                                                setLocalError(res);
                                                if (res) {
                                                    setBusy(false);
                                                }
                                            });
                                    }}
                                >
                                    Sign Up
                                </BaseButton>
                                {localError && (
                                    <Alert className={alert} elevation={0} severity="error" onClose={() => setLocalError('')}>
                                        {localError}
                                    </Alert>
                                )}
                                {renderLoading()}
                            </Box>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </Grid>
    );
};

Signup.propTypes = {
    // connect
    credentials: PropTypes.object.isRequired,
    requestError: PropTypes.string,

    // action creators - async
    signUp: PropTypes.func.isRequired,

    // action creators - sync
    resetRequests: PropTypes.func.isRequired,

    // withRouter
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    credentials: state.user.credentials,
    requestError: state.user.requestError
});

const mapDispatchToProps = dispatch => ({
    signUp: ({ email, password, handle }, { history, location }) => {
        return dispatch(signup({ email, password, handle }))
            .then(() => dispatch(emailsignin({ email, password })))
            .then((res) => {
                const { mixpanel } = window;
                if (mixpanel) {
                    mixpanel.track('app.signup.email', {
                        $device: 'Web',
                        Email: res.body.user.email,
                        'Display Name': res.body.user.displayName,
                        ID: res.body.user.id
                    });
                }
                dispatch(getuserpreferences());
            })

            .then(() => history.push({
                ...location,
                pathname: isMobile ? '/mobile-code' : '/init'
            }))
            // eslint-disable-next-line no-console
            .catch((error) => {
                console.log('catch dispatch error: ', error);
                return error;
            });
    },

    resetRequests: () => dispatch(resetrequests())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
