import { Box, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import React from 'react';
import lodash from 'lodash';

import { getAppInfo } from '../../../store/selectors/appSelectors';
import { rgbToHex, hexToRgb } from '../../../util/util';
import { updateAction } from '../../../store/actions/elementActions';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import BufferedSlider from '../../material-ui/BufferedSlider';
import ColorPickerField from '../../material-ui/ColorPickerField';
import DraggerInput from '../MuiDraggerInput';
import InspectableIdentity, { elementToInspectable } from '../common/MuiInspectableIdentity';
import InspectorInterface from '../common/InspectorInterface';
import Transform from '../MuiTransform';
import txns from '../../../store/actions/TxnManager';

const useStyles = makeStyles(theme => ({
  nearFarContainer: {
    '& > *': {
      marginRight: theme.spacing(2)
    }
  }
}));

const CameraElementInspector = ({
 appId, element, scene: { id: sceneId }, onUpdateElement
}) => {
  const classes = useStyles();
  const {
    id: elementId,
    schema: {
      strings: {} = {},
      ints: { 'camera.fov': fov = 45 } = {},
      floats: { 'camera.near': near = 0.3, 'camera.far': far = 1000 } = {},
      colors: {
        'camera.clear': clear = {
          r: 0,
          g: 0,
          b: 0,
          a: 0
        }
      } = {}
    } = {}
  } = element;

  return (
    <Box p={1}>
      <InspectableIdentity
        inspectable={element}
        inspectableTranslator={elementToInspectable}
        onUpdate={onUpdateElement}
      />

      <Transform hideTitle element={element} sceneId={sceneId} />

      <Box>
        <Typography variant="h6">FoV</Typography>
        <Box px={2}>
          <BufferedSlider
            onChange={(_, value) => txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.INT, 'camera.fov', value))
            }
            value={fov}
            step={1}
            min={0}
            max={180}
            valueLabelDisplay="auto"
          />
        </Box>
      </Box>

      <Box>
        <Typography variant="h6">Viewing Plane</Typography>
        <Box
          display="flex"
          flex={1}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          className={classes.nearFarContainer}
          my={1}
          pt={1}
        >
          {['near', 'far'].map(coord => (
            <DraggerInput
              key={coord}
              value={coord === 'near' ? near : far}
              scale={1}
              onChange={value => txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.FLOAT, `camera.${coord}`, value))
              }
            >
              <span>{lodash.capitalize(coord)}</span>
            </DraggerInput>
          ))}
        </Box>
      </Box>

      <ColorPickerField
        value={rgbToHex(clear)}
        onChange={color => txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.COL4, 'camera.clear', hexToRgb(color)))
        }
        label="Clear Color"
      />
    </Box>
  );
};

CameraElementInspector.propTypes = {
  ...InspectorInterface
};

const mapStateToProps = state => ({
  appId: getAppInfo(state).id
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CameraElementInspector));
