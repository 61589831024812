/**
 * Custom inspector for Experience elements.
 * Displays either ExperienceInspectorCore or SpacesInspector.
 */
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import ExperienceInspectorCore from '../experience/ExperienceInspectorCore';
import InspectorInterface from '../common/InspectorInterface';
import MeshesInspector from '../experience/MeshesInspector';
import SpacesInspector from '../spaces/MuiSpacesInspector';
import SpacesLinkInspector from '../spaces/SpaceLinkInspector';
import InspectableThumbnail from '../common/InspectableThumbnail';

const ExperienceElementInspector = ({ element, onUpdateElement }) => {
  const [renderDefaultView, setRenderDefaultView] = React.useState(true);

  const { appId } = element;

  return (
    <Box p={1}>
      {renderDefaultView && (
        <React.Fragment>
          <Box mb={0.5}>
            <Typography variant="h6">Experience Details</Typography>
          </Box>
          <InspectableThumbnail />
          <ExperienceInspectorCore appId={appId} urlPrefix={'i'} editable={true} />
          <SpacesLinkInspector
            root={element}
            onShowSpaces={() => setRenderDefaultView(false)}
            onUpdateElement={onUpdateElement}
          />
          <MeshesInspector root={element} />
        </React.Fragment>
      )}
      {!renderDefaultView && (
        <SpacesInspector root={element} onBack={() => setRenderDefaultView(true)} onUpdateElement={onUpdateElement} />
      )}
    </Box>
  );
};

ExperienceElementInspector.propTypes = {
  ...InspectorInterface,
  // All inspectors of this type take an element, but this one needs an element with the appId attached.
  element: PropTypes.shape({
    appId: PropTypes.string.isRequired
  }).isRequired
};

ExperienceElementInspector.defaultProps = {};

export default ExperienceElementInspector;
