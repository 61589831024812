import {
 Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import BaseButton from '../material-ui/BaseButton';
import CancelButton from '../material-ui/CancelButton';
import muiTheme, { defaultTheme } from '../../styles/muiTheme';

// const useStyles = makeStyles(theme => ({
//   confirmWrapper: props => ({
//     color: props.confirmIsDanger ? theme.palette.error.main : undefined
//   })
// }));

const themeDanger = createMuiTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: muiTheme.palette.error.main
    }
  }
});

const ConfirmationModal = (props) => {
  const {
 title, message, open, onClose, onConfirm, confirmIsDanger, isLoading, cancelLabel, confirmLabel
} = props;

  // const classes = useStyles({ confirmIsDanger });

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CancelButton onCancel={onClose}>
          {cancelLabel}
        </CancelButton>
        <MuiThemeProvider theme={confirmIsDanger ? themeDanger : undefined}>
          <BaseButton onClick={onConfirm} variant={'contained'} color={'primary'} disabled={isLoading}>
            {confirmLabel}
          </BaseButton>
        </MuiThemeProvider>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmIsDanger: PropTypes.bool
};

ConfirmationModal.defaultProps = {
  title: 'Are you sure?',
  message: 'Are you sure you want to perform this action?',
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
  isLoading: false
};

export default ConfirmationModal;
