import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

const getColor = (color, theme) => {
  switch (color) {
    case 'secondary':
      return theme.palette.secondary.main;
    case 'primary':
      return theme.palette.primary.main;
    case 'error':
      return theme.palette.error.main;
    default:
      return 'inherit';
  }
};

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: props => getColor(props.color, theme),
    '&:hover': {
      backgroundColor: props => getColor(props.color, theme),
      opacity: 0.75
    },
    '& .MuiIconButton-label': {
      color: theme.palette.common.white
    }
  }
}));

const FilledIconButton = ({
 icon, className, color, ...iconButtonProps
}) => {
  const classes = useStyles({ color });
  return (
    <IconButton {...iconButtonProps} className={clsx(className, classes.container)}>
      {React.createElement(icon)}
    </IconButton>
  );
};

FilledIconButton.propTypes = {
  icon: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.string
};

export default FilledIconButton;
