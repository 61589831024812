import { AsyncStatus, TrellisActions } from '@enklu/server-api';

import {
  TOUR_STAGE_SET,
  TOUR_STAGE_SET_MAX
} from '../actions/helpActions';

const { GETALLKVSBYTAG } = TrellisActions;

export const KV_TAG = 'help';
const primarySeparator = ',';
const secondarySeparator = '.';

const initialState = {
  stage: {
    max: 0,
    current: 0
  },
  kv: {
    byId: {},
    byTag: {} // of KVInterface[]
  }
};

const stage = (state = initialState.stage, { type, payload }) => {
  switch (type) {
    case TOUR_STAGE_SET: {
      // Reset to zero if we pass the end of the tour.
      return {
        current: payload > state.max ? 0 : payload,
        max: state.max
      };
    }

    case TOUR_STAGE_SET_MAX: {
      // If we're beyond the stage limit, bring it back.
      return {
        current: Math.min(state.current, payload),
        max: payload
      };
    }

    default: {
      return state;
    }
  }
};

const kv = (state = initialState.kv, { type, request, body }) => {
  switch (type) {
    case GETALLKVSBYTAG: {
      const { tags: kvTag } = request.replacements;
      if (kvTag.startsWith(KV_TAG)) {
        const newState = {
          byId: body.reduce((byId, kv) => ({
            ...byId,
            [kv.id]: kv
          }), state.byId),
          byTag: body.reduce((byTag, kv) => {
            // TODO If tags change, this will leave items orphaned under their last tag.
            const newByTag = kv.tags
              .split(primarySeparator)
              .reduce((accum1, namespacedTag) => {
                return namespacedTag.split(secondarySeparator)
                  .reduce((accum, tag, index, arr) => {
                    const newTag = arr.slice(0, index + 1)
                      .reduce((a, t) => `${a}.${t}`);
                    const newArr = accum[newTag] || [];
                    let oldKV;
                    if (newArr.length) {
                      oldKV = newArr.find(candidate => candidate.id === kv.id);
                    }

                    return {
                      ...accum,
                      [newTag]: oldKV ? newArr : [...newArr, kv]
                    };

                    // ? accum[newTag].filter((candidate) => candidate.id !== kv.id) : [];
                  }, accum1);
              }, byTag);

            return newByTag;
          }, state.byTag)
        };

        return newState;
      }
    }

    default: {
      return state;
    }
  }
};

export default (state = initialState, action) => ({
  stage: stage(state.stage, action),
  kv: action.status === AsyncStatus.SUCCESS ? kv(state.kv, action) : state.kv
});
