import {
 Actions, getActions, initActions, ioLoader, TrellisActions
} from '@enklu/server-api';
import { orderBy } from 'lodash';
import { log } from '../../util/log';

import { showmodal, hidemodal } from './modalActions';
import { getAppInfo } from '../selectors/appSelectors';
import { getUserCredentials, getUserProfile, getMyApps } from '../selectors/userSelectors';
import { assetList } from '../../util/bridgeMessages';
import { getAllAssets } from '../selectors/assetLibrariesSelectors';
import { getAllScripts } from '../selectors/scriptLibrariesSelectors';
import { getIframeId } from '../selectors/playerSelectors';
import { getGlobalSettingsIsLoaded, getRenderSettings } from '../selectors/globalSettingsSelectors';
import MessageTypes from '../../constants/MessageTypes';
import appSocketManager from './AppSocketManager';
import { getSpaceId } from '../selectors/spaceSelectors';
import spaceSocketManager from './SpaceSocketManager';
import playmodeLauncher from './PlaymodeLauncher';
import undoManager from './UndoManager';

initActions('trellis', { loader: ioLoader, baseUrl: window.env.trellisBaseUrl, Actions: TrellisActions });
const {
  createapp,
  getapp,
  getappassets,
  getappcollaborators,
  getappscripts,
  getmyapps,
  getpersonalassets,
  getpersonalscripts,
  getpublicassets,
  getpublicscripts,
  getpublishedapp,
  getpublishedassets,
  getpublishedscene,
  getpublishedappscripts,
  getscene,
  findspaces
} = getActions('trellis');

/**
 * Retrieves saved data for this app.
 */
export const GET_LOCAL_BLOB = 'init.getlocalblob';
export const getlocalblob = appId => ({
  type: GET_LOCAL_BLOB,
  appId
});

/**
 * Subscribes to Trellis for updates.
 */
export const SUBSCRIBE_ATTEMPT = 'subscribe.attempt';
export const SUBSCRIBE_SUCCESS = 'subscribe.success';
export const SUBSCRIBE_FAILURE = 'subscribe.failure';
export const appSubscribe = () => (dispatch, getState) => {
  dispatch({ type: SUBSCRIBE_ATTEMPT });
  // make subscribe request--but we don't need to wait for it
  const state = getState();
  const { id: appId } = getAppInfo(state);
  if (!appId) {
    return;
  }

  appSocketManager.connect(appId);
};

export const spaceSubscribe = () => (dispatch, getState) => {
  const spaceId = getSpaceId(getState());
  if (spaceId) {
    spaceSocketManager.connect(spaceId);
  }
};

export const informPlayer = () => async (dispatch, getState) => {
  let state = getState();
  window.bridge.reset();

  const iframeId = getIframeId(state);
  if (!window.bridge.registerFrame(iframeId)) {
    return;
  }

  console.log(' --- INFORMING PLAYER ---');

  window.bridge.onReady(() => {
    console.log(' --- BRIDGE READY ---');

    // refresh state
    state = getState();

    const { send } = window.bridge;

    // send init events:
    //  authorization, appInfo, assets, scripts, render settings
    const profile = getUserProfile(state);
    const credentials = getUserCredentials(state);
    send(MessageTypes.CREDENTIALS, { credentials, profile });

    const { id: appId, edit } = getAppInfo(state);
    send(MessageTypes.APP_INFO, { appId, edit });

    // TODO Why send this so often?
    send(MessageTypes.ENV_INFO, {
      trellisBaseUrl: `${window.env.trellisBaseUrl}/v1`,
      assetBaseUrl: window.env.assetBaseUrl,
      bundlesUrl: window.env.bundlesUrl,
      thumbsUrl: window.env.thumbsUrl,
      scriptsUrl: window.env.scriptsUrl,
      anchorsUrl: window.env.anchorsUrl,
      spacesUrl: window.env.spacesUrl
    });

    const assets = getAllAssets(state);
    send(MessageTypes.ASSET_LIST, assetList({ assets }));

    const scripts = getAllScripts(state);
    send(MessageTypes.SCRIPT_LIST, { scripts });

    send(MessageTypes.SPACE_LIST_SEND, { all: state.spaces.all });

    // dispatch(hidemodal());

    // ---
    const globalSettingsIsLoaded = getGlobalSettingsIsLoaded(state);

    if (globalSettingsIsLoaded) {
      const renderSettings = getRenderSettings(state);
      window.bridge.send(
        MessageTypes.INITIAL_EDITOR_SETTINGS,
        renderSettings.settings.reduce(
          (accum, { name, value }) => ({
            ...accum,
            [name]: value
          }),
          {}
        )
      );
    } else {
      console.log(' --- global settings not loaded ---');
    }
  });
};

export const INITIALIZE_RESET_STATUS = 'init.resetStatus';
export const initResetStatus = () => ({ type: INITIALIZE_RESET_STATUS });

export const INITIALIZE = 'init.initialize';

export const INITIALIZE_ERROR = 'init.initializeError';
const initializeError = error => ({ type: INITIALIZE_ERROR, payload: error });

export const INITIALIZE_COMPLETE = 'init.initializeComplete';
const initializeComplete = () => ({ type: INITIALIZE_COMPLETE });

export const loadApp = ({ appId }) => async (dispatch, getState) => {
  undoManager.reset();
  dispatch({ type: INITIALIZE, payload: { appId } });
  dispatch(initResetStatus());
  dispatch(showmodal({ blurVisible: true, spinnerVisible: false, message: 'Loading' }));

  const state = getState();
  const { id: userId } = getUserProfile(state);

  try {
    const {
      body: { scenes }
    } = await dispatch(getapp({ appId }));
    await Promise.all(scenes.map(sceneId => dispatch(getscene({ appId, sceneId }))));
    await Promise.all([
      dispatch(getappcollaborators({ appId })),
      dispatch(getpublicscripts({ tag: 'standard' })),
      dispatch(getpublicassets({ tag: 'standard' })),

      dispatch(getappassets({ appId })),
      dispatch(getappscripts({ appId })),

      dispatch(getpersonalscripts({ userId })),
      dispatch(getpersonalassets({ userId })),
      dispatch(findspaces({ owner: userId }))
    ]);

    await dispatch(getlocalblob(appId));
    await Promise.all([dispatch(appSubscribe()), dispatch(spaceSubscribe())]);

    await dispatch(informPlayer());
    await dispatch(playmodeLauncher.initialize);
  } catch (error) {
    // TODO If we arrive here, it's unrecoverable.
    console.error(error);
    dispatch(initializeError(error));
  }

  dispatch(hidemodal());
  dispatch(initializeComplete());
};

export const loadPublicApp = ({ publicAppId }) => async (dispatch, getState) => {
  console.log(' --- LOADING PUBLIC APP ---');
  undoManager.reset();
  dispatch({ type: INITIALIZE, payload: { appId: publicAppId } });
  dispatch(initResetStatus());
  dispatch(showmodal({ blurVisible: true, spinnerVisible: false, message: 'Loading' }));

  const state = getState();
  const { id: appId } = getAppInfo(state);
  console.log('appId: ' + appId);

  try {
    const {
      body: { scenes }
    } = await dispatch(getpublishedapp({ publicAppId }));

    await Promise.all(scenes.map(publicSceneId => dispatch(getpublishedscene({ publicAppId, publicSceneId }))));
    await Promise.all([
      // Most of these require JWTs.  Do we really need them?
      // dispatch(getappcollaborators({ appId })),
      // dispatch(getpublicscripts({ tag: 'standard' })),
      // dispatch(getpublicassets({ tag: 'standard' })),

      dispatch(getpublishedassets({ publicAppId })),
      dispatch(getpublishedappscripts({ publicAppId })),

      // dispatch(getpersonalscripts({ userId })),
      // dispatch(getpersonalassets({ userId })),
      // dispatch(findspaces({ owner: userId }))
    ]);

    await dispatch(getlocalblob(publicAppId));
    await Promise.all([dispatch(appSubscribe()), dispatch(spaceSubscribe())]);

    await dispatch(informPlayer());
    // await dispatch(playmodeLauncher.initialize);
  } catch (error) {
    // TODO If we arrive here, it's unrecoverable.
    console.error(error);
    dispatch(initializeError(error));
  }

  dispatch(hidemodal());
  dispatch(initializeComplete());
  console.log(' --- LOADING PUBLIC COMPLETED ---');
};

export const getPublicApp = ({ publicAppId }) => async (dispatch, getState) => {
  console.log(' --- GET PUBLIC APP ---');

  let publicAppScenes = {};

  try {
    const {
      body: { scenes }
    } = await dispatch(getpublishedapp({ publicAppId }));

    publicAppScenes = await Promise.all(scenes.map(publicSceneId => dispatch(getpublishedscene({ publicAppId, publicSceneId }))));
    publicAppScenes = publicAppScenes[0].response.body;

  } catch (error) {
    // TODO If we arrive here, it's unrecoverable.
    console.error(error);
    dispatch(initializeError(error));
  }

  console.log('publicAppScenes: ', publicAppScenes);
  console.log(' --- GET PUBLIC COMPLETED ---');
  return publicAppScenes;
};

export const loadLinkedComponent = ({ appId }) => async (dispatch, getState) => {
  await dispatch(getappassets({ appId }));
  const state = getState();
  const assets = getAllAssets(state);
  // Trying to do a soft reload here results in error and red bounding boxes half the time
  // window.bridge.send(MessageTypes.ASSET_LIST, assetList({ assets }));
};

export const initializeNewApp = ({ name, description }, { history }) => async (dispatch) => {
  const {
    body: { id: appId }
  } = await dispatch(createapp({ name, description }));
  await dispatch(getmyapps());
  await dispatch(loadApp({ appId }));
  history.push({ pathname: '/' });
};

export const initializeFromTemplate = ({ template }, { history }) => async (dispatch, getState) => {
  dispatch(showmodal({ blurVisible: true, spinnerVisible: true, message: `Creating a New ${template.name} Experience` }));
  await dispatch(
    createapp({
      name: `${template.name}`,
      description: template.description,
      appId: template.id
    })
  );
  await dispatch(getmyapps());
  const state = getState();
  const apps = getMyApps(state);
  const { id: userId } = getUserProfile(state);

  // after duplicating and reloading apps, assume the newest app
  // created by the current user is the one we want to load
  const orderedApps = orderBy(apps, [app => new Date(app.createdAt)], 'desc');
  let newest = {};
  for (const app of orderedApps) {
    if (app.owner === userId) {
      newest = app;
      break;
    }
  }

  await dispatch(loadApp({ appId: newest.id }));
  history.push({ pathname: '/' });
};

export const initializeUntitledApp = ({ history }) => initializeNewApp({ name: 'Untitled Experience', description: 'No Description' }, { history });

/**
 * Clear any previous error from the store.
 */
export const RESET_REQUESTS = 'reset_requests';
export const resetrequests = () => ({
  type: RESET_REQUESTS
});
