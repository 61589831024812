import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

// for when each onChange event takes longer than a keypress, buffer input to only change when input is blurred
const BufferedInput = ({
 value, onChange, input = TextField, ...otherProps
}) => {
  const [tempValue, setTempValue] = React.useState(value);
  const [hookedInput, setHookedInput] = React.useState(undefined);

  React.useEffect(() => {
    setTempValue(value);
  }, [value]);

  const onChangeHandle = (event, commit) => {
    const {
      target: { value: newValue }
    } = event;

    setTempValue(newValue);

    if (commit) {
      const duplicateEvent = Object.assign(event, { target: { value: newValue } });
      onChange(duplicateEvent);
    }
  };

  const hookInput = ref => {
    // Sometimes called with a null ref right before the prior ref again.
    if (!ref) return;

    const input = ref.getElementsByTagName('input')[0];
    if (!input) return;

    if (input === hookedInput) return;

    // Cleanup prior
    if (hookedInput) {
      hookedInput.removeEventListener('keydown');
    }

    input.addEventListener('keydown', event => {
      if (event.key === 'Enter') {
        event.target.blur();
      }
    });

    setHookedInput(input);
  }

  return React.createElement(input, {
    ...otherProps,
    value: tempValue,
    onChange: event => onChangeHandle(event, false),
    onBlur: event => onChangeHandle(event, true),
    ref: hookInput
  });
};

BufferedInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  input: PropTypes.object
};

export default BufferedInput;
