import queryString from 'query-string';

/**
 * A thunk that offers a single place to push nav state to the URL.
 * TODO Consider whether we should allow the rest of the store to hear about this.
 * @param navState - an object with the url param we are setting.
 */
export const pushNavState = (history, location, navState) => () => {
  const search = queryString.parse(location.search);
  const newSearch = { ...search, ...navState };
  history.push({
    ...location,
    search: queryString.stringify(newSearch)
  });
};
