import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import reactCSS from 'reactcss';

class EditableInput extends PureComponent {
  constructor(props) {
    super();

    this.state = {
      value: String(props.value).toUpperCase(),
      blurValue: String(props.value).toUpperCase()
    };
  }

  componentWillReceiveProps(nextProps) {
    const { input } = this;
    const { value: currentValue } = this.state;
    const { value: nextValue } = nextProps;

    if (nextValue !== currentValue) {
      if (input === document.activeElement) {
        this.setState({ blurValue: String(nextValue).toUpperCase() });
      } else {
        this.setState({ value: String(nextValue).toUpperCase() });
      }
    }
  }

  handleBlur = () => {
    if (this.state.blurValue) {
      this.setState({ value: this.state.blurValue, blurValue: null });
    }
  };

  handleChange = (e) => {
    const { label, onChange } = this.props;
    const {
      target: { value }
    } = e;

    if (onChange) {
      if (label) {
        onChange({ [label]: value }, e);
      } else {
        onChange(value, e);
      }
    }

    this.setState({ value });
  };

  render() {
    const { label: labelText, placeholder, style: { wrap = {}, input = {}, label = {} } = {} } = this.props;

    const styles = reactCSS(
      {
        default: {
          wrap: {
            position: 'relative'
          }
        },
        'user-override': {
          wrap,
          input,
          label
        },
        'dragLabel-true': {
          label: {
            cursor: 'ew-resize'
          }
        }
      },
      {
        'user-override': true
      },
      this.props
    );

    return (
      <div style={styles.wrap}>
        <input
          style={styles.input}
          ref={_input => (this.input = _input)}
          value={this.state.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          placeholder={placeholder}
          spellCheck="false"
        />
        {labelText ? <span style={styles.label}>{labelText}</span> : null}
      </div>
    );
  }
}

EditableInput.propTypes = {
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  dragLabel: PropTypes.string,
  dragMax: PropTypes.number,
  style: PropTypes.object.isRequired
};

EditableInput.defaultProps = {
  placeholder: ''
};

export default EditableInput;
