import { TrellisActions } from '@enklu/server-api';
import { THUMB_GENERATED, UPDATE_THUMB } from '../actions/thumbActions';

// Just a big ol' Record<AppId, Thumb> lookup.
const initialState = {};

const { CREATETHUMB, GETTHUMB } = TrellisActions;

export default (state = initialState, action) => {
    switch(action.type) {
        case GETTHUMB:
        case CREATETHUMB: {
            if (action.status !== 'success') return state;
            
            return {
                ...state,
                [action.body.id]: action.body
            }
        }
        case UPDATE_THUMB: 
        case THUMB_GENERATED: {
            return {
                ...state,
                [action.thumb.id]: action.thumb
            };
        }
    }
    return state;
};