/**
 * This component displays a list in one or two modes: "short" and "long." If a long mode is specified,
 * it also displays a button for switching between the two modes.
 *
 * @param small - the React component to display in short mode
 * @param startExpanded - whether to default to long mode
 * @param title - a title to display. If no title is provided, it will not display one.
 * @param children - the react components to add to short mode when in long mode
 */

import { Box, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main
  }
}));

const ExpandableList = ({
 small, children, startExpanded, title
}) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(startExpanded);

  return (
    <Box>
      {title && <Typography variant="h6">{title}</Typography>}
      <ul>
        {small}
        {isExpanded && children}
      </ul>
      {children && (
        <Link color="primary" onClick={() => setIsExpanded(!isExpanded)} className={classes.link}>
          <Typography>{!isExpanded ? 'More' : 'Less'}</Typography>
        </Link>
      )}
    </Box>
  );
};

ExpandableList.propTypes = {
  small: PropTypes.element.isRequired,
  children: PropTypes.element,
  startExpanded: PropTypes.bool,
  title: PropTypes.string
};

ExpandableList.defaultProps = {
  large: null,
  startExpanded: false
};

export default ExpandableList;
