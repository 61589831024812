import { SET_LIBRARY_CATEGORY, SET_OFFSET, SET_SCREEN_SIZE, SET_UI_VALUE } from '../actions/uiActions';
import { constrain, isObject } from '../../util/util';
import DrawerModes from '../../components/drawer/DrawerModes';

// TODO: Find a better spot to declare this
export const UsageTypes = {
  All: 'All',
  InUse: 'In Use',
  Unused: 'Unused'
};

export const initialState = {
  library: {
    usageFilter: UsageTypes.All,
    selectedCategoryName: {
      [DrawerModes.ASSETS]: '__app__',
      [DrawerModes.SCRIPTS]: '__app__'
    }
  },
  sizing: {
    isResizing: false,
    offsets: {
      current: {
        menu: 64,
        left: 344,
        right: 320,
        drawer: 360
      },
      min: {
        menu: 64,
        left: 345,
        right: 40,
        drawer: 46
      },
      max: {
        menu: 64,
        left: 1200,
        leftPct: 0.5,
        right: 1200,
        rightPct: 0.3,
        drawer: 1200,
        drawerPct: 0.75
      }
    },
    screen: {
      width: 800,
      height: 800,
      minWidth: 800,
      minHeight: 600
    }
  }
};

const createUpdater = (address, _initialState) => (state = _initialState, action) => {
  switch (action.type) {
    case SET_LIBRARY_CATEGORY:
      return {
        ...state,
        selectedCategoryName: {
          ...state.selectedCategoryName,
          [action.payload.mode]: action.payload.category
        }
      }

    case SET_UI_VALUE:
      const {
        payload: { name, value }
      } = action;
      const [targetAddress, paramName] = name.split('.');
      if (targetAddress !== address) {
        return state;
      }

      const currentValue = state[paramName];

      return {
        ...state,
        [paramName]: isObject(value) ? { ...currentValue, ...value } : value
      };

    default:
      return state;
  }
};

const library = createUpdater('library', initialState.library);

const sizing = (state = initialState.sizing, action) => {
  switch (action.type) {
    case SET_UI_VALUE: {
      // TODO Move this out.
      const { name, value } = action.payload;
      const [targetAddress, paramName] = name.split('.');
      if (targetAddress === 'sizing') {
        return {
          ...state,
          [paramName]: value
        };
      }
    }

    case SET_SCREEN_SIZE: {
      const { screen } = state;
      const { minWidth, minHeight } = screen;
      const { width = screen.width, height = screen.height } = action.payload;

      return {
        ...state,
        screen: {
          ...screen,
          width: Math.max(minWidth, width),
          height: Math.max(minHeight, height)
        }
      };
    }

    case SET_OFFSET: {
      const { id, value } = action.payload;
      const { offsets } = state;
      const {
        current,
        min: { [id]: min },
        max: { [id]: max }
      } = offsets;

      return {
        ...state,
        offsets: {
          ...offsets,
          current: {
            ...current,
            [id]: constrain(min, max, value)
          }
        }
      };
    }

    default: {
      return state;
    }
  }
};

// const _sizing = (state = initialState.sizing, action) => {}
//   const { payload: { name, value } } = action;
//   const [address, ...addressChunks] = name.split('.');
//   if (address !== 'sizing') {
//     return state;
//   }
//
//   // TODO Make this not horrible.
//   if (addressChunks[0] === 'isResizing') {
//     return {
//       ...state,
//       isResizing: value
//     };
//   }
//
//   if (addressChunks.join('.') === 'offsets.current') {
//     return {
//       ...state,
//       offsets: {
//         ...state.offsets,
//         current: {
//           ...state.offsets.current,
//           ...value
//         }
//       }
//     };
//   }
//
//   if (addressChunks[0] === 'screen') {
//     return {
//       ...state,
//       screen: {
//         ...state.screen,
//         ...value
//       }
//     };
//   }
//
//   return state;
// };

/**
 * This works with two-level params, like 'thing.otherThing'
 * @param state
 * @param action
 * @returns {{sizing: {offsets: {drawer: number}, isResizing: boolean}, library: {selectedScriptCategoryName: string, usageFilter: string, selectedAssetCategoryName: string, selectedLibraryType: string}}|{sizing: *, library: *}}
 */
export default (state = initialState, action) => ({
  library: library(state.library, action),
  sizing: sizing(state.sizing, action)
});
