import { FormControl, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import ColorPicker from '../inspector/colorPicker/ColorPicker';

const useStyles = makeStyles(theme => ({
  labeledInput: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: 0
  }
}));

const ColorPickerField = ({
 value, label, onChange, inputProps, ...otherProps
}) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth className={classes.labeledInput} {...otherProps}>
      <Typography>{label}</Typography>
      <ColorPicker color={value} onChange={onChange} {...inputProps} />
    </FormControl>
  );
};

ColorPickerField.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  inputProps: PropTypes.object
};

export default ColorPickerField;
