import {
 Box, Divider, IconButton, Menu, MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import BaseButton from './BaseButton';

// item is string or object
const MenuButton = ({
  children,
  items,
  onOpen,
  onItemClick,
  itemsDisabled,
  isIconButton,
  component,
  ...otherProps
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    if (onOpen) {
      onOpen(event);
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const listItems = items.map((item, index) => {
    let title;
    let customOnItemClick;
    let includeDivider;
    let props;

    if (typeof item === 'string') {
      title = item;
    } else {
      ({
 title, customOnItemClick, includeDivider, ...props
} = item);
    }

    const menuItem = (
      <MenuItem
        disabled={itemsDisabled}
        key={title}
        onClick={() => {
          handleClose();
          if (customOnItemClick) {
            customOnItemClick();
          } else if (onItemClick) {
            onItemClick(item, index);
          }
        }}
        {...props}
      >
        {title}
      </MenuItem>
    );

    if (includeDivider) {
      return (
        <div key={title}>
          {menuItem}
          <Divider />
        </div>
      );
    }
    return menuItem;
  });

  const ButtonComponent = isIconButton ? IconButton : BaseButton;

  return (
    <Box component={component}>
      <ButtonComponent color="inherit" onClick={handleClick} {...otherProps}>
        {children}
      </ButtonComponent>
      <Menu id="menu-appbar" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {listItems}
      </Menu>
    </Box>
  );
};

MenuButton.propTypes = {
  onItemClick: PropTypes.func,
  items: PropTypes.array.isRequired,
  onOpen: PropTypes.func,
  children: PropTypes.node,
  itemsDisabled: PropTypes.bool,
  isIconButton: PropTypes.bool,
  component: PropTypes.string
};

export default MenuButton;
