import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Qrious from 'qrious';
import uuid from 'uuid';

const DEFAULT_SIZE = 500;

class Qr extends React.Component {
  styles = {
    canvas: {
      width: DEFAULT_SIZE,
      height: DEFAULT_SIZE,
      margin: '10px 6.5px',
      position: 'relative',
      border: '1px solid lightgray',
      backgroundColor: '#ffffff'
    }
  };

  id = uuid();

  constructor(props) {
    super(props);
  }

  render() {
    const { canvas } = this.styles;
    const { payload } = this.props;

    if (this.qr) {
      this.qr.value = payload;
    }

    return (
      <div style={canvas}>
        <canvas id={`canvas_${this.id}`} />
      </div>
    );
  }

  componentDidMount() {
    this.qr = new Qrious({
      element: document.getElementById(`canvas_${this.id}`),
      value: this.props.payload,
      level: 'H',
      size: 275
    });
  }
};

Qr.propTypes = {
  payload: PropTypes.string.isRequired
};

// export default MouseHoveringDetection(Qr);
export default Qr;
