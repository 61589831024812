/**
 * This is a navigation component that allows the user to change the URL.
 * It changes the specified param, in the pattern ?paramName=value
 *
 * @param navItems - A list of strings to be used as titles (and param values).
 * @param paramName - The name of the URL param to change.
 * @param defaultNavIndex - The default nav item, should no param of name paramName be found in the URL. Defaults to 0.
 */
import { Paper, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import ld from 'lodash';
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.default
  }
}));

const UrlParamNav = ({
 navItems, paramName, defaultNavIndex, location: { search, pathname }, history
}) => {
  const query = queryString.parse(search);
  const { [paramName]: currentValue = navItems[defaultNavIndex] } = query;

  const handleCallToRouter = (_, value) => {
    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        [paramName]: value
      })
    });
  };

  const classes = useStyles();

  return (
    <Paper square elevation={3}>
      <Tabs
        value={currentValue}
        onChange={handleCallToRouter}
        variant="fullWidth"
        indicatorColor="primary"
        className={classes.paper}
      >
        {navItems.map(value => (
          <Tab key={value} value={value} label={ld.capitalize(value)} />
        ))}
      </Tabs>
    </Paper>
  );
};

UrlParamNav.propTypes = {
  navItems: PropTypes.array.isRequired,
  paramName: PropTypes.string.isRequired,
  defaultNavIndex: PropTypes.number,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

UrlParamNav.defaultProps = {
  defaultNavIndex: 0
};

export default withRouter(UrlParamNav);
