import { getActions } from '@enklu/server-api';

import { updateAction } from '../store/actions/elementActions';
import { elementById as elByIdInternal, parentByChildId as parentByChildIdInternal } from './elementHelpers';
import ActionSchemaTypes from '../constants/ActionSchemaTypes';

const { updatesceneelementstring } = getActions('trellis');

export const elementById = (element, elementId) => elByIdInternal(element, elementId);
export const parentByChildId = (element, elementId) => parentByChildIdInternal(element, elementId);

/**
 * Retrieves the meta object associated with an asset.
 * TODO Remove.
 */
export function metaForAsset(app, id) {
  for (var meta of app.assetsMeta) {
    if (meta.id === id) {
      return meta;
    }
  }

  return null;
}

/**
 * Retrieves a material object by id.
 * TODO Remove.
 */
export function materialById(app, id) {
  for (var material of app.materials) {
    if (material.id === id) {
      return material;
    }
  }

  return null;
}

/**
 * Retrieves a meterial for an asset.
 */
export function materialForAsset(app, id) {
  var meta = metaForAsset(app, id);
  if (meta) {
    return materialById(app, meta.materialId);
  }

  return null;
}

/**
 * Retrieves a scene by id from the state.
 * TODO Remove.
 */
export function sceneById(scenes, sceneId) {
  for (var scene of scenes) {
    if (scene.id === sceneId) {
      return scene;
    }
  }

  return null;
}

/**
 * Calls a method on each element of a scene.
 */
export function walkElement(element, cb) {
  if (!element) {
    return;
  }

  cb(element);

  for (var child of (element.children || [])) {
    cb(child);
  }
}

/**
 * Recursively finds all elements that reference a specific script and adds
 * them to the input array.
 */
function elementsReferencingScript(elements, element, scriptId) {
  if (!element) {
    return;
  }

  const {
    schema: {
      strings: {
        scripts = ''
      } = {}
    } = {},
    children = []
  } = element;

  if (-1 !== scripts.indexOf(scriptId)) {
    elements.push(element);
  }

  for (const child of children) {
    elementsReferencingScript(elements, child, scriptId);
  }
}

/**
 * Finds all elements that reference a specific script.
 */
export function getElementsReferencingScript(element, scriptId) {
  var elements = [];

  elementsReferencingScript(elements, element, scriptId);

  return elements;
}

/**
 * Removes a script from all elements that reference it.
 * TODO Move this to the reducer ASAP.
 *
 * @param appId
 * @param scenes
 * @param scriptId
 */
export const removeScriptFromElements = ({ appId, scriptId, scenes, dispatch }) => {
  scenes.forEach(({ elements, id: sceneId }) => {
    const actions = [];
    const referencingElements = getElementsReferencingScript(elements, scriptId);
    referencingElements.forEach(element => {
      const scripts = JSON.parse(element.schema.strings.scripts);
      for (let i = scripts.length - 1; i >= 0; i--) {
        const script = scripts[i];
        if (script.id === scriptId) {
          scripts.splice(i, 1);
        }
      }

      actions.push(updateAction(
        element.id,
        ActionSchemaTypes.STRING,
        'scripts',
        JSON.stringify(scripts)));
    });

    if (actions.length) {
      // send txn
      // This is NOT undoable.
      dispatch(updatesceneelementstring(
        { actions },
        {
          appId,
          sceneId
        }
      ));
    }
  });
};

/**
 * Removes an asset from all elements that reference it.
 * TODO: move into reducer
 */
export const removeAssetFromElements = ({ appId, assetId, scenes, dispatch }) => {
  scenes.forEach(({ id: sceneId, elements }) => {
    const actions = [];
    const referencingElements = getElementsReferencingAsset(elements, assetId);
    referencingElements.forEach(({ id: elementId }) => {
      actions.push(
        updateAction(
          elementId,
          ActionSchemaTypes.STRING,
          'assetSrc',
          ''
        )
      );
    });

    if (actions.length) {
      // send txn
      dispatch(
        updatesceneelementstring(
          { actions },
          {
            appId,
            sceneId
          }
        )
      );
    }
  });
};

/**
 * Recursively finds all elements that reference a specific asset and adds
 * them to the input array.
 */
function elementsReferencingAsset(elements, element, assetId) {
  if (!element) {
    return;
  }

  const {
    schema: {
      strings: {
        assetSrc = ''
      } = {}
    } = {},
    children = []
  } = element;

  if (assetSrc === assetId) {
    elements.push(element);
  }

  for (const child of children) {
    elementsReferencingAsset(elements, child, assetId);
  }
}

/**
 * Finds all elements that reference a specific asset.
 */
export function getElementsReferencingAsset(element, assetId) {
  var elements = [];

  elementsReferencingAsset(elements, element, assetId);

  return elements;
}
