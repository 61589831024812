import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import * as Sentry from '@sentry/browser';

import BaseButton from '../components/material-ui/BaseButton';

/**
 * Fullscreen error text.
 */

const FatalError = ({ eventId }) => (
  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
    <Box mt={4} mb={2}>
      <Typography variant="h3">Fatal Error</Typography>
      <Typography>The Enklu Editor suffered a fatal blow. Refresh or try again later.</Typography>
    </Box>
    <BaseButton variant="contained" color="primary" size="large" onClick={() => Sentry.showReportDialog({ eventId })}>
      File a Report
    </BaseButton>
  </Box>
);

FatalError.propTypes = {
  eventId: PropTypes.string.isRequired
};

export default FatalError;
