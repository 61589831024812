import {
  Box, Popper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { findIndex } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { typesAll } from '../constants/FileTypes';
import DragDropTypes from '../features/dragAndDrop/DragDropTypes';
import {
  addAssetToElement as _addAssetToElement, createAction
} from '../store/actions/elementActions';
import txns from '../store/actions/TxnManager';
import { getAppInfo } from '../store/selectors/appSelectors';
import { getScenes } from '../store/selectors/scenesSelectors';
import { elementById } from '../util/elementHelpers';
import { uuidv4 } from '../util/util';
import AssetLoadingBox from './AssetLoadingBox';

const useStyles = makeStyles(() => ({
  canvasOverlays: {
    height: '100%',
    position: 'absolute',
    width: '100%',
    pointerEvents: 'none',
  },
  dragDropOverlayWrapper: {
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  dragDropInterior: {
    borderRadius: 8,
    borderStyle: 'dashed',
    borderColor: 'white',
    borderWidth: 4,
  },
}));

const OverlayDropLayer = ({
    appId,
    scenes: [scene],
    addAssetToElement,
    createElement,
  }) => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  const [anchorEls, setAnchorEls] = React.useState([]);

  const { elements, id: sceneId } = scene;

  const elementsRef = useRef();
  elementsRef.current = elements;

  const anchorElsRef = useRef();
  anchorElsRef.current = anchorEls;

  const [iframeEntered, setIframeEntered] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'DRAG ENTER') {
        // console.log('ENTERED ENTER');
        setIframeEntered(true);
      }
      // if (event.data === 'DRAG LEAVE') {
      //   console.log('ENTERED LEAVE');
      //   // setIframeEntered(false);
      // }
    }, false);
  }, []);

  const createElementFromAsset = (item) => {
    const { item: newAsset } = item;
    const nodeId = uuidv4();
    const parentId = 'root';
    // First create a new element
    createElement({
      appId,
      undefined,
      sceneId,
      parentId,
      nodeId
    }).then(() => {
      history.push({
        ...location,
        pathname: `/i/scene/${sceneId}/element/${nodeId}`
      });
      window.bridge.send(3991, {
        sceneId: scene.id,
        elementId: nodeId
      });

      const element = elementById(elementsRef.current, nodeId);
      // Then attach the asset to the new element
      addAssetToElement({
        sceneId,
        element,
        asset: newAsset,
        oldAsset: undefined
      });
    });
  };

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: ['item', DragDropTypes.ASSET, NativeTypes.FILE],
      canDrop: item => item.type === DragDropTypes.ASSET || item.files,
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop: (item, monitor) => {
        if (item.files) {
          for (let i = 0; i < item.files.length; i++) {
            const droppedFile = item.files[i];
            const fileName = droppedFile.name.split('.')[0];
            const fileExtension = droppedFile.name.split('.')
              .pop()
              .toLowerCase();
            if (!typesAll.includes(fileExtension)) {
              return;
            }

            // TODO: Properly wait for upload to complete instead of hardcoding a delay
            const waitForUploadDelay = 8000 + Math.min((droppedFile.size * 0.25), 22000);

            const formData = new FormData();
            formData.append('qqfilename', droppedFile.name);
            formData.append('qqfile', droppedFile);

            const headers = {
              Authorization: `Bearer ${io.sails.token}`,
              app: appId
            };

            fetch(`${window.env.assetBaseUrl}/v1/asset`, {
              method: 'POST',
              headers,
              body: formData,
            })
              .then(response => response.json())
              .then((data) => {
                if (!data.success) {
                  throw new Error(data.error);
                }
                console.log('Success:', data);

                // TODO: Properly wait for upload to complete instead of hardcoding a delay
                setTimeout(() => {
                  createElementFromAsset({
                    item: {
                      id: data.body.id,
                      name: fileName
                    },
                    type: 'asset'
                  });
                }, waitForUploadDelay);
              })
              .catch((error) => {
                console.error('Error:', error);
              })
              .finally(() => {
                // close the loading box
                // TODO: Properly wait for upload to complete instead of hardcoding a delay
                setTimeout(() => {
                  const currAnchorEls = anchorElsRef.current;
                  const indexToRemove = findIndex(currAnchorEls, ae => ae.file.name === droppedFile.name);
                  if (indexToRemove !== -1) {
                    const copy = currAnchorEls.slice();
                    copy.splice(indexToRemove, 1);
                    setAnchorEls(copy);
                  }
                }, waitForUploadDelay);
              });

            // reader.readAsDataURL(droppedFile);

            const {
              x,
              y
            } = monitor.getClientOffset();
            if (x !== undefined && y !== undefined) {
              const copy = anchorEls.slice();
              copy.push({
                file: droppedFile,
                clientWidth: 0,
                clientHeight: 0,
                getBoundingClientRect: () => ({
                  top: y,
                  right: x,
                  bottom: y,
                  left: x,
                  width: 0,
                  height: 0,
                }),
              });
              setAnchorEls(copy);
            }
          }
        // Otherwise this is from the asset library and can be attached to an element
        } else if (item.type === DragDropTypes.ASSET) {
          createElementFromAsset(item);
        }
      }
    }), [anchorEls, elements, history, location]
  );

  React.useEffect(() => {
    if (!canDrop) {
      setIframeEntered(false);
    }
  }, [canDrop]);

  return (
    <Box className={classes.canvasOverlays}
      style={{ pointerEvents: iframeEntered ? 'auto' : 'none' }}
      // Without this, Firefox tries to do a redirect
      onDrop={(event) => {
       event.preventDefault();
      }}
    >
      <Box
        ref={drop}
        display="flex" className={classes.dragDropOverlayWrapper}
        style={{
          pointerEvents: canDrop ? 'auto' : 'none',
          backgroundColor: (isOver && canDrop) ? '#2DCCD3' : 'gray',
          opacity: canDrop ? 0.5 : 0
        }}>
        <Box flex={1} m={1} className={classes.dragDropInterior} />
      </Box>
      {(anchorEls.map((ae, index) => <Popper key={index} open={true} anchorEl={ae} transition placement="top">
        <AssetLoadingBox file={ae.file} />
      </Popper>))}

    </Box>

  );
};

OverlayDropLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  appId: PropTypes.string.isRequired,
  scenes: PropTypes.array.isRequired,
  addAssetToElement: PropTypes.func.isRequired,
  createElement: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  appId: getAppInfo(state).id,
  scenes: getScenes(state),
});

const mapDispatchToProps = dispatch => ({
  /**
   * Adds an asset to an element.
   */
  addAssetToElement: ({
    sceneId, element, asset, oldAsset
  }) => dispatch(
    _addAssetToElement({
      sceneId,
      element,
      asset,
      oldAsset
    })
  ),
  createElement: ({
    type, sceneId, parentId, strings = {}, nodeId
  }) => txns.request(sceneId, createAction(parentId, strings, type, nodeId)).then(() => { }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverlayDropLayer);
