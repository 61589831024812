import {
  Actions, getActions, initActions, ioLoader, TrellisActions
} from '@enklu/server-api';
import { getAppInfo } from '../selectors/appSelectors';

initActions('trellis', { loader: ioLoader, baseUrl: window.env.trellisBaseUrl, Actions: TrellisActions });

const {
  getapp,
  publishapp,
  unpublishapp
} = getActions('trellis');

export const publishApp = ({ appId }) => async (dispatch, getState) => {
  try {
    await dispatch(publishapp({}, { appId }));
  } catch (error) {
    console.error('Unable to publish: ', error);
  }

  const {
    body: { ispublic }
  } = await dispatch(getapp({ appId }));

  return ispublic;
};

export const unpublishApp = ({ appId }) => async (dispatch, getState) => {
  try {
    await dispatch(unpublishapp({}, { appId }));
  } catch (error) {
    console.error('Unable to unpublish: ', error);
  }

  const {
    body: { ispublic }
  } = await dispatch(getapp({ appId }));

  return ispublic;
};
