/* eslint-disable react/display-name */
import React from 'react';

import AccountModal from './MuiAccountModal';
import CreateExperienceModal from './MuiCreateExperienceModal';
import LoginHoloLensModal from './MuiLoginHoloLensModal';
import ModalTypes from '../../constants/ModalTypes';
import MuiInviteCollaboratorsModal from './MuiInviteCollaboratorsModal';
import SelectExperienceModal from './MuiSelectExperienceModal';

export default {
  [ModalTypes.NEW]: {
    title: 'New Experience',
    // eslint-disable-next-line react/prop-types
    render: ({ onClose }) => <CreateExperienceModal onClose={onClose} />
  },
  [ModalTypes.OPEN]: {
    title: 'My Experiences',
    render: ({ onClose }) => <SelectExperienceModal showClose onClose={onClose} />
  },
  [ModalTypes.SHARE]: {
    title: 'Collaborators',
    overrideDefault: true,
    render: ({ onClose }) => <MuiInviteCollaboratorsModal onClose={onClose} />
  },
  [ModalTypes.HOLOLENS_LOGIN]: {
    title: 'HoloLogin',
    render: () => <LoginHoloLensModal />
  },
  [ModalTypes.MANAGE_MY_ACCOUNT]: {
    title: 'My Account',
    render: () => <AccountModal />
  },
  [ModalTypes.PREFERENCES]: {
    title: 'Preferences',
    render: () => null
  }
};
