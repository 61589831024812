import { Slider, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

// for when each onChange event takes longer than a keypress, buffer input to only change when input is blurred
const BufferedSlider = ({ value, onChange, onPreview, min, max, step, autoMark, ...otherProps }) => {
  const [tempValue, setTempValue] = React.useState(value);

  React.useEffect(() => {
    setTempValue(value);
  }, [value]);

  const onChangeHandle = (value, commit) => {
    setTempValue(value);

    if (commit) {
      onChange(value);
    } else if (onPreview) {
      onPreview(value);
    }
  };

  let marks = undefined;
  switch (autoMark) {
    case BufferedSlider.MarkType.Coarse:
      marks = [
        { value: min, label: min },
        { value: max, label: max }
      ];
      break;

    case BufferedSlider.MarkType.Fine:
      const range = max - min;
      const stops = range / step;

      marks = [{ value: max, label: max }];
      for (let i = 0; i <= stops; i++) {
        marks.push({ value: i, label: i })
      }
      break;
  }

  return <Box display='flex' flexDirection='row'>
    {React.createElement(Slider, {
      ...otherProps,
      min,
      max,
      step,
      marks,
      value: tempValue,
      onChange: (_, newValue) => onChangeHandle(newValue, false),
      onChangeCommitted: (_, newValue) => onChangeHandle(newValue, true)
    })}
    <Typography align='left' variant='subtitle2' style={{ marginTop: '5px', marginLeft: '15px', width: '30px' }}>
      {tempValue}
    </Typography>
  </Box>
};

BufferedSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
  onPreview: PropTypes.func,
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
  autoMark: PropTypes.string
};

BufferedSlider.MarkType = {
  Coarse: 'coarse',
  Fine: 'fine'
};

export default BufferedSlider;
