import { Box } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { getAllAssetsById } from '../../store/selectors/assetLibrariesSelectors';
import { getAllScriptsById } from '../../store/selectors/scriptLibrariesSelectors';
import { getScreenSize, getUIOffsets } from '../../store/selectors/uiSelectors';
import AssetInspector from './asset/AssetInspector';
import DragBar from '../../features/dragBar/DragBar';
import DragBarIds from '../../features/dragBar/DragBarIds';
import ElementInspector from './element/ElementInspector';
import NoInspectable from './common/NoInspectable';
import ScriptInspector from './script/ScriptInspector';

/**
 * Renders the inspector.
 */
class Inspector extends React.Component {
  /**
   * Renders.
   */
  render() {
    const {
      assets,
      scripts,
      uiOffsets: { current },
      screenSize,
      style,
      classes
    } = this.props;

    return (
      <Box className={classes.inspector} style={style}>
        <DragBar id={DragBarIds.RIGHT} size={screenSize.height - current.menu} />
        <Box flex={1} display="flex" flexDirection="column">
          <Switch>
            {/* Element Inspector */}
            <Route path="/i/scene/:sceneId/element/:elementId" render={() => <ElementInspector />} />

            {/* Asset Inspector */}
            <Route
              path="/i/asset/:assetId"
              render={(props) => {
                const { assetId } = props.match.params;
                const asset = assets[assetId];

                return <AssetInspector asset={asset} />;
              }}
            />

            {/* Script Inspector */}
            <Route
              path="/i/script/:scriptId"
              render={(props) => {
                const { scriptId } = props.match.params;
                const script = scripts[scriptId];

                return <ScriptInspector script={script} />;
              }}
            />

            <Route path="*" render={() => <NoInspectable />} />
          </Switch>
        </Box>
      </Box>
    );
  }
}

Inspector.propTypes = {
  assets: PropTypes.object.isRequired,
  scripts: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  uiOffsets: PropTypes.object.isRequired,
  screenSize: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

Inspector.defaultProps = {
  style: {}
};

const mapStateToProps = state => ({
  assets: getAllAssetsById(state),
  scripts: getAllScriptsById(state),
  uiOffsets: getUIOffsets(state),
  screenSize: getScreenSize(state)
});

export default withStyles({
  inspector: {
    display: 'flex',
    alignItems: 'stretch',
    '&$panel-block': {
      marginBottom: '20px'
    },
    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0
    }
  }
})(withRouter(connect(mapStateToProps)(Inspector)));
