import { Box, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { elementsByType } from '../../../util/elementHelpers';
import ElementTypes from '../../../constants/ElementTypes';
import MeshInspector from './MeshInspector';

const MeshesInspector = ({ root }) => {
  const meshes = elementsByType(root, ElementTypes.SCAN) || [];

  const foundMeshes = meshes.length > 0;
  const multipleMeshes = meshes.length > 1;
  const meshData = meshes.map(({ schema = {} }) => {
    const { strings = {} } = schema;
    return {
      name: strings.name,
      id: strings.srcId,
      url: strings.srcUrl
    };
  });

  return (
    foundMeshes && (
      <React.Fragment>
        <Box pt={2}>
          <Typography variant="h6">Mesh Scan{multipleMeshes ? 's' : ''}</Typography>
          <Grid container>
            {meshData.map(m => (
              <Grid key={m.id} item xs={12} spacing={2}>
                <MeshInspector meshData={m} showName={multipleMeshes} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </React.Fragment>
    )
  );
};

MeshesInspector.propTypes = {
  root: PropTypes.object.isRequired
};

export default MeshesInspector;
