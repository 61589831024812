import { SELECT_NODE, TOGGLENODE } from '../../actions/inspector/treeActions';
import { GET_LOCAL_BLOB } from '../../actions/initializeActions';

const initialState = {
  expanded: []
};

const expanded = (state = initialState.expanded, action) => {
  switch (action.type) {
    case GET_LOCAL_BLOB: {
      const key = `__${action.appId}__`;

      const strValue = window.localStorage.getItem(key);
      if (strValue) {
        const blob = JSON.parse(strValue);

        if (blob.version === window.config.localDataVersion) {
          const {
            inspector: {
              tree: { expanded: newState = [] } = {}
            } = {}
          } = blob;

          return newState;
        }
      }

      return state;
    }

    case TOGGLENODE: {
      const newState = [...state];
      if (action.expanded) {
        newState.push(action.elementId);
      } else {
        const index = newState.indexOf(action.elementId);
        if (index !== -1) {
          newState.splice(index, 1);
        }
      }

      return newState;
    }

    default: {
      return state;
    }
  }
};

const tree = (state = initialState, action) => {
  return {
    expanded: expanded(state.expanded, action)
  };
};

export default tree;
