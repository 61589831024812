import { AsyncStatus, TrellisActions } from '@enklu/server-api';
import { ADD_UPLOADING_ASSET, ASSET_ERROR } from '../actions/assetActions';
import {SIGNUP} from '../actions/signupActions';
import { EMAILSIGNIN, INVITEUSER } from '@enklu/server-api/src/js/actions/trellisActions';
import { UPDATESCENE } from '../actions/TxnManager';
import ElementTypes from '../../constants/ElementTypes';
import { elementTypeToString } from '../../constants/ElementTypes';
import MetricsTypes from '../../constants/MetricsTypes';
import { metricsSchemaKeys, actionToMetric } from '../../constants/MetricsTypes';
import * as Sentry from '@sentry/browser';

const {
  GETAPP, CREATEAPP, CREATEAPPCOLLABORATOR, CREATESCRIPT, UPDATESCRIPT, UPDATEANASSETTHUMBNAIL, UPDATEAPPCOLLABORATOR,
  DELETEAPP, DELETEAPPCOLLABORATOR, DELETEMYASSET, DELETESCRIPT, DELETESPACE, UPDATESPACE
} = TrellisActions;

const toPayload = ({ request: { replacements = {} } = {} }) => replacements;

// eslint-disable-next-line no-unused-vars
const metrics = store => next => (action) => {
  const { type, status } = action;
  const { mixpanel } = window;

  if (mixpanel) {
    if (status !== AsyncStatus.IN_PROGRESS && status !== AsyncStatus.FAILURE) {
      switch (type) {
        case SIGNUP:{
          try {
          const eventKey = actionToMetric(type);
          mixpanel.track(eventKey, toPayload(action));
        } catch (err) {
          Sentry.captureException(err);
        }
        break;}

        case EMAILSIGNIN: {
          const { body: { user } } = action;

          try {
            mixpanel.identify(user.id);

            mixpanel.people.set({
              $email: user.email,
              $handle: user.displayName,
              USER_ID: user.id
            });

            const eventKey = actionToMetric(type);
            mixpanel.track(eventKey);
          } catch (err) {
            Sentry.captureException(err);
          }

          break;
        }

        // case ADD_UPLOADING_ASSET: {;
        //   const {body: {asset}} = action
        //   try {
        //     const eventKey = actionToMetric(type);
        //     console.log(asset.size)
        //     mixpanel.track(eventKey, toPayload(action), asset.size)
        //   } catch (err) {
        //     Sentry.captureException(err);
        //   }
        //   break;}

        case ADD_UPLOADING_ASSET:
        case ASSET_ERROR:
        case GETAPP:
        case CREATEAPP:
        case CREATEAPPCOLLABORATOR:
        case INVITEUSER:
        case CREATESCRIPT:
        case UPDATESCRIPT:
        case UPDATEANASSETTHUMBNAIL:
        case UPDATEAPPCOLLABORATOR:
        case DELETEAPP:
        case DELETEAPPCOLLABORATOR:
        case DELETEMYASSET:
        case DELETESCRIPT:
        case DELETESPACE:
        case UPDATESPACE:
          {
          try {
            const eventKey = actionToMetric(type);
            mixpanel.track(eventKey, toPayload(action));
          } catch (err) {
            Sentry.captureException(err);
          }

          break;
        }
        case UPDATESCENE: {
          try {
            trackSceneUpdate(mixpanel, action);
          } catch(err) {
            Sentry.captureException(err);
          }
          break;
        }

        default: {
          break;
        }
      }
    }
  }

  next(action);
};

const trackSceneUpdate = (mixpanel, action) => {
  if (!action || !mixpanel) {
    return;
  }

  const request = action.request || {};
  const body = request.body || {};
  const actions = body.actions || [];

  for (const actionData of actions) {
    if (!actionData) {
      continue;
    }

    const actionType = actionData.type;
    const key = actionData.key;
    const metricsData = actionData.metrics;
    const data = actionData.data  || {};
    const elementType = data.type || ElementTypes.UNKNOWN;

    // track if element type is defined or it has a key
    // we care about.
    let subType = elementTypeToString(elementType);
    if (!subType && metricsSchemaKeys.includes(key)) {
      subType = key;
    }

    if (actionType && subType) {
      const event = `${MetricsTypes.SCENE_UPDATE}.${actionType}.${subType}`;
      let payload = toPayload(action);
      if (metricsData) {
        payload = { ...payload, ...metricsData };
      }
      mixpanel.track(event, payload);
    }
  }
};

export const sendMetric = (event, payload) => {
  if (!window.mixpanel || !event) {
    return;
  }

  try {
    window.mixpanel.track(event, payload);
  } catch(err) {
    Sentry.captureException(err);
  }
};

export default metrics;
