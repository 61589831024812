/**
 * A component for setting global settings. Wraps a single ControlPanel and provides its props.
 */
import {
  Box,
  Typography,
  ExpansionPanel as Accordion,
  ExpansionPanelSummary as AccordionSummary,
  ExpansionPanelDetails as AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { getAppIsReadOnly } from '../../store/selectors/appSelectors';
import { getGeneralSettings, getRenderSettings } from '../../store/selectors/globalSettingsSelectors';
import { setsetting } from '../../store/actions/settingsActions';
import ControlPanel from '../common/MuiControlPanel';
import undoManager from '../../store/actions/UndoManager';

const EditorSettings = ({ generalSettings, renderSettings, onSetSetting, isReadOnly }) => (
  <Box flex={1} p={1}>
      {renderCategory(generalSettings, onSetSetting, isReadOnly)}
      {renderCategory(renderSettings, onSetSetting, isReadOnly)}
  </Box>
);

function renderCategory(category, onSetSetting, isReadOnly) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant='h6'>{category.displayName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: '100%' }}>
          <ControlPanel
            settingsCategory={{...category, displayName: ''}}
            onChange={({
                         category, setting, value, defaultValue
                       }) => {
              const undoValue = setting.value;
              undoManager.register(
                () => onSetSetting({
                  category,
                  setting,
                  value,
                  defaultValue
                }),
                () => onSetSetting({
                  category,
                  setting,
                  value: undoValue,
                  defaultValue
                })
              );

              onSetSetting({
                category,
                setting,
                value,
                defaultValue
              });
            }}
            isReadOnly={isReadOnly}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

EditorSettings.propTypes = {
  generalSettings: PropTypes.object.isRequired,
  renderSettings: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  onSetSetting: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  generalSettings: getGeneralSettings(state),
  renderSettings: getRenderSettings(state),
  isReadOnly: getAppIsReadOnly(state)
});

const dispatchMap = {
  onSetSetting: setsetting
};

export default compose(connect(mapStateToProps, dispatchMap))(EditorSettings);
