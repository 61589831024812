import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';

// Button that links to external page containing our privacy policy.
const MuiPrivacyPolicyLink = ({ history, location }) => (
  <Box position="absolute" bottom={7} right={7}>
    <Button
        href="https://enklu.notion.site/Privacy-Policy-1e8b78b8e6a9433797a3864a91b0a7e6"
        target="_blank" 

        // UNUSED: If you want to redirect to the privacy policy react page instead
        // onClick={(event) => {
        //   // history.push({ location, pathname: '#/privacypolicy' });
        // }}
    >
      <Typography variant='h6'>Privacy Policy</Typography>
    </Button>
  </Box>
);

export default MuiPrivacyPolicyLink;
