/**
 * Complete listing of everyone in the room.
 */
export const PRESENCELIST = 'presence.list';

export function list(users) {
  return {
    type: PRESENCELIST,
    users
  };
}

/**
 * When a user joins.
 */
export const PRESENCEJOINED = 'presence.joined';

export function joined(user) {
  return {
    type: PRESENCEJOINED,
    user
  };
}

/**
 * When a user leaves.
 */
export const PRESENCELEFT = 'presence.left';

export function left(socketId) {
  return {
    type: PRESENCELEFT,
    socketId
  };
}
