import { Actions } from '@enklu/server-api';

const { FATALERROR } = Actions;

export default (state = { fatalError: '' }, action) => {
  if (action.type === FATALERROR) {
    return {
      ...state,
      fatalError: action.error
    };
  }

  return state;
};
