import { FileTypeCategories } from '../../../constants/FileTypes';

const defaultOption = {
  name: 'OPTION',
  type: 'text',
  possibleValues: [],
  value: 'EMPTY',
  requires: null
};

const createOption = overrides => ({
  ...defaultOption,
  ...overrides
});

const defaultPdfOptions = () => [];

const defaultTextureOptions = () => ([
  createOption({
    name: 'transparent',
    type: 'checkbox',
    value: true
  })
]);

const defaultAudioOptions = () => ([
  createOption({
    name: 'looping',
    type: 'checkbox',
    value: false
  }),
  createOption({
    name: 'streaming',
    type: 'checkbox',
    value: true
  }),
  createOption({
    name: '3D',
    type: 'checkbox',
    value: true
  }),
  {
    name: 'range',
    type: 'number',
    value: 2,
    requires: '3D'
  }
]);

const defaultVideoOptions = () => ([
  createOption({
    name: 'looping',
    type: 'checkbox',
    value: false
  })
]);

const defaultObjOptions = () => [];

const createDefaultOptions = (type) => {
  if (!Object.prototype.hasOwnProperty.call(FileTypeCategories, type)) {
    return [];
  }

  return {
    [FileTypeCategories.PDF]: defaultPdfOptions,
    [FileTypeCategories.IMAGE]: defaultTextureOptions,
    [FileTypeCategories.AUDIO]: defaultAudioOptions,
    [FileTypeCategories.VIDEO]: defaultVideoOptions,
    [FileTypeCategories.OBJECT]: defaultObjOptions
  }[type]();
};

const filterOptions = (options) => {
  return options.filter((option, index, array) => {
    if (option.requires) {
      const requiredOption = array.find(_option => _option.name === option.requires);
      return requiredOption ? !!requiredOption.value : true;
    }

    return option;
  });
};

export {
  defaultPdfOptions, defaultTextureOptions, defaultAudioOptions, defaultVideoOptions, createDefaultOptions, filterOptions
};
