import {
  Box, IconButton, Typography, Tooltip
} from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLogFilteredMessages, getLogNumMessages } from '../../store/selectors/logSelectors';
import { getDebug } from '../../store/selectors/debugSelectors';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  subMessage: {
    fontSize: '75%'
  }
}));

/**
 * Displays common information for all log targets.
 */
const LogFooter = ({
  totalMessageCount,
  totalVisibleCount,
  logClear,
  debug: { context: { logging }}
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
      <Tooltip title="Clear All">
        <IconButton onClick={logClear} size="small">
          <HighlightOff />
        </IconButton>
      </Tooltip>
      <Typography className={classes.subMessage}>
        {logging
          ? `${totalMessageCount} messages (${totalMessageCount - totalVisibleCount} hidden)`
          : 'Logging is disabled (see options on login screen).'}
      </Typography>
      <div />
    </Box>
  )
}

LogFooter.propTypes = {
  totalMessageCount: PropTypes.number.isRequired,
  totalVisibleCount: PropTypes.number.isRequired,
  logClear: PropTypes.func.isRequired,

  debug: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  filteredMessages: getLogFilteredMessages(state),
  numTotalMessages: getLogNumMessages(state),
  debug: getDebug(state)
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(LogFooter);
