/**
 * Asset messages.
 */

/**
 * Creates a message for adding an asset.
 */
export const assetAdd = ({ asset }) => ({
  asset: {
    ...asset,
    stats: typeof asset.stats === 'string' ? JSON.parse(asset.stats) : asset.stats
  }
});

/**
 * Creates a message for updating an asset's stats.
 */
export const assetStats = ({ assetId, stats }) => ({
  assetId,
  stats: typeof stats === 'string' ? JSON.parse(stats) : stats
});

/**
 * Creates a message for sending a list of assets.
 */
export const assetList = ({ assets }) => ({ assets });

/**
 * Creates a message for updating an asset.
 */
export const assetUpdate = ({ asset }) => ({ asset });