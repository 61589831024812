import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import DraggerInput from './MuiDraggerInput';

const useStyles = makeStyles(theme => ({
  container: {
    '& > :first-child': {
      marginLeft: theme.spacing(0)
    },
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  }
}));

const VectorInput = ({
 value: currentValue, disabled, onChange, draggerScale, mod, onPreview
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      className={classes.container}
      mt={2}
    >
      {['x', 'y', 'z'].map(coord => (
        <DraggerInput
          key={coord}
          value={currentValue[coord]}
          scale={draggerScale}
          disabled={disabled}
          mod={mod}
          onPreview={value => onPreview({
              ...currentValue,
              [coord]: value
            })
          }
          onChange={value => onChange({
              ...currentValue,
              [coord]: value
            })
          }
        >
          <span>{coord}</span>
        </DraggerInput>
      ))}
    </Box>
  );
};
// <TextField
//   key={coord}
//   label={coord}
//   type="number"
//   margin="dense"
//   variant="outlined"
//   disabled={disabled}
//   value={currentValue[coord]}
//   onChange={({ target: { value } }) => {
//     onChange({
//       ...currentValue,
//       [coord]: value
//     });
//   }}
//   />

VectorInput.defaultProps = {
  label: '',
  onPreview: () => {},
  disabled: false,
  draggerScale: 1,
  mod: undefined
};

/**
 * PropTypes.
 */
VectorInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.object.isRequired,

  draggerScale: PropTypes.number,

  onChange: PropTypes.func.isRequired,
  onPreview: PropTypes.func,

  disabled: PropTypes.bool,
  mod: PropTypes.number
};

export default VectorInput;
