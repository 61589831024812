import {
 Box, Grid, IconButton, Paper, Typography
} from '@material-ui/core';
import { Close , Edit} from '@material-ui/icons'
import { connect } from 'react-redux';
import { getActions } from '@enklu/server-api';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import queryString from 'query-string';

import { getCurrentScriptValue } from '../../../store/actions/inspector/inspectorActions';
import { getAllScriptsById, getLibraries } from '../../../store/selectors/scriptLibrariesSelectors';
import { getAppInfo } from '../../../store/selectors/appSelectors';
import { log } from '../../../util/log';
import { tagsToProps } from './ScriptUtilities';
import { updateAction } from '../../../store/actions/elementActions';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import ItemSocket from '../MuiItemSocket';
import ItemTypes from '../../../features/dragAndDrop/DragDropTypes';
import VariableList from './MuiVariableList';
import txns from '../../../store/actions/TxnManager';

const { getappscripts, sharescriptwithapp } = getActions('trellis');

class ScriptsInspector extends React.Component {
  static propTypes = {
    // injected
    sceneId: PropTypes.string.isRequired,
    element: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    scripts: PropTypes.arrayOf(PropTypes.object),
    libraries: PropTypes.object.isRequired,
    appId: PropTypes.string.isRequired,
    shareScriptWithApp: PropTypes.func.isRequired,
    updateProp: PropTypes.func.isRequired,
    addScript: PropTypes.func.isRequired,
    removeScript: PropTypes.func.isRequired,
    onRemove: PropTypes.func,

    // withRouter
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
  };

  renderHeader() {
    const {
     classes, asset
} = this.props;

    return (
      /**
     * Runs title with asset name
     */
      <Paper square elevation={3} className={classes.tabWrapper}>
        <Typography variant="h6">
          {asset ? `${asset.name}'s Scripts` : "Scripts and Variables"} {/* if an asset exists name becomes first of two options */}
        </Typography>
      </Paper>
    );
  }

  renderScriptsAndVariables() {
    const {
      scripts, libraries, appId, shareScriptWithApp, sceneId, addScript, removeScript, history, location, element, element: { schema }, updateProp
    } = this.props;



    return (
      /**
     * Contains drop box with scripts and variables underneath
     */
      <Grid container spacing={1}>
        <Box
          width={'300%'}
          height={100}
          style={{ marginRight:'5px'  }}
        >
          <ItemSocket
            small
            item={null}
            type={ItemTypes.SCRIPT}
            onDrop={(newScript, oldScript) => {
              if (!libraries[appId] || !libraries[appId][newScript.id]) {
                shareScriptWithApp(appId, newScript.id)
                  .then((action) => {
                    const scriptCopy = action.response.body;
                    addScript({ appId, scriptId: scriptCopy.id, oldScriptId: oldScript ? oldScript.id : null });
                  })
                  .catch(err => log.error(`Could not share copy script: ${err}`));
              } else {
                  addScript({ appId, scriptId: newScript.id, oldScriptId: oldScript ? oldScript.id : null });
              }
            }}
            placeholder={'Drop a script here.'}
          />
        {scripts
          .filter(scriptData => typeof scriptData === 'object')
          .reverse()
          .map(({name, tags }, index) => {
            const defs = tagsToProps(tags);
            const fields = defs.map((def) => {
              const { [def.name]: value = def.defaultValue } = schema[`${def.type}s`] || {};
              return {
                ...def,
                value,
                onPreview: (/* val */) => {},
                onCommit: val => updateProp(def.type, def.name, val, def.defaultValue)
              };
            });
          const item = scripts.find(script => script && script.name === name)
          return(
            <Box key={index} marginTop = "5px" marginBottom = "5px">
              <Typography variant="h6">
                {name}
                <div style={{ float: 'right' }}>
                  <IconButton size="small" onClick={() => {
                      const search = queryString.parse(location.search);
                      history.push({
                        ...location,
                        search: queryString.stringify({ ...search, script: item ? (item.id) : null, sidebar: 'script' })
                      });
                    }}>
                    <Edit />
                  </IconButton>
                  <IconButton size="small" onClick = {() => removeScript(item.id)}>
                    <Close />
                  </IconButton>
                </div>
              </Typography>
              {fields.length ? <VariableList fields={fields} element={element} sceneId={sceneId}/> : null }
              </Box>);
        })}
      </Box>
      </Grid>
    );
  }

  render() {
    return (
      /**
     * Contains all of the section
     */
      <Box>
        {this.renderHeader()}
        <Box p={1}>
          {this.renderScriptsAndVariables()}
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state, { sceneId, element }) => {
  // TODO Ideally this would take place in the reducer, but the reducer doesn't know what element is selected.
  const { schema } = element;
  const elementScriptsStr = schema && schema.strings && schema.strings.scripts;
  let elementScripts = [];
  try {
	if (typeof elementScriptsStr !== 'undefined') {
      elementScripts = JSON.parse(elementScriptsStr);
    }
  } catch (err) {
    log.error('Could not parse script ids on element: ', err, element);
  }
  const validatedElementScripts = elementScripts.filter(scriptData => typeof scriptData.id === 'string');
  const allScriptsById = getAllScriptsById(state);
  // TODO Apparently validatedElementScripts can contain a script that allScriptsById does not.
  const scripts = validatedElementScripts.map(({ id }) => allScriptsById[id]).filter(value => value);

  return {
    scripts,
    appId: getAppInfo(state).id,
    libraries: getLibraries(state),

    /**
     * Updates a prop.
     */
    updateProp: (type, prop, value, defaultValue) => {
      // TODO: Trellis doesn't understand phrases yet
      if (type === ActionSchemaTypes.PHRASES) {
        type = ActionSchemaTypes.STRING;
        value = JSON.stringify(value);
        prop = `phrases_${prop}`;
      }
      txns.request(sceneId, updateAction(element.id, type, prop, value, defaultValue));
    },

    /**
     * Removes a script from an element.
     */
    removeScript: (scriptId) => {
    const { id: elementId } = element;
    const currentScripts = getCurrentScriptValue(element);

    const filteredScripts = currentScripts.filter(script => script.id !== scriptId);
    if (filteredScripts.length === currentScripts.length) {
       log.error('Could not find script on element.');
       return;
      }

    txns.request(
      sceneId,
      updateAction(elementId, ActionSchemaTypes.STRING, 'scripts', JSON.stringify(filteredScripts))
      );
    }
  };
};

const mapDispatchToProps = (dispatch, { sceneId, element }) => ({
  /**
   * Adds a script to an element.
   */
  addScript: async ({ appId, scriptId, oldScriptId }) => {
    const currentScripts = getCurrentScriptValue(element);

    // Never duplicate scripts.
    if (currentScripts.find(script => script.id === scriptId)) {
      return;
    }

    // If we are replacing a script, stick it in the same slot.
    let index = currentScripts.length;
    if (oldScriptId) {
      index = currentScripts.findIndex(script => script.id === oldScriptId);
    }

    currentScripts[index] = { id: scriptId };

    await txns.request(
      sceneId,
      updateAction(element.id, ActionSchemaTypes.STRING, 'scripts', JSON.stringify(currentScripts))
    );

    dispatch(getappscripts({ appId }));
  },

  /**
   * Shares the script with the app library.
   */
  shareScriptWithApp: (appId, scriptId) => dispatch(sharescriptwithapp({ scriptId }, { appId }))
});

export default withStyles(theme => ({
  tabWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.default
  }
}))(withRouter(connect(mapStateToProps, mapDispatchToProps)(ScriptsInspector)));
