import { Alert } from '@material-ui/lab';
import { AsyncStatus } from '@enklu/server-api';
import {
 Box, Button, CircularProgress, DialogActions, DialogContent, TextField, Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import { getAccount } from '../../store/selectors/userSelectors';
import { signin, getsubscription, cancelsubscription } from '../../store/actions/accountActions';

const CONTENT_WIDTH = 366;

const useStyles = makeStyles(theme => ({
  form: {
    height: 200
  },
  content: {
    width: CONTENT_WIDTH
  },
  input: {
    marginTop: theme.spacing(1),
    '& > *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const SignInAction = ({ dispatch, onNext }) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [status, setStatus] = React.useState(AsyncStatus.UNSENT);

  const classes = useStyles();

  const startSignIn = () => {
    setStatus(AsyncStatus.IN_PROGRESS);

    let accessToken;

    dispatch(
      signin({
        email,
        password
      })
    )
      .then(({ token }) => {
        accessToken = token;

        return dispatch(getsubscription({ token }));
      })
      .then(plan => onNext({ token: accessToken, ...plan }))
      .catch((newError) => {
        setStatus(AsyncStatus.FAILURE);
        setError(newError);
      });
  };

  const inProgress = AsyncStatus.IN_PROGRESS === status;

  return (
    <React.Fragment>
      <DialogContent className={classes.content}>
        <Typography>Please Sign In to Continue</Typography>

        <form className={classes.form}>
          <div className={classes.input}>
            <TextField
              id="email"
              type="email"
              label="email"
              autoComplete="username email"
              fullWidth
              autoFocus
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
              variant="outlined"
            />

            <TextField
              id="password"
              type="password"
              label="password"
              placeholder="********"
              autoComplete="current-password"
              fullWidth
              value={password}
              onChange={({ target: { value } }) => setPassword(value)}
              variant="outlined"
            />
          </div>

          {inProgress && (
            <Box display="flex" justifyContent="center" alignItems="center" flex={1} mt={2}>
              <CircularProgress size={30} />
            </Box>
          )}

          {error && (
            <Alert className={alert} elevation={0} severity="error" onClose={() => setError('')}>
              <Typography variant="h6">{error}</Typography>
            </Alert>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={startSignIn} color="primary" disabled={inProgress}>
          Login
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

SignInAction.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

const useStyles2 = makeStyles(theme => ({
  content: {
    width: CONTENT_WIDTH
  },
  errorColor: { color: theme.palette.error.main },
  noSubscriptionWrapper: {
    marginBottom: theme.spacing(2)
  },
  cancelText: {
    marginTop: theme.spacing(2)
  }
}));

const AccountInformation = ({
 dispatch, actionState: {
 id, nickname, amount, interval, token
} = {}
}) => {
  const classes = useStyles2();
  const [confirmationState, setConfirmationState] = React.useState('none');
  const [status, setStatus] = React.useState(AsyncStatus.UNSENT);

  const startCancelSubscription = () => {
    setStatus(AsyncStatus.IN_PROGRESS);

    dispatch(cancelsubscription({ token }))
      .then(() => document.location.reload())
      .catch(() => {
        setStatus(AsyncStatus.FAILURE);
      });
  };

  if (id === 'none') {
    return (
      <DialogContent className={clsx(classes.noSubscriptionWrapper, classes.content)}>
        <Typography>No subscription. You are using a license.</Typography>
      </DialogContent>
    );
  }

  return (
    <React.Fragment>
      <DialogContent className={classes.content}>
        <Typography>Plan: {nickname}</Typography>
        <Typography>{`$${amount / 100} / ${interval}`}</Typography>
        {confirmationState === 'confirm' && (
          <Typography className={classes.cancelText}>
            Canceling your subscription will immediately revoke access to your account. Are you sure you want to do
            this?
          </Typography>
        )}
        {status === AsyncStatus.IN_PROGRESS && (
          <Box display="flex" justifyContent="center" alignItems="center" flex={1} mt={2}>
            <CircularProgress size={30} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {confirmationState === 'none' && (
          <Button
            variant="outlined"
            onClick={() => {
              setConfirmationState('confirm');
            }}
          >
            Cancel Subscription
          </Button>
        )}
        {confirmationState === 'confirm' && (
          <React.Fragment>
            <Button
              variant="outlined"
              onClick={() => {
                setConfirmationState('none');
              }}
            >
              Cancel
            </Button>
            <span className={classes.errorColor}>
              <Button color="inherit" variant="outlined" onClick={startCancelSubscription}>
                Confirm Cancellation
              </Button>
            </span>
          </React.Fragment>
        )}
      </DialogActions>
    </React.Fragment>
  );
};

AccountInformation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  actionState: PropTypes.object.isRequired
};

const AccountModal = ({ dispatch }) => {
  const [action, setAction] = React.useState('signin');
  const [actionState, setActionState] = React.useState({});

  switch (action) {
    case 'signin':
      return (
        <SignInAction
          dispatch={dispatch}
          onNext={(nextActionState) => {
            setAction('account');
            setActionState(nextActionState);
          }}
        />
      );

    case 'account':
      return <AccountInformation dispatch={dispatch} actionState={actionState} />;

    default:
      return null;
  }
};

AccountModal.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect(
  state => ({ account: getAccount(state) }),
  dispatch => ({ dispatch })
)(AccountModal);
