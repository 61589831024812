import {
 Box, FormControlLabel, Radio, RadioGroup, Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { getActions } from '@enklu/server-api';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';

import { updateAction } from '../../../store/actions/elementActions';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import InspectableIdentity, { elementToInspectable } from '../common/MuiInspectableIdentity';
import InspectorInterface from '../common/InspectorInterface';
import Transform from '../MuiTransform';
import txns from '../../../store/actions/TxnManager';

const { getmyfiles } = getActions('trellis');

const useStyles = makeStyles({
  radioGroup: {
    '& > .MuiFormControlLabel-root': {
      marginLeft: 0
    }
  }
});

/**
 * Custom inspector for scan elements.
 */
const ScanElementInspector = ({
  element,
  scene,
  onUpdateElement,
  refresh,
  files: { worldScans = [] },
  app: {
    scenes: { all }
  },
  element: { id: elementId, schema: { strings: { srcId } = {} } = {} },
  select
}) => {
  const classes = useStyles();

  React.useEffect(() => {
    refresh();
  }, []);

  const renderSpaces = () => {
    let ids = 0;
    const elements = worldScans.map((file) => {
      const date = new Date(file.updatedAt);
      const hours = date.getHours();

      // eslint-disable-next-line no-return-assign
      return (
        <FormControlLabel
          onChange={() => select(all[0].id, elementId, file.id, file.relUrl)}
          key={(ids += 1)}
          value={file}
          control={<Radio />}
          label={`${date.getDate()}/${date.getMonth()}/${date.getFullYear()} - ${hours % 12}:${date.getMinutes()}`}
        />
      );
    });

    elements.splice(
      0,
      0,
      <FormControlLabel
        key={(ids += 1)}
        value={undefined}
        control={<Radio />}
        onChange={() => select('', '')}
        label="none"
      />
    );

    return (
      <RadioGroup value={srcId} className={classes.radioGroup}>
        {elements}
      </RadioGroup>
    );
  };

  const { id: sceneId } = scene;

  return (
    <Box p={1}>
      <InspectableIdentity
        inspectable={element}
        inspectableTranslator={elementToInspectable}
        onUpdate={onUpdateElement}
      />

      <Transform hideTitle element={element} sceneId={sceneId} />

      {/* Scan selection. */}
      <Box>
        <Typography variant="h6">Scan</Typography>
        {renderSpaces()}
      </Box>
    </Box>
  );
};

ScanElementInspector.propTypes = {
  ...InspectorInterface,
  files: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired
};

export default withRouter(
  connect(
    ({ files, app }) => ({
      files,
      app
    }),
    dispatch => ({
      /**
       * Refreshes spaces.
       */
      refresh: () => {
        dispatch(getmyfiles());
      },

      /**
       * Selects a space.
       */
      select: (sceneId, elementId, srcId, srcUrl) => {
        txns.request(
          sceneId,
          updateAction(elementId, ActionSchemaTypes.STRING, 'srcId', srcId),
          updateAction(elementId, ActionSchemaTypes.STRING, 'srcUrl', srcUrl)
        );
      }
    })
  )(ScanElementInspector)
);
