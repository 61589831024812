import { createSelector } from 'reselect';

const getUser = ({ user }) => user;

export const getUserProfile = createSelector(getUser, ({ profile }) => profile);
export const getUserCredentials = createSelector(getUser, ({ credentials }) => credentials);
export const getAccount = createSelector(getUser, ({ account }) => account);
export const getMyApps = createSelector(getUser, ({ apps }) => apps.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)));
export const getMyTemplates = createSelector(getUser, ({ templates }) => templates.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)));

//holologin
export const getHoloAuthCode = createSelector(getUser, ({ holocode }) => holocode);

export const getCanManageStandardAssets = createSelector(
  getUser,
  ({ canManageStandardAssets }) => canManageStandardAssets
);
export const getCanManageStandardScripts = createSelector(
  getUser,
  ({ canManageStandardScripts }) => canManageStandardScripts
);
export const getUserIsAuthenticated = createSelector(getUser, ({ isAuthenticated }) => isAuthenticated);
