import queryString from 'query-string';

/**
 * Returns whether the object is empty (i.e. has no properties).
 * @param obj
 * @returns {boolean}
 */
const isEmptyObject = obj => Object.keys(obj).length === 0 && obj.constructor === Object;

/**
 * Gets a value out of an object, subject to internal rules.
 * @param obj
 * @param keyName
 * @returns {void|string|boolean}
 */
const getValue = (obj, keyName) => {
  const value = obj[keyName].toLowerCase();
  switch (value) {
    case 'true': return true;
    case 'false': return false;
    default: return value;
  }
};

/**
 * Factory function. Creates a selector that will retrieve the component's state from the URL.
 * @param keys - the keys to keep track of, with default values
 * @returns {getNavState}
 */
const createNavSelector = (keys) => {
  if (!keys || isEmptyObject(keys)) {
    throw new Error('createNavSelector requires a "keys" object (or it is useless).');
  }

  /**
   * A selector that retrieves a specified piece of state from the URL.
   * @param store - the store (currently unused, but future-proofed against reselect's API)
   * @param props.location - the location object
   * @param props.location.search - search string
   */
  const getNavState = (store, props) => {
    const {
      location: { search = '' } = {}
    } = props;
    const searchObj = queryString.parse(search);
    const keyNames = Object.keys(keys);

    const urlState = keyNames.reduce((accum, keyName) => ({
      ...accum,
      [keyName]: Object.prototype.hasOwnProperty.call(searchObj, keyName)
        ? getValue(searchObj, keyName)
        : keys[keyName]
    }), {});

    return urlState;
  };

  return getNavState;
};

export default createNavSelector;
