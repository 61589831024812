import {
 Box, CircularProgress, DialogContent, Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { getActions } from '@enklu/server-api';
import PropTypes from 'prop-types';
import React from 'react';

import { getAppInfo } from '../../store/selectors/appSelectors';
import { getHoloAuthCode } from '../../store/selectors/userSelectors';
import Qr from '../MuiQr';

const { holoauthorize } = getActions('trellis');

// const useStyles = makeStyles(theme => ({}));

const HoloLogin = ({ holocode, app: { id }, authorize }) => {
  React.useEffect(() => {
    authorize({ appId: id });
  }, []);

  // Uncomment if we enable QR code inverting
  // const [filter, setFilter] = React.useState('none');

  return (
    <DialogContent>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" pb={3}>
        {holocode ? (
          <React.Fragment>
            {/* In case we want to invert the QR code.  Currently the HL cannot read inverted QR codes */}
            {/* <Box style={{ filter }} mb={2}> */}
              <Qr payload={btoa(`${holocode}:bar`)} />
            {/* </Box> */}
            {/* <Box */}
            {/*  style={{ margin: '-18px 0 20px', cursor: 'pointer' }} */}
            {/*  onClick={() => { */}
            {/*    if (filter === 'invert(1)') { */}
            {/*      setFilter('none'); */}
            {/*    } else { */}
            {/*      setFilter('invert(1)'); */}
            {/*    } */}
            {/*  }} */}
            {/* > */}
            {/*  <img */}
            {/*    style={{ color: 'white', width: '18px', margin: '0 4px -5px', filter: 'invert(1)' }} */}
            {/*    src={'assets/icons/invert-icon.svg'} */}
            {/*  /> */}
            {/*  <span>Having trouble scanning?  Try inverting the color of the QR code.</span> */}
            {/* </Box> */}
            <Typography align="center">
              Use this QR code to login into the HoloLens.
              <br />
              Point the holographic device camera directly at the QR code.
              <br />
              For best results, stay still.
            </Typography>
          </React.Fragment>
        ) : (
          <CircularProgress size={20} />
        )}
      </Box>
    </DialogContent>
  );
};

HoloLogin.propTypes = {
  style: PropTypes.object,
  app: PropTypes.object,

  // connect()
  holocode: PropTypes.string.isRequired,
  authorize: PropTypes.func.isRequired
};

HoloLogin.defaultProps = {
  style: {}
};

const mapStateToProps = state => ({
  holocode: getHoloAuthCode(state),
  app: getAppInfo(state)
});

const mapDispatchToProps = dispatch => ({
  /**
   * Generates a holologin code.
   */
  authorize: payload => dispatch(holoauthorize({ payload }))
});

export default connect(mapStateToProps, mapDispatchToProps)(HoloLogin);
