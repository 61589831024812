import { AsyncStatus, TrellisActions } from '@enklu/server-api';

import scenes, { initialState as scenesInitialState } from './scenesReducer';
import presence from './presenceReducer';
import { DELETETHUMB } from '@enklu/server-api/src/js/actions/trellisActions';
import { THUMB_GENERATED, UPDATE_THUMB } from '../actions/thumbActions';

const { GETAPP, UPDATEAPP, GETPUBLISHEDAPP, PUBLISHAPP, UNPUBLISHAPP } = TrellisActions;

export const initialState = {
  users: [
    // Users should follow this pattern:
    // newPresence({id: 0, name: 'Ralph', thumbUrl: 'someUrl', device: [HOLOLENS, MOBILE, DESKTOP][Math.floor(Math.random() * 3)]})
  ],

  // map from request name => { status, error }
  requests: {
    //
  },

  // info about the current app
  info: {
    id: '',
    name: '',
    owner: '',
    description: '',
    ispublic: false,
    createdAt: '',
    updatedAt: '',
    scenes: [], // ids
    thumb: ''
  },

  // scenes
  scenes: scenesInitialState
};

/**
 * Reducer for requests, currently unused.
 */
const requests = (state, action) => state;

/**
 * Reducer for app info.
 */
const info = (state = initialState.info, action) => {
  switch (action.type) {
    case GETPUBLISHEDAPP:
    case GETAPP: {
      return action.status === AsyncStatus.SUCCESS ? { ...action.body } : state;
    }

    case UPDATEAPP: {
      // This can be triggered by editing an app other than the current one.
      if (action.request.replacements.appId !== state.id) {
        return state;
      }

      if (action.status === AsyncStatus.SUCCESS) {
        const {
          response: {
            body: { name, description }
          }
        } = action;

        return {
          ...state,
          name,
          description
        };
      }

      return state;
    }
    
    case DELETETHUMB: {
      return {
        ...state,
        thumb: null
      }
    }
    case THUMB_GENERATED:
    case UPDATE_THUMB: {
      return {
        ...state,
        thumb: action.thumb.id
      }
    }

    default: {
      return state;
    }
  }
};

// /////////////////////////////////////////////////////////////////////////////
// / Reducer.
// /////////////////////////////////////////////////////////////////////////////
function app(state = initialState, action) {
  return {
    scenes: scenes(state.scenes, action),
    users: presence(state.users, action),
    requests: requests(state.requests, action),
    info: info(state.info, action)
  };
}

export default app;
