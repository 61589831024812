import {
 Box, DialogContent, Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { getAppInfo } from '../../store/selectors/appSelectors';
import Qr from '../MuiQr';

// This Qr Code Modal generates a code that only encodes for appId, used with logging into public experiences.
const PublishedAppQrCode = ({ app: { id } }) => {
  return (
    <DialogContent>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" pb={1}>
        <React.Fragment>  
            <Qr payload={btoa(`${id}:appId`)} />
        </React.Fragment>
      </Box>
    </DialogContent>
  );
};

PublishedAppQrCode.propTypes = {
  style: PropTypes.object,
  app: PropTypes.object,
};

PublishedAppQrCode.defaultProps = {
  style: {}
};

const mapStateToProps = state => ({
  app: getAppInfo(state)
});

export default connect(mapStateToProps, null)(PublishedAppQrCode);
