import { getActions } from '@enklu/server-api';

import { getCollaboratorsByUserId } from '../selectors/collaboratorsSelectors';
import { getUserCache } from '../selectors/userCacheSelectors';

const { getuser } = getActions('trellis');

// export const LOAD_MISSING_USERS = 'combo.loadMissingUsers';
export const loadmissingusers = byId => (dispatch, getState) => {
    const userCache = getUserCache(getState());
    const unloadedCollaborators = Object.values(byId)
        .reduce((accum, collaborator) => {
            // The data object may use either of these values (collaborators use userId)
            const { id, userId } = collaborator;
            if ((userId && !userCache[userId]) || (!userId && id && !userCache[id])) {
                accum.push(collaborator);
            }

            return accum;
        }, []);

    // A call to a getified action creator returns a thunk.
    // The thunk, when fed dispatch(), returns a promise.
    return Promise.all(
        unloadedCollaborators.map(({ id, userId }) => getuser({ userId: userId || id })(dispatch))
    );
};

// export const LOAD_COLLABORATORS_INFO = 'combo.collaborators.loadInfo';
export const loadcollaboratorsinfo = () => (dispatch, getState) => {
    const collaboratorsById = getCollaboratorsByUserId(getState());
    return loadmissingusers(collaboratorsById)(dispatch, getState);
};
