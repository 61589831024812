import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';

/**
 * A schema update consists of three pieces of information in two properties.
 *
 * @param type - @see ActionSchemaTypes
 * @param name - the name of the schema property to update
 * @param value - the new value of the schema property
 */
export const createSchemaUpdate = ({ type = ActionSchemaTypes.STRING, name, value }) => ({
  type,
  name,
  value
});
