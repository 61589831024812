import { Box } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import queryString from 'query-string';

import { getScreenSize, getUIOffsets } from '../../store/selectors/uiSelectors';
import DragBar from '../../features/dragBar/DragBar';
import DragBarIds from '../../features/dragBar/DragBarIds';
import ElementHierarchy from './MuiElementHierarchy';
import SceneInspector from './MuiSceneSettings';
import ScriptEditor from '../../features/scriptEditor/MuiScriptEditor';
import UrlParamNav from '../common/MuiUrlParamNav';
import EditorSettings from './EditorSettings';

/**
 * Displays menu in the sidebar of the workspace.
 */
const Sidebar = ({ style, uiOffsets: { current }, screenSize }) => (
  <Box square="true" style={{ ...style, display: 'flex' }}>
    <Box flex={1} display="flex" flexDirection="column">
      <UrlParamNav navItems={['hierarchy', 'scene', 'editor', 'script']} paramName="sidebar" />

      <Route
        path="/:i?/:scene?/:sceneId?/:element?/:elementId?"
        render={({ location, history }) => {
          const search = queryString.parse(location.search);
          const { sidebar } = search;

          switch (sidebar) {
            case 'scene': {
              return <SceneInspector />;
            }

            case 'editor': {
              return <EditorSettings />;
            }

            case 'script': {
              const { script } = search;

              return (
                <ScriptEditor
                  scriptId={script}
                  onClose={() => {
                    const { script: _, ...newSearch } = search;
                    history.push({
                      ...location,
                      search: queryString.stringify({ ...newSearch, sidebar: 'hierarchy' })
                    });
                  }}
                />
              );
            }

            default: {
              return <ElementHierarchy />;
            }
          }
        }}
      />
    </Box>
    <DragBar id={DragBarIds.LEFT} size={screenSize.height - current.menu} />
  </Box>
);

Sidebar.propTypes = {
  // external
  style: PropTypes.object,

  // connect
  uiOffsets: PropTypes.object.isRequired,
  screenSize: PropTypes.object.isRequired
};

Sidebar.defaultProps = {
  style: {}
};

const mapStateToProps = state => ({
  uiOffsets: getUIOffsets(state),
  screenSize: getScreenSize(state)
});

export default compose(withRouter, connect(mapStateToProps))(Sidebar);
