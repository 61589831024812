import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {
 IconButton, Paper, Tab, Tabs
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { pushNavState as _pushNavState } from '../../store/actions/navActions';
import { getScreenSize, getUIOffsets } from '../../store/selectors/uiSelectors';
import DragBar from '../../features/dragBar/DragBar';
import DragBarIds from '../../features/dragBar/DragBarIds';
import DrawerModes, { defaultDrawerMode } from './DrawerModes';
import Library from '../library/MuiLibrary';
import Log from '../../features/log/MuiLog';
import createNavSelector from '../../store/selectors/navSelector';

// const NavSpacer = props => <li className="nav-spacer" style={{ flex: 1 }}></li>;

const DrawerContainer = (props) => {
  const {
    style,
    uiOffsets: { current },
    screenSize,
    navState: { showBlocks, drawerMode },
    pushNavState,
    classes
  } = props;

  const handleTabChange = (_, value) => {
    pushNavState({
      showBlocks: true,
      drawerMode: value
    });
  };

  return (
    <Paper square style={style} className={classes.container}>
      <DragBar id={DragBarIds.DRAWER} size={screenSize.width - current.left - current.right} />
      <Paper square elevation={3} className={classes.tabWrapper}>
        <Tabs value={drawerMode} onChange={handleTabChange} variant="standard">
          <Tab value={DrawerModes.ASSETS} label="Assets" />
          <Tab value={DrawerModes.SCRIPTS} label="Scripts" />
          <Tab value={DrawerModes.LOG} label="Log" />
        </Tabs>

        <IconButton
          size="small"
          onClick={() => {
            pushNavState({ showBlocks: !showBlocks });
          }}
        >
          {showBlocks ? <ExpandMore /> : <ExpandLess />}
        </IconButton>
      </Paper>

      {drawerMode === DrawerModes.LOG && <Log />}
      {(drawerMode === DrawerModes.ASSETS || drawerMode === DrawerModes.SCRIPTS) && <Library mode={drawerMode} />}
    </Paper>
  );
};

DrawerContainer.propTypes = {
  // external
  style: PropTypes.object,

  // withRouter
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  // connect
  uiOffsets: PropTypes.object.isRequired,
  screenSize: PropTypes.object.isRequired,
  navState: PropTypes.object.isRequired,
  pushNavState: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

DrawerContainer.defaultProps = {
  style: {}
};

const createMapStateToProps = () => {
  const getNavState = createNavSelector({
    showBlocks: true,
    drawerMode: defaultDrawerMode
  });

  return (state, ownProps) => ({

    navState: getNavState(state, ownProps),
    uiOffsets: getUIOffsets(state),
    screenSize: getScreenSize(state)
  });
};

const mapDispatchToProps = (dispatch, { history, location }) => ({
  pushNavState: navState => dispatch(_pushNavState(history, location, navState))
});

export default withStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch'
  },
  tabWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.default
  }
}))(withRouter(connect(createMapStateToProps, mapDispatchToProps)(DrawerContainer)));
