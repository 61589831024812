import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const BaseStandardTextField = withStyles(theme => ({
  root: {
    paddingTop: 0,
    marginTop: 0,
    marginBottom: 0,
    '& .MuiOutlinedInput-root': {
      borderRadius: props => (props.rounded ? 100 : undefined),
      background: props => (props.darkbackground ? theme.palette.background.default : theme.palette.background.paper),
      boxShadow: props => (props.noshadow ? 'none' : undefined),
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0
      },
      '&$focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0
      },
      '&$error .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0
      },
      '&$disabled .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0
      }
    }
  }
}))(TextField);

BaseStandardTextField.defaultProps = {
  rounded: "true"
};

BaseStandardTextField.propTypes = {
  dark: PropTypes.string,
  noshadow: PropTypes.string
};

export default BaseStandardTextField;
