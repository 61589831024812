/**
 * Forwards metrics data sent from the player to a metrics service.
 */
import { useState, useEffect } from 'react';
import { sendMetric } from '../../store/middleware/metrics';
import MessageTypes from '../../constants/MessageTypes';


const handleBridgeMessage = (message) => {
  if (!message || message.type !== MessageTypes.METRICS_REQUEST) {
    return;
  }

  const {
    metric: {
      event,
      payload = {}
    } = {}
  } = message;

  sendMetric(event, payload);
};

const MetricsListener = () => {
  const [isListening, setIsListening] = useState(false);

  useEffect(() => {
    if (!isListening) {
      window.bridge.listen(handleBridgeMessage);
      setIsListening(true);
    }
  });

  return null;
};

export default MetricsListener;
