import { getUserProfile } from '../selectors/userSelectors';
import InitializationStatus from '../../constants/InitializationStatus';

var timeoutId = -1;

/**
 * Redux middleware that saves off editor state.
 */
const storeApp = store => next => action => {
	const prevState = store.getState();
	const result = next(action);
	const nextState = store.getState();

	const appData = {
		version: window.config.localDataVersion,
		inspector: nextState.inspector,
		assets: nextState.assets,

	};

	const globalData = {
		version: window.config.localDataVersion,
		globalSettings: nextState.globalSettings
	}

	// wait for multiple changes if need be
	if (-1 !== timeoutId) {
		clearTimeout(timeoutId);
	}

	const appId = nextState.app.info.id;

	// set timeout to save in a bit
	timeoutId = setTimeout(function() {
		timeoutId = -1;

		// wait till after init to start saving
		if (store.getState().init.status === InitializationStatus.INITIALIZATION_COMPLETE) {
			// save locally
			window.localStorage.setItem(
				`__${appId}__`,
				JSON.stringify(appData)
			);
			window.localStorage.setItem(
				`__global__`,
				JSON.stringify(globalData)
			);
		}

		// We also save global preferences under a different key.
		if (nextState.preferences.lastAppId !== '') {
			const userId = getUserProfile(nextState).id;
			const userKey = `__${userId}__`;
			const globalBlob = {
				version: window.config.localDataVersion,
				preferences: nextState.preferences
			};
			const globalValue = JSON.stringify(globalBlob);
			window.localStorage.setItem(userKey, globalValue);
		}

	}, 100);

	return result;
};

export default storeApp;
