import { Box, Tooltip, Typography } from '@material-ui/core';
import {
 Delete, Edit, Public, VpnLock
} from '@material-ui/icons';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getActions } from '@enklu/server-api';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import queryString from 'query-string';

import { deleteScript, updateScript } from '../../../store/actions/inspector/inspectorActions';
import { getAppInfo } from '../../../store/selectors/appSelectors';
import { getCanManageStandardScripts, getMyApps } from '../../../store/selectors/userSelectors';
import { getLibraries } from '../../../store/selectors/scriptLibrariesSelectors';
import { getScenes } from '../../../store/selectors/scenesSelectors';
import {
 getScriptTagsArray, addToScriptTags, removeFromScriptTags, formatTimestamp
} from '../../../util/util';
import ConfirmationModal from '../../modals/MuiConfirmationModal';
import CopyableText from '../../common/CopyableText';
import ExpandableList from '../../common/MuiExpandableList';
import FilledIconButton from '../../material-ui/FilledIconButton';
import InspectableIdentity from '../common/MuiInspectableIdentity';
import ItemSharing from '../MuiItemSharing';
import ItemTags from '../MuiItemTags';
import LibraryTypes from '../../../constants/LibraryTypes';

const { promotescripttostandard, demotescripttopersonal } = getActions('trellis');

/**
 * Builds an array of share objects required by the ItemSharing component.
 */
const buildShares = (allApps, libraries, script) => {
  const newShare = (name, shared) => ({
    name,
    shared: shared !== undefined
  });

  if (libraries[LibraryTypes.PUBLIC][script.id]) {
    return [newShare('Public', true)];
  }

  return allApps.filter(({ id }) => libraries[id] && libraries[id][script.id]).map(({ name }) => newShare(name, true));
};

class ScriptInspector extends React.Component {
  static propTypes = {
    canManageStandardScripts: PropTypes.bool.isRequired,
    script: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      tags: PropTypes.string.isRequired
    }),
    appId: PropTypes.string.isRequired,
    allApps: PropTypes.array.isRequired,
    libraries: PropTypes.object.isRequired,
    scenes: PropTypes.array.isRequired,
    onUpdateScript: PropTypes.func.isRequired,
    onDeleteScript: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequred,
    promote: PropTypes.func.isRequired,
    demote: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = { showDeleteModal: false };
  }

  addTag({ id, tags }, tag) {
    const tagArr = getScriptTagsArray(tags);
    if (tagArr.includes(tag)) {
      return;
    }

    this.props.onUpdateScript({
      id,
      tags: addToScriptTags(tags, tag)
    });
  }

  removeTag({ id, tags }, tag) {
    const tagArr = getScriptTagsArray(tags);
    if (!tagArr.includes(tag)) {
      return;
    }

    this.props.onUpdateScript({
      id,
      tags: removeFromScriptTags(tags, tag)
    });
  }

  renderStandardModal() {
    const {
      script: { id, name },
      promote,
      demote,
      appId
    } = this.props;
    const { demoteModal, promoteModal } = this.state;

    return (
      <React.Fragment>
        <ConfirmationModal
          open={promoteModal}
          title="Promote to Standard Script"
          message={`Are you sure you want to promote '${name}' to a standard script?`}
          onClose={() => this.setState({ promoteModal: false })}
          onConfirm={() => {
            promote({ scriptId: id, appId });

            this.setState({ promoteModal: false });
          }}
        />
        <ConfirmationModal
          open={demoteModal}
          title="Demote from Standard Script"
          message={`Are you sure you want to demote '${name}' from the standard script library?`}
          onClose={() => this.setState({ demoteModal: false })}
          onConfirm={() => {
            demote({ scriptId: id, appId });

            this.setState({ demoteModal: false });
          }}
          confirmIsDanger
        />
      </React.Fragment>
    );
  }

  render() {
    const {
      script,
      libraries,
      allApps,
      history,
      location,
      onUpdateScript,
      onDeleteScript,
      canManageStandardScripts,
      classes
    } = this.props;

    const { showDeleteModal } = this.state;

    if (!script) {
      return (
        <Box p={1}>
          <Typography variant="h5">No script found.</Typography>
        </Box>
      );
    }

    const { id, name, updatedAt } = script;
    const shares = buildShares(allApps, libraries, script);
    const isPublic = undefined !== libraries[LibraryTypes.PUBLIC][id];

    return (
      <Box p={1} overflow="auto" className="scrollable-y">
        <ConfirmationModal
          confirmIsDanger
          open={showDeleteModal}
          title="Delete"
          message={`Are you sure you want to permanently delete '${name}'?`}
          onClose={() => this.setState({ showDeleteModal: false })}
          onConfirm={() => {
            onDeleteScript({ id });
            this.setState({ showDeleteModal: false });
          }}
        />

        <InspectableIdentity
          inspectable={script}
          onUpdate={({ name: key, value }) => onUpdateScript({ [key]: value, id })}
        />

        {!isPublic?<Box>
          <Typography variant="h6">Actions</Typography>
          <Box flexDirection="row" alignItems="center" className={classes.actionsContainer}>
            {!isPublic && canManageStandardScripts && (
              <Tooltip title={'Promote to Standard'} placement="top" arrow>
                <FilledIconButton icon={Public} color="primary" onClick={() => this.setState({ promoteModal: true })} />
              </Tooltip>
            )}
            {isPublic && canManageStandardScripts && (
              <Tooltip title={'Demote from Standard'} placement="top" arrow>
                <FilledIconButton icon={VpnLock} color="error" onClick={() => this.setState({ demoteModal: true })} />
              </Tooltip>
            )}
            <Tooltip title={'Edit'} placement="top" arrow>
              <FilledIconButton
                icon={Edit}
                color="primary"
                onClick={() => {
                  const search = queryString.parse(location.search);
                  history.push({
                    ...location,
                    search: queryString.stringify({ ...search, script: id, sidebar: 'script' })
                  });
                }}
              />
            </Tooltip>
            <Tooltip title={'Delete'} placement="top" arrow>
              <FilledIconButton icon={Delete} color="error" onClick={() => this.setState({ showDeleteModal: true })} />
            </Tooltip>
          </Box>
        </Box>:null}

        <Box mt={1}>
          <ExpandableList
            title="Info"
            small={
              <React.Fragment>
                <li>
                  <Typography>
                    ID: <CopyableText text={id} />
                  </Typography>
                </li>
                <li>
                  <Typography>{`Last Updated: ${formatTimestamp(updatedAt, {
                    includeDate: true
                  })}`}</Typography>
                </li>
              </React.Fragment>
            }
          />

          <ItemTags
            item={script}
            tagResolver={getScriptTagsArray}
            onAddTag={tag => this.addTag(script, tag)}
            onRemoveTag={tag => this.removeTag(script, tag)}
            canManageStandardAssets={canManageStandardScripts}
          />

          <ItemSharing shares={shares} />
        </Box>

        {this.renderStandardModal()}
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  scenes: getScenes(state),
  appId: getAppInfo(state).id,
  allApps: getMyApps(state),
  libraries: getLibraries(state),
  canManageStandardScripts: getCanManageStandardScripts(state)
});

const dispatchMap = {
  onUpdateScript: updateScript,
  onDeleteScript: deleteScript,
  promote: promotescripttostandard,
  demote: demotescripttopersonal
};

export default withStyles(theme => ({
  actionsContainer: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  }
}))(compose(connect(mapStateToProps, dispatchMap), withRouter)(ScriptInspector));
