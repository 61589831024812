import { INITIALIZE } from '../actions/initializeActions';

const initialState = {
  selected: null // id
};

const selected = (state = initialState.selected, action) => {
  switch (action.type) {
    case INITIALIZE: {
      return action.payload.appId;
    }

    default: { return state; }
  }
};

export default (state = initialState, action) => ({
  selected: selected(state.selected, action)
});
