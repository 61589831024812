import {
 Box, InputAdornment, MenuItem, OutlinedInput, Select
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import BaseStandardTextField from '../material-ui/BaseStandardTextField';

const useStyles = makeStyles(theme => ({
  container: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },

  filledStyle: {
    width: 100,
    borderRadius: 100,
    backgroundColor: theme.palette.background.default,
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0
    },
    '&.Mui-focused': {
      boxShadow: 'none'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0
    }
  }
}));

const DrawerItemHeader = ({
  filterString,
  onFilterStringChanged,

  categories,
  category,
  onCategoryChanged,

  children
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="row" alignItems="center" px={1} className={classes.container} py={1}>
      <BaseStandardTextField
        darkbackground="true"
        noshadow="true"
        margin="dense"
        placeholder={'Filter'}
        value={filterString}
        onChange={({ target: { value } }) => onFilterStringChanged(value)}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />

      <Select
        value={category}
        onChange={({ target: { value } }) => onCategoryChanged(value)}
        input={<OutlinedInput margin="dense" className={classes.filledStyle} />}
      >
        {categories.map(cat => (
          <MenuItem key={cat.value !== undefined ? cat.value : cat} value={cat.value !== undefined ? cat.value : cat}>
            {_.capitalize(cat.name || cat)}
          </MenuItem>
        ))}
      </Select>

      {children}
    </Box>
  );
};

DrawerItemHeader.propTypes = {
  filterString: PropTypes.string.isRequired,
  onFilterStringChanged: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]).isRequired,
  onCategoryChanged: PropTypes.func.isRequired,
  children: PropTypes.any
};

export default DrawerItemHeader;
