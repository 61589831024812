import {
 CardMedia, CircularProgress, Grid, Typography, useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%'
  },
  shadowContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '36px',
    boxShadow: `5px 5px 35px 15px ${theme.palette.common.boxShadow}38`
  },
  loadingIcon: {
    margin: '4px',
    height: '20px !important',
    width: '20px !important'
  },
  loadingText: {
    fontSize: '1.25em'
  },
  title: {
    fontSize: '2em'
  },
  titleContainer: {
    textAlign: 'center',
    width: '90%'
  },
  appStoreBadgeApple: {
    width: '86%'
  },
  appStoreBadgeGoogle: {
    width: '100%'
  },
  appStoreBadgeContainer: {
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  },
  appStoreBadgeGrid: {
    marginBottom: theme.spacing(3),
    maxWidth: '300px',
    textAlign: 'center',
    width: '90%'
  },
  image: {
    height: '100px',
    width: '100px',
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7)
  },
  footerContainer: {
    justifyContent: 'center',
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  footerItem: {
    width: '90%'
  },
  footerText: {
    fontSize: '1.25em'
  }
}));

/**
 * Mobile app store page.
 */
export const MobileAppStore = () => {
  const isDesktop = useMediaQuery('(min-width:600px)');
  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  const {
    wrapper,
    shadowContainer,
    appStoreBadgeApple,
    appStoreBadgeGoogle,
    appStoreBadgeContainer,
    appStoreBadgeGrid,
    image,
    loadingIcon,
    loadingText,
    footerContainer,
    footerItem,
    footerText,
    title,
    titleContainer
  } = classes;

  // Attempt to open in app for 6 seconds
  setTimeout(() => {
    // Assume it will not open in app and hide loading spinner
    setLoading(false);
  }, 6000);

  const containerClass = isDesktop ? shadowContainer : null;
  const loadingDisplay = loading ? 'block' : 'none';

  return (
    <Grid container direction="row" justify="center" alignItems="center" className={wrapper}>
      <Grid item xs={12} sm={8} md={6} lg={5} xl={4} className={containerClass}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={3} md={2}>
            <CardMedia component="img" image="/assets/img/enklu_logo_2DCCD3.svg" alt="Enklu Logo" className={image} />
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center" justify="space-between">
          <Grid item className={titleContainer}>
            {/* <Grid item style={{ display: loadingDisplay }}>
              <CircularProgress className={loadingIcon}/>
              <Typography variant="h3" align="center" className={loadingText}>
                Attempting to open in app...
              </Typography>
            </Grid> */}
            <Typography variant="h3" align="center" className={title}>
              Download the Enklu mobile app
            </Typography>
          </Grid>
        </Grid>
        <Grid className={appStoreBadgeContainer} container>
          <Grid item className={appStoreBadgeGrid}>
            <a href="https://apps.apple.com/us/app/experience-enklu/id1585949748?itsct=apps_box_badge&amp;itscg=30200" >
              <img className={appStoreBadgeApple} src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1637539200&h=4ba28d9878faf78b68fc4d346a6b7668" alt="Download on the App Store" />
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.enklu.enklu&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img className={appStoreBadgeGoogle} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
            </a>
          </Grid>
        </Grid>
        <Grid className={footerContainer} container>
          <Grid item className={footerItem}>
            <Typography className={footerText} variant="h5" align="center">
              The Apple logo is a trademark of Apple Inc. App Store is a service mark of Apple Inc., registered in the U.S. and other countries. Google Play and the Google Play logo are trademarks of Google LLC.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter((MobileAppStore));
