import {
Box, CircularProgress, Grid, GridListTileBar, IconButton, Typography, Tooltip
} from '@material-ui/core';
import { Description, Search, Clear, Delete } from '@material-ui/icons';
import { DragSource } from 'react-dnd';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import ScriptThreeDotDropDown from '../inspector/ScriptThreeDotDropDown';

import { assetThumbUrl } from '../../util/util';

const TITLE_HEIGHT = 24;
const FONT_SIZE = 10;
const CELL_HEIGHT = 100;
const TOOLTIP_DELAY = 500;
const TOOLTIP_LEAVE_DELAY = 200;

class ItemTile extends React.Component {
  render() {
    const {
      appId,
      item = {},
      version,
      onEdit,
      connectDragSource,
      isHoveringOver,
      onCreate,
      onDelete,
      onWrite,
      onRemove,
      classes,
      // isSelected,
      // cellHeight
      // expanded,
      showText,
      selectedCategoryName
    } = this.props;

    const {
 id, uriThumb, tags, name, owner
} = item;

    const isReady = !Object.prototype.hasOwnProperty.call(item, 'uploadId');

    const isVine = tags && tags.indexOf('vine') > -1;
    const isBehavior = tags && tags.indexOf('behavior') > -1;
    const isLoading = isHoveringOver && id && !isReady;
    const isAbleToWrite = owner !== 'standard' && onWrite && isHoveringOver && id && isReady;
    const isRemovable = onRemove && isHoveringOver && id && isReady;
    const isDeletable = owner !== 'standard' && onDelete && isHoveringOver && id && isReady;
    // const actionIconClass = isVine || isBehavior ? scriptActionIcon : actionIcon;

    
    const thumbUrl = assetThumbUrl(item, version);
    const copyName = "Copy of " + item.name;

    let image = '';
    if (uriThumb) {
      image = thumbUrl;
    } else if (isVine) {
      image = 'assets/img/Vine.Thumb.png';
    } else if (isBehavior) {
      image = 'assets/img/Behavior.Thumb.png';
    }

    return connectDragSource(
      <div
        onClick={() => {
          if (onEdit && isReady) {
            onEdit(item);
          }
        }}
        // Without this, Firefox tries to do a redirect
        onDrop={(event) => {
          event.preventDefault();
        }}
      >
        <Box height={'100%'}>
          <Box position="relative" height={'100%'}>
            <img src={image} className={classes.image} />
            <Box
              display="flex"
              flexDirection="row"
              position="absolute"
              alignItems="center"
              justifyContent="space-between"
              top={0}
              right={0}
            >
                {/* Delete */
                  isDeletable && (
                    <IconButton
                      size="small"
                      onClick={(evt) => {
                        evt.stopPropagation();
                        onDelete(item);
                      }}
                    >
                      <Delete className={classes.icon} />
                    </IconButton>
                  )}

                {/* Remove */
                  isRemovable && (
                    <IconButton
                      size="small"
                      onClick={(evt) => {
                        evt.stopPropagation();
                        onRemove(item);
                      }}
                    >
                      <Clear className={classes.icon} />
                    </IconButton>
                  )}
              <Grid container spacing={1}>
              <Grid item xs="7">
                <Box
                  className={clsx(
                    classes.scriptTextWrapper,
                    isVine ? classes.scriptTextWrapperVine : isBehavior ? classes.scriptTextWrapperBehavior : ''
                  )}
                >
                  <Typography align="center" className={classes.scriptText}>
                    {isVine ? 'Vine' : ''} {isBehavior ? 'Behavior' : ''}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs="5" >
                <Box>
                  {/* Loading */
                    isLoading && (
                      <CircularProgress size={16} />
                  )}

                  {/* Delete */
                 isDeletable && (
                  <IconButton
                    size="small"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      onDelete(item);
                    }}
                  >
                    <Delete className={classes.icon} />
                  </IconButton>
                )}

                  {/* Write */
                    isAbleToWrite && (
                      <IconButton
                        size="small"
                        onClick={(evt) => {
                          evt.stopPropagation();
                          onWrite(item);
                        }}
                      >
                        <Description className={classes.icon} />
                      </IconButton>
                  )}


                </Box>
              </Grid>
                <Grid 
                  item xs={7} 
                >
                  {(isVine || isBehavior) && ( 
                    <Tooltip 
                      title = {item.type === "model" || item.type === "image" ? "" : item.description}
                      placement="top" 
                      enterDelay={TOOLTIP_DELAY} 
                      enterNextDelay={TOOLTIP_DELAY}
                      leaveDelay={TOOLTIP_LEAVE_DELAY}
                      arrow>
                      <IconButton
                        size="small"
                        onClick={(evt) => {
                          evt.stopPropagation();
                        }}
                      >
                        <Search className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                {(isVine || isBehavior) && (
                  <Grid
                  item xs={5}>
                    {/* Menu for editing scripts */
                    <ScriptThreeDotDropDown 
                      script = {item}
                      copyName = {copyName}                  
                      onCreate = {onCreate}
                      appId = {appId}
                      categoryName = {selectedCategoryName}
                      onEdit = {onEdit}>
                    </ScriptThreeDotDropDown>}
                  </Grid>
                )}

              </Grid>
            </Box>
          </Box>
          {showText && <GridListTileBar title={name} className={classes.gridListTileBar} />}
        </Box>
      </div>
    );
  }
}

ItemTile.propTypes = {
  type: PropTypes.string.isRequired /* @see DragDropTypes */,

  isDragging: PropTypes.bool.isRequired,
  isHoveringOver: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,

  isSelected: PropTypes.bool,
  item: PropTypes.object,
  version: PropTypes.number,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  onWrite: PropTypes.func,
  onRemove: PropTypes.func,
  cellHeight: PropTypes.number,

  classes: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  showText: PropTypes.bool.isRequired
};

ItemTile.defaultProps = {
  isHoveringOver: true,
  expanded: false,
  showText: true,
  cellHeight: CELL_HEIGHT,
};

const itemSource = {
  // eslint-disable-next-line no-unused-vars
  beginDrag(props, monitor, component) {
    const { item, type } = props;
    return {
      item,
      type
    };
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

// export default DragSource('item', itemSource, collect)(MouseHoveringDetection(ItemTile));
export default withStyles(theme => ({
  image: (props) => {
    {
      let baseStyles = {
        height: props.expanded ? '100%' : props.cellHeight - TITLE_HEIGHT - theme.spacing(1),
        borderWidth: props.isSelected ? 2 : 0,
        width: '100%',
        // height: '100%',
        backgroundColor: theme.palette.background.default,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        objectFit: 'cover',
        objectPosition: '50% 50%'
      };

      if (props.expanded) {
        baseStyles = Object.assign(baseStyles, {
          borderRadius: 4,
          boxShadow: `inset 0 0 10px ${theme.palette.common.boxShadow}`,
          '-moz-box-shadow': `inset 0 0 10px ${theme.palette.common.boxShadow}`,
          '-webkit-box-shadow': `inset 0 0 10px ${theme.palette.common.boxShadow}`
        });
      }

      return baseStyles;
    }
  },
  scriptTextWrapper: {
    padding: '2px 4px 2px 4px'
  },
  scriptTextWrapperVine: {
    backgroundColor: theme.palette.info.main
  },
  scriptTextWrapperBehavior: {
    backgroundColor: theme.palette.secondary.main
  },
  scriptText: {
    fontSize: FONT_SIZE
  },
  gridListTileBar: {
    background: 'transparent',
    height: TITLE_HEIGHT,
    '& .MuiGridListTileBar-titleWrap': {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      '& .MuiGridListTileBar-title': {
        textAlign: 'center',
        fontSize: FONT_SIZE
      }
    }
  },
  icon: {
    color: theme.palette.grayscale.veryGray
  }
}))(DragSource('item', itemSource, collect)(ItemTile));
