import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import CancelButton from 'react-fine-uploader/cancel-button';
import DeleteButton from 'react-fine-uploader/delete-button';
import Dropzone from 'react-fine-uploader/dropzone';
import FileInput from 'react-fine-uploader/file-input';
import Filename from 'react-fine-uploader/filename';
import Filesize from 'react-fine-uploader/filesize';
import RetryButton from 'react-fine-uploader/retry-button';
import PauseResumeButton from 'react-fine-uploader/pause-resume-button';
import ProgressBar from 'react-fine-uploader/progress-bar';
import Status from 'react-fine-uploader/status';
import Thumbnail from 'react-fine-uploader/thumbnail';
import PauseIcon from 'react-fine-uploader/gallery/pause-icon';
import PlayIcon from 'react-fine-uploader/gallery/play-icon';
import UploadIcon from 'react-fine-uploader/gallery/upload-icon';
import UploadFailedIcon from 'react-fine-uploader/gallery/upload-failed-icon';
import UploadSuccessIcon from 'react-fine-uploader/gallery/upload-success-icon';
import XIcon from 'react-fine-uploader/gallery/x-icon';
import UploadOptions from './UploadOptions';

import { createDefaultOptions, filterOptions } from './options';
import { CategoryByType, FileTypeCategories } from '../../../constants/FileTypes';

/**
 * This implementation is derived from the Gallery component in react-fine-uploader.
 */
class UploaderGallery extends Component {
  static propTypes = {
    className: PropTypes.string,
    uploader: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: '',
    'cancelButton-children': <XIcon />,
    'deleteButton-children': <XIcon />,
    'dropzone-disabled': false,
    'dropzone-dropActiveClassName': 'react-fine-uploader-gallery-dropzone-active',
    'dropzone-multiple': true,
    'fileInput-multiple': true,
    'pauseResumeButton-pauseChildren': <PauseIcon />,
    'pauseResumeButton-resumeChildren': <PlayIcon />,
    'retryButton-children': <PlayIcon />
  };

  constructor(props) {
    super(props);

    this.state = {
      visibleFiles: [],
      optionsById: {}
    };

    const statusEnum = props.uploader.qq.status;

    this._onStatusChange = (id, oldStatus, status) => {
      const { visibleFiles } = this.state;

      if (status === statusEnum.SUBMITTED) {
        // visibleFiles.push({ id });
        // this.setState({ visibleFiles });
      } else if (isFileGone(status, statusEnum)) {
        this._removeVisibleFile(id);
      } else if (status === statusEnum.UPLOAD_SUCCESSFUL || status === statusEnum.UPLOAD_FAILED) {
        if (status === statusEnum.UPLOAD_SUCCESSFUL) {
          const visibleFileIndex = this._findFileIndex(id);
          if (visibleFileIndex < 0) {
            visibleFiles.push({ id, fromServer: true });
          }
        }
        this._updateVisibleFileStatus(id, status);
      }
    };
  }

  componentDidMount() {
    const { uploader } = this.props;

    uploader.on('statusChange', this._onStatusChange);
    uploader.on('submit', this._onSubmit);
    uploader.on('upload', this._onUpload);
  }

  componentWillUnmount() {
    const { uploader } = this.props;

    uploader.off('statusChange', this._onStatusChange);
    uploader.off('submit', this._onSubmit);
    uploader.off('upload', this._onUpload);
  }

  render() {
    const cancelButtonProps = getComponentProps('cancelButton', this.props);
    const dropzoneProps = getComponentProps('dropzone', this.props);
    const fileInputProps = getComponentProps('fileInput', this.props);
    const filenameProps = getComponentProps('filename', this.props);
    const filesizeProps = getComponentProps('filesize', this.props);
    const progressBarProps = getComponentProps('progressBar', this.props);
    const retryButtonProps = getComponentProps('retryButton', this.props);
    const statusProps = getComponentProps('status', this.props);
    const thumbnailProps = getComponentProps('thumbnail', this.props);
    const { uploader } = this.props;

    const chunkingEnabled = uploader.options.chunking && uploader.options.chunking.enabled;
    const deleteEnabled = uploader.options.deleteFile && uploader.options.deleteFile.enabled;
    const deleteButtonProps = deleteEnabled && getComponentProps('deleteButton', this.props);
    const pauseResumeButtonProps = chunkingEnabled && getComponentProps('pauseResumeButton', this.props);

    return (
      <MaybeDropzone
        content={this.props.children}
        hasVisibleFiles={this.state.visibleFiles.length > 0}
        uploader={uploader}
        {...dropzoneProps}
      >
        {!fileInputProps.disabled && <FileInputComponent uploader={uploader} {...fileInputProps} />}
        <ProgressBar
          className="react-fine-uploader-gallery-total-progress-bar"
          uploader={uploader}
          {...progressBarProps}
        />
        <TransitionGroup
          component="ul"
          className="react-fine-uploader-gallery-files"
          enter={!this.props.animationsDisabled}
          exit={!this.props.animationsDisabled}
        >
          {this.state.visibleFiles.map(({ id, status, fromServer }) => {
            const { optionsById } = this.state;
            const options = optionsById[id] || [];

            return (
              <CSSTransition
                key={id}
                classNames="react-fine-uploader-gallery-files"
                timeout={{ enter: 500, exit: 300 }}
              >
                <li key={id} className="react-fine-uploader-gallery-file">
                  <ProgressBar
                    className="react-fine-uploader-gallery-progress-bar"
                    id={id}
                    uploader={uploader}
                    {...progressBarProps}
                  />
                  <div style={{ borderRadius: '9px 9px 0 0' }}>
                    <Thumbnail
                      className="react-fine-uploader-gallery-thumbnail"
                      id={id}
                      fromServer={fromServer}
                      uploader={uploader}
                    />
                  </div>
                  {status === 'upload successful' && (
                    <span>
                      <UploadSuccessIcon className="react-fine-uploader-gallery-upload-success-icon" />
                      <div className="react-fine-uploader-gallery-thumbnail-icon-backdrop" />
                    </span>
                  )}
                  {status === 'upload failed' && (
                    <span>
                      <UploadFailedIcon className="react-fine-uploader-gallery-upload-failed-icon" />
                      <div className="react-fine-uploader-gallery-thumbnail-icon-backdrop" />
                    </span>
                  )}
                  <div
                    className="react-fine-uploader-gallery-file-footer"
                    style={{
                      background: 'rgba(255, 255, 255, 0.7)',
                      position: 'absolute',
                      bottom: 0,
                      zIndex: 10,
                      paddingTop: 5,
                      width: '100%'
                    }}
                  >
                    <Filename
                      className="react-fine-uploader-gallery-filename"
                      id={id}
                      uploader={uploader}
                      {...filenameProps}
                    />
                    <Status
                      className="react-fine-uploader-gallery-status"
                      id={id}
                      uploader={uploader}
                      {...statusProps}
                    />
                    <Filesize
                      className="react-fine-uploader-gallery-filesize"
                      id={id}
                      uploader={uploader}
                      {...filesizeProps}
                    />
                    <UploadOptions
                      options={options}
                      onChange={(newOptions) => {
                        this.setState({
                          optionsById: { ...optionsById, [id]: newOptions }
                        });
                      }}
                    />
                  </div>
                  <CancelButton
                    className="react-fine-uploader-gallery-cancel-button"
                    id={id}
                    uploader={uploader}
                    {...cancelButtonProps}
                  />
                  <RetryButton
                    className="react-fine-uploader-gallery-retry-button"
                    id={id}
                    uploader={uploader}
                    {...retryButtonProps}
                  />
                  {deleteEnabled && (
                    <DeleteButton
                      className="react-fine-uploader-gallery-delete-button"
                      id={id}
                      uploader={uploader}
                      {...deleteButtonProps}
                    />
                  )}
                  {chunkingEnabled && (
                    <PauseResumeButton
                      className="react-fine-uploader-gallery-pause-resume-button"
                      id={id}
                      uploader={uploader}
                      {...pauseResumeButtonProps}
                    />
                  )}
                </li>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </MaybeDropzone>
    );
  }

  _onSubmit = (id, name) => {
    const { visibleFiles, optionsById: _optionsById } = this.state;

    const suffix = name
      .split('.')
      .pop()
      .toLowerCase();
    const type = CategoryByType[suffix];
    const optionsById = {
      ..._optionsById,
      [id]: createDefaultOptions(type)
    };

    visibleFiles.push({ id });
    this.setState({ visibleFiles, optionsById });

    return true;
  };

  _onUpload = (id, name) => {
    const { uploader } = this.props;
    const { optionsById } = this.state;
    const options = optionsById[id];
    const filteredOptions = filterOptions(options);
    const optionsObj = filteredOptions.reduce(
      (accum, { name, value }) => ({
        ...accum,
        [name]: value
      }),
      {}
    );

    uploader.methods.setParams(optionsObj, id);
  };

  _removeVisibleFile(id) {
    const visibleFileIndex = this._findFileIndex(id);

    if (visibleFileIndex >= 0) {
      const { visibleFiles, optionsById: _optionsById } = this.state;
      const { [id]: _, ...optionsById } = _optionsById;

      visibleFiles.splice(visibleFileIndex, 1);
      this.setState({ visibleFiles, optionsById });
    }
  }

  _updateVisibleFileStatus(id, status) {
    this.state.visibleFiles.some((file) => {
      if (file.id === id) {
        file.status = status;
        this.setState({ visibleFiles: this.state.visibleFiles });
        return true;
      }
    });
  }

  _findFileIndex(id) {
    let visibleFileIndex = -1;

    this.state.visibleFiles.some((file, index) => {
      if (file.id === id) {
        visibleFileIndex = index;
        return true;
      }
    });

    return visibleFileIndex;
  }
}

const MaybeDropzone = ({
 children, content, hasVisibleFiles, uploader, ...props
}) => {
  const { disabled, ...dropzoneProps } = props;

  let dropzoneDisabled = disabled;
  if (!dropzoneDisabled) {
    dropzoneDisabled = !uploader.qq.supportedFeatures.fileDrop;
  }

  if (hasVisibleFiles) {
    content = <span />;
  } else {
    content = content || getDefaultMaybeDropzoneContent({ content, disabled: dropzoneDisabled });
  }

  if (dropzoneDisabled) {
    return (
      <div className="react-fine-uploader-gallery-nodrop-container">
        {content}
        {children}
      </div>
    );
  }

  return (
    <Dropzone className="react-fine-uploader-gallery-dropzone" uploader={uploader} {...dropzoneProps}>
      {content}
      {children}
    </Dropzone>
  );
};

const FileInputComponent = ({ uploader, ...props }) => {
  const { children, ...fileInputProps } = props;
  const content = children || (
    <span>
      <UploadIcon className="react-fine-uploader-gallery-file-input-upload-icon" />
      Select Files
    </span>
  );

  return (
    <FileInput className="react-fine-uploader-gallery-file-input-container" uploader={uploader} {...fileInputProps}>
      <span className="react-fine-uploader-gallery-file-input-content">{content}</span>
    </FileInput>
  );
};

const getComponentProps = (componentName, allProps) => {
  const componentProps = {};

  Object.keys(allProps).forEach((propName) => {
    if (propName.indexOf(`${componentName}-`) === 0) {
      const componentPropName = propName.substr(componentName.length + 1);
      componentProps[componentPropName] = allProps[propName];
    }
  });

  return componentProps;
};

const getDefaultMaybeDropzoneContent = ({ content, disabled }) => {
  const className = disabled
    ? 'react-fine-uploader-gallery-nodrop-content'
    : 'react-fine-uploader-gallery-dropzone-content';

  if (disabled && !content) {
    return <span className={className}>Upload files</span>;
  } if (content) {
    return <span className={className}>{content}</span>;
  } if (!disabled) {
    return (
      <span className={className}>
        <UploadIcon className="react-fine-uploader-gallery-dropzone-upload-icon" />
        Drop files here
      </span>
    );
  }
};

const isFileGone = (statusToCheck, statusEnum) => [statusEnum.CANCELED, statusEnum.DELETED].indexOf(statusToCheck) >= 0;

export default UploaderGallery;
