import {
  Box, LinearProgress, makeStyles, Paper
} from '@material-ui/core';
import {
  Audiotrack,
  Description,
  Image,
  Movie
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({
  image: {
    height: '60px',
    width: '60px',
    objectFit: 'cover'
  }
});


const mimeTypeToIcon = {
  image: Image,
  video: Movie,
  audio: Audiotrack
};

function AssetLoadingBox({ file }) {
  const mimeType = file.type.split('/')[0];
  const Icon = mimeTypeToIcon[mimeType] || Description;
  const classes = useStyles();

  const [previewImage, setPreviewImage] = React.useState(null);


  React.useEffect(() => {
    if (mimeType === 'image') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);


  return (
    <Paper >
      <Box p={1} display="flex" flexDirection="column" alignItems="stretch">
        {/* <Typography variant="body">{ae.file.name}</Typography> */}
        <Box mb={1} alignSelf="center" display="flex" fontSize={60}>
          {previewImage ? <img src={previewImage} className={classes.image} /> : <Icon fontSize="inherit" />}</Box>
        <LinearProgress />
      </Box>
    </Paper>);
};


AssetLoadingBox.propTypes = {

  file: PropTypes.object.isRequired,

};


export default (AssetLoadingBox);
