import DragBarIds from './DragBarIds';
import { constrain } from '../../util/util';

export const baseStyle = {
  container: {
    display: 'grid',
    width: '100vw',
    minWidth: 800,
    height: '100vh',
    minHeight: 600
  },

  menu: {
    gridArea: 'menu / full'
  },

  left: {
    gridArea: 'main / left'
  },

  right: {
    gridArea: 'main / right'
  },

  player: {
    gridArea: 'player / center',
  },

  drawer: {
    gridArea: 'drawer / center'
  },

  leftHalf: {
    gridArea: 'main / left-half'
  },

  rightHalf: {
    gridArea: 'main / right-half'
  }
};

export const getGrid = ({ screenSize, current, max, min, menu }, style = baseStyle) => {
  let { left, right, drawer } = current;
  const width = Math.max(screenSize.minWidth, screenSize.width);
  const height = Math.max(screenSize.minHeight, screenSize.height);

  left = Math.max(min.left, left);
  let maxLeft = Math.min(max.left, screenSize.width * max.leftPct);
  left = Math.min(maxLeft, left);

  right = Math.max(min.right, right);
  let maxRight = Math.min(max.right, screenSize.width * max.rightPct);
  right = Math.min(maxRight, right);

  drawer = Math.max(min.drawer, drawer);
  let maxDrawer = Math.min(max.drawer, screenSize.height * max.drawerPct);
  drawer = Math.min(maxDrawer, drawer);

  const centerWidth = width - left - right;

  return {
    ...style,
    container: {
      ...style.container,
      gridTemplateRows: `[menu-start] ${menu}px [menu-end main-start player-start] ${height - menu - drawer}px [player-end drawer-start] ${drawer}px [drawer-end main-end]`,
      gridTemplateColumns: `[full-start left-start left-half-start] ${left}px [left-end center-start] ${centerWidth / 2}px [left-half-end right-half-start] ${centerWidth / 2}px [center-end right-start] ${right}px [right-half-end right-end full-end]`
    }
  };
};

/**
 * Constrains the various values used by the screen resize system.
 *
 * @param id
 * @param currentOffset
 * @param initialOffset
 * @param uiOffsets
 * @param screenSize
 * @returns {{x: *, width: number, y: *, height: number}}
 */
export const getGridValues = ({ id, currentOffset, initialOffset, uiOffsets, screenSize }) => {
  const { current, min, max } = uiOffsets;
  let { x, y } = currentOffset;
  let width = 10;
  let height = 10;

  switch (id) {
    case DragBarIds.LEFT: {
      height = screenSize.height - current.menu;
      let maxLeft = Math.min(max.left, max.leftPct * screenSize.width);
      x = constrain(min.left, maxLeft, x);
      ({ y } = initialOffset);

      break;
    }

    case DragBarIds.RIGHT: {
      height = screenSize.height - current.menu;
      let maxRight = Math.min(max.right, max.rightPct * screenSize.width);
      x = constrain(screenSize.width - maxRight, screenSize.width - min.right, x);
      ({ y } = initialOffset);

      break;
    }

    case DragBarIds.DRAWER: {
      width = screenSize.width - current.left - current.right;
      let maxDrawer = Math.min(max.drawer, max.drawerPct * screenSize.height);
      ({ x } = initialOffset);
      y = constrain(screenSize.height - maxDrawer, screenSize.height - min.drawer, y);

      break;
    }

    default: {
      throw new Error(`Invalid DragBar id '${id}'`);
    }
  }

  return { x, y, height, width };
};
