import { AsyncStatus, getActions } from '@enklu/server-api';
import {
 Button, Dialog, DialogActions, DialogContent, TextField
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { funcNewMonitor } from '../../util/util';
import ModalHeader from '../material-ui/ModalHeader';

const { updatescript } = getActions('trellis');

//called from ScriptThreeDotDropDown and creates a modal to edit a script
const EditScriptModal = ({
 updateScript, classes, script, onClose, isRenameScript
}) => {
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');

  const { status: updateStatus, runAction: runUpdateStatus } = funcNewMonitor(updateScript);

  React.useEffect(() => {
    if (!script) {
      setName('');
      setDescription('');
    } else {
      setName(script.name);
      setDescription(script.description);
    }
  }, [script]);

  const submit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    runUpdateStatus({
      params: [
        {
          name,
          description
        },
        { scriptId: script.id }
      ],
      onFinally: () => {
        onClose(event);
      }
    });
  };

  return (
    <Dialog 
      onClose={onClose} 
      open={!!script} 
      onClick={(event) => {
        event.stopPropagation();
      }} 
      fullWidth
    >
      <ModalHeader title={isRenameScript ? 'Rename' : 'Edit Description'} onClose={onClose} />
      <form>
        {isRenameScript ? 
          //shows if renaming script
          <DialogContent>
            <div className={classes.inputFieldsContainer}>
              <TextField
                label="Script Name"
                id="scriptName"
                placeholder="Enter Name"
                value={name}
                fullWidth
                onClick={(event) => {
                  event.stopPropagation();}}
                onChange={({ target: { value } }) => setName(value)}
                variant="outlined"
              />
            </div>
          </DialogContent>
          :
          //shows if editing script's description
          <DialogContent>
            <div className={classes.inputFieldsContainer}>
              <TextField
                label="Script Description"
                id="scriptDescription"
                placeholder="Enter Description"
                value={description}
                fullWidth
                onClick={(event) => {
                  event.stopPropagation();}}
                onChange={({ target: { value } }) => setDescription(value)}
                variant="outlined"
              />
            </div>
          </DialogContent>}
        <DialogActions>
          <Button
            onClick={onClose}
            color="default"
            variant="outlined"
            disabled={updateStatus === AsyncStatus.IN_PROGRESS}
          >
            Cancel
          </Button>
          {isRenameScript ? 
            <Button
              type="submit"
              onClick={submit}
              color="primary"
              variant="outlined"
              disabled={!name || updateStatus === AsyncStatus.IN_PROGRESS}
            >
              Update
            </Button>
            :
            <Button
              type="submit"
              onClick={submit}
              color="primary"
              variant="outlined"
              disabled={!description || updateStatus === AsyncStatus.IN_PROGRESS}
            >
              Update
            </Button>
          }
        </DialogActions>
      </form>
    </Dialog>
  );
};

EditScriptModal.propTypes = {
  // withRouter
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  updateScript: PropTypes.func.isRequired,

  classes: PropTypes.object.isRequired,
  script: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  isRenameScript: PropTypes.bool.isRequired
};
EditScriptModal.defaultProps = {};

const mapDispatchToProps = dispatch => ({
  updateScript: ({ name, description }, { scriptId }) => dispatch(
      updatescript(
        {
          name, description
        },
        { scriptId }
      )
    )
});


export default withStyles(theme => ({
  inputFieldsContainer: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }
}))(withRouter(connect(null, mapDispatchToProps)(EditScriptModal)));