import { createSelector } from 'reselect';
import { addParents } from '../reducers/scenesReducer';

const getRawScenes = state => state.app.scenes.rawScenes;

export const getScenes = createSelector(
  getRawScenes,
  (scenes) => {
    const newScenes = scenes.map((scene) => {
      // The prop we care about is `elements`, which is the root node of the tree,
      const { elements } = scene;

      // Add a parent ref to every node.
      return {
        ...scene,
        elements: addParents(elements)
      };
    });

    return newScenes;
  }
);

// eslint-disable-next-line no-unused-vars
export const getActiveSceneIndex = state => 0;

export const getScene = createSelector(
  getScenes,
  getActiveSceneIndex,
  (scenes, index) => scenes[index]
);
