import LogMessageTypes from './LogMessageTypes';

export default {
    [LogMessageTypes.UNKNOWN]: 'Unknown',
    [LogMessageTypes.DEBUG]: 'Debug',
    [LogMessageTypes.INFO]: 'Info',
    [LogMessageTypes.WARN]: 'Warning',
    [LogMessageTypes.ERROR]: 'Error',
    [LogMessageTypes.CONNECTION]: 'Connection',
    [LogMessageTypes.ALL]: 'All'
};
