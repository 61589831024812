export const INITIALIZATION = "INITIALIZATION";

export const SET_TOKEN = "SET_TOKEN";
export const VALID_TOKEN = "VALID_TOKEN";
export const INVALID_TOKEN = "INVALID_TOKEN";

export const SET_EMAIL = "SET_EMAIL";
export const VALID_EMAIL = "VALID_EMAIL";
export const INVALID_EMAIL = "INVALID_EMAIL";

export const SET_PASSWORD = "SET_PASSWORD";
export const VALID_PASSWORD = "VALID_PASSWORD";
export const INVALID_PASSWORD = "INVALID_PASSWORD";
export const MATCHING_PASSWORDS = "MATCHING_PASSWORDS";

export const HTTP_ACTION = "HTTP_ACTION";
// httpMiddleware sets these _REQUEST, _SUCCESS, _FAILED types
export const GET_TOKEN = "GET_TOKEN";
export const GET_TOKEN_REQUEST = "GET_TOKEN_REQUEST";
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS";
export const GET_TOKEN_FAILED = "GET_TOKEN_FAILED";

export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const VALIDATE_TOKEN_REQUEST = "VALIDATE_TOKEN_REQUEST";
export const VALIDATE_TOKEN_SUCCESS = "VALIDATE_TOKEN_SUCCESS";
export const VALIDATE_TOKEN_FAILED = "VALIDATE_TOKEN_FAILED";

export const PASSWORD_RESET = "PASSWORD_RESET";
export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILED = "PASSWORD_RESET_FAILED";
