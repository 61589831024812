import { AsyncStatus, TrellisActions } from '@enklu/server-api';
import { ADD_UPLOADING_ASSET, ASSET_ERROR } from '../store/actions/assetActions';
import { EMAILSIGNIN } from '@enklu/server-api/src/js/actions/trellisActions';
import { INVITEUSER } from '@enklu/server-api/src/js/actions/trellisActions';
import { UPDATESCENE } from '../store/actions/TxnManager';
import { SIGNUP } from '../store/actions/signupActions';

const {
  GETAPP, CREATEAPP, CREATEAPPCOLLABORATOR, CREATESCRIPT, UPDATESCRIPT, UPDATEANASSETTHUMBNAIL, UPDATEAPPCOLLABORATOR,
  DELETEAPP, DELETEAPPCOLLABORATOR, DELETEMYASSET, DELETESCRIPT
} = TrellisActions;

// Keys for tracking user metrics
const MetricsTypes = {
    SIGNUP_EMAIL: 'app.signup.email',
    SIGNIN_EMAIL: 'app.signin.email',
    ASSET_UPLOAD: 'asset.upload',
    ASSET_THUMBNAIL: 'asset.thumbnail.update',
    ASSET_DELETE: 'asset.delete',
    ASSET_ERROR: 'asset.error',
    EXP_CREATE: 'experience.create',
    EXP_GET: 'experience.get',
    EXP_DELETE: 'experience.delete',
    COLLABORATOR_ADD: 'experience.collaborator.add',
    COLLABORATOR_UPDATE: 'experience.collaborator.update',
    COLLABORATOR_DELETE: 'experience.collaborator.delete',
    USER_INVITE: 'experience.user.invite',
    SCENE_UPDATE: 'scene.update',
    SCRIPT_CREATE: 'script.create',
    SCRIPT_UPDATE: 'script.update',
    SCRIPT_DELETE: 'script.delete',
    NONE: ''
}

// schema keys we are interested in for metrics
export const metricsSchemaKeys = [
  'assetSrc'
];

// convert the various actions we track to human readable metric events
export const actionToMetric = (action) => {
  switch (action) {
      case SIGNUP: return MetricsTypes.SIGNUP_EMAIL;
    case EMAILSIGNIN: return MetricsTypes.SIGNIN_EMAIL;
    case ADD_UPLOADING_ASSET: return MetricsTypes.ASSET_UPLOAD;
    case UPDATEANASSETTHUMBNAIL: return MetricsTypes.ASSET_THUMBNAIL;
    case DELETEMYASSET: return MetricsTypes.ASSET_DELETE;
    case ASSET_ERROR: return MetricsTypes.ASSET_ERROR;
    case CREATEAPP: return MetricsTypes.EXP_CREATE;
    case GETAPP: return MetricsTypes.EXP_GET;
    case DELETEAPP: return MetricsTypes.EXP_DELETE;
    case UPDATESCENE: return MetricsTypes.SCENE_UPDATE;
    case CREATEAPPCOLLABORATOR: return MetricsTypes.COLLABORATOR_ADD;
    case UPDATEAPPCOLLABORATOR: return MetricsTypes.COLLABORATOR_UPDATE;
    case DELETEAPPCOLLABORATOR: return MetricsTypes.COLLABORATOR_DELETE;
      case INVITEUSER : return MetricsTypes.USER_INVITE;
    case CREATESCRIPT: return MetricsTypes.SCRIPT_CREATE;
    case UPDATESCRIPT: return MetricsTypes.SCRIPT_UPDATE;
    case DELETESCRIPT: return MetricsTypes.SCRIPT_DELETE;
    default: return MetricsTypes.NONE;
  }
};


export default MetricsTypes;
