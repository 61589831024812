import { Add } from '@material-ui/icons';
import {
 Box, Chip, IconButton, InputAdornment, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import BaseStandardTextField from '../material-ui/BaseStandardTextField';

const useStyles = makeStyles(theme => ({
  tagsContainer: {
    '& > *': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  chip: {
    borderRadius: 100
  }
}));

const ItemTags = ({
 onAddTag, onRemoveTag, item: { tags, owner }, tagResolver, canManageStandardAssets
}) => {
  const [newTag, setNewTag] = React.useState('');

  const classes = useStyles();

  let counter = 0;
  const tagControls = tagResolver(tags).map(tag => (
    <Chip
      className={classes.chip}
      key={(counter += 1)}
      label={tag}
      onDelete={() => {
        onRemoveTag(tag);
      }}
      color="secondary"
    />
  ));

  return (
    <Box my={1}>
      <Typography variant="h6">Tags</Typography>
      <Box my={1}>
        <BaseStandardTextField
          placeholder="Tag"
          value={newTag}
          onChange={({ target: { value } }) => setNewTag(value)}
          variant="outlined"
          fullWidth
          disabled={!(owner !== 'standard' || canManageStandardAssets)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!!newTag && newTag.length > 0 && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (newTag) {
                        onAddTag(newTag.toLowerCase());
                        setNewTag('');
                      }
                    }}
                  >
                    <Add />
                  </IconButton>
                )}
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box px={1} className={classes.tagsContainer}>
        {tagControls}
      </Box>
    </Box>
  );
};

ItemTags.propTypes = {
  item: PropTypes.shape({
    tags: PropTypes.string.isRequired,
    owner: PropTypes.string.isRequired
  }),
  tagResolver: PropTypes.func.isRequired,
  onAddTag: PropTypes.func.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
  canManageStandardAssets: PropTypes.bool.isRequired
};

export default ItemTags;
