import { Box } from '@material-ui/core';
import React from 'react';

import InspectableIdentity, { elementToInspectable } from '../common/MuiInspectableIdentity';
import InspectorInterface from '../common/InspectorInterface';
import Transform from '../MuiTransform';

/**
 * Custom inspector for container elements.
 */
const ContainerElementInspector = ({ onUpdateElement, element, scene }) => {
  const { id: sceneId } = scene;

  return (
    <Box p={1}>
      <InspectableIdentity
        inspectable={element}
        inspectableTranslator={elementToInspectable}
        onUpdate={onUpdateElement}
      />

      <Transform hideTitle element={element} sceneId={sceneId} />
    </Box>
  );
};

ContainerElementInspector.propTypes = {
  ...InspectorInterface
};

export default ContainerElementInspector;
