import * as Sentry from '@sentry/browser';

export const log = {
  level: 4,

  debug: function(...args) {
    if (log.level < 1) {
      return;
    }

    var f = console.log || function() {};
    if (console.debug) {
      f = console.debug;
    }

    f.apply(null, args);
  },

  info: function(...args) {
    if (log.level < 2) {
      return;
    }

    var f = console.log || function() {};
    if (console.info) {
      f = console.info;
    }

    f.apply(null, args);
  },

  warn: function(...args) {
    if (log.level < 3) {
      return;
    }

    var f = console.log || function() {};
    if (console.warn) {
      f = console.warn;
    }

    f.apply(null, args);
  },

  error: function(...args) {
    if (log.level < 4) {
      return;
    }

    var f = console.log || function() {};
    if (console.error) {
      f = console.error;
    }

    f.apply(null, args);

    // forward to Sentry when in production
    if (window.env.build === 'production') {
      Sentry.captureMessage.apply(null, args);
    }
  }
};
