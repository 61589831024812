const ElementTypes = {
  UNKNOWN: -1,
  GROUP : 0,
  // Temporarily using same ID group/container element type until we know what we really want to do with it
  LINKED_COMPONENT : 0,
  EXPERIENCE_ROOT_COPY : 0,
  IMAGE : 5,
  BUTTON : 10,
  CURSOR : 20,
  CAPTION : 30,
  MENU : 100,
  TEXTCRAWL : 120,
  FLOAT : 130,
  SCREEN: 131,
  TOGGLE : 140,
  SLIDER : 150,
  SELECT : 200,
  GRID : 201,
  OPTION : 210,
  OPTION_GROUP : 211,
  CONTENT : 1000,
  ASSET : 1000,
  LIGHT: 1100,
  SCAN: 1200,
  KINECT: 1300,
  CAMERA: 1400,
  WORLD_ANCHOR: 1000000,
  QR_ANCHOR: 1000001,
  IMAGE_ANCHOR: 1000100,
  NETWORKED_TEXT: 2000,
  NETWORKED_BUTTON: 3000
};

export const elementTypeToString = (type) => {
  switch (type) {
    case ElementTypes.GROUP : return 'group';
    case ElementTypes.LINKED_COMPONENT : return 'linkedcomponent';
    case ElementTypes.EXPERIENCE_ROOT_COPY : return 'experiencerootcopy';
    case ElementTypes.IMAGE : return 'image';
    case ElementTypes.BUTTON : return 'button';
    case ElementTypes.CURSOR : return 'cursor';
    case ElementTypes.CAPTION : return 'caption';
    case ElementTypes.MENU : return 'menu';
    case ElementTypes.TEXTCRAWL : return 'textcrawl';
    case ElementTypes.FLOAT : return 'float';
    case ElementTypes.SCREEN: return 'screen';
    case ElementTypes.TOGGLE : return 'toggle';
    case ElementTypes.SLIDER : return 'slider';
    case ElementTypes.SELECT : return 'select';
    case ElementTypes.GRID : return 'grid';
    case ElementTypes.OPTION : return 'option';
    case ElementTypes.OPTION_GROUP : return 'optiongroup';
    case ElementTypes.CONTENT : return 'content';
    case ElementTypes.ASSET : return 'asset';
    case ElementTypes.LIGHT: return 'light';
    case ElementTypes.SCAN: return 'scan';
    case ElementTypes.KINECT: return 'kinect';
    case ElementTypes.CAMERA: return 'camera';
    case ElementTypes.WORLD_ANCHOR: return 'worldanchor';
    case ElementTypes.QR_ANCHOR: return 'qranchor';
    case ElementTypes.IMAGE_ANCHOR: return 'imageanchor';
    default: return '';
  }
};

export default ElementTypes;
