import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Dragger from './Dragger';

class DraggerInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayValue: props.value.toString(),
      localValue: undefined,
      grabbedValue: null
    };

    this.debounceTimeout = null;
    this.debounceDelay = 300;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      displayValue: nextProps.value.toString()
    });
  }

  onDraggerChange({ currentValue, draggerValue, commit }) {
    const { mod } = this.props;
    let { grabbedValue } = this.state;

    if (grabbedValue === null) {
      grabbedValue = currentValue;
    }

    let newValue = grabbedValue + draggerValue;

    // Wrap value if necessary.
    if (mod) {
      // eslint-disable-next-line no-mixed-operators
      newValue = ((newValue % mod) + mod) % mod;
    }

    this.setState({
      grabbedValue: commit ? null : grabbedValue
    });

    this.debouncedOnChange({
      value: newValue,
      commit,
      type: 'change'
    });
  }

  debouncedOnChange(changeEvent) {
    const { value: currentValue, onChange, onPreview } = this.props;

    // Allow blank entry if we haven't tabbed out yet.
    const newDisplayValue = changeEvent.type === 'change' ? changeEvent.value : changeEvent.value || currentValue;

    this.setState({
      localValue: newDisplayValue
    }, () => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        this.onChange(changeEvent);
      }, this.debounceDelay);
    });
  }

  onChange({ value, type, commit }) {
    const { value: currentValue, onChange, onPreview } = this.props;

    // Allow blank entry if we haven't tabbed out yet.
    const newDisplayValue = type === 'change' ? value : value || currentValue;

    // If we *do* tab out on a blank, set it back to what it was.
    const newNumericValue = newDisplayValue === '' ? currentValue : parseFloat(newDisplayValue);

    if (Math.abs(newNumericValue - currentValue) > Number.EPSILON) {
      if (commit) {
        onChange(newNumericValue);
      } else if (this.props.onPreview) {
        onPreview(newNumericValue);
      }
    }
  }

  render() {
    const {
 children, scale, value: currentValue, disabled, classes, inputProps
} = this.props;

    const { displayValue, localValue } = this.state;

    return (
      <FormControl>
        <InputLabel className={classes.inputLabel}>
          <Dragger
            onChange={(value, commit) => {
              this.onDraggerChange({
                currentValue,
                draggerValue: value,
                commit
              });
            }}
            scale={scale}
            disabled={disabled}
          >
            {children}
          </Dragger>
        </InputLabel>
        <OutlinedInput
          className={classes.outlinedInput}
          margin="dense"
          type="number"
          step="any"
          value={localValue === "" ? "" : localValue || displayValue}
          onChange={({ target: { value }, type }) => this.debouncedOnChange({
            value,
            type,
            commit: true
          })}
          onBlur={({ target: { value }, type }) => {
            this.setState({
              localValue: undefined
            });
            if (this.debounceTimeout) {
              clearTimeout(this.debounceTimeout);
            }
            this.onChange({
              value,
              type,
              commit: true
            });
          }}
          disabled={disabled}
          {...inputProps}
        />
      </FormControl>
    );
  }
}

DraggerInput.propTypes = {
  value: PropTypes.number.isRequired,
  onPreview: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,

  scale: PropTypes.number,
  mod: PropTypes.number,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  inputProps: PropTypes.object
};

DraggerInput.defaultProps = {
  scale: 1,
  mod: undefined,
  disabled: false,
  dense: true
};

export default withStyles(() => ({
  inputLabel: {
    // color: theme.palette.primary.main,
    transform: 'translate(4px, -6px) scale(0.75)'
  },
  outlinedInput: {
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingRight: 0
    }
  }
}))(DraggerInput);
