import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  icon: {
    textAlign: 'center'
  }
}));

const SvgFileIcon = ({ svgFile, className, ...iconProps }) => {
  const classes = useStyles();
  return (
    <Icon className={clsx(classes.icon, className)} {...iconProps}>
      <img src={svgFile} height={'100%'} />
    </Icon>
  );
};

SvgFileIcon.propTypes = {
  className: PropTypes.string,
  svgFile: PropTypes.string.isRequired
};

export default SvgFileIcon;
