import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import CheckboxField from '../material-ui/CheckboxField';
import { setRenderingSetting } from '../../store/actions/sceneActions';
import txns from '../../store/actions/TxnManager';
import { updateAction } from '../../store/actions/elementActions';
import { getScene } from '../../store/selectors/scenesSelectors';
import BufferedSlider from '../material-ui/BufferedSlider';
import SchemaContainersByType from '../../constants/SchemaContainersByType';
import ActionSchemaTypes from '../../constants/ActionSchemaTypes';

const BLOOM_EFFECT = 'bloom';
const PARAM_ENABLED = 'enabled';
const PARAM_THRESHOLD = 'threshold';
const PARAM_INTENSITY = 'intensity';
const PARAM_BLURSTRIDE = 'blurStride';
const PARAM_PASSES = 'passes';

const DEFAULTS = {
  [PARAM_ENABLED]: false,
  [PARAM_THRESHOLD]: 0.8,
  [PARAM_INTENSITY]: 1.2,
  [PARAM_BLURSTRIDE]: 2,
  [PARAM_PASSES]: 3
};

const getSchemaKey = (effect, parameter) => {
  return `postprocessing.${effect}.${parameter}`;
}

class PostProcessingSettings extends React.Component {

  render() {
    const {
      scene: { id: sceneId, elements: { schema = {} } },
      onUpdateSceneSetting,
    } = this.props;

    const storage = {
      [SchemaContainersByType.bool]: schema[SchemaContainersByType.bool] || {},
      [SchemaContainersByType.float]: schema[SchemaContainersByType.float] || {},
      [SchemaContainersByType.int]: schema[SchemaContainersByType.int] || {}
    }

    const getValue = (storage, param) => {
      let stored = storage[getSchemaKey(BLOOM_EFFECT, param)];
      if (stored === undefined) stored = DEFAULTS[param];
      return stored;
    }

    // Builds a slider component for a specific parameter / config combination
    const buildSlider = ({ effect, parameter, schemaType, min, max, step, autoMark }) => {
      const onChange = pushChange => {
        return value => {
          onUpdateSceneSetting({
            sceneId,
            schemaType,
            effect,
            parameter,
            value,
            pushChange
          });
        }
      }
      return (
        <BufferedSlider
          value={getValue(storage[schemaType], parameter)}
          onChange={onChange(true)}
          // TODO: Enable once the player reacts to schema changes directly
          // onPreview={onChange(false)}
          min={min}
          max={max}
          step={step}
          autoMark={autoMark}
        />);
    }

    return (
      <div style={{ width: '100%' }}>
        <Typography variant='h6'>Bloom</Typography>
        <CheckboxField
          label='Enabled'
          value={getValue(storage[SchemaContainersByType.bool], PARAM_ENABLED)}
          onChange={(_, value ) => {
            onUpdateSceneSetting({
              sceneId,
              schemaType: SchemaContainersByType.bool,
              effect: BLOOM_EFFECT,
              parameter: PARAM_ENABLED,
              value,
              pushChange: true
            });
          }}
        />

        <Typography style={{ marginTop: '10px' }}>Threshold</Typography>
        {buildSlider({
          effect: BLOOM_EFFECT,
          parameter: PARAM_THRESHOLD,
          schemaType: SchemaContainersByType.float,
          min: 0,
          max: 2,
          step: 0.01,
          autoMark: BufferedSlider.MarkType.Coarse
        })}

        <Typography>Intensity</Typography>
        {buildSlider({
          effect: BLOOM_EFFECT,
          parameter: PARAM_INTENSITY,
          schemaType: SchemaContainersByType.float,
          min: 0,
          max: 5,
          step: 0.01,
          autoMark: BufferedSlider.MarkType.Coarse
        })}

        <Typography>Blur Size</Typography>
        {buildSlider({
          effect: BLOOM_EFFECT,
          parameter: PARAM_BLURSTRIDE,
          schemaType: SchemaContainersByType.float,
          min: 1,
          max: 5,
          step: 0.1,
          autoMark: BufferedSlider.MarkType.Coarse
        })}

        <Typography>Passes</Typography>
        {buildSlider({
          effect: BLOOM_EFFECT,
          parameter: PARAM_PASSES,
          schemaType: SchemaContainersByType.int,
          min: 1,
          max: 5,
          step: 1,
          autoMark: BufferedSlider.MarkType.Fine
        })}
      </div>);
  }
}

PostProcessingSettings.propTypes = {
  scene: PropTypes.object.isRequired,
  onUpdateSceneSetting: PropTypes.func.isRequired,
}

const mapStoreToProps = store => ({
  scene: getScene(store)
});

const mapDispatchToProps = dispatch => ({
  onUpdateSceneSetting: ({
    sceneId, schemaType, effect, parameter, value, pushChange
  }) => {
    const key = getSchemaKey(effect, parameter);
    dispatch(setRenderingSetting({
      sceneId,
      schemaType,
      key,
      value
    }));

    // This is dumb.
    let actionType;
    switch (schemaType) {
      case SchemaContainersByType.string:
        actionType = ActionSchemaTypes.STRING;
        break;
      case SchemaContainersByType.int:
        actionType = ActionSchemaTypes.INT;
        break;
      case SchemaContainersByType.float:
        actionType = ActionSchemaTypes.FLOAT;
        break;
      case SchemaContainersByType.bool:
        actionType = ActionSchemaTypes.BOOL;
        break;
      case SchemaContainersByType.vec3:
        actionType = ActionSchemaTypes.VEC3;
        break;
      case SchemaContainersByType.col4:
        actionType = ActionSchemaTypes.COL4;
        break;
    }

    // Sets the scene setting for real.
    if (pushChange) {
      txns.request(sceneId, updateAction('root', actionType, key, value, value));
    }
  }
});

export default connect(mapStoreToProps, mapDispatchToProps)(PostProcessingSettings);
