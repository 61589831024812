import {
 Box, Button, FormControl, InputLabel, MenuItem, Select, TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import BaseButton from '../../material-ui/BaseButton';
import CreatorShape from '../../../interfaces/CreatorShape';
import ScriptTypes from '../../../constants/ScriptTypes';

const useStyles = makeStyles(theme => ({
  cancelButton: {
    marginRight: theme.spacing(1)
  },
  select: {
    marginLeft: theme.spacing(2),
    minWidth: 131
  }
}));

const CreateNewScript = ({
  defaultTemplate,
  namePlaceholder,
  descriptionPlaceholder,

  appId,
  onCreate,
  onClose
}) => {
  const classes = useStyles();
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [template, setTemplate] = React.useState(defaultTemplate);
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  return (
    <Box display="flex" flexDirection="column" px={1} mt={2}>
      <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
        <TextField
          label="Name"
          autoFocus
          value={name}
          placeholder={namePlaceholder}
          onChange={({ target: { value } }) => setName(value)}
          variant="outlined"
        />
        <FormControl className={classes.select}>
          <InputLabel variant="outlined" shrink>
            Type
          </InputLabel>
          <Select
            autoWidth
            variant="outlined"
            value={template}
            onChange={({ target: { value } }) => setTemplate(value)}
          >
            {Object.keys(ScriptTypes).map((scriptType, index) => {
              const scriptName = ScriptTypes[scriptType];
              return (
                <MenuItem key={index} value={scriptName}>
                  {_.capitalize(scriptName)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      <TextField
        label="Description"
        value={description}
        placeholder={descriptionPlaceholder}
        onChange={({ target: { value } }) => setDescription(value)}
        variant="outlined"
      />

      <Box flexDirection="row" display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
        <Button size="large" color="default" variant="outlined" onClick={onClose} className={classes.cancelButton}>
          Cancel
        </Button>
        <BaseButton
          size="large"
          color="primary"
          variant="outlined"
          disabled={!(name && template && !isSubmitted)}
          onClick={() => {
            // TODO There has to be a better way.
            const element = document.getElementById(`scripttemplate.${template}`);

            onCreate({
              appId,
              name,
              description,
              source: btoa(element.text),
              tags: template
            });

            setIsSubmitted(true);
            onClose(true);
          }}
        >
          Create
        </BaseButton>
      </Box>
    </Box>
  );
};

CreateNewScript.propTypes = {
  ...CreatorShape,

  namePlaceholder: PropTypes.string,
  descriptionPlaceholder: PropTypes.string,
  defaultTemplate: PropTypes.string
};

CreateNewScript.defaultProps = {
  namePlaceholder: 'Script Name',
  descriptionPlaceholder: "This is a quick description of this script's functionality.",
  defaultTemplate: ScriptTypes.BEHAVIOR
};

export default CreateNewScript;
