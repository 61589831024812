import PropTypes from 'prop-types';
import { AsyncStatus } from '@enklu/server-api';

export default PropTypes.shape({
    status: PropTypes.oneOf([
        AsyncStatus.UNSENT,
        AsyncStatus.FAILURE,
        AsyncStatus.IN_PROGRESS,
        AsyncStatus.SUCCESS
    ]),
    error: PropTypes.string
});

export const defaults = {
    status: AsyncStatus.UNSENT,
    error: ''
};

// TODO Good candidate for an Immutable.
export const newProgressShape = () => ({
    status: AsyncStatus.UNSENT,
    error: ''
});
