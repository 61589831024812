import {
 Box, ButtonBase, Card, Typography
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { connect } from 'react-redux';
import { getActions } from '@enklu/server-api';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { canDelete, canEdit } from '../../constants/CollaboratorTypes';
import { getCollaboratorsByAppId } from '../../store/selectors/collaboratorsSelectors';
import { getUserCache } from '../../store/selectors/userCacheSelectors';
import { getUserProfile } from '../../store/selectors/userSelectors';
import { loadcollaboratorsinfo } from '../../store/actions/comboActions';
import { mapCollabsToUsers } from '../../store/reducers/collaboratorsReducer';
import MenuButton from '../material-ui/MenuButton';
import PresenceList from '../../features/presence/MuiPresenceList';
import PresenceShape from '../../interfaces/PresenceShape';

const useGridItemStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.black
  },
  bottomContainer: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2
  },
  imageWrapper: {
    width: '100%',
    height: 250,
    [theme.breakpoints.down('lg')]: {
      height: 160
    }
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  placeholderImage: {
    height: '75%',
    width: '75%',
    objectFit: 'contain'
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minHeight: 18
  }
}));

const { getappcollaborators, getthumb } = getActions('trellis');

const MyExperiencesGridItem = ({
  onLoadApp,
  history,
  app,
  getMenuItems,
  getCollaborators,
  userPresences,
  userWithRole,
  thumb,
  getThumb
}) => {
  const classes = useGridItemStyles();
  const role = userWithRole ? userWithRole.role : '';

  React.useEffect(() => {
    if (app) {
      getCollaborators({ appId: app.id });

      if (app.thumb) {
        getThumb(app.thumb);
      }
    }
  }, []);

  return (
    <Card className={classes.card}>
      <ButtonBase className={classes.imageWrapper} onClick={() => onLoadApp(app.id, history)}>
        {thumb ? (
          <img className={classes.image} src={thumb.url} />
        ) : (
          <img className={classes.placeholderImage} src={'assets/img/Logo.Gray.png'} />
        )}
      </ButtonBase>
      <Box
        flex={1}
        pl={2}
        pr={1}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className={classes.bottomContainer}
        width="100%"
      >
        <Box display="flex" flexDirection="column" className={classes.text}>
          <Typography variant="subtitle2" className={classes.text}>
            {app.name}
          </Typography>
          <Typography variant="caption" className={classes.text}>
            {app.description}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" ml={1}>
          <PresenceList customUsers={userPresences} includeSelf={false} numItemsInRow={3} small appId={app.id} />
          <MenuButton isIconButton size="small" items={getMenuItems(canDelete(role), canEdit(role))}>
            <MoreVert />
          </MenuButton>
        </Box>
      </Box>
    </Card>
  );
};

const mapDispatchToProps = dispatch => ({
  getCollaborators: ({ appId }) => dispatch(getappcollaborators({ appId })).then(() => dispatch(loadcollaboratorsinfo())),
  getThumb: thumbId => dispatch(getthumb({ thumbId }))
});

const mapStateToProps = (state, props) => {
  const appId = props.app.id;
  const userCache = getUserCache(state);
  const userProfile = getUserProfile(state);
  const collaboratorsByAppId = getCollaboratorsByAppId(state);
  const collaborators = Object.entries(collaboratorsByAppId[appId] || {}).map(([, collaborator]) => collaborator);
  const userPresences = mapCollabsToUsers(collaborators, userCache)
    .sort((c1, c2) => c1.displayName.localeCompare(c2.displayName))
    .sort((c1, c2) => {
      if (c1.id === userProfile.id) return -1;
      if (c2.id === userProfile.id) return 1;
      return 0;
    })
    .map(user => ({
      ...user,
      userId: user.id
    }));

  const thumb = state.thumbs[props.app.thumb];

  const userWithRole = collaborators.find(collab => collab.userId === userProfile.id);
  return {
    userWithRole,
    userPresences,
    thumb
  };
};

MyExperiencesGridItem.propTypes = {
  app: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onLoadApp: PropTypes.func.isRequired,
  getMenuItems: PropTypes.func.isRequired,
  userPresences: PropTypes.arrayOf(PresenceShape),
  getCollaborators: PropTypes.func,
  userWithRole: PropTypes.object,
  thumb: PropTypes.object,
  getThumb: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(MyExperiencesGridItem);
