import { Add, Delete, FileCopy } from '@material-ui/icons';
import { AsyncStatus, getActions } from '@enklu/server-api';
import {
 Box, DialogActions, DialogContent, Grid, IconButton
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getAppInfo } from '../../store/selectors/appSelectors';
import { getLastAppId } from '../../store/selectors/preferencesSelectors';
import { getMyApps } from '../../store/selectors/userSelectors';
import { initializeUntitledApp, loadApp } from '../../store/actions/initializeActions';
import { newMonitor } from '../../util/util';
import { newProgressShape } from '../../interfaces/ProgressShape';
import BaseButton from '../material-ui/BaseButton';
import CancelButton from '../material-ui/CancelButton';
import ConfirmationModal from './MuiConfirmationModal';
import ExperienceInspectorCore from '../inspector/experience/ExperienceInspectorCore';
import LoadedItemList from '../common/MuiLoadedItemList';

const { deleteapp, createapp, getmyapps } = getActions('trellis');

const Modes = {
  NORMAL: 'normal',
  EDITING: 'editing'
};

class SelectExperienceModal extends Component {
  monitor;

  constructor(props) {
    super(props);

    this.state = {
      selectedAppId: null,
      mode: Modes.NORMAL,
      appToDelete: null,

      getAppsProgress: newProgressShape(),
      newAppProgress: newProgressShape(),

      deleteAppProgress: newProgressShape(),
      duplicateAppProgress: newProgressShape()
    };

    this.monitor = newMonitor(this);
  }

  componentDidMount() {
    const { getApps } = this.props;

    this.monitor({
      name: 'getApps',
      actionCreator: getApps
    });
  }

  render() {
    const {
      appId,
      apps,
      lastAppId,

      deleteApp,
      duplicateApp,
      onLoadApp,

      history,
      // location,
      onInitializeUntitledApp,

      classes,
      showClose,
      onClose
    } = this.props;

    const {
      appToDelete,
      appToDuplicate,

      getAppsProgress,
      deleteAppProgress,
      duplicateAppProgress
    } = this.state;

    // const lastApp = apps.find(({ id }) => id === lastAppId) || {};
    // const selectedAppId = this.state.selectedAppId || appId || lastApp.id || (apps.length ? apps[0].id : null);
    const selectedAppId = this.state.selectedAppId || appId || lastAppId || (apps.length ? apps[0].id : null);

    return (
      <DialogContent className={classes.dialogContent}>
        <ConfirmationModal
          title={'Delete Experience'}
          message={`Are you sure you want to delete '${appToDelete ? appToDelete.name : ''}'? This cannot be undone.`}
          isLoading={deleteAppProgress.status === AsyncStatus.IN_PROGRESS}
          onClose={() => this.setState({ appToDelete: null })}
          onConfirm={() => this.monitor({
              name: 'deleteApp',
              actionCreator: deleteApp,
              params: [{ appId: appToDelete.id }],
              onFinally: () => this.setState({
                  appToDelete: null,
                  selectedAppId: null
                })
            })
          }
          open={!!appToDelete}
          confirmIsDanger
        />
        <ConfirmationModal
          title={'Duplicate'}
          message={`Are you sure you want to duplicate '${appToDuplicate ? appToDuplicate.name : ''}'?`}
          isLoading={duplicateAppProgress.status === AsyncStatus.IN_PROGRESS}
          onClose={() => this.setState({ appToDuplicate: null })}
          onConfirm={() => this.monitor({
              name: 'duplicateApp',
              actionCreator: duplicateApp,
              params: [{ app: appToDuplicate }],
              onFinally: () => this.setState({
                  appToDuplicate: null,
                  selectedAppId: null
                })
            })
          }
          open={!!appToDuplicate}
        />

        <Grid container spacing={3}>
          <Grid item container direction="column" xs={6}>
            <LoadedItemList
              color="default"
              customClass={classes.itemList}
              items={apps.map(app => ({
                ...app,
                displayName: app.name
              }))}
              onSelect={({ id }) => this.setState({
                  selectedAppId: id,
                  mode: Modes.NORMAL
                })
              }
              progress={getAppsProgress}
              selectedItemId={selectedAppId}
            />

            <Box display="flex" justifyContent="center">
              <IconButton
                onClick={() => this.monitor({
                    name: 'onInitializeUntitledApp',
                    actionCreator: onInitializeUntitledApp,
                    params: [{ history }],
                    onFinally: () => {}
                  })
                }
              >
                <Add />
              </IconButton>
              <IconButton
                onClick={() => {
                  this.setState({ appToDuplicate: apps.find(app => app.id === selectedAppId) });
                }}
              >
                <FileCopy />
              </IconButton>
              <IconButton
                onClick={() => {
                  this.setState({ appToDelete: apps.find(app => app.id === selectedAppId) });
                }}
              >
                <Delete className={classes.errorColor} />
              </IconButton>
            </Box>
          </Grid>

          <Grid item container xs={6} direction="column">
            <ExperienceInspectorCore appId={selectedAppId} currentAppId={appId} urlPrefix={'m'} color="default" />
          </Grid>
        </Grid>
        <DialogActions>
          {showClose && <CancelButton onCancel={onClose}>Cancel</CancelButton>}

          <BaseButton
            size="large"
            variant="contained"
            color="primary"
            disabled={appId === selectedAppId}
            onClick={() => onLoadApp(selectedAppId, history)}
          >
            Load
          </BaseButton>
        </DialogActions>
      </DialogContent>
    );
  }
}

SelectExperienceModal.propTypes = {
  // connect
  lastAppId: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  apps: PropTypes.array.isRequired,

  getApps: PropTypes.func.isRequired,
  deleteApp: PropTypes.func.isRequired,
  duplicateApp: PropTypes.func.isRequired,
  onLoadApp: PropTypes.func.isRequired,
  onInitializeUntitledApp: PropTypes.func.isRequired,

  // withRouter
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,

  classes: PropTypes.object,
  showClose: PropTypes.bool,
  onClose: PropTypes.func
};

SelectExperienceModal.defaultProps = {};

const mapStateToProps = state => ({
  lastAppId: getLastAppId(state),
  appId: getAppInfo(state).id,
  apps: getMyApps(state)
});

const mapDispatchToProps = dispatch => ({
  getApps: () => dispatch(getmyapps()),

  onInitializeUntitledApp: ({ history: appHistory }) => dispatch(initializeUntitledApp({ history: appHistory })),

  deleteApp: ({ appId }) => dispatch(deleteapp({ appId })).then(() => dispatch(getmyapps())),

  duplicateApp: ({ app }) => dispatch(
      createapp({
        name: `Copy of ${app.name}`,
        description: app.description,
        appId: app.id
      })
    ).then(() => dispatch(getmyapps())),

  onLoadApp: async (appId, history) => {
    await dispatch(loadApp({ appId }));
    history.push({ pathname: '/' });
  }
});

export default withStyles(theme => ({
  errorColor: {
    color: theme.palette.error.main
  },
  itemList: {
    flex: 1,
    maxHeight: 400,
    marginBottom: theme.spacing(1)
  },

  // prevent scrollbar from negative margin on nested Grid
  dialogContent: {
    paddingBottom: theme.spacing(2)
  }
}))(withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectExperienceModal)));
