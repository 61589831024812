// eslint-disable-next-line no-unused-vars
export const signin = ({ email, password }) => dispatch => new Promise(
  (res, rej) => fetch(
    `${window.env.stargazerBaseUrl}/v1/auth/email/signin`,
    {
      method: 'POST',
      body: JSON.stringify({
        email,
        password
      })
    }
  )
    .then(response => response.json())
    .then(({ success, error, body = {} }) => {
      if (!success) {
        rej(error || 'An unknown signup error occurred.');
      } else {
        res(body);
      }
    })
    .catch(rej)
);

// eslint-disable-next-line no-unused-vars
export const getsubscription = ({ token }) => dispatch => new Promise(
  (res, rej) => fetch(
    `${window.env.stargazerBaseUrl}/v1/subscription`,
    {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` }
    }
  )
    .then(response => response.json())
    .then(({ success, error, body = {} }) => {
      if (!success) {
        rej(error || 'An unknown error occurred.');
      } else {
        res(body);
      }
    })
    .catch(rej)
);

// eslint-disable-next-line no-unused-vars
export const cancelsubscription = ({ token }) => dispatch => new Promise(
  (res, rej) => fetch(
    `${window.env.stargazerBaseUrl}/v1/subscription`,
    {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${token}` }
    }
  )
    .then(response => response.json())
    .then(({ success, error, body = {} }) => {
      if (!success) {
        rej(error || 'An unknown error occurred.');
      } else {
        res(body);
      }
    })
    .catch(rej)
);
