import { createSelector } from 'reselect';
import { log } from '../../util/log';
import { KEY_SPACE_ID } from '../../components/inspector/spaces/spaceUtils';

const getRawScenes = state => state.app.scenes.rawScenes;

export const getSpaceId = createSelector(
    getRawScenes,
    (scenes) => {
        if (scenes.lenth === 0) {
            log.warn('Looking for space before scenes have loaded?');
            return null;
        }
        const root = scenes[0].elements;
        if (!root) {
            log.warn('Scene contains no root element.');
            return null;
        }

        const { schema: { strings: { [KEY_SPACE_ID]: spaceId = '' } = {} } = {} } = root;
        return spaceId;
    }
)
