import { Box, Paper, Typography } from '@material-ui/core';
import { DropTarget } from 'react-dnd';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { muiPalette } from '../../styles/muiTheme';
import ItemTile from './MuiItemTile';
import ItemTypes from '../../features/dragAndDrop/DragDropTypes';

class ItemSocket extends Component {
  render() {
    const {
      connectDropTarget,
      item,
      version,
      type,
      placeholder,
      errorText,
      noticeText,
      onEdit,
      onDelete,
      onRemove,
      classes,
      small
    } = this.props;

    return connectDropTarget(
      <div className={classes.wrapper}>
        <Paper square className={classes.dropItemWrapper} style={{ flexDirection: 'column' }}>
          <Typography variant={small ? 'body1' : 'h5'} align="center" style={{ background: '#2DCCD344', margin: 'auto' }}>
            {noticeText}
          </Typography>
          <Box flex={1} p={1} display="flex" height={'100%'} position="relative">
            {item ? (
              <ItemTile
                expanded
                item={item}
                version={version}
                type={type}
                onDelete={onDelete}
                onRemove={onRemove}
                onEdit={onEdit}
                showText={true}
              />
            ) : (
              <Box
                display="flex"
                flex={1}
                flexDirection='column'
                justifyContent="center"
                alignItems="center"
                border={`2px dashed ${muiPalette.grayscale.darkGray}`}
                p={1}
              >
                <Typography variant={small ? 'body1' : 'h5'} align="center" style={{ margin: 'auto' }}>
                  {placeholder}
                </Typography>
                <span style={{ align: 'center', background: '#FF000044' }}>
                  {errorText}
                </span>
              </Box>
            )}
          </Box>
        </Paper>
      </div>
    );
  }
}

const target = {
  canDrop(props, monitor) {
    const { type: allowedType } = props;
    const { type: droppedType } = monitor.getItem();

    return allowedType === droppedType;
  },

  // eslint-disable-next-line no-unused-vars
  hover(props, monitor, component) {},

  // eslint-disable-next-line no-unused-vars
  drop(props, monitor, component) {
    props.onDrop(monitor.getItem().item, props.item);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}

ItemSocket.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,

  item: PropTypes.object,
  version: PropTypes.number,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  noticeText: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRemove: PropTypes.func,
  classes: PropTypes.object.isRequired,
  small: PropTypes.bool
};

export default withStyles({
  wrapper: { width: '100%', height: '100%', display: 'flex' },
  dropItemWrapper: { display: 'flex', flex: 1 }
})(DropTarget('item', target, collect)(ItemSocket));
