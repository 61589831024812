import { log } from '../../util/log';
import { getGeneralSettings } from '../selectors/globalSettingsSelectors';
import MessageTypes from '../../constants/MessageTypes';
import { setPlayerEdit } from './playerActions';

/**
 * Handles starting play mode, regardless of play mode opening style.
 * In popup mode, this is responsible for ferrying credential data to the new runtime.
 */
class PlaymodeLauncher {

  _dispatch;
  _getState;
  _popup;

  popupActive;

  initialize(dispatch, getState) {
    playmodeLauncher._dispatch = dispatch;
    playmodeLauncher._getState = getState;
  }

  enter() {
    // This is called from a reducer, hacky, but exit the reducer's invoke chain so we can get global state.
    setTimeout(this._startPlaymode, 1);
  }

  exit() {
    if (this._popup) {
      this._popup.close();
      this._popup = null;
      this.popupActive = false;
    } else {
      window.bridge.send(MessageTypes.BRIDGE_HELPER_PLAYEDIT, {
        edit: true
      });
    }
  }

  /**
   * Grabs all necessary state and begins the playmode start.
   */
  _startPlaymode() {
    const state = playmodeLauncher._getState();

    const generalSettings = getGeneralSettings(state);
    let useEmbedded = generalSettings.settings.find(setting => setting.name = 'EmbeddedPlayMode').value;

    const appId = state.app.info.id;
    const userId = state.user.profile.id;
    const auth = state.user.credentials.token;

    if (!useEmbedded) {
      if (!playmodeLauncher._openPopup(appId, userId, auth)) {
        log.warn('Unable to create popup. Falling back to embedded.');
        useEmbedded = true;
      }
    }

    if (useEmbedded) {
      window.bridge.send(MessageTypes.BRIDGE_HELPER_PLAYEDIT, {
        edit: false
      });
    }
  }

  /**
   * Attempts to open the popup. Returns true if successful.
   */
  _openPopup(appId, user, auth) {
    const options = 'width=1440,height=810';
    playmodeLauncher._popup = window.open(
      `/#/app/?app=${appId}`,
      'Enklu - Play',
      options);

    // return early if popup wasn't created, possibly by browser settings.
    if (!playmodeLauncher._popup){
      return false;
    }

    // Wait for popup to be ready.
    playmodeLauncher._popup.addEventListener('message', evt => {
      if (evt.data.event === 'ready') {
        // the user may have closed the popup.
        if (!playmodeLauncher._popup) return;

        log.info('PlayMode ready. Sending authentication.');
        playmodeLauncher._popup.postMessage({
          event: 'authenticate',
          payload: {
            auth,
            user
          }
        });
      }
    });

    const launchTime = performance.now();
    playmodeLauncher._popup.addEventListener('unload', evt => {
      // For some reason this fires when the popup opens.
      // Helpful right? And it's the only event that consistently fires when the popup directly is closed.
      if (performance.now() - launchTime <= 1000) return;

      // Ignore when editron closed the popup.
      if (!playmodeLauncher._popup) return;

      playmodeLauncher._dispatch(setPlayerEdit(true));
    });

    playmodeLauncher.popupActive = playmodeLauncher._popup !== undefined && playmodeLauncher._popup !== null;
    return playmodeLauncher.popupActive;
  }

}

const playmodeLauncher = new PlaymodeLauncher();

window.addEventListener('unload', _ => playmodeLauncher.exit());

export default playmodeLauncher;
