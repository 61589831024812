const CollaboratorTypes = {
  VIEWER: ['view'],
  EDITOR: ['view', 'edit'],
  ADMIN: ['view', 'edit', 'delete']
};

export default CollaboratorTypes;

// admin is also editor
export const canEdit = role => role.includes('edit');
export const canDelete = role => role.includes('delete');

export const hasHigherRole = (role1, role2) => role1.length > role2.length;
