import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

/**
 * Theme definitions used by the Material UI package
 */
// Text-related constants
const BodyFont = 'Open Sans, Helvetica, sans-serif';
const HeadingFont = 'Cairo, Roboto, Arial, sans-serif';
const Light = 300;
const Regular = 400;
const Bold = 600;
const Heavy = 700;

export const TAB_HEIGHT = 36;
// const BodySmall = '1rem';
// const BodyRegular = '1.25rem';
// const BodyLarge = '1.5rem';
// const HeadingSmall = '1.5rem';
// const HeadingRegular = '3rem';
// const HeadingLarge = '6rem';

export const muiPalette = {
  // TODO: uncomment when this them is applied globally
  type: 'dark',
  background: {
    paper: '#2a314c',
    default: '#1b2238'
  },
  grayscale: {
    white: '#ffffff',
    almostWhite: '#f4f4f4',
    lightestGray: '#eeeeee',
    lighterGray: '#dde2e5',
    lightGray: '#d2d8db',
    veryGray: '#b3bec4',
    midGray: '#97a4ad',
    darkGray: '#667c8a',
    darkerGray: '#4a5b64',
    darkestGray: '#39484f',
    deepGray: '#293237',
    black: '#000000'
  },
  common: {
    white: '#eeeeee',
    black: '#0e1521',
    grey: '#667c8a',
    darkGrey: '#283287',
    boxShadow: '#040708'
  },
  primary: {
    main: '#2DCCD3',
    contrastText: '#eeeeee'
  },
  secondary: {
    main: '#5c39bc',
    contrastText: '#eeeeee'
  },
  icon: { light: '#888888' },

  info: { main: '#4c5dfc', light: '#6be4fc', dark: '#bb0db9' },
  success: { main: '#43bf56', light: '#8afe7e' },
  warning: { main: '#fcaf03' },
  error: { main: '#CF1720' },
  action: {
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
    disabled: 'rgba(255, 255, 255, 0.3)'
  },

  avatar: {
    darkBlue: '#5c39bc',
    navy: '#3d2d7f',
    violet: '#8e31f7',
    purple: '#ae33b4',
    lightPink: '#ff8fa6',
    pink: '#fd37a2',
    burgundy: '#9e005d',
    green: '#43bf56'
  },

  webgl: { main: '#0e0e17' }
};

export const muiTypography = {
  fontSize: 12,
  // htmlFontSize: 10,
  fontFamily: BodyFont,
  h1: {
    fontFamily: HeadingFont,
    fontWeight: Light
  },
  h2: {
    fontFamily: HeadingFont,
    fontWeight: Regular
  },
  h3: {
    fontFamily: HeadingFont,
    fontWeight: Bold,
    // fontSize: HeadingRegular,
    color: muiPalette.common.white,
    padding: '0 0 3rem'
  },
  h4: {
    fontFamily: HeadingFont
  },
  h5: {
    fontFamily: HeadingFont,
    color: muiPalette.common.white
    // padding: '0 0 3rem'
  },
  h6: {
    // fontFamily: HeadingFont
  },
  subtitle1: {
    fontFamily: BodyFont,
    fontWeight: Regular
    // fontSize: HeadingSmall
  },
  subtitle2: {
    fontFamily: BodyFont,
    fontWeight: Bold
  },
  body2: {
    // fontSize: BodyRegular
  },
  button: {
    fontFamily: HeadingFont,
    fontWeight: 500
    // fontSize: BodyRegular,
    // textShadow: "0 0 8px #000000cc",
  }
};

export const buttonShadow = '0 0 8px #000000cc';
export const textFieldShadow = `0px 15px 15px 5px ${muiPalette.common.boxShadow}4a`;
export const controlBarItem = { cursor: 'pointer', width: '25px', margin: '5px' };

// CSS overrides of existing components. The JSS syntax used here
// is described at https://cssinjs.org/jss-plugin-nested/?v=v10.1.1
export const overrides = {
  MuiPaper: {
    root: {
      // background: `linear-gradient(90deg, ${muiPalette.background.paper} 0%, ${muiPalette.background.default} 100%)`
    }
  },
  MuiMenu: {
    paper: {
      background: muiPalette.background.paper
    }
  },
  MuiSelect: {
    icon: {
      top: 'calc(50% - 10px)',
      right: 4
    },
    select: {
      '&:focus': {
        borderRadius: undefined,
        backgroundColor: 'transparent'
      }
    }
  },
  MuiSnackbarContent: {
    root: {
      background: 'none'
    }
  },
  MuiTooltip: {
    tooltip: {
      fontSize: '0.8rem',
      backgroundColor: muiPalette.background.paper
    }
  },
  // MuiInput: {
  //   underline: {
  //     "&:before": {
  //       height: "100%",
  //       borderBottom: "none",
  //       paddingTop: "58px",
  //       transition: "none",
  //       boxShadow: `0px 15px 15px 5px ${muiPalette.common.boxShadow}4a`,
  //     },
  //     "&:after": {
  //       height: "100%",
  //       borderBottom: "none",
  //       paddingTop: "58px",
  //       transition: "none",
  //     },
  //     "&:hover:not(.Mui-disabled):before": {
  //       borderBottom: "none",
  //       boxShadow: `0px 15px 15px 5px ${muiPalette.common.boxShadow}4a`,
  //     },
  //   },
  // },
  // MuiFormLabel: {
  //   root: {
  //     fontFamily: muiTypography.h6.fontFamily,
  //     fontWeight: Bold,
  //     color: muiPalette.common.white,
  //     "&$Mui": {
  //       focused: {
  //         color: muiPalette.common.white,
  //         fontWeight: Bold,
  //       },
  //     },
  //   },
  // },

  MuiLink: {
    root: {
      color: muiPalette.common.white,
      '&:hover': {
        color: muiPalette.common.white
      }
    }
  },
  MuiInputBase: {
    root: {},
    input: {
      // fontSize: BodyLarge,
      // fontFamily: HeadingFont,
      // fontWeight: Bold,
      color: muiPalette.common.white
    }
  },
  MuiFormControl: {
    root: {
      paddingTop: '8px'
      // fontFamily: HeadingFont
    }
  },
  MuiTextField: {
    root: {
      paddingTop: '8px'
      // fontFamily: HeadingFont
    }
  },
  MuiFormLabel: {
    root: {
      // fontSize: BodyLarge,
      // fontFamily: HeadingFont,
      fontWeight: Heavy,
      color: muiPalette.common.white,
      textTransform: 'none',
      '&$focused': {
        color: muiPalette.info.main
      },
      '&$disabled': {
        color: muiPalette.action.disabled
      }
    }
  },
  MuiInputLabel: {
    textTransform: 'none',
    outlined: {
      '&$shrink': {
        transform: 'translate(4px, -6px) scale(0.75)'
      }
    }
  },
  MuiFormHelperText: {
    root: {
      color: muiPalette.info.main
    }
    // contained: {
    //   marginLeft: "4px",
    // },
  },
  MuiOutlinedInput: {
    root: {
      '&$focused': {
        boxShadow: textFieldShadow
      },
      // "& $notchedOutline": {
      //   borderColor: "green",
      // },
      '&:hover $notchedOutline': {
        borderColor: muiPalette.info.main
      },
      '&$focused $notchedOutline': {
        borderColor: muiPalette.info.main
      },
      '&$disabled': {
        backgroundColor: muiPalette.action.disabledBackground,
        '& $notchedOutline': {
          borderWidth: 0
        }
      }
    }
  },

  MuiTab: {
    root: {
      textTransform: 'none',
      backgroundColor: 'transparent',
      minHeight: TAB_HEIGHT,
      height: TAB_HEIGHT,
      '&$selected': {
        backgroundColor: muiPalette.background.paper
      }
    },
    fullWidth: {
      minWidth: 0,
      '@media (min-width: 0px)': {
        minWidth: 0
      }
    }
  },
  MuiTabs: {
    root: {
      minHeight: TAB_HEIGHT,
      height: TAB_HEIGHT
    },
    indicator: {
      height: 3
    }
  },
  MuiSlider: {
    root: {
      height: 3
    },
    thumb: {
      border: `3px solid ${muiPalette.grayscale.white}`,
      backgroundColor: muiPalette.background.default
    },
    valueLabel: {
      left: 'calc(-300% + 5px)'
    },
    track: {
      height: 3
    },
    rail: {
      height: 3
    },
    mark: {
      height: 3
    }
  },
  MuiButton: {
    root: {
      textTransform: 'none',
      borderRadius: 36
    },
    containedPrimary: {
      textTransform: 'uppercase',
      '&$label': {
        color: muiPalette.common.white,
        boxShadow: '0 0 10px rgba(0,0,0,0.85)'
      }
    },
    containedSecondary: {
      textTransform: 'uppercase'
    },

    sizeLarge: {
      // fontSize: BodyRegular
    },
    textSizeLarge: {
      // fontSize: BodyRegular
    }
    // containedSecondary: {
    //   "&:hover": {
    //     color: muiPalette.common.white,
    //   },
    // },
  },
  MuiCheckbox: {
    root: {
      '&$checked': {
        color: muiPalette.info.main
      },
      '&:hover': {
        color: muiPalette.info.main
      }
    },
    colorPrimary: {
      '&:hover': {
        color: muiPalette.primary.main
      }
    },
    colorSecondary: {
      '&:hover': {
        color: muiPalette.secondary.main
      }
    },
    indeterminate: {
      color: muiPalette.info.main
    }
  },
  MuiRadio: {
    root: {
      '&$checked': {
        color: muiPalette.info.main
      },
      '&:hover': {
        color: muiPalette.info.main
      }
    },
    colorPrimary: {
      '&:hover': {
        color: muiPalette.primary.main
      }
    },
    colorSecondary: {
      '&:hover': {
        color: muiPalette.secondary.main
      }
    }
  },
  MuiAvatar: {
    colorDefault: {
      color: `${muiPalette.grayscale.white} !important`,
      backgroundColor: muiPalette.info.main,
      border: 'none !important',
      fontWeight: 'bold'
    }
  },
  MuiAlert: {
    icon: {
      alignItems: 'center'
    }
  },
  MuiChip: {
    root: {
      backgroundColor: muiPalette.info.main,
      color: muiPalette.grayscale.white,
      borderRadius: 4
    },
    deleteIcon: {
      width: 16,
      height: 16
    }
  },
  MuiAppBar: {
    colorDefault: {
      background: muiPalette.background.default,
      backgroundColor: muiPalette.background.default
    }
  },
  MuiTreeItem: {
    root: {
      '&$selected > $content $label': {
        backgroundColor: `${muiPalette.secondary.main} !important`
      },

      '&$selected > $content $label, &$selected:focus > $content $label': {
        '&:hover': {
          backgroundColor: muiPalette.secondary.main
        }
      }
    },
    label: {
      marginLeft: 4,
      backgroundColor: muiPalette.background.paper
    },

    iconContainer: {
      height: 0,
      alignItems: 'center'
    },
    content: {
      marginBottom: 6
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18
    }
    // selected: {
    //   "& > *": {
    //     backgroundColor: muiPalette.secondary.main,
    //   },
    // },
    // content: {
    //   backgroundColor: muiPalette.secondary.main,
    // },
    // root: {
    //   "&$selected": {
    //     "&label": {
    //       backgroundColor: muiPalette.secondary.main,
    //     },
    //   },
    // },
  },
  MuiFormControlLabel: {
    root: {
      marginBottom: 0
    }
  },
  MuiDialogTitle: {
    root: {
      '& .MuiTypography-h6': {
        fontWeight: Bold
      }
    }
  }
};

export const props = {
  MuiList: {
    disablePadding: true
  },
  MuiCheckbox: {
    color: 'default'
  },
  MuiRadio: {
    color: 'default'
  },
  MuiOutlinedInput: {
    notched: false
  },
  MuiInputLabel: {
    shrink: true
  },
  MuiPaper: {
    // square: true
  },
  MuiMenu: {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    }
  },
  MuiTabs: {
    variant: 'fullWidth',
    indicatorColor: 'primary'
    // TabIndicatorProps: {
    //   style: {
    //     display: 'none'
    //   }
    // }
  }
};

export const defaultTheme = {
  typography: muiTypography,
  palette: muiPalette,
  overrides,
  props
};

export default responsiveFontSizes(createMuiTheme(defaultTheme));
