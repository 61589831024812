import { Actions } from '@enklu/server-api';

const { createAddHandler } = Actions;

const initialState = {};

const handlers = {};
const addHandler = createAddHandler(handlers);

export default function spaces(state = initialState, action) {
  const handler = handlers[action.type];
  if (!handler) {
    return state;
  }

  return handler(state, action);
}
