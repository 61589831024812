import { createSelector } from 'reselect';
import { getMyApps } from './userSelectors';
import { getSelectedAppId } from './appSelectionSelectors';
import { getUserCache } from './userCacheSelectors';
import { getAppUsers } from './appSelectors';

export const getSelectedApp = createSelector(
  getMyApps,
  getSelectedAppId,
  (apps, appId) => apps.find(app => app.id === appId)
);

export const getPresences = createSelector(
  getAppUsers,
  getUserCache,
  (users, cachedUsers) => {
    return users.map(user => ({ ...user, ...cachedUsers[user.userId] }));
  }
);
