import { Box, Chip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { formatTimestamp } from '../../util/util';
import { muiPalette } from '../../styles/muiTheme';
import LogMessageNames from '../../constants/LogMessageNames';
import LogMessageTypes from '../../constants/LogMessageTypes';

const labelMap = {
  // [LogMessageTypes.UNKNOWN]: '#999999',
  [LogMessageTypes.DEBUG]: muiPalette.info.dark,
  [LogMessageTypes.INFO]: muiPalette.info.main,
  [LogMessageTypes.WARN]: muiPalette.warning.main,
  [LogMessageTypes.ERROR]: muiPalette.error.main,
  [LogMessageTypes.CONNECTION]: muiPalette.success.main
};

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(1),
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  message: { flex: 1, wordBreak: 'break-word', userSelect: 'text' },
  grayColor: { color: theme.palette.grayscale.veryGray },
  chip: props => ({
    width: 80,
    borderColor: labelMap[props.item.level],
    color: labelMap[props.item.level]
  })
}));

const LogRow = (props) => {
  const { item } = props;

  const classes = useStyles({ item });

  return (
    <Box display="flex" flexDirection="row" alignItems="start" className={classes.container}>
      <Chip size="small" label={LogMessageNames[item.level]} variant="outlined" className={classes.chip} />
      <Typography variant="subtitle1" className={classes.grayColor}>
        {formatTimestamp(item.timestamp)}
      </Typography>
      <Typography variant="subtitle1" className={classes.message}>
        {item.message}
      </Typography>
      {item.category && (
        <Box fontStyle="italic">
          <Typography variant="subtitle1" className={classes.grayColor}>
            System
          </Typography>
        </Box>
      )}
    </Box>
  );
};

LogRow.propTypes = {
  item: PropTypes.object.isRequired
};

export default LogRow;
