import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getActions } from '@enklu/server-api';

import { withSnackbar } from 'notistack';
import { notifSceneCreation, notifSceneUpdate } from '../../store/actions/sceneActions';
import { joined, left, list } from '../../store/actions/presenceActions';
import { log } from '../../util/log';
import {
  addSharedAsset,
  assetError,
  notifyAssetUpdate,
  removeProcessingAsset,
  removeSharedAsset
} from '../../store/actions/assetActions';
import {
  // eslint-disable-next-line camelcase
  scriptcreated_notif,
  scriptdeleted_notif,
  scriptshared_notif,
  scriptupdated_notif
} from '../../store/actions/scriptActions';
import { logMessage as _logMessage } from '../../store/actions/logActions';
import MessageTypes from '../../constants/MessageTypes';
import { getAllScriptsById } from '../../store/selectors/scriptLibrariesSelectors';
import { getAllAssets } from '../../store/selectors/assetLibrariesSelectors';
import { getDebug } from '../../store/selectors/debugSelectors';
import { getScene } from '../../store/selectors/scenesSelectors';
import { assetAdd, assetStats } from '../../util/bridgeMessages';
import undoManager from '../../store/actions/UndoManager';
import { createUndoActions } from '../../store/actions/undoHelper';
import { updateScene } from '../../store/actions/TxnManager';
import spaceSocketManager from '../../store/actions/SpaceSocketManager';
import { transformSpace } from '../../store/actions/controlBarActions';
import { deleteSpace, updateSpace } from '../../store/actions/spaceActions';
import { assistConnect, assistLeft, assistLog } from '../../store/actions/remoteAssistActions';
import { notifyGeneratedThumb } from '../../store/actions/thumbActions';

const {
 getappassets, getpersonalassets, getwebversion, getspace
} = getActions('trellis');

const useSnackbarForAssetUpdateNotifications = false;
class NotificationHandler extends React.Component {
  /**
   * Renders.
   */
  render() {
    return null;
  }

  handleAssetMessage(message) {
    const {
      user: {
        profile: { id: userId }
      },
      app: {
        info: { id: appId }
      },
      allAssets,
      refreshAssets,
      assetImportError,
      assetImportComplete,
      addAssetToLibrary,
      removeAssetFromLibraries,
      assetUpdateNotification
    } = this.props;

    if (message.messageType === 'assetcreation') {
      // ignore creation events from yourself
      if (message.payload.userId !== userId) {
        const key = this.props.enqueueSnackbar('New asset created!', {
          variant: 'success',
          onClick: () => {
            this.props.closeSnackbar(key);
          }
        });
        
        refreshAssets(userId, appId);
      }

      // send
      window.bridge.send(MessageTypes.ASSET_ADD, assetAdd({ asset: message.payload.asset }));

      return true;
    }

    if (message.messageType === 'assetstats') {
      const { assetId, stats } = message.payload;

      window.bridge.send(
        MessageTypes.ASSET_STATS,
        assetStats({
          assetId,
          stats
        })
      );

      return true;
    }

    if (message.messageType === 'assetimportcomplete') {
      const { assetId, name, platformComplete: platform } = message.payload;
      const msgText = `Import complete for ${name} (${platform}).`;
      if (useSnackbarForAssetUpdateNotifications) {
        const key = this.props.enqueueSnackbar(msgText, {
          variant: 'success',
          onClick: () => {
            this.props.closeSnackbar(key);
          }
        });

        // push to bridge
        if (platform === 'webgl') {
          assetUpdateNotification(assetId);
        }
      } else {
        log.info(msgText);
      }

      assetImportComplete(assetId);
      refreshAssets(userId, appId);

      return true;
    }

    if (message.messageType === 'assetupdate') {
      const { name } = message.payload;

      const msgText = `Asset '${name}' has been updated.`;
      if (message.messageType === 'assetimportcomplete') {
        const key = this.props.enqueueSnackbar(msgText, {
          variant: 'success',
          onClick: () => {
            this.props.closeSnackbar(key);
          }
        });
      } else {
        log.info(msgText);
      }

      refreshAssets(userId, appId);
      return true;
    }

    if (message.messageType === 'asseterror') {
      const { assetId, name, errors } = message.payload;

      const key = this.props.enqueueSnackbar(`There were errors importing ${name}.`, {
        variant: 'error',
        onClick: () => {
          this.props.closeSnackbar(key);
        }
      });

      assetImportError({
        assetId,
        name,
        errors
      });
      refreshAssets(userId, appId);

      return true;
    }

    if (message.messageType === 'assetdeleted') {
      const { assetId } = message.payload;

      // find asset
      // eslint-disable-next-line no-restricted-syntax
      for (const asset of allAssets) {
        if (asset.id === assetId) {
          const key = this.props.enqueueSnackbar(`'${asset.name}' has been deleted.`, {
            variant: 'success',
            onClick: () => {
              this.props.closeSnackbar(key);
            }
          });

          break;
        }
      }

      removeAssetFromLibraries(assetId);

      return true;
    }

    if (message.messageType === 'assetshared') {
      const {
        payload: { asset },
        shareType
      } = message;

      // find asset
      let found = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const localAsset of allAssets) {
        if (localAsset.id === asset.id) {
          found = true;
          break;
        }
      }

      if (!found) {
        // only notify if we can't already see this asset.
        const key = this.props.enqueueSnackbar(`Asset '${asset.name} has been shared with the application.`, {
          variant: 'success',
          onClick: () => {
            this.props.closeSnackbar(key);
          }
        });
      }

      // send to bridge
      window.bridge.send(MessageTypes.ASSET_ADD, assetAdd({ asset }));

      addAssetToLibrary(shareType, asset);

      return true;
    }

    return false;
  }

  handleScriptMessage(message) {
    const { dispatch, scripts } = this.props;

    if (message.messageType === 'scriptcreated') {
      const { payload: script } = message;

      const key = this.props.enqueueSnackbar(`Script '${script.name}' has been created.`, {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(key);
        }
      });

      dispatch(scriptcreated_notif(script));

      return true;
    }

    if (message.messageType === 'scriptupdated') {
      const { payload: script } = message;

      const key = this.props.enqueueSnackbar(`Script '${script.name}' has been updated.`, {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(key);
        }
      });

      dispatch(scriptupdated_notif(script));

      return true;
    }

    if (message.messageType === 'scriptdeleted') {
      const { payload: scriptId } = message;

      const script = scripts[scriptId];
      if (!script) {
        log.error(`Could not find deleted script '${scriptId}'.`);
        return false;
      }

      const key = this.props.enqueueSnackbar(`Script '${script.name}' has been deleted.`, {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(key);
        }
      });

      dispatch(scriptdeleted_notif(scriptId));

      return true;
    }

    if (message.messageType === 'scriptshared') {
      const {
        payload: { script }
      } = message;

      const key = this.props.enqueueSnackbar(`Script '${script.name}' has been forked.`, {
        variant: 'success',
        onClick: () => {
          this.props.closeSnackbar(key);
        }
      });

      dispatch(scriptshared_notif(script));

      return true;
    }

    return false;
  }

  /**
   * Handles Space related messages from Trellis.
   * @param message
   */
  handleSpaceMessage(message) {
    const { dispatch } = this.props;

    if (message.type === MessageTypes.SPACE_UPDATE) {
      dispatch(updateSpace(message.space));
    } else if (message.type === MessageTypes.SPACE_DELETE) {
      dispatch(deleteSpace(message.id));
    }

    // Always return false so the player gets these updates too.
    return false;
  }

  /**
   * Handles a message.
   */
  handleNetworkMessage(message) {
    switch (message.type) {
      case MessageTypes.WEB_UPDATE: {
        this.props.checkForUpdates();
        return true;
      }
      case MessageTypes.SCENE_UPDATE: {
        this.props.sceneUpdate(message.id, message.sceneId, message.actions);
        return false;
      }
      case MessageTypes.SCENE_CREATE: {
        this.props.sceneCreation(message.id, message.sceneId, message.actions);
        return false;
      }
      case MessageTypes.PRESENCE_LIST: {
        this.props.presenceList(message.users);
        return true;
      }
      case MessageTypes.PRESENCE_JOINED: {
        this.props.presenceJoined(message.user);
        return true;
      }
      case MessageTypes.PRESENCE_LEFT: {
        this.props.presenceLeft(message.socketId);
        return true;
      }
      case MessageTypes.REMOTE_ASSIST_CONNECTING:
        this.props.remoteAssistConnecting();
        return true;
      case MessageTypes.REMOTE_ASSIST_LEFT:
        this.props.remoteAssistLeft();
        return true;
      case MessageTypes.REMOTE_ASSIST_LOG:
        this.props.remoteAssetLog(message.payload);
        return true;
      default: {
        return false;
      }
    }
  }

  handleBridgeMessage(message) {
    const {
      debug: {
        context: { logging }
      },
      app: {
        info: { id: appId }
      },
      scene
    } = this.props;

    switch (message.type) {
      case MessageTypes.SELECT: {
        const loc = message.sceneId && message.elementId ? `/i/scene/${message.sceneId}/element/${message.elementId}` : '/';

        // ignore if we're already there
        if (this.props.location.pathname === loc) {
          return;
        }

        const { search } = this.props.location;
        this.props.history.push({
          pathname: loc,
          search
        });

        break;
      }

      case MessageTypes.LOG: {
        if (logging) {
          this.props.logMessage(message);
        }
        break;
      }

      case MessageTypes.UPDATE_ACTIONS: {
        const { actions } = message;
        const { id: sceneId } = scene;

        const undoActions = createUndoActions(scene, actions);
        undoManager.register(
          updateScene({ actions }, { appId, sceneId }),
          updateScene({ actions: undoActions }, { appId, sceneId })
        );
        // Uncomment when using automatic thumbnail generation
        // window.bridge.send(MessageTypes.BRIDGE_HELPER_THUMBNAIL_SCHEDULE, {});
        break;
      }

      case MessageTypes.UNDO_REQUEST: {
        undoManager.undo();
        break;
      }

      case MessageTypes.REDO_REQUEST: {
        undoManager.redo();
        break;
      }

      case MessageTypes.SPACE_LISTEN_REQUEST:
        this.props.listenSpace(message.spaceId);
        break;

      case MessageTypes.BRIDGE_HELPER_TRANSFORM_SPACE_CHECK:
        this.props.transformSpaceCheck(message.payload);
        break;
        
      case MessageTypes.BRIDGE_HELPER_THUMBNAIL_GENERATED:
        this.props.thumbnailGenerated(message.payload);
        break;

      default: {
        log.warn('Received unknown bridge message.', message);
      }
    }
  }

  /**
   * Adds socket handlers.
   */
  componentDidMount() {
    // listen to bridge
    window.bridge.listen(message => this.handleBridgeMessage(message));

    // listen to socket
    io.socket.on('message', (message) => {
      log.debug('Received event from server.', message);

      if (this.handleAssetMessage(message)) {
        return;
      }

      if (this.handleScriptMessage(message)) {
        return;
      }

      if (this.handleSpaceMessage(message)) {
        return;
      }

      if (message.type) {
        if (this.handleNetworkMessage(message)) {
          return;
        }

        // forward to bridge
        window.bridge.send(message.type, message);
        return;
      }

      log.warn('Received unknown message from trellis.', message);
    });
  }
}

NotificationHandler.propTypes = {
  user: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  allAssets: PropTypes.array.isRequired,
  refreshAssets: PropTypes.func.isRequired,
  assetImportError: PropTypes.func.isRequired,
  assetImportComplete: PropTypes.func.isRequired,
  addAssetToLibrary: PropTypes.func.isRequired,
  removeAssetFromLibraries: PropTypes.func.isRequired,
  assetUpdateNotification: PropTypes.func.isRequired,
  checkForUpdates: PropTypes.func.isRequired,
  sceneUpdate: PropTypes.func.isRequired,
  sceneCreation: PropTypes.func.isRequired,
  presenceList: PropTypes.func.isRequired,
  presenceJoined: PropTypes.func.isRequired,
  presenceLeft: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  scripts: PropTypes.object.isRequired,
  debug: PropTypes.object.isRequired,
  logMessage: PropTypes.func.isRequired,
  listenSpace: PropTypes.func.isRequired,
  transformSpaceCheck: PropTypes.func.isRequired,
  remoteAssistConnecting: PropTypes.func.isRequired,
  remoteAssistLeft: PropTypes.func.isRequired,
  remoteAssetLog: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  thumbnailGenerated: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { app, user } = state;
  return {
    app,
    user,
    scene: getScene(state),
    allAssets: getAllAssets(state),
    scripts: getAllScriptsById(state),
    debug: getDebug(state)
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,

    /**
     * Refreshes assets.
     */
    refreshAssets: (userId, appId) => {
      dispatch(
        getpersonalassets({
          userId
        })
      );

      dispatch(
        getappassets({
          appId
        })
      );
    },

    /**
     * Adds an asset to a library.
     */
    addAssetToLibrary: (shareType, asset) => {
      dispatch(addSharedAsset(shareType, asset));
    },

    /**
     * Removes an asset from all libraries.
     */
    removeAssetFromLibraries: assetId => dispatch(removeSharedAsset(assetId)),

    /**
     * Handles import complete.
     */
    assetImportComplete: (assetId) => {
      dispatch(removeProcessingAsset(assetId));
    },

    /**
     * Handles an asset error.
     */
    assetImportError: ({ assetId, errors }) => {
      dispatch(
        assetError({
          assetId,
          errors
        })
      );
    },

    /**
     * Called when an asset has been updated.
     */
    assetUpdateNotification: id => dispatch(notifyAssetUpdate(id)),

    /**
     * On scene creation notif.
     */
    sceneCreation: (id, sceneId, actions) => {
      dispatch(notifSceneCreation(id, sceneId, actions));
    },

    /**
     * On scene update notif.
     */
    sceneUpdate: (id, sceneId, actions) => {
      dispatch(notifSceneUpdate(id, sceneId, actions));
    },

    /**
     * On presence listing.
     */
    presenceList: (users) => {
      dispatch(list(users));
    },

    /**
     * On user joined.
     */
    presenceJoined: (user) => {
      dispatch(joined(user));
    },

    /**
     * On user left.
     */
    presenceLeft: (id) => {
      dispatch(left(id));
    },

    /**
     * Checks for updates.
     */
    checkForUpdates: () => dispatch(getwebversion()),

    /**
     * Log a message.
     * @param message - { level, message, timestamp, isSystem }
     */
    logMessage: message => dispatch(_logMessage(message)),

    /**
     * Gets space data and opens a socket for listening to updates.
     */
    listenSpace: async (spaceId) => {
      const space = (await dispatch(getspace({ spaceId }))).body;
      bridge.send(MessageTypes.SPACE_SEND, space);
      spaceSocketManager.connect(spaceId);
    },

    /**
     * Control bar
     */
    transformSpaceCheck: payload => dispatch(transformSpace(payload)),

    /**
     * Remote Assist actions
     */
    remoteAssistConnecting: () => dispatch(assistConnect()),
    remoteAssistLeft: () => dispatch(assistLeft()),
    remoteAssetLog: (payload) => dispatch(assistLog(payload)),

    /**
     * Thumbnail Generation
     */
    thumbnailGenerated: (thumb) => dispatch(notifyGeneratedThumb(thumb))
  };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationHandler)));
