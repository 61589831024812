import { Box } from '@material-ui/core';
import { DropTarget } from 'react-dnd';
import { Route, withRouter } from 'react-router';
import { Shortcuts } from 'react-shortcuts/lib';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { TAB_HEIGHT } from '../styles/muiTheme';
import { pushNavState as _pushNavState } from '../store/actions/navActions';
import { setScreenSize as _setScreenSize, setOffset as _setOffset } from '../store/actions/uiActions';
import { getGrid } from '../features/dragBar/dragBarUtil';
import { getScreenSize, getUIOffsets } from '../store/selectors/uiSelectors';
import ControlBar from '../components/ControlBar';
import DragBarIds from '../features/dragBar/DragBarIds';
import DragDropTypes from '../features/dragAndDrop/DragDropTypes';
import DrawerContainer from '../components/drawer/DrawerContainer';
import Inspector from '../components/inspector/Inspector';
import MenuBar from '../features/menus/MuiMenuBar';
import OverlayDropLayer from '../components/OverlayDropLayer';
import Sidebar from '../components/sidebar/Sidebar';
import WebGLView from '../components/webgl/WebGLView';
import createNavSelector from '../store/selectors/navSelector';
import undoManager from '../store/actions/UndoManager';

/**
 * Main workspace area.
 */
class Workspace extends React.Component {
  constructor(props) {
    super(props);
    this.resize = this.resize.bind(this);
  }

  resizeTimeout = null;

  resize() {
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null;
        this.reportSize();
      }, 500);
    }
  }

  reportSize() {
    const { setScreenSize } = this.props;
    setScreenSize({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.reportSize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    clearTimeout(this.resizeTimeout);
  }

  render() {
    const {
      navState: { showBlocks },
      uiOffsets: { current, max, min },
      screenSize,
      connectDropTarget,
      location
    } = this.props;

    const {
      container: containerStyle,
      menu: menuBarStyle,
      left: leftBarStyle,
      right: rightBarStyle,
      player: playerStyle,
      drawer: drawerStyle,
      leftHalf: leftHalfStyle,
      rightHalf: rightHalfStyle
    } = getGrid({
      screenSize,
      menu: current.menu,
      current: {
        ...current,
        drawer: showBlocks ? current.drawer : TAB_HEIGHT + 6
      },
      max,
      min
    });

    return connectDropTarget(
      <div>
        <Shortcuts
          targetNodeSelector={'body'}
          name={'GLOBAL'}
          global={true}
          handler={(action) => {
            if (action === 'UNDO') {
              undoManager.undo();
            } else if (action === 'REDO') {
              undoManager.redo();
            }
          }}
        >

          <Box style={containerStyle}>
            <MenuBar style={menuBarStyle} />
            <Sidebar style={leftBarStyle} />

            <Inspector style={rightBarStyle} />

            <ControlBar style={playerStyle} />

            <Route render={() => <WebGLView style={playerStyle} />} />

            <DrawerContainer style={drawerStyle} />
          </Box>
        </Shortcuts>
      </div>
    );
  }
}

Workspace.propTypes = {
  navState: PropTypes.object.isRequired,
  uiOffsets: PropTypes.object.isRequired,
  screenSize: PropTypes.object.isRequired,
  pushNavState: PropTypes.func.isRequired,
  setScreenSize: PropTypes.func.isRequired,
  setOffsets: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};
Workspace.defaultProps = {};

const createMapStateToProps = () => {
  const getNavState = createNavSelector({
    showBlocks: true
  });

  return (state, ownProps) => ({
    navState: getNavState(state, ownProps),
    uiOffsets: getUIOffsets(state),
    screenSize: getScreenSize(state)
  });
};
const mapDispatchToProps = (dispatch, { history, location }) => ({
  pushNavState: navState => dispatch(_pushNavState(history, location, navState)),
  setScreenSize: ({ width, height }) => dispatch(_setScreenSize({ width, height })),
  setOffsets: ({ id, value }) => dispatch(_setOffset({ id, value }))
});

const dropTarget = {
  canDrop: () => true,
  drop: (props, monitor) => {
    const item = monitor.getItem();
    const { id } = item;
    const { setOffsets, screenSize } = props;
    const offset = monitor.getClientOffset();

    switch (id) {
      case DragBarIds.LEFT: {
        setOffsets({ id, value: offset.x });
        break;
      }

      case DragBarIds.RIGHT: {
        setOffsets({ id, value: screenSize.width - offset.x });
        break;
      }

      case DragBarIds.DRAWER: {
        setOffsets({ id, value: screenSize.height - offset.y });
        break;
      }

      default: {
        break;
      }
    }
  }
};

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget()
});

export default withRouter(
  connect(createMapStateToProps, mapDispatchToProps)(DropTarget(DragDropTypes.DRAGBAR, dropTarget, collect)(Workspace))
);
