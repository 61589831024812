import { SET_CONTEXT } from '../actions/debugActions';

const storage = window.localStorage;
const key = '__debug__';

let initialState = {
  debugMode: true,

  context: {
    local: true,
    disabled: false,
    logging: true,
    ip: 'localhost',
    port: 4649
  }
};

if (storage) {
  const storedValue = storage.getItem(key);
  if (storedValue) {
    const parsedValue = JSON.parse(storedValue);
    initialState = {
      ...initialState,
      ...parsedValue,
      context: {
        ...initialState.context,
        ...(parsedValue.context || {})
      }
    };
  } else {
    storage.setItem(key, JSON.stringify(initialState));
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTEXT: {
      const newState = {
        ...state,
        context: action.options
      };

      storage.setItem(key, JSON.stringify(newState));

      return newState;
    }
  }

  return state;
};
