// src/js/store/actions/credentialActions.js
import { INITIALIZATION, SET_TOKEN, UPDATE_EMAIL, SET_EMAIL, VALID_EMAIL, VALID_PASSWORD, MATCHING_PASSWORDS, GET_TOKEN, VALIDATE_TOKEN, PASSWORD_RESET, HTTP_ACTION } from "../../constants/AccountActionTypes";

function httpAction(action) {
  const httpActionTemplate = {
  type: "",
  dump: false,
  endpoint: null,
  verb: "GET",
  payload: null,
  headers: []
  };
  return {
    type: HTTP_ACTION,
    actionInfo: Object.assign({}, httpActionTemplate, action)
  };
}
export function init(payload) {
  return { type: INITIALIZATION, payload }
};

export function setEmail(payload) {
  return { type: SET_EMAIL, payload };
}

export function validateEmail(payload) {
  return { type: VALID_EMAIL, payload };
}

export function validatePassword(payload) {
  return { type: VALID_PASSWORD, payload };
}

export function matchingPasswords(payload) {
  return { type: MATCHING_PASSWORDS, payload };
}

export function setToken(payload) {
  return { type: SET_TOKEN, payload };
}

export const getTokenAction = ({ email }) => httpAction({
  type: GET_TOKEN,
  endpoint: `${window.env.stargazerBaseUrl}/v1/reset/issueToken?email=${email}`
});

export function validateTokenAction(props) {
  return httpAction({
  type: VALIDATE_TOKEN,
  endpoint: `${window.env.stargazerBaseUrl}/v1/reset/validToken`,
  headers: {'Authorization': `Bearer ${props.token}`}
  });
}

export function sendPasswordResetAction(props) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${props.token}`);
  return httpAction({
  type: PASSWORD_RESET,
  verb: "POST",
  endpoint: `${window.env.stargazerBaseUrl}/v1/reset/resetPassword`,
  headers: headers,
  payload: JSON.stringify({ password: `${props.password}`})
  });
}
