// TODO: DEPRECATED FOR MATERIAL_UI
import {
 Box, Paper, Tab, Tabs
} from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';

import { getAppInfo } from '../../../store/selectors/appSelectors';
import { getInspectorClass } from './elementUtils';
import { getScenes } from '../../../store/selectors/scenesSelectors';
import { sceneById, elementById } from '../../../util/appHelpers';
import { updateElement } from '../../../store/actions/inspector/inspectorActions';
import NoInspectable from '../common/NoInspectable';
import SchemaRenderer from './MuiSchemaRenderer';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.default
  }
}));

/**
 * Wraps inspector renderers.
 */
const ElementInspector = ({
 appId, scenes, onUpdateElement, match
}) => {
  const {
    params: { sceneId, elementId }
  } = match;

  const scene = sceneById(scenes, sceneId);
  let element = scene && elementById(scene.elements, elementId);
  if (element && element.id === 'root') {
    element = { ...element, appId };
  }
  const InspectorClass = element && getInspectorClass(element);

  const [value, setValue] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      {!scene && <NoInspectable text="Invalid scene ID." />}
      {scene && !element && <NoInspectable text="Invalid element ID." />}
      {InspectorClass && (
        <React.Fragment>
          <Paper square elevation={3} className={classes.paper}>
            <Tabs value={value} onChange={handleChangeTab} variant="fullWidth" indicatorColor="primary">
              <Tab value={0} label={'Inspector'} />
              <Tab value={1} label={'Advanced'} />
            </Tabs>
          </Paper>
          <Box flex={1} display="flex" flexDirection="column" overflow="auto" className="scrollable-y">
            {value === 0 ? (
              <InspectorClass
                scene={scene}
                element={element}
                onUpdateElement={(update) => {
                  onUpdateElement({ sceneId: scene.id, elementId: element.id, update });
                }}
              />
            ) : (
              <Box p={1}>
                <SchemaRenderer element={element} scene={scene} />
              </Box>
            )}
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

ElementInspector.propTypes = {
  appId: PropTypes.string.isRequired,
  scenes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUpdateElement: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  appId: getAppInfo(state).id,
  scenes: getScenes(state)
});

const dispatchMap = {
  onUpdateElement: updateElement
};

export default withRouter(connect(mapStateToProps, dispatchMap)(ElementInspector));
