import 'babel-polyfill';

import '../css/style.scss';

import { CssBaseline } from '@material-ui/core';
import { Provider } from 'react-redux';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { createLogger } from 'redux-logger';
import { createStore, compose, applyMiddleware } from 'redux';
import { initActions, ioLoader, TrellisActions } from '@enklu/server-api';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';

import * as Sentry from '@sentry/browser';

import { SnackbarProvider } from 'notistack';
import { createBridge } from './iframeBridge';
import { log } from './util/log';
import App from './App';
import FatalError from './pages/FatalError';
import appReducers from './store/reducers/reducers';
import appSocketManager from './store/actions/AppSocketManager';
import httpMiddleware from './store/middleware/httpMiddleware';
import metrics from './store/middleware/metrics';
import spaceSocketManager from './store/actions/SpaceSocketManager';
import storeApp from './store/middleware/storeApp';
import theme from './styles/muiTheme';
import undoManager from './store/actions/UndoManager';


// Create server interface.
initActions('trellis', { loader: ioLoader, baseUrl: window.env.trellisBaseUrl, Actions: TrellisActions });

// logging
if (window.env.build === 'production') {
  log.level = 0;
} else {
  log.level = 4;
}

// create bridge for iframes
createBridge();

// create app middleware
const middleware = window.env.build !== 'production'
  ? applyMiddleware(
    thunk,
    metrics,
    storeApp,
    httpMiddleware,
    createLogger()
  )
  : applyMiddleware(
    thunk,
    metrics,
    httpMiddleware,
    storeApp
  );

// https://stackoverflow.com/questions/56215220/react-redux-error-passing-several-store-enhancers-to-createstore
/* eslint-disable no-underscore-dangle */
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable no-underscore-dangle */
const store = createStore(appReducers, composeEnhancer(middleware));
undoManager.initialize(store.dispatch);
appSocketManager.initialize(store.dispatch);
spaceSocketManager.initialize(store.dispatch);

class AppBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      eventId: null
    };
  }

  componentDidCatch(error, errorInfo) {
    console.log('CAUGHT', error, errorInfo);

    this.setState({ error });

    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);

      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    try {
      if (this.state.error) {
        return null;
      }

      return this.props.children;
    } catch (error) {
      console.log('ERROR', error);
      return null;
    }
  }
}

AppBoundary.propTypes = {
  children: PropTypes.any
};

// err reporting
if (window.env.build === 'production') {
  console.log('Setting up crash reporting.');

  Sentry.init({
    dsn: window.env.ravenDsn,
    release: window.config.version,
    environment: window.env.name,
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        beacon: true,
        console: false,
        dom: true,
        fetch: true,
        history: true,
        sentry: true,
        xhr: true
      })
    ]
  });
} else {
  console.log('Crash reporting disabled.');
}

try {
  /*
 * TODO: Fix hashes in URL for mobile deep linking & universal links
 * Hashes break mobile deep linking & universal links for embedded browsers.
 * We could try to remove hashes entirely.  Or we could try to find a workaround
 * to be able to use them with deep / universal links.
 *
 * This current implementation just makes all generated public links no longer include hashes.
 * Then when a user tries to navigate to a public link without a hash, we add one and redirect.
 *
 * It does not look great on web as it requires an extra page load.  But it does allow deep linking
 * to work on mobile until we find a better solution.
 */
  let locationURL = window.location.href;
  const appHash = '#/app/?app=';
  const appPath = '/?app=';

  if (!locationURL.includes(appHash) && locationURL.includes(appPath)) {
    locationURL = locationURL.replace(appPath, appHash);
    window.location.replace(locationURL);

    // We shouldn't actually ever reach this render... but just in case, we need to avoid the hash router
    ReactDOM.render(
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Provider store={store}>
            <BrowserRouter>
              <AppBoundary>
                <SnackbarProvider disableWindowBlurListener={true} preventDuplicate={true}>
                  <App store={store} />
                </SnackbarProvider>
              </AppBoundary>
            </BrowserRouter>
          </Provider>
        </ThemeProvider>,
        document.getElementById('app')
    );
  } else {
    ReactDOM.render(
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Provider store={store}>
            <HashRouter>
              <AppBoundary>
                <SnackbarProvider disableWindowBlurListener={true} preventDuplicate={true}>
                  <App store={store} />
                </SnackbarProvider>
              </AppBoundary>
            </HashRouter>
          </Provider>
        </ThemeProvider>,
        document.getElementById('app')
    );
  }
} catch (error) {
  // eslint-disable-next-line no-unused-vars
  Sentry.withScope((scope) => {
    const eventId = Sentry.captureException(error);

    ReactDOM.render(
      <MuiThemeProvider>
        <Provider store={store}>
          <FatalError eventId={eventId} />
        </Provider>
      </MuiThemeProvider>,
      document.getElementById('app')
    );
  });
}
