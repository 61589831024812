import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import LogRow from './MuiLogRow';
import LogFooter from './LogFooter';
import remoteAssistSocketManager from '../../store/actions/RemoteAssistSocketManager';
import { assistTerminate, assistLogClear } from '../../store/actions/remoteAssistActions';

/**
 * Displays logging from a remote connection. Maintains the socket connection for debugging.
 */
class RemoteLog extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Watches for changes to the log target, to ensure the socket is only built as needed.
     */
    static getDerivedStateFromProps(props, state) {
        if (!props.targetUser && state.user) {
            return {
                user: null
            };
        }

        if (props.targetUser && props.targetUser !== state.user) {
            props.logClear();
            remoteAssistSocketManager.connect(props.targetUser.userId, props.targetUser.deviceInfo.id);

            return {
                user: props.targetUser
            };
        }

        return state;
    }

    /**
     * Cleanup the log data from state, as well as shutting down the socket connection.
     */
    componentWillUnmount() {
        this.props.terminate();
        remoteAssistSocketManager.disconnect();
    }

    render() {
        const { messages, filteredMessages } = this.props.remoteAssist;

        return (
          <React.Fragment>
              <Box flex={1} overflow="auto" className="scrollable-y" px={1}>
                  {filteredMessages.map((message, index) => (
                    <LogRow index={index} key={index} item={message} />
                  ))}
              </Box>
              <LogFooter
                totalMessageCount={messages.length}
                totalVisibleCount={filteredMessages.length}
                logClear={this.props.logClear}
              />
          </React.Fragment>
        )
    }
}

RemoteLog.propTypes = {
    targetUser: PropTypes.object.isRequired,

    // State
    remoteAssist: PropTypes.object.isRequired,

    // Dispatch
    terminate: PropTypes.func.isRequired,
    logClear: PropTypes.func.isRequired
}

RemoteLog.defaultProps = {
    users: [],
    remoteAssist: {
        filteredMessages: []
    }
}

const mapStateToProps = state => ({
    remoteAssist: state.remoteAssist
});

const mapDispatchToProps = dispatch => ({
    terminate: _ => dispatch(assistTerminate()),
    logClear: () => dispatch(assistLogClear())
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoteLog);
