import { createSelector } from 'reselect';
import { initialGeneralSettings, initialRenderSettings } from '../reducers/globalSettingsReducer';

export const getGlobalSettings = state => state.globalSettings;

export const getGlobalSettingsIsLoaded = state => state.globalSettings.isLoaded;

const getSettingsCategory = (globalSettings, initialSettings, categoryName) => {
  // find all settings in render category
  const category = globalSettings.settings.find(category => category.name === categoryName);

  // remove settings that are not in initial state
  category.settings = category.settings
    .filter(({ name }) => initialSettings.some(setting => name === setting.name));

  // add settings that are not in saved render settings
  for (const renderSetting of initialSettings) {
    if (!category.settings.some(({ name }) => name === renderSetting.name)) {
      category.settings.push({ ...renderSetting });
    }
  }

  return category;
}

export const getRenderSettings = createSelector(
  getGlobalSettings,
  (globalSettings) => {
    return getSettingsCategory(globalSettings, initialRenderSettings, 'render');
  }
);

export const getGeneralSettings = createSelector(
  getGlobalSettings,
  (globalSettings) => {
    return getSettingsCategory(globalSettings, initialGeneralSettings, 'general');
  }
);
