import { AvatarGroup } from '@material-ui/lab';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { connect } from 'react-redux';
import { find, shuffle } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';

import { getApp } from '../../store/selectors/appSelectors';
import { getPresences } from '../../store/selectors/comboSelectors';
import { getUserProfile } from '../../store/selectors/userSelectors';
import { muiPalette } from '../../styles/muiTheme';
import EnkluAvatar from '../../components/material-ui/EnkluAvatar';
import PresenceShape from '../../interfaces/PresenceShape';

const useStyles = makeStyles(() => ({
  smallAvatar: {
    height: 30,
    width: 30,
    fontSize: 15
  }
}));

const PresenceList = ({
 userProfile, users, numItemsInRow, includeSelf, small
}) => {
  const classes = useStyles();
  const [showMax, setShowMax] = React.useState(false);

  const myUserPresence = find(users, user => user.userId === userProfile.id);
  const otherUserPresences = users.filter(user => user.userId && user.userId !== userProfile.id);

  const [randomizedColors] = React.useState(shuffle(Object.values(muiPalette.avatar)));

  const avatarsLength = includeSelf && myUserPresence ? users.length : otherUserPresences.length;

  return (
    <React.Fragment>
      <AvatarGroup
        classes={{
          avatar: small ? classes.smallAvatar : undefined
        }}
        max={showMax ? avatarsLength : numItemsInRow}
        justify="center"
      >
        {includeSelf && myUserPresence && (
          <EnkluAvatar badgeProps={{ invisible: small }} color="info" user={myUserPresence} />
        )}
        {otherUserPresences.map((userPresence, i) => (
          <EnkluAvatar
            badgeProps={{ invisible: small }}
            key={i}
            user={userPresence}
            color={randomizedColors[i % randomizedColors.length]}
          />
        ))}
      </AvatarGroup>

      {avatarsLength > numItemsInRow && (
        <IconButton
          size="small"
          onClick={() => {
            setShowMax(!showMax);
          }}
        >
          {showMax ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      )}
    </React.Fragment>
  );
};

PresenceList.defaultProps = {
  users: [],
  numItemsInRow: 5,
  includeSelf: true,
  small: false
};

PresenceList.propTypes = {
  userProfile: PropTypes.any,
  users: PropTypes.arrayOf(PresenceShape),
  customUsers: PropTypes.arrayOf(PresenceShape),
  numItemsInRow: PropTypes.number,
  includeSelf: PropTypes.bool,
  small: PropTypes.bool
};

const mapStateToProps = (state, props) => {
  let users;
  if (props.customUsers) {
    users = props.customUsers;
  } else {
    users = getPresences(state);
  }

  return {
    app: getApp(state),
    users,
    userProfile: getUserProfile(state)
  };
};

export default connect(mapStateToProps, null)(PresenceList);
