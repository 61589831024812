const DragBarDirection = {
  HORIZONTAL: 'dragBar.horizontal',
  VERTICAL: 'dragBar.vertical'
};

export const dragDirectionById = {
  left: DragBarDirection.HORIZONTAL,
  right: DragBarDirection.HORIZONTAL,
  drawer: DragBarDirection.VERTICAL
};

export default DragBarDirection;
