import PropTypes from 'prop-types';

import Devices from '../constants/userList/Devices';

export default PropTypes.shape({
  displayName: PropTypes.string.isRequired,
  thumbUrl: PropTypes.string,
  device: PropTypes.oneOf([Devices.DESKTOP, Devices.HOLOLENS, Devices.MOBILE])
});

export const newPresence = ({
  socketId = '',
  userId = '',
  name = '',
  deviceInfo = {}
}) => ({
  socketId,
  userId,
  name,
  deviceInfo
});
