import { createSelector } from 'reselect';

const getLog = state => state.log;
export const getLogLevel = createSelector(getLog, ({ level: _level }) => _level);
export const getLogShowSystem = createSelector(getLog, ({ showSystem: _showSystem }) => _showSystem);
export const getLogFilter = createSelector(getLog, ({ filter: _filter }) => _filter);
export const getLogNumMessages = createSelector(
  getLog,
  ({ messages: _messages }) => _messages.length
);
export const getLogFilteredMessages = createSelector(
  getLog,
  ({ filteredMessages: _filteredMessages }) => _filteredMessages
);
