import { withStyles, createStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';

class StandardPopover extends React.Component {
  state = {
    anchorEl: null
  };

  handleStandardPopoverOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleStandardPopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
 classes, children, popoverText, typographyProps
} = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <div
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={this.handleStandardPopoverOpen}
          onMouseLeave={this.handleStandardPopoverClose}
          className={classes.childrenContainer}
        >
          {children}
        </div>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={this.handleStandardPopoverClose}
          disableRestoreFocus
        >
          <Typography className={classes.popoverText} {...typographyProps}>
            {popoverText}
          </Typography>
        </Popover>
      </div>
    );
  }
}

StandardPopover.propTypes = {
  classes: PropTypes.object.isRequired,
  popoverText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  typographyProps: PropTypes.object
};

const styles = theme => createStyles({
    popover: {
      pointerEvents: 'none'
    },
    paper: {
      padding: theme.spacing(1)
    },
    popoverText: {
      fontFamily: 'Open Sans'
    },
    childrenContainer: {
      display: 'flex'
    }
  });

export default withStyles(styles)(StandardPopover);
