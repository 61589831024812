import React from 'react';
import PropTypes from 'prop-types';
import InternalPicker from './InternalPicker';

const style = {
  thumb: {
    width: '28px',
    height: '28px',
    border: '1px solid black'
  },

  picker: {
    zIndex: 1000,
    position: 'fixed',
    left: '50%',
    top: '50%',
    marginLeft: '-257px',
    width: '514px',
    height: '305px',
    marginTop: '-152px'
  }
};

/**
 * Component that allows users to pick a color!
 */
class ColorPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPicker: false,
      bufferColor: '#FFFFFF'
    };
  }

  renderThumb() {
    const { color } = this.props;
    const { thumb } = style;

    return (
      <div
        style={{ ...thumb, backgroundColor: color }}
        onClick={() => this.setState({
            showPicker: true,
            bufferColor: color
          })
        }
      ></div>
    );
  }

  renderPicker() {
    const { bufferColor } = this.state;
    const { picker } = style;

    return (
      <div style={picker}>
        <InternalPicker
          color={bufferColor}
          onChange={color => this.setState({
              bufferColor: color.hex
            })
          }
          onAccept={() => {
            this.setState({
              showPicker: false
            });
            this.props.onChange(
              this.state.bufferColor || {
                r: 0,
                g: 0,
                b: 0
              }
            );
          }}
          onCancel={() => {
            this.setState({
              showPicker: false
            });
          }}
        />
      </div>
    );
  }

  render() {
    const { showPicker } = this.state;

    return (
      <div>
        {this.renderThumb()}
        {showPicker && this.renderPicker()}
      </div>
    );
  }
}

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ColorPicker;
