/**
 * Keymap is in this format:
 * <Namespace>: {
 * <Action>: '<key>'
 * }
 */
export default {
  GLOBAL: {
    UNDO: {
      windows: ['ctrl+z'],
      osx: ['command+z']
    },
    REDO: {
      windows: ['ctrl+shift+z'],
      osx: ['command+shift+z']
    }
  },
  ELEMENT: {
    FOCUS: ['f', 'F'],
    ADJUST_POSITION: ['q', 'Q'],
    ADJUST_ROTATION: ['w', 'W'],
    ADJUST_SCALE: ['e', 'E'],
    TRANSFORM_SPACE: ['r', 'R'],
    DUPLICATE: ['ctrl+d']
  },
  SCRIPT: {
    SAVE: {
      windows: ['ctrl+s'],
      osx: ['command+s']
    }
  }
};
